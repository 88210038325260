// HTML Files
require('./index.html');
require('./version.html');

// CSS Files
//require('../ext/normalize.css');
//require('../ext/fontawesome-free-5.15.3-web/css/all.min.css');
require('../../css/main.css');
require('../../css/feedlok.css');
require('../../css/dashboardsElements.css');

// JS Files
require('../../js/feedlok/main.js');
