'use strict';

export class Debug{
    constructor(){
        this._logs = new Array()
        this.debugOn = false
        this.debugFilters = new Map();
    }

    /**
     * Create a message to be output in the console for debugging purposes
     * @param {string} message Message to be output to console
     * @param {string} filter Category to include the log in
     * @param {int} priority Number representing urgency - 0 => Most Urgent
     */
    log(message, filter = 'general', priority = 0){
        this._logs.push({
            'time': new Date().toLocaleString(),
            'message': message,
            'filter': filter,
            'priority': parseInt(priority)
        })

        if(this.debugOn == true){//new log and the debugger is running
            this.on()
        }
    }

    /**
     * Turn the debug system on and display all saved logs, and output the live logs as we receive them
     * @param {string} newFilter Log Category to output
     */
    on(newFilter = undefined){//will output all relevent logs saved in this._logs when called - otherwise it waits for new log to be created and re-runs to see if it matches parameters
        if(this.debugFilters.get('filter') == undefined && newFilter !== undefined){//if we dont have any current filters and we are passed a new filter
            this.debugFilters.set('filter', [newFilter])
        }else if(this.debugFilters.get('filter') !== undefined && newFilter !== undefined){//if we have filters and have a new filter
            if(!this.debugFilters.get('filter').includes(newFilter)){
                this.debugFilters.get('filter').push(newFilter)
            }
        }
        this.debugOn = true
        if(this.debugFilters.get('priority') == undefined){ this.setPriority() }
        let logsToDelete = new Array()
        let logOffset = 0
        this._logs.forEach(log => {
            //show logs
            if(this.debugFilters.get('filter') == undefined){//output all messages within priority setting - if no priority set it outputs all
                if(log.priority <= this.debugFilters.get('priority')){
                    console.log("["+log.time+"] "+log.message)
                    logsToDelete.push(logOffset)
                }
            }else{
                if(this.debugFilters.get('filter').includes(log.filter) && log.priority <= this.debugFilters.get('priority')){//check if log has filter match and prio match
                    console.log("["+log.time+"] "+log.message)
                    logsToDelete.push(logOffset)
                }
            }
            logOffset++
        });
        //REMOVE LOG AFTER SHOWING IT
        let offsetOffset = 0;//because as we remove items from the array we need to adjust for the offsets we removed...theres got to be a better way...right?
        logsToDelete.forEach(deleteLogOffset => {
            this._logs.splice(deleteLogOffset-offsetOffset, 1)
            offsetOffset++
        })
    }

    /**
     * Set the prioriry max we want to see - 0 => Most urgent
     * @param {int} priority Highest priority we want to see
     */
    setPriority(priority = Infinity){
        this.debugFilters.set('priority', priority)
    }

    /**
     * 
     * @param {string} filterName Remove a particular category so we no longer see the logs for it
     */
    removeFilter(filterName = undefined){
        if(filterName == undefined){//no specified for remove all
            this.debugFilters.delete('filter')
        }else{//specified filter to remove
            if(this.debugFilters.get('filter').includes(filterName)){//check if we actually have that filter set
                this.debugFilters.get('filter').splice(this.debugFilters.get('filter').indexOf(filterName), 1)
                if(this.debugFilters.get('filter').length == 0){ this.debugFilters.delete('filter') }//remove filter tag if we no longer have filters...will display all within priority
            }
        }
    }

    /**
     * Turn out live debug off
     */
    off(){
        this.debugOn = false
        this.debugFilters = new Map()//remove filter if we turn off debugging...this can be removed without issue...jus thought it would be nice to not have to manually remove filters
    }
}

let debug = new Debug()
window.debug = debug

export { debug };