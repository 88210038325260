'use strict';

import {LoadingOverlay} from '../ms/ms.js';
import {buildCache, buildTheme} from './main';

export class SyncPage {
	constructor() {
        this.mainContent
        this.errorBox
        this.lastSynced = Date.now()
	}

	/**
	 * Generate the button that will be added to the left menu
	 * @param {element} button HTML Element where the button will be created
	 */
	generateButton(button) {
		button.div.classList.add('navbar-items-item')
		button.icon.classList.add('navbar-items-icon')
		button.icon.classList.add('fa')
		button.icon.classList.add('fa-cog')
		button.text.classList.add('large')
		button.text.innerHTML = 'Settings'
		button.div.append(button.icon)
		button.div.append(button.text)
	}

	/**
	 * Generate the sub-navigation of the page
	 * @param {element} subnav HTML Element of the left menu where the page's subnav will be created
	 */
	generateSubnav(subnav) {//hard codes menu since it wont be dynamic...
		let changeTheme = document.createElement('div')
		changeTheme.classList.add('nav-asset-list-item')
		changeTheme.innerHTML = 'Toggle Dark Mode'
		if(localStorage.getItem('theme') == 'dark'){changeTheme.innerHTML = 'Toggle Light Mode'}
		subnav.append(changeTheme)

		changeTheme.onclick = () => {
            if(localStorage.getItem('theme') == 'dark'){
				localStorage.setItem('theme', 'light')
				changeTheme.innerHTML = 'Toggle Dark Mode'
				buildTheme()
				return
			}
			localStorage.setItem('theme', 'dark')
			changeTheme.innerHTML = 'Toggle Light Mode'
			buildTheme()
		}

        let syncAll = document.createElement('div')
		syncAll.classList.add('nav-asset-list-item')
		syncAll.innerHTML = 'Sync All Data'
		subnav.append(syncAll)

		syncAll.onclick = () => {
            LoadingOverlay.on()
			buildCache('mcos', true)
            this.lastSynced = Date.now()
            this.mainContent.innerHTML = 'Last synced: '+new Date(this.lastSynced).toLocaleString()
            LoadingOverlay.off()
		}

		let logout = document.createElement('div')
		logout.classList.add('nav-asset-list-item')
		logout.innerHTML = 'Logout'
		subnav.append(logout)

		logout.onclick = () => {
            localStorage.removeItem('user')
			window.location.replace('/')
		}
	}

	/**
	 * Creates a basic background and styling for the main content section
	 * @param {element} content Main content section of the webpage where we want to build the content
	 */
	generateContent(content) {
        content.classList.add('dashboard', 'content-page')

		let mainContentSection = document.createElement('div')
		mainContentSection.classList.add('card', 'dashboard-content')
        mainContentSection.style.display = 'block'
        mainContentSection.style.textAlign = 'center'
        mainContentSection.style.fontSize = '2rem'
		content.append(mainContentSection)

        let mainContentSectionData = document.createElement('div')
        mainContentSectionData.style.fontSize = '2rem'
		mainContentSection.append(mainContentSectionData)

        let mainContentSectionError = document.createElement('div')
        mainContentSectionError.style.fontSize = '2rem'
        mainContentSectionError.style.display = 'none'
		mainContentSection.append(mainContentSectionError)
        this.errorBox = mainContentSectionError

		this.mainContent = mainContentSectionData
        this.mainContent.innerHTML = 'Last synced: '+new Date(this.lastSynced).toLocaleString()
	}

	start(page) {
		page.getButton().div.classList.add('navbar-items-item-active')
	}

	stop(page) {
		page.getButton().div.classList.remove('navbar-items-item-active')
	}

	initCB(page) {
	}
}
