'use strict'

export class ChangeTracker{
    constructor(){
        this.changedUUIDs = {}
        this.originalData = {}
        this.changedData = {}
    }

    /**
     * See if a data set contains changes for a particular UUID
     * @param {string} type Subset of data to look in
     * @param {string} uuid UUID to check if exists
     * @returns true/false depending on if it exists or not
     */
    contains(type, uuid){
        if(this.changedUUIDs[type] == undefined){return false}
        if(this.changedUUIDs[type].includes(uuid)){
            return true
        }
        return false
    }

    /**
     * Add a data point to changes
     * @param {string} type Data subset we want to track this change in
     * @param {string} uuid UUID or identifier of the thing we changed
     * @param {*} previous Old value
     * @param {*} newest New value
     */
    addToChanged(type, uuid, previous, newest){
        if(this.changedUUIDs[type] == undefined){ this.changedUUIDs[type] = [] }
        if(this.originalData[type] == undefined){ this.originalData[type] = {} }
        if(this.changedData[type] == undefined){ this.changedData[type] = {} }

        //ALWAYS CHECK PREVIOUS TO SEE IF WE HAVE THE ORIGIN - IF NOT ADD IT
        if(this.originalData[type][uuid] == undefined){ this.originalData[type][uuid] = {} }
        Object.keys(previous).forEach((field) => {//loop through given
            if(this.originalData[type][uuid][field] == undefined){//if we do not have the data already
                this.originalData[type][uuid][field] = previous[field]//add previous data as original
                if(previous[field] == undefined){ this.originalData[type][uuid][field] = "~~DELETE~~" }//if it didnt exist before delete it when we revert - special terminology
            }
        })

        if(this.changedUUIDs[type].includes(uuid)){//already in changed so should have existing objects in other areas
            Object.keys(newest).forEach((field) => {
                this.changedData[type][uuid][field] = newest[field]
            })
        }else if(this.changedUUIDs[type].includes(uuid) == false){//no yet changed so it must have origin data from DB in it...
            this.changedUUIDs[type].push(uuid)

            if(this.changedData[type][uuid] == undefined){ this.changedData[type][uuid] = {} }
            Object.keys(newest).forEach((field) => {
                this.changedData[type][uuid][field] = newest[field]
            })
        }
    }

    /**
     * Revert the changes back to the oldest values in the passed object and return the entire object
     * @param {string} type Data subset to fetch changes from
     * @param {string} uuid UUID to fetch from the data subset
     * @param {object} completeObject The object we want to update to reflect the original value
     * @returns Reverted completeObject
     */
    revertChanges(type, uuid, completeObject){
        if(this.contains(type, uuid) == false){return completeObject}//no changes

        let changedFields = this.originalData[type][uuid]

        Object.keys(changedFields).forEach((field) => {
            if(changedFields[field] == "~~DELETE~~"){
                delete completeObject[field]
                return
            }
            completeObject[field] = changedFields[field]
        })

        this.clearChanges(type, uuid)

        return completeObject
    }

    /**
     * Check if a UUID exists in a certain subset of data - if it doest attach warning flag to defined element
     * @param {string} type Data subset to fetch changes from
     * @param {string} uuid UUID to check if it has changes
     * @param {element} attachTo Parent element to append the warning icon to
     */
    getWarning(type, uuid, attachTo){
        if(this.contains(type, uuid)){
            let alertFlag = document.createElement('div')
            alertFlag.classList.add('fa', 'fa-exclamation-circle')
            alertFlag.style.color = 'white' 
            alertFlag.style.marginLeft = '4px'
            alertFlag.title = 'This item has unsaved data'
            attachTo.append(alertFlag)
        }
    }

    /**
     * Get all current changes to a certain UUID in a data subset
     * @param {string} type Data subset to fetch changes from
     * @param {string} uuid UUID to fetch from data subset
     * @returns Object of changes in the format of: field => newValue
     */
    getChanges(type, uuid){
        if(this.changedData[type] == undefined || this.changedData[type][uuid] == undefined){return false}
        return this.changedData[type][uuid]
    }

    /**
     * Clear a particular UUID's changes from a particular subset
     * @param {string} type Data subset to clearn changes from 
     * @param {string} uuid UUID to clear changes for within subset
     * @returns 
     */
    clearChanges(type, uuid){
        if(this.changedData[type] == undefined || this.changedData[type][uuid] == undefined){return}//if there were no changes
        delete this.originalData[type][uuid]
        delete this.changedData[type][uuid]
        this.changedUUIDs[type].splice(this.changedUUIDs[type].indexOf(uuid), 1)
    }

    /**
     * Get original data to a certain UUID in a data subset
     * @param {string} type Data subset to fetch original data from
     * @param {string} uuid UUID to fetch from data subset
     * @returns Object of original values in the format of: field => oldValue
     */
    getOriginal(type, uuid){
        if(this.originalData[type] == undefined || this.originalData[type][uuid]){return false}
        return this.originalData[type][uuid]
    }
}