'use strict';

import {QReq, DOMClearChildren} from '../ms/ms.js';
import {feedlokAPI, changeTracker, overlay, millTimezone, errorHandler} from './main';

export class MillPage {
	constructor() {
		this.selectedUser = false
	}

	generateButton(button) {
		button.div.classList.add('navbar-items-item')
		button.icon.classList.add('navbar-items-icon')
		button.icon.classList.add('fa')
		button.icon.classList.add('fa-id-card')
		button.text.classList.add('large')
		button.text.innerHTML = 'Mill'
		button.div.append(button.icon)
		button.div.append(button.text)
	}

	generateSubnav(subnav) {//hard codes menu since it wont be dynamic...
		let millLink = document.createElement('div')
		millLink.classList.add('nav-asset-list-item')
		millLink.innerHTML = 'Mill Info'
		millLink.onclick = () => {
			millLink.classList.add('nav-asset-list-item-active')
			usersLink.classList.remove('nav-asset-list-item-active')
			this._buildMillInfoContent()
		}
		subnav.append(millLink)

		let usersLink = document.createElement('div')
		usersLink.classList.add('nav-asset-list-item')
		usersLink.innerHTML = 'Users'
		usersLink.onclick = () => {
			millLink.classList.remove('nav-asset-list-item-active')
			usersLink.classList.add('nav-asset-list-item-active')
			this._buildUsersContent()
		}
		subnav.append(usersLink)
	}

	generateContent(content) {
		content.classList.add('dashboard', 'content-page')

		let mainContentSection = document.createElement('div')
		mainContentSection.classList.add('card', 'dashboard-content')
		content.append(mainContentSection)

		this.mainContent = mainContentSection
	}

	/**
	 * Build the main content of the Mill Info with all of the data about our mill
	 */
	_buildMillInfoContent(){
		DOMClearChildren(this.mainContent)

		let mainGrid = document.createElement('div')
		mainGrid.classList.add('content-order-panel-grid')
		this.mainContent.append(mainGrid)

		let millMessage = document.createElement('div')
		millMessage.style.display = 'none'
		millMessage.style.gridColumn = '1/3'
		millMessage.style.textAlign = 'center'
		millMessage.style.color = 'green'
		millMessage.style.fontSize = '2rem'
		mainGrid.append(millMessage)

		let millHeader = document.createElement('div')
		millHeader.classList.add('content-title')
		millHeader.style.gridColumn = '1/3'
		millHeader.style.textAlign = 'center'
		millHeader.innerHTML = 'Mill Information: '
		mainGrid.append(millHeader)

		QReq.request(feedlokAPI.getMill()).then(resp => {
			if(resp.addr == undefined){resp.addr = {
				addr1: "",
				addr2: "",
				city: "",
				state: "",
				zip: ""
			}}

			let millName = document.createElement('div')
			millName.classList.add('content-section')
			millName.innerHTML = 'Mill Name: '
			mainGrid.append(millName)

			let millNameInput = document.createElement('input')
			millNameInput.classList.add('content-input')
			millNameInput.value = resp.name
			millName.append(millNameInput)

			let timezones = [
				'US/Alaska',
				'US/Aleutian',
				'US/Arizona',
				'US/Central',
				'US/East-Indiana',
				'US/Eastern',
				'US/Hawaii',
				'US/Indiana-Starke',
				'US/Michigan',
				'US/Mountain',
				'US/Pacific',
				'US/Samoa',
				'Canada/Atlantic',
				'Canada/Central',
				'Canada/Eastern',
				'Canada/Mountain',
				'Canada/Newfoundland',
				'Canada/Pacific',
				'Canada/Saskatchewan',
				'Canada/Yukon'
			]			

			let millTimezoneC = document.createElement('div')
			millTimezoneC.classList.add('content-section')
			millTimezoneC.innerHTML = 'Mill Timezone: '
			mainGrid.append(millTimezoneC)

			let millTimezoneInput = document.createElement('select')
			millTimezoneInput.classList.add('content-dropdown')
			millTimezoneInput.value = resp.name
			millTimezoneC.append(millTimezoneInput)

			timezones.forEach(timezone => {
				let timezoneOption = document.createElement('option')
				timezoneOption.value = timezone
				timezoneOption.innerHTML = timezone
				millTimezoneInput.append(timezoneOption)
			})

			millTimezoneInput.value = millTimezone

			let millEmail = document.createElement('div')
			millEmail.classList.add('content-section')
			millEmail.innerHTML = 'Email: '
			mainGrid.append(millEmail)

			let millEmailInput = document.createElement('input')
			millEmailInput.classList.add('content-input')
			millEmailInput.value = resp.email
			millEmail.append(millEmailInput)

			let millPhone = document.createElement('div')
			millPhone.classList.add('content-section')
			millPhone.innerHTML = 'Phone: '
			mainGrid.append(millPhone)

			let millPhoneInput = document.createElement('input')
			millPhoneInput.classList.add('content-input')
			millPhoneInput.value = resp.phone
			millPhone.append(millPhoneInput)

			let millAddressHeader = document.createElement('div')
			millAddressHeader.classList.add('content-title')
			millAddressHeader.style.gridColumn = '1/3'
			millAddressHeader.style.marginTop = '10px'
			millAddressHeader.style.textAlign = 'center'
			millAddressHeader.innerHTML = 'Mill Address: '
			mainGrid.append(millAddressHeader)

			let millAddr1 = document.createElement('div')
			millAddr1.classList.add('content-section')
			millAddr1.innerHTML = 'Address 1: '
			mainGrid.append(millAddr1)

			let millAddr1Input = document.createElement('input')
			millAddr1Input.classList.add('content-input')
			millAddr1Input.value = resp.addr.addr1
			millAddr1.append(millAddr1Input)

			let millAddr2 = document.createElement('div')
			millAddr2.classList.add('content-section')
			millAddr2.innerHTML = 'Address 2: '
			mainGrid.append(millAddr2)

			let millAddr2Input = document.createElement('input')
			millAddr2Input.classList.add('content-input')
			millAddr2Input.value = resp.addr.addr1
			millAddr2.append(millAddr2Input)

			let millCity = document.createElement('div')
			millCity.classList.add('content-section')
			millCity.innerHTML = 'City: '
			mainGrid.append(millCity)

			let millCityInput = document.createElement('input')
			millCityInput.classList.add('content-input')
			millCityInput.value = resp.addr.city
			millCity.append(millCityInput)

			let millState = document.createElement('div')
			millState.classList.add('content-section')
			millState.innerHTML = 'State: '
			mainGrid.append(millState)

			let millStateInput = document.createElement('input')
			millStateInput.classList.add('content-input')
			millStateInput.value = resp.addr.state
			millState.append(millStateInput)

			let millZip = document.createElement('div')
			millZip.classList.add('content-section')
			millZip.innerHTML = 'Zip: '
			mainGrid.append(millZip)

			let millZipInput = document.createElement('input')
			millZipInput.classList.add('content-input')
			millZipInput.value = resp.addr.zip
			millZip.append(millZipInput)

			//UPDATE
			let millUpdate = document.createElement('div')
			millUpdate.classList.add('content-button')
			millUpdate.style.gridColumn = '1/3'
			millUpdate.style.width = 'min-content'
			millUpdate.style.justifySelf = 'center'
			millUpdate.innerHTML = 'Update'
			millUpdate.onclick = () => {
				let changes = {
					'name': millNameInput.value,
					'email': millEmailInput.value,
					'phone': millPhoneInput.value,
					'timezone': millTimezoneInput.value,
					'addr': {
						'addr1': millAddr1Input.value,
						'addr2': millAddr2Input.value,
						'city': millCityInput.value,
						'state': millStateInput.value,
						'zip': millZipInput.value,
					}
				}
				

				QReq.request(feedlokAPI.putMill(changes)).then(resp => {
					millMessage.innerHTML = 'Mill information updated!'
					millMessage.style.display = 'block'
					setTimeout(()=>{
						millMessage.style.display = 'none'
					}, 3000)
				}).catch((e) => {
					errorHandler(e)
				})
			}
			mainGrid.append(millUpdate)
		}).catch((e) => {
			errorHandler(e)
		})
	}

	/**
	 * Build Users content including a list of users and main content area
	 */
	_buildUsersContent(){
		DOMClearChildren(this.mainContent)

		let mainContentGrid = document.createElement('div')
		mainContentGrid.classList.add('content-layout')
		this.mainContent.append(mainContentGrid)

		let mainContentGridTitle = document.createElement('div')
		mainContentGridTitle.classList.add('content-title')
		mainContentGridTitle.innerHTML = 'Current Users:'
		mainContentGrid.append(mainContentGridTitle)

		let mainContentGridOrderTitle = document.createElement('div')
		mainContentGridOrderTitle.classList.add('content-title')
		mainContentGridOrderTitle.style.borderRight = 'none'
		mainContentGridOrderTitle.innerHTML = 'User Information:'
		mainContentGrid.append(mainContentGridOrderTitle)

		let mainContentGridList = document.createElement('div')
		mainContentGridList.classList.add('content-list')
		mainContentGrid.append(mainContentGridList)

		QReq.request(feedlokAPI.getUsers()).then(resp => {
			resp.users.forEach((user) => {
				let userListDiv = document.createElement('div')
				userListDiv.classList.add('content-list-item')
				if(this.selectedUser !== false){
					if(user.uuid == this.selectedUser.data.uuid){ 
						userListDiv.classList.add('content-list-item-active')
						this.selectedUser.div = userListDiv
						this.buildUserChangeForm(mainContent)
					}
				}
				userListDiv.innerHTML = user.name
				userListDiv.onclick = () => {
					if (userListDiv.classList.contains('content-list-item-active')) {//if they re-click the already selected order
						userListDiv.classList.remove('content-list-item-active')
						if(this.selectedUser.data.uuid == user.uuid){ //reverify if they re-click the already selected order
							DOMClearChildren(mainContent)
							this.selectedUser = false//no order selected
						}else{ return }
					} else {//one new order clicked
						userListDiv.classList.add('content-list-item-active')
						if(this.selectedUser !== false && this.selectedUser.data.uuid !== user.uuid){//an order is already clicked - we need to remove it
							this.selectedUser.div.classList.remove('content-list-item-active')
						}
						//set the new selected order
						this.selectedUser = {}
						this.selectedUser.data = user//raw info
						this.selectedUser.div = userListDiv//raw link div
						this.buildUserChangeForm(mainContent)//load in the order info into main content area of grid
					}
				}
	
				//changeTracker.getWarning('mcos', mco.uuid, userListDiv)
				
	
				mainContentGridList.append(userListDiv)
			})
		}).catch((e) => {
			errorHandler(e)
		})

		let mainContentGridContent = document.createElement('div')
		mainContentGridContent.classList.add('content-order-panel')
		mainContentGrid.append(mainContentGridContent)

		let mainContent = document.createElement('div')
		mainContent.classList.add('content-order-panel-grid')
		mainContentGridContent.append(mainContent)

		let mainContentGridButtons = document.createElement('div')
		mainContentGridButtons.classList.add('content-list-buttons')
		mainContentGrid.append(mainContentGridButtons)

		let mainContentGridAddNewButton = document.createElement('div')
		mainContentGridAddNewButton.classList.add('content-button')
		mainContentGridAddNewButton.innerHTML = '+ Add New'
		mainContentGridAddNewButton.onclick = () => {
			overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
				let div = document.createElement('div')
				div.classList.add('overlay-container')
				overlayDiv.append(div)
	
				let title = document.createElement('div')
				title.classList.add('overlay-title')
				title.innerHTML = 'New User'
				div.append(title)
	
				let formGrid = document.createElement('div')
				formGrid.style.display = 'grid'
				formGrid.style.gridTemplateColumns = '1fr 1fr'
				formGrid.style.fontSize = '1.5rem'
				formGrid.style.marginTop = '4px'
				formGrid.style.rowGap = '4px'
				div.append(formGrid)
	
				let userName = document.createElement('div')
				userName.innerHTML = 'Name:'
				formGrid.append(userName)
	
				let userNameInputContainer = document.createElement('div')
				formGrid.append(userNameInputContainer)
	
				let userNameInput = document.createElement('input')
				userNameInput.classList.add('content-input')
				userNameInputContainer.append(userNameInput)
	
				let userUsername = document.createElement('div')
				userUsername.innerHTML = 'Username:'
				formGrid.append(userUsername)
	
				let userUsernameInputContainer = document.createElement('div')
				formGrid.append(userUsernameInputContainer)
	
				let userUsernameInput = document.createElement('input')
				userUsernameInput.classList.add('content-input')
				userUsernameInputContainer.append(userUsernameInput)

				let userLevel = document.createElement('div')
				userLevel.innerHTML = 'Level:'
				formGrid.append(userLevel)
	
				let userLevelInputContainer = document.createElement('div')
				formGrid.append(userLevelInputContainer)
	
				let userLevelInput = document.createElement('select')
				userLevelInput.classList.add('content-input')
				userLevelInputContainer.append(userLevelInput)

				//USER LEVEL OPTIONS
				let userLevelOption = document.createElement('option')
				userLevelOption.value = "1"
				userLevelOption.innerHTML = "User"
				userLevelInput.append(userLevelOption)

				let adminLevelOption = document.createElement('option')
				adminLevelOption.value = "0"
				adminLevelOption.innerHTML = "Admin"
				userLevelInput.append(adminLevelOption)

				userLevelInput.value = "1"

				let userPassword = document.createElement('div')
				userPassword.innerHTML = 'Password:'
				formGrid.append(userPassword)
	
				let userPasswordInputContainer = document.createElement('div')
				formGrid.append(userPasswordInputContainer)
	
				let userPasswordInput = document.createElement('input')
				userPasswordInput.classList.add('content-input')
				userPasswordInput.type = 'password'
				userPasswordInputContainer.append(userPasswordInput)

				let userConfirmPassword = document.createElement('div')
				userConfirmPassword.innerHTML = 'Confirm Password:'
				formGrid.append(userConfirmPassword)
	
				let userConfirmPasswordInputContainer = document.createElement('div')
				formGrid.append(userConfirmPasswordInputContainer)
	
				let userConfirmPasswordInput = document.createElement('input')
				userConfirmPasswordInput.classList.add('content-input')
				userConfirmPasswordInput.type = 'password'
				userConfirmPasswordInputContainer.append(userConfirmPasswordInput)
	
				let buttonContainer = document.createElement('div')
				buttonContainer.style.gridColumn = '1/3'
				formGrid.append(buttonContainer)
	
				let submitButton = document.createElement('div')
				submitButton.classList.add('overlay-button')
				submitButton.innerHTML = 'Create'
				submitButton.onclick = () => {
					if(userNameInput.value.length == 0){
						alert('A name is required!')
						return
					}else if(userUsernameInput.value.length == 0){
						alert('A username is required!')
						return
					}else if(userPasswordInput.value !== userConfirmPasswordInput.value){
						alert('Passwords do no match!')
						return
					}
	
					let newUser = {
						'name': userNameInput.value,
						'username': userUsernameInput.value,
						'password': userPasswordInput.value,
						'level': parseInt(userLevelInput.value)
					}
	
					QReq.request(feedlokAPI.postUsers(newUser)).then(resp => {
						if(this.selectedUser == false){
							this.selectedUser = {}
							this.selectedUser.data = {}
						}
						this.selectedUser.data = resp
						this._buildUsersContent()
						this.buildUserChangeForm(mainContent)
						overlay.close()
					}).catch((resp) => {
						console.log(resp)
						if(resp.status == 409){
							alert('Username is already taken!')
						}
					})
				}
				buttonContainer.append(submitButton)
	
				overlay.show()
			})
		}
		mainContentGridButtons.append(mainContentGridAddNewButton)
	}

	/**
	 * Create the form for editing a user - assign to a defined parent
	 * @param {element} div Parent element to attach the form to
	 */
	buildUserChangeForm(div){
		DOMClearChildren(div)

		let notices = document.createElement('div')
		notices.style.gridColumn = '1/3'
		notices.style.textAlign = 'center'
		notices.style.fontSize = '2rem'
		notices.style.color = 'green'
		notices.style.display = 'none'
		div.append(notices)

		let userName = document.createElement('div')
		userName.classList.add('content-section')
		userName.style.marginTop = '10px'
		userName.innerHTML = 'Name: '
		div.append(userName)

		let userNameInput = document.createElement('input')
		userNameInput.classList.add('content-input')
		userNameInput.value = this.selectedUser.data.name
		userNameInput.onkeyup = () => {
			changeTracker.addToChanged('users', this.selectedUser.data.uuid, {'name': this.selectedUser.data.name}, {'name': userNameInput.value})
			this.selectedUser.data.name = userNameInput.value
		}
		userName.append(userNameInput)

		let userUsername = document.createElement('div')
		userUsername.classList.add('content-section')
		userUsername.style.marginTop = '10px'
		userUsername.innerHTML = 'Username: '
		div.append(userUsername)

		let userUsernameInput = document.createElement('input')
		userUsernameInput.classList.add('content-input')
		userUsernameInput.disabled = 'true'
		userUsernameInput.value = this.selectedUser.data.username
		userUsername.append(userUsernameInput)

		let userLevel = document.createElement('div')
		userLevel.classList.add('content-section')
		userLevel.innerHTML = 'Level: '
		div.append(userLevel)

		let userLevelInput = document.createElement('select')
		userLevelInput.classList.add('content-input')
		userLevelInput.onchange = () => {
			changeTracker.addToChanged('users', this.selectedUser.data.uuid, {'level': this.selectedUser.data.level}, {'level': parseInt(userLevelInput.value)})
			this.selectedUser.data.level = userLevelInput.value
		}
		userLevel.append(userLevelInput)

		//USER LEVEL OPTIONS
		let userLevelOption = document.createElement('option')
		userLevelOption.value = "1"
		userLevelOption.innerHTML = "User"
		userLevelInput.append(userLevelOption)

		let adminLevelOption = document.createElement('option')
		adminLevelOption.value = "0"
		adminLevelOption.innerHTML = "Admin"
		userLevelInput.append(adminLevelOption)

		userLevelInput.value = this.selectedUser.data.level

		let userPassword = document.createElement('div')
		userPassword.classList.add('content-section')
		userPassword.innerHTML = 'Password: '
		div.append(userPassword)

		let userPasswordInput = document.createElement('input')
		userPasswordInput.classList.add('content-input')
		userPasswordInput.type = 'password'
		userPasswordInput.onkeyup = () => {
			changeTracker.addToChanged('users', this.selectedUser.data.uuid, {'password': ''}, {'password': userPasswordInput.value})
		}
		userPassword.append(userPasswordInput)

		let userConfirmPassword = document.createElement('div')
		userConfirmPassword.classList.add('content-section')
		userConfirmPassword.innerHTML = 'Confirm Password: '
		div.append(userConfirmPassword)

		let userConfirmPasswordInput = document.createElement('input')
		userConfirmPasswordInput.classList.add('content-input')
		userConfirmPasswordInput.type = 'password'
		userConfirmPassword.append(userConfirmPasswordInput)

		let userOldPasswordInput
		if(this.selectedUser.data.uuid == JSON.parse(localStorage.getItem('user')).uuid){
			let userOldPassword = document.createElement('div')
			userOldPassword.classList.add('content-section')
			userOldPassword.innerHTML = 'Old Password: '
			div.append(userOldPassword)

			userOldPasswordInput = document.createElement('input')
			userOldPasswordInput.classList.add('content-input')
			userOldPasswordInput.type = 'password'
			userOldPassword.append(userOldPasswordInput)
		}

		let userUpdate = document.createElement('div')
		userUpdate.classList.add('content-button')
		userUpdate.style.gridColumn = '1/3'
		userUpdate.style.width = 'min-content'
		userUpdate.style.justifySelf = 'center'
		userUpdate.innerHTML = 'Update'
		userUpdate.onclick = () => {
			let changes = changeTracker.getChanges('users', this.selectedUser.data.uuid)
			if(changes == false){return}

			if(changes.password !== undefined){
				let passwordObject = {'newpasswd': userPasswordInput.value}

				if(userPasswordInput.value.length > 0){
					if(userPasswordInput.value !== userConfirmPasswordInput.value){
						alert('Passwords do not match!')
						return
					}else if(this.selectedUser.data.uuid == JSON.parse(localStorage.getItem('user')).uuid){
						if(userOldPasswordInput.value == ''){
							alert('You must enter your old password to change password!')
							return
						}
						passwordObject['oldpasswd'] = userOldPasswordInput.value
					}

					QReq.request(feedlokAPI.postUsersUserIDChangepasswd(this.selectedUser.data.uuid, passwordObject)).then(resp => {
						
					}).catch((e) => {
						errorHandler(e)
					})
				}
				delete changes.password
			}

			QReq.request(feedlokAPI.putUsersUserID(this.selectedUser.data.uuid, changes)).then(resp => {
				changeTracker.clearChanges('users', this.selectedUser.data.uuid)

				notices.innerHTML = 'Updated successfully!'
				notices.style.display = 'block'
				setTimeout(() => {
					notices.style.display = 'none'
				}, 3000)
			}).catch((e) => {
				errorHandler(e)
			})
		}
		div.append(userUpdate)

		let userDelete = document.createElement('div')
		userDelete.classList.add('content-button')
		userDelete.style.gridColumn = '1/3'
		userDelete.style.width = 'min-content'
		userDelete.style.justifySelf = 'center'
		userDelete.innerHTML = 'Delete'
		userDelete.onclick = () => {
			let deleteConfirm = confirm("Are you sure you would like to delete this user?")

			if(deleteConfirm){
				QReq.request(feedlokAPI.deleteUserUserID(this.selectedUser.data.uuid)).then(resp => {
					this.selectedUser = false
					this._buildUsersContent()
				}).catch((e) => {
					errorHandler(e)
				})
			}
		}
		div.append(userDelete)
	}

	start(page) {
		page.getButton().div.classList.add('navbar-items-item-active')
	}

	stop(page) {
		page.getButton().div.classList.remove('navbar-items-item-active')
	}

	initCB(page) {
	}
}
