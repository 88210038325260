'use strict';

import {Button} from './ui/button';

// Exports
// DOMClearChildren: function
// DOMCreateElement: function
// GetSelectedOptions: function
// GroupNameToUsername: function
// Sleep: function
// base64ToByteArray: function
// bytesToBase64: function
// TemplateString: function
// Mutex: class
// BatchRequester: class
// QReq: Instance of BatchRequester(2)
// OverlayHandler: class
// LoadingOverlay: An object to manage an overlay
//                 to help with popups for user interaction
// ErrorOverlay: An object to manage an overlay to 
//               help with Errors
// Footer: class
// Apps: class
// Navigation: class
// Page: class

/* List of Functions
 *   DOMClearChildren(HTMLElement) // Safely and efficiently removes all children from an html element
 *   DOMCreateElement(Desc) HTMLElement // Creates an HTMLElement from standardized JSON format
 *   async Sleep(Milliseconds) // Sleeps
 *   TempalteString(tmpl, var) string // Attempts to apply var to tmpl '$(var)'
 *
 * Classes:
 *   BatchRequester(maxParallel requests) // HTTP is limited to 6 concurrent connections
 *     QReq = new BatchRequester(2) // Hit the ground running, maybe you don't need more
 *   OverlayHandler({modal,close,content}) // Easy Popups
 *   _loadingOverlay
 *     LoadingOverlay // Generic Loading Spinner to Cover Content
 *   _errorHandler
 *     ErrorOverlay // Generic way to handle errors
 *   Footer // Creates a footer
 *   Apps // Creates a method for accessing and switching between users Apps and Tools
 */

/* DOMClearChildren(el)
 * @author Xavier Loose 2020
 * @description: This will remove all children from the HTMLElement
 * @args
 *   el - HTMLElement
 *
 * Why: Helper/Old Bug
 * while(el.firstChild){el.removeChild(el.firstChild)} can get stuck in an infinite
 * loop
 */
export function DOMClearChildren(el) {
	for (let i = el.children.length; i > 0; i--) {
		el.children[i - 1].remove()
	}
}

/* DOMCreateElement(element) el
 * @author Xavier Loose 2020
 * @description:
 */
export function DOMCreateElement(element) {
	if ('type' in element) {
		switch (element.type) {
		default:
			let el = document.createElement(element.type)
			if ('attr' in element) {
				Object.keys(element.attr).forEach((key) => {
					el.setAttribute(key, element.attr[key])
				})
			}

			if ('id' in element) {
				el.id = element.id
			}

			if ('name' in element) {
				el.name = element.name
			}

			if ('classList' in element) {
				element.classList.forEach((c) => {
					el.classList.add(c)
				})
			}

			if ('innerHTML' in element) {
				el.innerHTML = element.innerHTML
			}

			if ('src' in element) {
				el.src = element.src
			}

			if ('style' in element) {
				el.style = element.style
			}

			if ('placeholder' in element) {
				el.placeholder = element.placeholder
			}

			if ('inputType' in element) {
				el.type = element.inputType
			}

			if ('value' in element) {
				el.value = element.value
			}

			if (typeof element.onclick == 'function') {
				el.onclick = () => {
					element.onclick()
				}
			}

			if (typeof element.ondblclick == 'function') {
				el.ondblclick = () => {
					element.ondblclick()
				}
			}

			return el
		}
	}

	let nullel = document.createElement('div')
	nullel.style.display = 'none'
	return nullel
}

export function GetSelectedOptions(el) {
	let result = []
	let options = el && el.options
	let opt

	for (let i = 0; i<options.length; i++) {
		opt = options[i]

		if (opt.selected) {
			result.push(opt.value)
		}
	}
	return result
}

export function GroupNameToUsername(name) {
	return name.replace(/\s/g, '').toLowerCase()
}

/* Sleep(dur) promise
 * @author Xavier Loose 2018
 * @description Creates a promise that will resolve after dur Milliseconds
 * @args
 *   dur - Duration to sleep in Milliseconds
 * @ret
 *   A promise that will resolve ater give duration
 */
export function Sleep(dur) {
	return new Promise(resolve => setTimeout(resolve, dur))
}

export function base64ToByteArray(str) {
	return Uint8Array.from(atob(str), c => c.charCodeAt(0))
}

const base64abc = [
	"A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
	"N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
	"a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
	"n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
	"0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "/"
];

export function bytesToBase64(bytes) {
	let result = '', i, l = bytes.length;
	for (i = 2; i < l; i += 3) {
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
		result += base64abc[((bytes[i - 1] & 0x0F) << 2) | (bytes[i] >> 6)];
		result += base64abc[bytes[i] & 0x3F];
	}
	if (i === l + 1) { // 1 octet yet to write
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[(bytes[i - 2] & 0x03) << 4];
		result += "==";
	}
	if (i === l) { // 2 octets yet to write
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
		result += base64abc[(bytes[i - 1] & 0x0F) << 2];
		result += "=";
	}
	return result;
}

// NEEDS WORK
// Var format {varName: realVar}
export function TemplateString(tmpl, v) {
	let str = ''

	if (tmpl.length > 3 && tmpl[0] == '$' && tmpl[1] == '(' && tmpl[tmpl.length - 1] == ')') {
		let tmplVar = tmpl.substring(2, tmpl.length - 1)
		//str = _TemplateStringRecurse
	}

	return str
}

/* Mutex
 * @author Xavier Loose 2020
 *
 * Why: Helper
 * Javascript is single threaded in nature. In true multi-threaded world, there
 * are Semaphore and Mutexes. This is a basic implementation that seems to work well.
 * Since we utilize promises and are working on data it can be such that 2 things
 * may want to work on the same data or other race like conditions. While we may
 * not have the same race issue in Javascript(it's purely single-threaded)
 * we may be able to see similar affects, improper data or missing data
 */
export class Mutex {
	constructor(val) {
		this.val = val
		this._state = false
	}
	async lock() {
		while(this._state) {
			Sleep(100)
		}
		this._state = true
	}
	unlock() {
		this._state = false
	}
}

/* BatchRequester
 * @author Xavier Loose 2018
 *
 * Why: Helper
 * According to HTTP/1.1 a client can only have 6 active connections to a server.
 * Typically this isn't an issue but during FeedLok Development we found that we
 * can have serious performance penalties for spawning off large numbers of request.
 * The goal of this is to make it easier for developers to not care about that by
 * throttling parallel requests.
 */
export class BatchRequester {
	constructor(nReqs) {
		this._n = nReqs
		this._mutex = new Mutex()
	}

	request(req) {
		let reqUrl = `${req.url.host}${req.url.path}${req.url.query}`
		let reqConfig = {
			method: req.method,
			headers: req.headers,
			body: req.body
		}
		switch(req.respType) {
			case 'arraybuffer':
				return new Promise((rslv, rjct) => {
					fetch(reqUrl, reqConfig).then(resp => {
						if (resp.status == 200) {
							rslv(resp.arrayBuffer())
						} else {
							rjct(resp)
						}
					}).catch((err) => {
						rjct(err)
					})
				})
				break
			case 'blob':
				return new Promise((rslv, rjct) => {
					fetch(reqUrl, reqConfig).then(resp => {
						if (resp.status == 200) {
							rslv(resp.blob())
						} else {
							rjct(resp)
						}
					}).catch((err) => {
						rjct(err)
					})
				})
				break
			case 'formdata':
				return new Promise((rslv, rjct) => {
					fetch(reqUrl, reqConfig).then(resp => {
						if (resp.status == 200) {
							rslv(resp.formData())
						} else {
							rjct(resp)
						}
					}).catch((err) => {
						rjct(err)
					})
				})
				break
			case 'json':
				return new Promise((rslv, rjct) => {
					fetch(reqUrl, reqConfig).then(resp => {
						if (resp.status == 200) {
							rslv(resp.json())
						} else {
							rjct(resp)
						}
					}).catch((err) => {
						rjct(err)
					})
				})
				break
			case 'text':
				return new Promise((rslv, rjct) => {
					fetch(reqUrl, reqConfig).then(resp => {
						if (resp.status == 200) {
							rslv(resp.text())
						} else {
							rjct(resp)
						}
					}).catch((err) => {
						rjct(err)
					})
				})
				break
			default:
				return new Promise((rslv, rjct) => {
					fetch(reqUrl, reqConfig).then(resp => {
						if (resp.status == 200) {
							rslv(resp)
						} else {
							rjct(resp)
						}
					})
				})
				break
		}
	}

	async batch(reqBatch, callback) {
		await this._mutex.lock()
		this._batch(reqBatch, callback, [])
	}

	_batch(reqBatch, callback, _returnBatch) {
		if (reqBatch.length == 0) {
			callback(_returnBatch)
			this._mutex.unlock()
			return
		}

		let reqs = []

		for (let i = 0; i < reqBatch.length && i < this._n; i++) {
			reqs.push(this.request(reqBatch[i]))
		}

		Promise.all(reqs).then(resps => {
			resps.forEach((resp) => {
				_returnBatch.push(resp)
			})
			if (reqBatch.length < this._n) {
				this._batch(reqBatch.slice(reqBatch.length, reqBatch.length), callback, _returnBatch)
			} else {
				this._batch(reqBatch.slice(this._n, reqBatch.length), callback, _returnBatch)
			}
		})
	}
}

/* QReq = new BatchRequester(2)
 * @author Xavier Loose 2019
 * Why: Helper
 * To encourage use and adoption of BatchRequester a basic one that consomes
 * at most 2 of the 6 active connections is available to the developer
 */
export let QReq = new BatchRequester(2)

/* OverlayHandler
 * @author Xavier Loose 2019
 *
 */
export class OverlayHandler {
	constructor(overlay) {
		this._overlay = overlay
		this._inuse = new Mutex()
		this._overlay.close.style.display = 'none'
	}

	async getOverlay() {
		await this._inuse.lock()
		let contentBarrier = DOMCreateElement({type:'div'})
		this._overlay.content.append(contentBarrier)
		return contentBarrier
	}

	show(closecb) {
		this._overlay.close.style.display = 'grid'
		this._overlay.close.onclick = () => {
			this._close()
		}

		if (typeof closecb == 'function') {
			this._overlay.close.onclick = () => {
				closecb()
				this._close()
			}
		}

		this._overlay.modal.style.display = 'grid'
	}

	close() {
		this._close()
	}

	_close() {
		this._overlay.modal.style.display = 'none'
		DOMClearChildren(this._overlay.content)
		this._inuse.unlock()
	}
}

/* _loadingOverlay
 * @author Xavier Lose 2019
 * internal class not meant to be used unless you are working on LoadingOverlay
 */
class _loadingOverlay {
	constructor() {
		this.mainDiv = document.createElement('div')
		this.mainDiv.id = 'loadingOverlay'
		this.mainDiv.classList.add('modal')

		document.body.append(this.mainDiv)

		this.spinnerDiv = document.createElement('div')
		this.spinnerDiv.classList.add('spinner-ms')
		this.mainDiv.append(this.spinnerDiv)

		this.textDiv = document.createElement('div')
		this.textDiv.classList.add('loading-text')
		this.textDiv.innerHTML = ''
		this.mainDiv.append(this.textDiv)
	}

	setTimer(time){
		this.on()
		setTimeout(() => {
			this.off()
		}, time)
	}

	setText(text = ''){
		this.textDiv.innerHTML = text
	}

	on() {
		this.mainDiv.style.display = 'grid'
	}

	off() {
		this.mainDiv.style.display = 'none'
	}

	state(state) {
		switch(state) {
		case 'on':
			this.on()
			break
		case 'off':
			this.off()
			break
		default:
			this.off()
		}
	}
}

/* LoadingOverlay - Generic Loading Spinner to cover content
 * @author Xavier Loose 2019
 */
export let LoadingOverlay = new _loadingOverlay()

/* _errorHandler
 * @author Xavier Lose 2019
 * internal class not meant to be used unless you are working on ErrorOverlay
 */
class _errorHandler {
	constructor(errors) {
		this._errors = errors
		this._overlay = {
			modal:DOMCreateElement({
				type:'div',
				id:'errorModal',
				classList: ['modal']
			}),
			close:DOMCreateElement({
				type: 'div',
				id: 'overlayClose',
				classList: ['modal-close', 'noselect', 'nowrap'],
				innerHTML: 'Close'
			}),
			content:DOMCreateElement({
				type: 'div',
				id: 'errorContent',
			})
		}
		this._overlayHandler = new OverlayHandler(this._overlay)

		this._overlay.modal.append(this._overlay.close)
		this._overlay.modal.append(this._overlay.content)
		document.body.append(this._overlay.modal)
	}

	registerError(error, msg, cb) {
		this._errors.set(error, {msg:msg, cb:cb})
	}

	throw(err, meta) {

	}

	thworError(msg, cb) {

	}

	_display(error) {
		DOMClearChildren(this._overlay.content)
		this._overlay.close.onclick = () => {
			this._overlay.parent.style.display = 'none'
		}
		this._overlay.content.append(this._generateErrorForm())
		this._overlay.parent.style.display = 'grid'
	}

	_generateErrorForm(error) {
		let errorDiv = document.createElement('div')
		errorDiv.classList.add('error')
		errorDiv.classList.add('card')

		let errorHdr = document.createElement('div')
		errorHdr.classList.add('error-header')
		errorHdr.innerHTML = `Error!`
		errorDiv.append(errorHdr)

		let errorDescription = document.createElement('div')
		errorDescription.classList.add('error-description')
		errorDescription.innerHTML = 'Error Description'
		errorDiv.append(errorDescription)

		let errorButton = document.createElement('div')
		errorButton.classList.add('error-button')
		errorButton.classList.add('noselect')
		errorButton.classList.add('nowrap')
		errorButton.innerHTML = `Continue`
		errorDiv.append(errorButton)

		return errorDiv
	}
}

/* ErrorOverlay - Generic way to handle errors
 * @author Xavier Loose
 */
export let ErrorOverlay = new _errorHandler()

/* Footer
 * @author Xavier Loose 2019
 */
export class Footer {
	constructor(info) {
		this._info = info
		this._footer = new DOMCreateElement({
			type:'footer',
			id: 'footer',
			classList: ['nowrap']
		})

		this._text = new DOMCreateElement({
			type: 'div',
			innerHTML: this._info.text
		})

		this._appscontrol = new DOMCreateElement({
			type:'div',
			style: 'text-align:left;'
		})

		this._appinfo = new DOMCreateElement({
			type: 'div',
			style: 'justify-self:right;text-align:right;cursor:pointer;',
			innerHTML: `${this._info.app}: ${this._info.vers}`,
			ondblclick: () => {
				window.location.href = `${location.pathname}/version`
			}
		})

		if (typeof this._info.customVersCB == 'function') {
			this._appinfo.ondblclick = () => {
				this._info.customVersCB()
			}
		}

		this._footer.append(this._appscontrol)
		this._footer.append(this._text)
		this._footer.append(this._appinfo)

		document.body.append(this._footer)
	}

	setText(txt) {
		this._info.text = txt
		this._text.innerHTML = txt
	}

	show()  {
		this._footer.style.display = 'grid'
	}

	hide() {
		//this._footer.style.display = 'none'
	}
}

/* Apps
 * @author Xavier Loose 2019
 *
 */
export class Apps {
	constructor(msacc, overlay, error) {
		this._msacc = msacc
		this._overlay = overlay
		this._error = error

		this._button = new Button(
			{
				"div": {
					type:"div",
					classList: [
						'navbar-items-item',
					]
				},
				"icon": {
					type:"i",
					classList: [
						'fa',
						'fa-th'
					]
				},
				"text": {
					type:"div",
					classList: ['large'],
					innerHTML: 'Apps'
				}
			},
			() => {this.launchAppDrawer()}
		)
	}

	getNavButton() {
		return this._button
	}

	async launchAppDrawer(appSwitchCB) {
		this._launchAppDrawer(appSwitchCB)
	}

	_launchAppDrawer(cb) {
		this._overlay.getOverlay().then((overlayDiv) => {
			this._populateOverlayDiv(overlayDiv, cb)
			this._overlay.show()
		})
	}

	_populateOverlayDiv(overlayDiv, cb) {
		let products = this._msacc.products()

		products.forEach((product) => {
			switch(product) {
			case 'MobileStarLegacy':
				overlayDiv.append(DOMCreateElement({
					type:"div",
					style:"background:#F0F0F0;color:#000000;padding:8px;font-size:2rem;border-radius:4px;cursor:pointer",
					innerHTML:"MobileStar Legacy"
				}))
				break
			}
		})
	}
}

export class Navigation {
	/* constructor(nav, content)
	 * nav - Navigation div
	 * content - Content div
	 */
	constructor(nav, content, opts) {
		this._nav = nav
		this._content = content
		this._pages = new Map()
		this._navbuttons = new Map()
		//this._activePageRunning = false
		this._activePage = ""
		if (typeof opts != 'undefined') {
			if (opts.subnav) {
				this._subnav = opts.subnav
			}
		}
	}

	/* addPage(ref, page)
   * page is required to have the following functions and variables
	 * start()
	 * stop()
	 * getButton() returns div that navigation will attach to nav
	 * getContent() returns div that navigation will attach to content
	 */
	addPage(ref, page, setID = false) {
		let pageButton = page.getButton().div
		//Josiah added to test some prototype functioning for msmanagement - below code along with setID parameter added!
		if(setID == true){
			pageButton.id = ref+"-page-button"
		}
		//end of what Josiah ended
		pageButton.onclick = () => {
			if (this._activePage.length > 0) {
				this._pages.get(this._activePage).stop()
			}
			this._activePage = ref
			page.start()
		}
		this._nav.append(pageButton)
		this._content.append(page.getContent())

		if (typeof this._subnav != 'undefined') {
			this._subnav.append(page.getSubNav())
		}

		page.stop()
		this._pages.set(ref, page)
	}

	removePage(ref) {
		if (this._pages.has(ref)) {
			this._pages.delete(ref)
		}
	}

	loadPage(ref) {
		if (this._activePage.length > 0) {
			this._pages.get(this._activePage).stop()
		}
		this._activePage = ref
		this._pages.get(ref).start()
	}

	addNavButton(ref, button) {
		this._nav.append(button.div)
		this._navbuttons.set(ref, button)
	}

	show() {
		this._nav.style.display = 'grid'
		this._content.style.display = 'grid'
	}

	hide() {
		this._nav.style.display = 'none'
		this._content.style.display = 'none'
	}

	showNav() {
		this._nav.style.display = 'grid'
	}

	hideNav() {
		this._nav.style.display = 'none'
	}

	restartPage() {
		if (this._activePage.length > 0) {
			if (this._activePageRunning) {
				this._pages.get(this._activePage).stop()
			}
			this._pages.get(this._activePage).start()
			this._activePageRunning = true
		}
	}

	start() {
		if (this._activePage.length > 0 && !this._activePageRunning) {
			this._pages.get(this._activePage).start()
			this._activePageRunning = true
		}
	}

	stop() {
		if (this._activePage.length > 0 && this._activePageRunning) {
			this._pages.get(this._activePage).stop()
			this._activePageRunning = false
		}
	}
}

export class Page {
	constructor(pageHandler) {
		this._ph = pageHandler
		this._inited = false
		this._button = {
			div: document.createElement('div'),
			icon: document.createElement('i'),
			text: document.createElement('div')
		}
		this._content = DOMCreateElement({
			type:"div",
			style:"display:none"
		})

		this._subnav = DOMCreateElement({
			type:"div",
			style:"display:grid;height:min-content"
		})

		this._subnavused = false

		if (typeof this._ph != 'undefined') {
			if (typeof this._ph.generateButton == 'function') {
				this._ph.generateButton(this._button)
			}

			if (typeof this._ph.generateSubnav == 'function') {
				this._ph.generateSubnav(this._subnav)
				this._subnavused = true
			}

			if (typeof this._ph.generateContent == 'function') {
				this._ph.generateContent(this._content)
			}

			if (typeof this._ph.initCB == 'function') {
				this._initcb = this._ph.initCB
			}

			if (typeof this._ph.setNavReference == 'function') {
				this._setNavReference = this._ph.setNavReference
			}
		}

		if (typeof this._initcb == 'undefined') {
			this._inited = true
		}
	}
	start() {
		if (!this._inited) {
			this._ph.initCB(this)
			this._inited = true
		}

		this._content.style.display = 'grid'
		this._subnav.style.display = 'grid'
		this._ph.start(this)
	}
	stop() {
		this._content.style.display = 'none'
		this._subnav.style.display = 'none'
		this._ph.stop(this)
	}
	getButton() {
		return this._button
	}
	getContent() {
		return this._content
	}
	getSubNav() {
		return this._subnav
	}
	getSelf() {
		return this._ph
	}
}