'use strict';

import {QReq, DOMClearChildren} from '../ms/ms.js';
import {Filter} from '../libraries/filter';
import {debug} from '../ms/debug';
import {buildDataMaps, _bins, _farms, _fdos2, _mcos2, _owners, _trailers, _training, feedlokAPI, globalCache, changeTracker, overlay, millTimezone, errorHandler} from './main';
import {MCO} from './mco.js';
import {FDO} from './fdo.js';

export class OrdersPage {
	constructor() {
		/**
		 * HTML Element where the main content of the page will be
		 */
		this.mainContent
		/**
		 * Object containing information about the MCO order we are viewing so we can re-load if user leaves and comes back - false means no MCO being viewed
		 */
		this.selectedMCOOrder = false
		this.mcoSortSettings = {sort: 'number'}
		/**
		 * Object containing information about the FDO order we are viewing so we can re-load if user leaves and comes back - false means no FDO being viewed
		 */
		this.selectedFDOOrder = false
		this.fdoSortSettings = {sort: 'number'}
		/**
		 * Object that stores HTML elements related to different portions of the UI
		 */
		this._divs = {}
		/**
		 * Object storing the main subnav button so we can change styling when swapping between pages
		 */
		this._subnavButtons = {}
		/**
		 * Class containing the filter rules and function to make filering simple for mcos
		 */
		this._filterClassMCO = new Filter()
		/**
		 * Class containing the filter rules and function to make filering simple for fdos
		 */
		this._filterClassFDO = new Filter()

		//LOADOUT TESTING
		this.selectedLoadoutFDO = false
	}

	/**
	 * Generate the button that will be added to the left menu
	 * @param {element} button HTML Element where the button will be created
	 */
	generateButton(button) {
		button.div.classList.add('navbar-items-item')
		button.icon.classList.add('navbar-items-icon')
		button.icon.classList.add('fa')
		button.icon.classList.add('fa-inbox')
		button.text.classList.add('large')
		button.text.innerHTML = 'Orders'
		button.div.append(button.icon)
		button.div.append(button.text)
	}

	/**
	 * Generate the sub-navigation of the page
	 * @param {element} subnav HTML Element of the left menu where the page's subnav will be created
	 */
	generateSubnav(subnav) {//hard codes menu since it wont be dynamic...
		let mcosLink = document.createElement('div')
		mcosLink.classList.add('nav-asset-list-item')
		mcosLink.innerHTML = 'MCO\'s'
		subnav.append(mcosLink)

		mcosLink.onclick = () => {
			QReq.request(feedlokAPI.getMCOrders()).then(resp => {
				globalCache.mergeCacheListItems('mcos', resp.orders, 'uuid', {
					updated: (definingFieldValue) => {
						changeTracker.clearChanges('mcos', definingFieldValue)
					},
					finished: () => {
						buildDataMaps()

						if(this.selectedMCOOrder !== false){//reset data
							this.selectedMCOOrder.data = _mcos2[this.selectedMCOOrder.data.uuid].getAllData()
						}

						debug.log('MCO Link Clicked')
						DOMClearChildren(this.mainContent)
						mcosLink.classList.add('nav-asset-list-item-active')
						mcosLinkRefresh.style.display = 'inline-block'
						fdosLink.classList.remove('nav-asset-list-item-active')
						fdosLinkRefresh.style.display = 'none'
						loadoutLink.classList.remove('nav-asset-list-item-active')
						loadoutLinkRefresh.style.display = 'none'
						this.generateContentGrid()
					}
				})
			})
		}

		let mcosLinkRefresh = document.createElement('div')
		mcosLinkRefresh.classList.add('fa', 'fa-redo')
		mcosLinkRefresh.style.display = 'none'
		mcosLinkRefresh.style.marginLeft = '8px'
		mcosLink.append(mcosLinkRefresh)

		let fdosLink = document.createElement('div')
		fdosLink.classList.add('nav-asset-list-item')
		fdosLink.innerHTML = 'FDO\'s'
		subnav.append(fdosLink)

		fdosLink.onclick = () => {
			QReq.request(feedlokAPI.getFDOrders()).then(resp => {
				globalCache.mergeCacheListItems('fdos', resp.orders, 'uuid', {
					updated: (definingFieldValue) => {
						changeTracker.clearChanges('fdos', definingFieldValue)
					},
					finished: () => {
						buildDataMaps()

						if(this.selectedFDOOrder !== false){//reset data
							this.selectedFDOOrder.data = _fdos2[this.selectedFDOOrder.data.uuid].getAllData()
						}

						debug.log('FDO Link Clicked')
						DOMClearChildren(this.mainContent)
						mcosLink.classList.remove('nav-asset-list-item-active')
						mcosLinkRefresh.style.display = 'none'
						fdosLink.classList.add('nav-asset-list-item-active')
						fdosLinkRefresh.style.display = 'inline-block'
						loadoutLink.classList.remove('nav-asset-list-item-active')
						loadoutLinkRefresh.style.display = 'none'
						this.generateContentGrid('FDO')
					}
				})
			})
		}

		let fdosLinkRefresh = document.createElement('div')
		fdosLinkRefresh.classList.add('fa', 'fa-redo')
		fdosLinkRefresh.style.display = 'none'
		fdosLinkRefresh.style.marginLeft = '8px'
		fdosLink.append(fdosLinkRefresh)

		let loadoutLink = document.createElement('div')
		loadoutLink.classList.add('nav-asset-list-item')
		loadoutLink.innerHTML = 'Loadout'
		subnav.append(loadoutLink)

		loadoutLink.onclick = () => {
			QReq.request(feedlokAPI.getTrailers()).then(resp => {
				globalCache.mergeCacheListItems('trailers', resp.trailers, 'uuid', {
					updated: (definingFieldValue) => {
						changeTracker.clearChanges('trailers', definingFieldValue)
					},
					finished: () => {
						buildDataMaps()

						QReq.request(feedlokAPI.getFDOrders()).then(resp => {
							globalCache.mergeCacheListItems('fdos', resp.orders, 'uuid', {
								updated: (definingFieldValue) => {
									changeTracker.clearChanges('fdos', definingFieldValue)
								},
								finished: () => {
									buildDataMaps()
		
									debug.log('Loadout Link Clicked')
									DOMClearChildren(this.mainContent)
									//this._filterClass = new Filter()
									mcosLink.classList.remove('nav-asset-list-item-active')
									mcosLinkRefresh.style.display = 'none'
									fdosLink.classList.remove('nav-asset-list-item-active')
									fdosLinkRefresh.style.display = 'none'
									loadoutLink.classList.add('nav-asset-list-item-active')
									loadoutLinkRefresh.style.display = 'inline-block'
									this.generateContentGrid('loadout')
								}
							})
						})
					}
				})
			})
		}

		let loadoutLinkRefresh = document.createElement('div')
		loadoutLinkRefresh.classList.add('fa', 'fa-redo')
		loadoutLinkRefresh.style.display = 'none'
		loadoutLinkRefresh.style.marginLeft = '8px'
		loadoutLink.append(loadoutLinkRefresh)

		this._subnavButtons = {
			mco: mcosLink,
			fdo: fdosLink,
			loadout: loadoutLink
		}
	}

	/**
	 * Regenerate the grid in the main content section and populate titles of that grid based on type
	 * @param {*} type Which subpage we are currently viewing - MCO's, FDO's, Loadout, etc...
	 */
	generateContentGrid(type = 'MCO'){//basic layout grid for the sections we will need to edit
		debug.log('Main content grid created - type: '+type)
		let mainContentGrid = document.createElement('div')
		mainContentGrid.classList.add('content-layout')
		this.mainContent.append(mainContentGrid)

		let mainContentGridMCOTitle = document.createElement('div')
		mainContentGridMCOTitle.classList.add('content-title')
		mainContentGridMCOTitle.innerHTML = 'Available Orders:'
		mainContentGrid.append(mainContentGridMCOTitle)
		this._divs.listTitle = mainContentGridMCOTitle

		let mainContentGridOrderTitle = document.createElement('div')
		mainContentGridOrderTitle.classList.add('content-title')
		mainContentGridOrderTitle.style.borderRight = 'none'
		mainContentGridOrderTitle.innerHTML = 'Select an order...'
		mainContentGrid.append(mainContentGridOrderTitle)
		this._divs.title = mainContentGridOrderTitle

		let mainContentGridMCOList = document.createElement('div')
		mainContentGridMCOList.classList.add('content-list')
		mainContentGrid.append(mainContentGridMCOList)
		this._divs.list = mainContentGridMCOList

		let mainContentGridMCO = document.createElement('div')
		mainContentGridMCO.classList.add('content-order-panel')
		mainContentGrid.append(mainContentGridMCO)

		let mainContent = document.createElement('div')
		mainContent.classList.add('content-order-panel-grid')
		mainContentGridMCO.append(mainContent)
		this._divs.content = mainContent

		let mainContentGridMCOButtons = document.createElement('div')
		mainContentGridMCOButtons.classList.add('content-list-buttons')
		mainContentGrid.append(mainContentGridMCOButtons)
		this._divs.listButtons = mainContentGridMCOButtons

		let mainContentGridMCOAddNewButton = document.createElement('div')
		mainContentGridMCOAddNewButton.classList.add('content-button')
		mainContentGridMCOAddNewButton.style.gridColumn = '1/3'
		mainContentGridMCOAddNewButton.style.marginBottom = '0'
		mainContentGridMCOAddNewButton.innerHTML = '+ Add New'

		let mainContentGridMCOExportButton = document.createElement('div')
		mainContentGridMCOExportButton.classList.add('content-button')
		mainContentGridMCOExportButton.onclick = () => {
			debug.log('Export button clicked - export type: '+type)
			let dataString = ""
			switch(type){
				case "MCO":
					dataString = 'ID,UUID,Creation Date,Name,Owner,FDO UUID\n'
					globalCache.getCacheList('mcos').forEach((mco) => {
						if(mco.assignedTo == undefined || mco.assignedTo == ""){ mco.assignedTo = "" }
						let ownerName = ""
						if(mco.owner !== undefined && mco.owner !== "" && mco.owner !== null){ ownerName = _owners[mco.owner].name }

						dataString += mco.customid+','+mco.uuid+','+new Date(mco.created*1000).toLocaleDateString('en-US', {timezone: millTimezone})+','+mco.name+','+ownerName+','+mco.assignedTo+'\n'
					})
					break
				case "FDO":
					dataString = 'ID,UUID,Creation Date,Name,MCO Count,Override,Trailer\n'
					globalCache.getCacheList('fdos').forEach((fdo) => {
						if(fdo.override == undefined){ fdo.override = "" }
						if(fdo.mcos == undefined){fdo.mcos = []}

						dataString += fdo.customid+','+fdo.uuid+','+new Date(fdo.created*1000).toLocaleDateString('en-US', {timezone: millTimezone})+','+fdo.name+','+fdo.mcos.length+','+fdo.override+','+fdo.trailer+'\n'
					})
					break
			}
			
			debug.log('Export file contents created')


			let csvContent = "data:text/csv;charset=utf-8,"+dataString
			var encodedUri = encodeURI(csvContent);
			var link = document.createElement("a")
			link.setAttribute("href", encodedUri)
			link.setAttribute("download", type.toLocaleLowerCase()+"Export.csv")
			document.body.appendChild(link) // Required for FF
			link.click()
			document.body.removeChild(link)
			debug.log('Export file downloaded')
		}
		mainContentGridMCOExportButton.innerHTML = 'Export'

		let mainContentGridMCOImportButton = document.createElement('div')
		mainContentGridMCOImportButton.classList.add('content-button')
		mainContentGridMCOImportButton.onclick = () => {
			debug.log('Import button clicked - import type: '+type)
			overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
				let div = document.createElement('div')
				div.classList.add('overlay-container')
				overlayDiv.append(div)

				let title = document.createElement('div')
				title.classList.add('overlay-title')
				title.innerHTML = 'Import Data'
				div.append(title)

				let errorLog = document.createElement('div')
				errorLog.style.paddingTop = '4px'
				div.append(errorLog)

				let fileInput = document.createElement('input')
				fileInput.style.paddingTop = '4px'
				fileInput.type = 'file'
				fileInput.required = true
				fileInput.onchange = (event) => {
					event.preventDefault()
					processImport(type, event, errorLog, (newEntries) => {
						debug.log('Import file submitted - Processing...')
						if(type == 'MCO'){
							newEntries.forEach((orderObject) => {
								debug.log('Attempting import with data: '+JSON.stringify(orderObject))

								let existingOrder = globalCache.getCacheList('mcos').filter(order => order.customid == parseInt(orderObject.customid))
								let ownerList = globalCache.getCacheList('owners').filter(owner => owner.customid == parseInt(orderObject.owner))
								if(ownerList.length !== 1 && orderObject.owner.length-1 > 0){
									debug.log('Import line skipped due to an invalid owner being specified')
									alert('The creation of MCO "'+orderObject.name+'" has been aborted due to the specified owner not existing!')
									return
								}

								orderObject.date = new Date(orderObject.date).getTime()/1000
								orderObject.owner = ""
								if(ownerList.length == 1){
									debug.log('Existing owner\'s uuid has been populated')
									orderObject.owner = ownerList[0].uuid
								}

								if(existingOrder.length == 1){
									debug.log('Import has been detected as an update of existing MCO: '+JSON.stringify(existingOrder[0]))
									QReq.request(feedlokAPI.putMCOrderOrderID(existingOrder[0].uuid, orderObject)).then(resp => {
										debug.log('MCO updated successfully...')
										Object.keys(orderObject).forEach(field => {
											debug.log('Updating MCO\'s "'+field+'" field to '+orderObject[field])
											_mcos[resp.uuid][field] = orderObject[field]
										})
										this._populateMCOOrderList()
									})
								}else if(existingOrder.length == 0){//new order
									debug.log('Import has been detected as a new MCO')
									QReq.request(feedlokAPI.postMCOrders(orderObject)).then(resp => {
										_mcos2[resp.uuid] = new MCO(resp)
										_mcos2[resp.uuid].setAllData(resp)
										_mcos2[resp.uuid].setFarms([])
										globalCache.setNewCacheListItem('mcos', resp)
										debug.log('MCO created succesfully and added to cache: '+JSON.stringify(resp))
										this._populateMCOOrderList()
									})
								}else{
									debug.log('Import line skipped due to having too many matching "customid"s in existing MCOs')
									alert('The editing of MCO "'+orderObject.name+'" has been canceled due to multiple matching IDs')
								}
							})
						}else{
							newEntries.forEach((orderObject) => {
								debug.log('Attempting import with data: '+JSON.stringify(orderObject))
								let existingOrder = globalCache.getCacheList('fdos').filter(order => order.customid == parseInt(orderObject.customid))

								let trailerList = globalCache.getCacheList('trailers').filter(trailer => trailer.customid == parseInt(orderObject.trailer))
								if(trailerList.length !== 1 && orderObject.trailer.length-1 > 0){
									debug.log('Import line skipped due to an invalid trailer being specified')
									alert('The creation of FDO "'+orderObject.name+'" has been aborted due to the specified trailer not existing!')
									return
								}

								orderObject.trailer = ""
								if(trailerList.length == 1){
									debug.log('Existing trailer\'s uuid has been populated')
									orderObject.trailer = trailerList[0].uuid
								}

								if(existingOrder.length == 1){
									debug.log('Import has been detected as an update of existing FDO: '+JSON.stringify(existingOrder[0]))
									QReq.request(feedlokAPI.putFDOrderOrderID(existingOrder[0].uuid, orderObject)).then(resp => {
										//_fdos[resp.uuid] = resp
										Object.keys(orderObject).forEach(field => {
											_fdos[resp.uuid][field] = orderObject[field]
										})
										this._populateFDOOrderList()
									})
								}else if(existingOrder.length == 0){//new order
									debug.log('Import has been detected as a new FDO')
									QReq.request(feedlokAPI.postFDOrders(orderObject)).then(resp => {
										_fdos2[resp.uuid] = new FDO(resp)
										_fdos2[resp.uuid].setAllData(resp)
										_fdos2[resp.uuid].setEarliestMCODate(0)

										globalCache.setNewCacheListItem('fdos', resp)
										debug.log('FDO created succesfully and added to cache: '+JSON.stringify(resp))
										this._populateFDOOrderList()
									})
								}else{
									debug.log('Import line skipped due to having too many matching "customid"s in existing FDOs')
									alert('The editing of FDO "'+orderObject.name+'" has been canceled due to multiple matching IDs')
								}
							})
						}
					})
				}
				div.append(fileInput)
	
				overlay.show()
			})
		}
		mainContentGridMCOImportButton.innerHTML = 'Import'

		if(type == 'MCO'){
			this._divs.listTitle.innerHTML = 'Available MCO\'s:'

			//button management
			this._divs.listButtons.style.display = 'grid'
			this._divs.listButtons.style.gridTemplateColumns = '1fr 1fr'
			DOMClearChildren(this._divs.listButtons)
			this._divs.listButtons.append(mainContentGridMCOAddNewButton)
			this._divs.listButtons.append(mainContentGridMCOExportButton)
			this._divs.listButtons.append(mainContentGridMCOImportButton)
			debug.log('Added "+ Add New", "Export", and "Import" buttons to left-side list')
			mainContentGridMCOAddNewButton.onclick = () => {
				debug.log('Add new button clicked - type: '+type)
				this.generateNewMCOOverlay()
			}

			//create the data
			this._populateMCOOrderList()//after we make the grid and know where the list is we need to populate the list
			if(this.selectedMCOOrder !== false){ debug.log('Loading pre-selected MCO\'s order content - MCO UUID: '+this.selectedMCOOrder.data.uuid); this._buildMCOOrderContent() }
			return
		}else if(type == 'FDO'){
			this._divs.listTitle.innerHTML = 'Available FDO\s:'

			//button management
			this._divs.listButtons.style.display = 'grid'
			this._divs.listButtons.style.gridTemplateColumns = '1fr 1fr'
			DOMClearChildren(this._divs.listButtons)
			this._divs.listButtons.append(mainContentGridMCOAddNewButton)
			this._divs.listButtons.append(mainContentGridMCOExportButton)
			this._divs.listButtons.append(mainContentGridMCOImportButton)
			debug.log('Added "+ Add New", "Export", and "Import" buttons to left-side list')
			mainContentGridMCOAddNewButton.onclick = () => {
				debug.log('Add new button clicked - type: '+type)
				this.generateNewFDOOverlay()
			}

			//create data
			this._populateFDOOrderList()
			if(this.selectedFDOOrder !== false){ debug.log('Loading pre-selected FDO\'s order content - FDO UUID: '+this.selectedFDOOrder.data.uuid); this._buildFDOOrderContent() }
		}else if(type == 'loadout'){
			mainContentGridMCO.style.gridColumn = '2'
			mainContentGridMCO.style.gridRow = '2/4'
			this._divs.content.style.gridColumn = '2'
			this._divs.content.style.gridRow = '2/4'
			this._divs.listTitle.innerHTML = 'Select a Trailer:'
			this._divs.listButtons.style.display = 'none'
			debug.log('Buttons hidden on left-side list')
			this._populateTrailerList()
		}
	}

	/**
	 * Generate overlay to allow the creation of a new MCO
	 */
	generateNewMCOOverlay(){
		debug.log('Creating new MCO overlay...')
		overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
			let div = document.createElement('div')
			div.classList.add('overlay-container')
			overlayDiv.append(div)

			let title = document.createElement('div')
			title.classList.add('overlay-title')
			title.innerHTML = 'New MCO'
			div.append(title)

			let formGrid = document.createElement('div')
			formGrid.style.display = 'grid'
			formGrid.style.gridTemplateColumns = '1fr 1fr'
			formGrid.style.fontSize = '1.5rem'
			formGrid.style.marginTop = '4px'
			formGrid.style.rowGap = '4px'
			div.append(formGrid)

			let orderName = document.createElement('div')
			orderName.innerHTML = 'Name:'
			formGrid.append(orderName)

			let orderNameInputContainer = document.createElement('div')
			formGrid.append(orderNameInputContainer)

			let orderNameInput = document.createElement('input')
			orderNameInput.classList.add('content-input')
			orderNameInputContainer.append(orderNameInput)

			let orderDate = document.createElement('div')
			orderDate.innerHTML = 'Delivery Date:'
			formGrid.append(orderDate)

			let orderDateInputContainer = document.createElement('div')
			formGrid.append(orderDateInputContainer)

			let orderDateInput = document.createElement('input')
			orderDateInput.type = 'date'
			orderDateInput.style.padding = '4px'
			orderDateInput.classList.add('date-input')
			orderDateInputContainer.append(orderDateInput)

			let orderCustomID = document.createElement('div')
			orderCustomID.innerHTML = 'Custom ID:'
			formGrid.append(orderCustomID)

			let orderCustomIDInputContainer = document.createElement('div')
			formGrid.append(orderCustomIDInputContainer)

			let orderCustomIDInput = document.createElement('input')
			orderCustomIDInput.classList.add('content-input')
			orderCustomIDInputContainer.append(orderCustomIDInput)

			let buttonContainer = document.createElement('div')
			buttonContainer.style.gridColumn = '1/3'
			formGrid.append(buttonContainer)

			let submitButton = document.createElement('div')
			submitButton.classList.add('overlay-button')
			submitButton.innerHTML = 'Create'
			submitButton.onclick = () => {
				debug.log('Submit button clicked')
				if(orderNameInput.value.length == 0){
					debug.log('Submission rejected due to no name being specified')
					alert('A name is required!')
					return
				}

				let newDate = new Date(orderDateInput.value)

				let newOrder = {
					'name': orderNameInput.value,
					'customid': orderCustomIDInput.value,
					'date': parseInt((newDate.getTime())/1000),
				}

				debug.log('Submission accepted - new MCO information: '+JSON.stringify(newOrder))

				QReq.request(feedlokAPI.postMCOrders(newOrder)).then(resp => {
					_mcos2[resp.uuid] = new MCO(resp)
					_mcos2[resp.uuid].setAllData(resp)
					_mcos2[resp.uuid].setFarms([])
					globalCache.setNewCacheListItem('mcos', resp)
					if(this.selectedMCOOrder == false){this.selectedMCOOrder = {}}
					this.selectedMCOOrder.data = _mcos2[resp.uuid].getAllData()
					debug.log('New MCO created and added to cache: '+JSON.stringify(resp))
					this._populateMCOOrderList()
					this._buildMCOOrderContent()
					overlay.close()
				})
			}
			buttonContainer.append(submitButton)

			overlay.show()
		})
	}

	/**
	 * Generate overlay to allow the creation of a new FDO
	 */
	generateNewFDOOverlay(){
		debug.log('Creating new FDO overlay...')
		overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
			let div = document.createElement('div')
			div.classList.add('overlay-container')
			overlayDiv.append(div)

			let title = document.createElement('div')
			title.classList.add('overlay-title')
			title.innerHTML = 'New FDO'
			div.append(title)

			let formGrid = document.createElement('div')
			formGrid.style.display = 'grid'
			formGrid.style.gridTemplateColumns = '1fr 1fr'
			formGrid.style.fontSize = '1.5rem'
			formGrid.style.marginTop = '4px'
			formGrid.style.rowGap = '4px'
			div.append(formGrid)

			let orderName = document.createElement('div')
			orderName.innerHTML = 'Name:'
			formGrid.append(orderName)

			let orderNameInputContainer = document.createElement('div')
			formGrid.append(orderNameInputContainer)

			let orderNameInput = document.createElement('input')
			orderNameInput.classList.add('content-input')
			orderNameInputContainer.append(orderNameInput)

			let orderCustomID = document.createElement('div')
			orderCustomID.innerHTML = 'Custom ID:'
			formGrid.append(orderCustomID)

			let orderCustomIDInputContainer = document.createElement('div')
			formGrid.append(orderCustomIDInputContainer)

			let orderCustomIDInput = document.createElement('input')
			orderCustomIDInput.classList.add('content-input')
			orderCustomIDInputContainer.append(orderCustomIDInput)

			let buttonContainer = document.createElement('div')
			buttonContainer.style.gridColumn = '1/3'
			formGrid.append(buttonContainer)

			let submitButton = document.createElement('div')
			submitButton.classList.add('overlay-button')
			submitButton.innerHTML = 'Create'
			submitButton.onclick = () => {
				debug.log('Submit button clicked')
				if(orderNameInput.value.length == 0){
					debug.log('Submission rejected due to no name being specified')
					alert('A name is required!')
					return
				}

				let newOrder = {
					'name': orderNameInput.value,
					'customid': orderCustomIDInput.value,
				}

				debug.log('Submission accepted - new FDO information: '+JSON.stringify(newOrder))

				QReq.request(feedlokAPI.postFDOrders(newOrder)).then(resp => {
					_fdos2[resp.uuid] = new FDO(resp)
					_fdos2[resp.uuid].setAllData(resp)
					_fdos2[resp.uuid].setEarliestMCODate(0)
					globalCache.setNewCacheListItem('fdos', resp)
					if(this.selectedFDOOrder == false){this.selectedFDOOrder = {}}
					this.selectedFDOOrder.data = _fdos2[resp.uuid].getAllData()
					debug.log('New FDO created and added to cache: '+JSON.stringify(resp))
					this._populateFDOOrderList()
					this._buildFDOOrderContent()
					overlay.close()
				})
			}
			buttonContainer.append(submitButton)

			overlay.show()
		})
	}

	/**
	 * Removes everything from content section and list and resets titles
	 */
	_resetOrderContent(){//remove everything from content section...we reuse this in multiple places so made a quick function
		this._divs.title.innerHTML = 'Select an order...'
		DOMClearChildren(this._divs.content)
		debug.log('Order content cleared and title reset')
	}

	/**
	 * Builds a list of farms that a specified owner owns with selections 
	 * @param {element} attachTo HTML Element to attach the farm options to
	 * @param {string} owner UUID of the farm owner we want to build the selection for
	 * @param {function} onchangeCallback Function to execute once we have a change - this can be used to update UI if we select a farm
	 * @returns List of UUIDs of the selected farms in the list through the callback
	 */
	_buildFarmSelect(attachTo, owner, onchangeCallback){//build the farm dropdown selection based on the owner that user has selected
		debug.log('Building farm selection list...')
		DOMClearChildren(attachTo)

		let orderContentFarmSearch = document.createElement('input')
		orderContentFarmSearch.classList.add('content-input')
		orderContentFarmSearch.style.display = 'inline'
		orderContentFarmSearch.placeholder = 'Search...'
		orderContentFarmSearch.onkeyup = () => {
			debug.log('MCO list search triggered...')
			let farmDivs = attachTo.querySelectorAll('div.farm-checkbox-item')//get all of the divs in "machineList" div which contains asset list

			for (var i = 0; i < farmDivs.length; i++) {//loop through all divs
				if(!farmDivs[i].innerText.toLocaleLowerCase().includes(orderContentFarmSearch.value.toLocaleLowerCase())){//see if innerHMTL include search value
					farmDivs[i].style.display = 'none'//hide since we were checking if includes = false
				}else{
					farmDivs[i].style.display = 'block'//this is the default for this particular element
				}
			}
		}
		attachTo.append(orderContentFarmSearch)

		let ownerFarms = globalCache.getCacheList('farms').filter(farm => farm.owner == owner)

		ownerFarms.forEach((farm) => {
			debug.log('Adding '+farm.name+' to list')
			let orderContentFarmDiv = document.createElement('div')
			orderContentFarmDiv.classList.add('farm-checkbox-item')
			attachTo.append(orderContentFarmDiv)

			let orderContentFarmCheckbox = document.createElement('input')
			orderContentFarmCheckbox.type = 'checkbox'
			orderContentFarmCheckbox.checked = this.selectedMCOOrder.data.farms.includes(farm.uuid)
			orderContentFarmCheckbox.onchange = () => {
				debug.log('Farm "'+farm.name+'"s selection option has been changed')
				let oldFarms = []
				this.selectedMCOOrder.data.farms.forEach((farmUUID) => {oldFarms.push(farmUUID)})

				if(orderContentFarmCheckbox.checked == false){//remove from selected
					debug.log('Farm "'+farm.name+'" has been removed')
					this.selectedMCOOrder.data.farms.splice(this.selectedMCOOrder.data.farms.indexOf(farm.uuid), 1)
				}else{//add to selected
					debug.log('Farm "'+farm.name+'" has been selected')
					this.selectedMCOOrder.data.farms.push(farm.uuid)
				}
				changeTracker.addToChanged('mcos', this.selectedMCOOrder.data.uuid, {'farms': oldFarms}, {'farms': this.selectedMCOOrder.data.farms})
				debug.log('Farm selection changes have been sent to changeTracker')
				onchangeCallback(this.selectedMCOOrder.data.farms)//returns all of the selected farms in the entire list
			}
			orderContentFarmDiv.append(orderContentFarmCheckbox)

			let orderContentFarmLabel = document.createElement('span')
			orderContentFarmLabel.classList.add('display-data')
			orderContentFarmLabel.innerHTML = ' '+farm.name
			orderContentFarmDiv.append(orderContentFarmLabel)
		})
	}

	/**
	 * Builds a filter with specified settings
	 * @param {*} sort What value the list is sorted by
	 */
	_buildFilterList(){//build the filter div
		debug.log('Building MCO filter list settings...')

		this._filterClassMCO.clearFilterDiv()//build entirely new filter
		let ownerSectionSettings = {
			filterID: 'owner',
			title: 'Owners',
			availableFilters: globalCache.getCacheList('owners'),
			labelKey: 'name',
			identifierKey: 'uuid',
			dataAlias: 'owner',
			type: 0,//we check the availableFilters list against what we have -> check 1 (our value) to many (availableFilters)
			onFilterChange: () => {this._populateMCOOrderList(true)}
		}
		this._filterClassMCO.createFilterSection(ownerSectionSettings)
		debug.log('Owners added to the filter')

		let farmSectionSettings = {
			filterID: 'farm',
			title: 'Farms',
			availableFilters: globalCache.getCacheList('farms'),
			labelKey: 'name',
			identifierKey: 'uuid',
			dataAlias: 'farms',
			type: 1,//we check what we have against a list of the available -> check many (our values - such as farms list) to many (availableFilters)
			onFilterChange: () => {this._populateMCOOrderList(true)}
		}
		this._filterClassMCO.createFilterSection(farmSectionSettings)
		debug.log('Farms added to the filter')

		this._filterClassMCO.buildFilterDiv()
	}

	/**
	 * Build FDO Filter dropdown list
	 */
	_buildFilterListFDO(){//build the filter div
		debug.log('Building FDO filter list settings...')

		this._filterClassFDO.clearFilterDiv()//build entirely new filter
		let trailerSectionSettings = {
			filterID: 'trailers',
			title: 'Trailers',
			availableFilters: globalCache.getCacheList('trailers'),
			labelKey: 'name',
			identifierKey: 'uuid',
			dataAlias: 'trailer',
			type: 0,//we check the availableFilters list against what we have -> check 1 (our value) to many (availableFilters)
			onFilterChange: () => {this._populateFDOOrderList(true)}
		}
		this._filterClassFDO.createFilterSection(trailerSectionSettings)
		debug.log('Trailers added to the filter')

		this._filterClassFDO.buildFilterDiv()
	}

	_showContentAlert(callback = ()=>{}){
		debug.log('Showing alert panel in content')
		this._divs.alert.style.display = 'block'
		callback()
	}

	_hideContentAlert(){
		debug.log('Hiding alert panel in content')
		this._divs.alert.style.display = 'none'
	}

	/**
	 * Creates a basic background and styling for the main content section
	 * @param {element} content Main content section of the webpage where we want to build the content
	 */
	generateContent(content) {
		debug.log('Generating orders content...')

		content.classList.add('dashboard', 'content-page')

		let mainContentSection = document.createElement('div')
		mainContentSection.classList.add('card', 'dashboard-content')
		content.append(mainContentSection)

		this.mainContent = mainContentSection
	}

	start(page) {
		page.getButton().div.classList.add('navbar-items-item-active')
	}

	stop(page) {
		page.getButton().div.classList.remove('navbar-items-item-active')
	}

	initCB(page) {
	}

	/***************************************************************************************MCO*********************************************************************************/
	/**
	 * Builds out the list on the left side of the main content and sorts list by specified field
	 * @param {string} sort Defines what field we want to sort by
	 * @param {bool} filtersOpen Boolean that determines whether we want to show filter or not on regeneration
	 */
	_populateMCOOrderList(filtersOpen = false){//create the left side list of MCOs
		debug.log('Populating MCO list with sort of "'+this.mcoSortSettings.sort+'" and filtersOpen of "'+filtersOpen+'"')

		DOMClearChildren(this._divs.list)

		switch(this.mcoSortSettings.sort){
			case 'number':
				globalCache.getCacheList('mcos').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -(a.name.toLowerCase() === b.name.toLowerCase()) ? ((a.date > b.date) ? 1 : -1) : -1 )
				debug.log('MCO cache sorted by "name" (asc)')
				break;
			case 'date':
				globalCache.getCacheList('mcos').sort((a, b) => (a.date > b.date) ? 1 : -(a.date === b.date) ? ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) : -1 )
				debug.log('MCO cache sorted by "date" (asc)')
				break;
		}

		//SORT
		let sortBy = document.createElement('div')
		sortBy.innerHTML = 'Sort By: '
		sortBy.style.color = '#fff'
		sortBy.style.textAlign = 'end'
		sortBy.style.paddingBottom = '4px'
		this._divs.list.append(sortBy)

		let sortSelect = document.createElement('select')
		sortSelect.classList.add('content-dropdown')
		sortSelect.onchange = () => {
			debug.log('MCO list sort changed...')
			this.mcoSortSettings.sort = sortSelect.value
			this._populateMCOOrderList(filtersOpen)//rebuild list to apply sort
		}
		sortBy.append(sortSelect)

		let sortNumberOption = document.createElement('option')
		sortNumberOption.value = 'number'
		sortNumberOption.innerHTML = 'Name'
		sortSelect.append(sortNumberOption)

		let sortDeliverOption = document.createElement('option')
		sortDeliverOption.value = 'date'
		sortDeliverOption.innerHTML = 'Date'
		sortSelect.append(sortDeliverOption)

		sortSelect.value = this.mcoSortSettings.sort

		let filters = document.createElement('span')
		filters.innerHTML = 'Filters '
		filters.classList.add('filters-button')
		if(Object.keys(this._filterClassMCO.filters).length > 0){filters.classList.add('filters-button-active')}
		filters.onclick = () => {
			debug.log('Showing MCO filter list...')

			if(Object.keys(this._filterClassMCO.filters).length > 0){filters.classList.add('filters-button-active')}else{filters.classList.remove('filters-button-active')}

			if(filtersDropdown.style.display == 'none'){//on open of filter section
				filtersDropdown.style.display = 'block'
				filtersOpen = true
				this._buildFilterList()//build the filter list
				return
			}
			filtersOpen = false//if we close we want to make sure it doesnt reopen if we reload of sort application or something like that...
			filtersDropdown.style.display = 'none'//close filter section
		}
		sortBy.append(filters)

		let searchRow = document.createElement('div')
		searchRow.style.display = 'grid'
		searchRow.style.gridTemplateColumns = '1fr min-content'
		this._divs.list.append(searchRow)

		let listSearch = document.createElement('input')
		listSearch.classList.add('content-input')
		listSearch.style.width = 'calc(100% - 6px)'
		listSearch.style.marginBottom = '4px'
		listSearch.placeholder = 'Search...'
		searchRow.append(listSearch)

		let searchButton = document.createElement('div')
		searchButton.classList.add('content-button')
		searchButton.style.marginTop = '0'
		searchButton.style.marginRight = '0'
		searchButton.innerHTML = 'Search'
		searchButton.onclick = () => {
			debug.log('MCO list search triggered...')
			let assetDivs = this._divs.list.querySelectorAll('div.content-list-item')//get all of the divs in "machineList" div which contains asset list

			for (var i = 0; i < assetDivs.length; i++) {//loop through all divs
				if(!assetDivs[i].innerText.toLocaleLowerCase().includes(listSearch.value.toLocaleLowerCase())){//see if innerHMTL include search value
					assetDivs[i].style.display = 'none'//hide since we were checking if includes = false
				}else{
					assetDivs[i].style.display = 'block'//this is the default for this particular element
				}
			}
		}
		searchRow.append(searchButton)

		let filtersArrow = document.createElement('span')
		filtersArrow.classList.add('fa', 'fa-chevron-down')
		filters.append(filtersArrow)

		let filtersDropdown = document.createElement('div')
		filtersDropdown.classList.add('filters-dropdown')
		filtersDropdown.style.display = 'none'//by default we hide
		filtersDropdown.style.color = 'var(--content-color)'
		this._filterClassMCO.setParent(filtersDropdown)
		if(filtersOpen == true){ debug.log('Showing MCO filter list due to passed parameter'); filtersDropdown.style.display = 'block'; this._buildFilterList() }//if we want to show after repopulating (when changing filters)
		sortBy.append(filtersDropdown)

		//APPLY FILTERS
		let filteredMCOList = this._filterClassMCO.filterData(globalCache.getCacheList('mcos'))

		//GENERATE LIST
		filteredMCOList.forEach((mco) => {
			debug.log('Adding "'+mco.name+'" to MCO list')
			let mainContentGridMCOTestDiv = document.createElement('div')
			mainContentGridMCOTestDiv.id = 'so-me-uuid-here'+mco.uuid
			mainContentGridMCOTestDiv.classList.add('content-list-item')
			if(this.selectedMCOOrder !== false){
				if(mco.uuid == this.selectedMCOOrder.data.uuid){ 
					debug.log('"'+mco.name+'" is detected as being selected...setting to active item')
					mainContentGridMCOTestDiv.classList.add('content-list-item-active')
					this.selectedMCOOrder.div = mainContentGridMCOTestDiv
				}
			}
			mainContentGridMCOTestDiv.innerHTML = mco.name
			mainContentGridMCOTestDiv.onclick = () => {
				debug.log('"'+mco.name+'" has been clicked...')
				if (mainContentGridMCOTestDiv.classList.contains('content-list-item-active')) {//if they re-click the already selected order
					mainContentGridMCOTestDiv.classList.remove('content-list-item-active')
					if(this.selectedMCOOrder.data.uuid == mco.uuid){ //reverify if they re-click the already selected order
						debug.log('"'+mco.name+'" has been deselected')
						this._resetOrderContent()//clear main grid content area
						this.selectedMCOOrder = false//no order selected
					}else{ return }
				} else {//one new order clicked
					mainContentGridMCOTestDiv.classList.add('content-list-item-active')
					if(this.selectedMCOOrder !== false && this.selectedMCOOrder.data.uuid !== mco.uuid){//an order is already clicked - we need to remove it
						debug.log('"'+this.selectedMCOOrder.data.name+'" has been automatically deselected')
						this.selectedMCOOrder.div.classList.remove('content-list-item-active')
					}
					//set the new selected order
					this.selectedMCOOrder = {}
					this.selectedMCOOrder.data = _mcos2[mco.uuid].getAllData()//raw info

					this.selectedMCOOrder.div = mainContentGridMCOTestDiv//raw link div
					debug.log('"'+mco.name+'" has been selected')
					this._buildMCOOrderContent()//load in the order info into main content area of grid
				}
			}

			changeTracker.getWarning('mcos', mco.uuid, mainContentGridMCOTestDiv)
			

			this._divs.list.append(mainContentGridMCOTestDiv)
		})
	}

	/**
	 * Builds the form that allows us to edit the selected MCO
	 */
	_buildMCOOrderContent(){//display current info for existing order...new order will be different
		debug.log('Building MCO content...')

		QReq.request(feedlokAPI.getOwners()).then(resp => {
			globalCache.mergeCacheListItems('owners', resp.owners, 'uuid', {
				finished: () => {
					buildDataMaps()

					this._resetOrderContent()

					//so we can reference later
					let farmSelection

					//SET CONTENT TITLE
					this._divs.title.innerHTML = this.selectedMCOOrder.data.name

					/************************REFRESH BUTTON*************************/
					let refreshButtonContainer = document.createElement('div')
					refreshButtonContainer.style.gridColumn = '1/3'
					refreshButtonContainer.style.width = 'min-content'
					refreshButtonContainer.style.justifySelf = 'end'
					this._divs.content.append(refreshButtonContainer)

					let refreshButton = document.createElement('div')
					refreshButton.classList.add('content-button')
					refreshButton.style.width = 'min-content'
					refreshButton.style.justifySelf = 'center'
					refreshButton.innerHTML = 'Refresh'
					refreshButton.onclick = () => {
						this._subnavButtons.mco.click()
					}
					refreshButtonContainer.append(refreshButton)

					//GENERATE CONTENT
					let orderContentMessage = document.createElement('div')
					orderContentMessage.style.gridColumn = '1/3'
					orderContentMessage.style.display = 'none'
					orderContentMessage.style.textAlign = 'center'
					orderContentMessage.style.fontSize = '2rem'
					this._divs.content.append(orderContentMessage)

					/************************MCO NUMBER AND INPUT*************************/
					let orderContentNumber = document.createElement('div')
					orderContentNumber.classList.add('content-section')
					orderContentNumber.style.marginTop = '10px'
					orderContentNumber.innerHTML = 'Name: '
					this._divs.content.append(orderContentNumber)

					let orderContentNumberInput = document.createElement('input')
					orderContentNumberInput.classList.add('content-input')
					orderContentNumberInput.value = this.selectedMCOOrder.data.name
					orderContentNumberInput.onkeyup = () => {
						changeTracker.addToChanged('mcos', this.selectedMCOOrder.data.uuid, {'name': this.selectedMCOOrder.data.name}, {'name': orderContentNumberInput.value})
						this.selectedMCOOrder.data.name = orderContentNumberInput.value
						debug.log('Sent MCO name changes to changeTracker')
						this._showContentAlert(this._populateMCOOrderList())
					}
					orderContentNumber.append(orderContentNumberInput)

					let orderContentCustomID = document.createElement('div')
					orderContentCustomID.classList.add('content-section')
					orderContentCustomID.style.marginTop = '10px'
					orderContentCustomID.innerHTML = 'Custom ID: '
					this._divs.content.append(orderContentCustomID)

					let orderContentCustomIDInput = document.createElement('input')
					orderContentCustomIDInput.classList.add('content-input')
					orderContentCustomIDInput.value = this.selectedMCOOrder.data.customid
					orderContentCustomIDInput.onkeyup = () => {
						changeTracker.addToChanged('mcos', this.selectedMCOOrder.data.uuid, {'customid': this.selectedMCOOrder.data.customid}, {'customid': orderContentCustomIDInput.value})
						this.selectedMCOOrder.data.customid = orderContentCustomIDInput.value
						debug.log('Sent MCO customid changes to changeTracker')
						this._showContentAlert(this._populateMCOOrderList())
					}
					orderContentCustomID.append(orderContentCustomIDInput)

					/************************MCO STATUS AND SELECTION*************************/
					let orderContentStatus = document.createElement('div')
					orderContentStatus.classList.add('content-section')
					orderContentStatus.innerHTML = 'Assigned To FDO: '
					this._divs.content.append(orderContentStatus)

					if(this.selectedMCOOrder.data.assignedTo == undefined || this.selectedMCOOrder.data.assignedTo == 0){
						orderContentStatus.append('Not Assigned')
					}else{
						let orderContentStatusFDO = document.createElement('span')
						orderContentStatusFDO.innerHTML = _fdos2[this.selectedMCOOrder.data.assignedTo].getName()
						changeTracker.getWarning('fdos', this.selectedMCOOrder.data.assignedTo, orderContentStatusFDO)
						orderContentStatus.append(orderContentStatusFDO)

						let viewFDOButton = document.createElement('div')
						viewFDOButton.classList.add('content-button')
						viewFDOButton.style.display = 'inline'
						viewFDOButton.style.paddingTop = '0'
						viewFDOButton.style.paddingBottom = '0'
						viewFDOButton.innerHTML = 'View/Edit'
						viewFDOButton.onclick = () => {//GO TO FDO
							debug.log('FDO quick link clicked in MCO view...')
							if(this.selectedFDOOrder == false){
								this.selectedFDOOrder = {}
							}
							this._subnavButtons.mco.classList.remove('nav-asset-list-item-active')
							this._subnavButtons.fdo.classList.add('nav-asset-list-item-active')
							this.selectedFDOOrder.data = _fdos2[this.selectedMCOOrder.data.assignedTo].getAllData()

							this._divs.listTitle.innerHTML = 'Available FDO\'s:'
							this._buildFDOOrderContent()
							this._populateFDOOrderList()
						}
						orderContentStatus.append(viewFDOButton)
					}

					/************************MCO DELIVERY DATE AND INPUT*************************/
					let orderContentDeliverBy = document.createElement('div')
					orderContentDeliverBy.classList.add('content-section')
					orderContentDeliverBy.innerHTML = 'Delivery Date: '
					this._divs.content.append(orderContentDeliverBy)

					let orderContentDeliverByInput = document.createElement('input')
					orderContentDeliverByInput.classList.add('content-date-input')
					orderContentDeliverByInput.type = 'date'
					orderContentDeliverByInput.valueAsDate = new Date(this.selectedMCOOrder.data.date*1000)
					orderContentDeliverByInput.onchange = () => {
						let newDate = new Date(orderContentDeliverByInput.value)
						let utcTimeInSeconds = Math.round(parseInt(newDate.getTime())/1000)
						changeTracker.addToChanged('mcos', this.selectedMCOOrder.data.uuid, {'date': this.selectedMCOOrder.data.date}, {'date': utcTimeInSeconds})
						this.selectedMCOOrder.data.date = utcTimeInSeconds
						debug.log('Sent MCO date changes to changeTracker')
						this._showContentAlert(this._populateMCOOrderList())
					}
					orderContentDeliverBy.append(orderContentDeliverByInput)

					/************************MCO OWNER AND SELECTION*************************/
					let orderContentOwner = document.createElement('div')
					orderContentOwner.classList.add('content-section')
					orderContentOwner.innerHTML = 'Owner: '
					this._divs.content.append(orderContentOwner)

					let orderContentOwnerSelection = document.createElement('input')
					orderContentOwnerSelection.classList.add('content-dropdown')
					orderContentOwnerSelection.setAttribute('list', 'ownersList')
					orderContentOwnerSelection.onchange = () => {
						debug.log('MCO owner changed...')

						if(orderContentOwnerSelectionDataList.querySelector('option[value="'+orderContentOwnerSelection.value+'"]') == null){
							alert("Invalid owner selected in the \"Owner\" section!")
							return
						}

						let newValue = orderContentOwnerSelectionDataList.querySelector('option[value="'+orderContentOwnerSelection.value+'"]').id

						QReq.request(feedlokAPI.getFarmsByOwner(newValue)).then(resp => {
							globalCache.mergeCacheListItems('farms', resp.farms, 'uuid', {
								finished: () => {
									buildDataMaps()
			
									changeTracker.addToChanged('mcos', this.selectedMCOOrder.data.uuid, {'owner': this.selectedMCOOrder.data.owner, 'farms': this.selectedMCOOrder.data.farms, 'bins': this.selectedMCOOrder.data.bins}, {
										'owner': newValue,
										'farms': [],
										'bins': []
									})
									debug.log('Sent MCO owner changes to changeTracker')
									this._buildFarmBinsGrid([], orderContentFarmBinsGrid)
									debug.log('Farm selection list and selected bins list reset')
									this.selectedMCOOrder.data.owner = newValue
									this.selectedMCOOrder.data.farms = []
									farmSelection = this._buildFarmSelect(orderContentFarm, newValue,  (selections) => {
										this._buildFarmBinsGrid(selections, orderContentFarmBinsGrid)
									})//where we use the selected owner to build the list
									debug.log('Farm selection list updated to reflect newly selected owner')
									this._showContentAlert(this._populateMCOOrderList())
								}
							})
						})
					}
					orderContentOwner.append(orderContentOwnerSelection)

					let orderContentOwnerSelectionDataList = document.createElement('datalist')
					orderContentOwnerSelectionDataList.id = 'ownersList'
					orderContentOwner.append(orderContentOwnerSelectionDataList)

					globalCache.getCacheList('owners').forEach((owner) => {
						let orderContentOwnerOption = document.createElement('option')
						orderContentOwnerOption.id = owner.uuid
						orderContentOwnerOption.value = owner.name
						orderContentOwnerSelectionDataList.append(orderContentOwnerOption)
						debug.log('Owner "'+owner.name+'" added to owner drop down')
					})

					if(this.selectedMCOOrder.data.owner !== null && this.selectedMCOOrder.data.owner !== undefined && this.selectedMCOOrder.data.owner.length !== 0){
						orderContentOwnerSelection.value = _owners[this.selectedMCOOrder.data.owner].name
					}

					/************************MCO FARM AND SELECTION*************************/
					let orderContentFarm = document.createElement('div')
					orderContentFarm.classList.add('content-section')
					orderContentFarm.style.maxHeight = '100px'
					orderContentFarm.style.overflowY = 'auto'
					orderContentFarm.innerHTML = 'Farms: '
					this._divs.content.append(orderContentFarm)

					var selectedOwner = orderContentOwnerSelectionDataList.querySelector('option[value="'+orderContentOwnerSelection.value+'"]')
					if(selectedOwner !== null && selectedOwner !== undefined){
						farmSelection = this._buildFarmSelect(orderContentFarm, selectedOwner.id, (selections) => {
							debug.log('Farm selection change detected...')
							this._buildFarmBinsGrid(selections, orderContentFarmBinsGrid)
							this._showContentAlert(this._populateMCOOrderList())
						})//where we use the selected owner to build the list
					}
					debug.log('Initial farm selection list created')

					/***************************************BINS********************************/
					let orderContentFarmBins = document.createElement('div')
					orderContentFarmBins.classList.add('content-section')
					orderContentFarmBins.style.textAlign = 'center'
					orderContentFarmBins.style.gridColumn = '1/3'
					orderContentFarmBins.style.fontWeight = 'bolder'
					orderContentFarmBins.innerHTML = 'Bins:'
					this._divs.content.append(orderContentFarmBins)

					let orderContentFarmBinsGrid = document.createElement('div')
					orderContentFarmBinsGrid.classList.add('mco-content-bin-section')
					orderContentFarmBinsGrid.style.gridColumn = '1/3'
					this._divs.content.append(orderContentFarmBinsGrid)

					this._buildFarmBinsGrid(this.selectedMCOOrder.data.farms, orderContentFarmBinsGrid)
					debug.log('Initial bin selection list created')
					/******************************UPDATE BUTTON********************************/
					let orderContentUpdate = document.createElement('div')
					orderContentUpdate.classList.add('content-button')
					orderContentUpdate.style.gridColumn = '1/3'
					orderContentUpdate.style.width = 'min-content'
					orderContentUpdate.style.justifySelf = 'center'
					orderContentUpdate.innerHTML = 'Update'
					orderContentUpdate.onclick = () => {
						debug.log('Update button clicked...')
						let changes = changeTracker.getChanges('mcos', this.selectedMCOOrder.data.uuid)
						if(changes == false){debug.log('No changes detected...returning');return}

						if(changes.farms !== undefined){ delete changes.farms }

						QReq.request(feedlokAPI.putMCOrderOrderID(this.selectedMCOOrder.data.uuid, changes)).then(resp => {
							//to make MCOs2 work
							_mcos2[resp.uuid].setAllData(resp)
							globalCache.setCacheListItem('mcos', {uuid: resp.uuid}, resp)

							buildDataMaps()

							debug.log('MCO successfully updated')
							changeTracker.clearChanges('mcos', this.selectedMCOOrder.data.uuid)
							debug.log('MCO changes removed from tracker')
							this._populateMCOOrderList()
							this._buildMCOOrderContent()
						}).catch((resp) => {
							if(resp.status == 403){
								debug.log('MCO update failed due to it being locked')
								orderContentMessage.innerHTML = '<font color ="red">This MCO is currently locked and can not be edited!</font>'
								orderContentMessage.style.display = 'block'
								setTimeout(() => {
									orderContentMessage.style.display = 'none'
								}, 3000)
								return
							}
							debug.log('Unknown update error - potential API outage')
							errorHandler(resp)
						})
					}
					this._divs.content.append(orderContentUpdate)

					/************************SENSITIVE BUTTONS*************************/
					let orderContentButtonsContainer = document.createElement('div')
					orderContentButtonsContainer.style.gridColumn = '1/3'
					orderContentButtonsContainer.style.width = 'min-content'
					orderContentButtonsContainer.style.justifySelf = 'center'
					this._divs.content.append(orderContentButtonsContainer)

					let orderContentDeleteButton = document.createElement('div')
					orderContentDeleteButton.classList.add('content-button')
					orderContentDeleteButton.style.width = 'min-content'
					orderContentDeleteButton.style.justifySelf = 'center'
					orderContentDeleteButton.innerHTML = 'Delete'
					orderContentDeleteButton.onclick = () => {
						debug.log('Delete button clicked...')
						let confirmation = confirm('Are you sure you would like to delete this MCO?')
						if(confirmation == true){
							debug.log('Delete confirmed...')
							QReq.request(feedlokAPI.deleteMCOrderOrderID(this.selectedMCOOrder.data.uuid)).then(resp => {
								if(resp.status == 409){
									debug.log('MCO detected as being in use - delete failed')
									alert('This MCO can not be deleted due to being in use!')
									return
								}
								globalCache.deleteCacheListItemByUUID('mcos', this.selectedMCOOrder.data.uuid)
								delete _mcos2[this.selectedMCOOrder.data.uuid]
								this.selectedMCOOrder = false
								debug.log('Delete successful and removed from cache')
								this._populateMCOOrderList()
								this._resetOrderContent()
							}).catch((e) => {
								errorHandler(e)
							})
							return
						}
						debug.log('Delete canceled')
					}
					orderContentButtonsContainer.append(orderContentDeleteButton)
					

					/************************UNSAVED ALERT CENTER*************************/
					let orderContentAlert = document.createElement('div')
					orderContentAlert.classList.add('content-alert')
					orderContentAlert.innerHTML = 'THIS MCO HAS UNSAVED DATA'
					this._divs.content.append(orderContentAlert)
					this._divs.alert = orderContentAlert
					if(changeTracker.contains('mcos', this.selectedMCOOrder.data.uuid)){ this._showContentAlert() }

					let orderContentAlertButtonContainer = document.createElement('div')
					orderContentAlertButtonContainer.style.fontSize = '1rem'
					orderContentAlertButtonContainer.style.textAlign = 'center'
					this._divs.alert.append(orderContentAlertButtonContainer)

					let orderContentAlertRevertButton = document.createElement('div')
					orderContentAlertRevertButton.classList.add('content-button')
					orderContentAlertRevertButton.style.fontSize = '1rem'
					orderContentAlertRevertButton.innerHTML = 'Revert Changes'
					orderContentAlertRevertButton.onclick = () => {
						debug.log('Revert Changes button clicked...')
						this.selectedMCOOrder.data = changeTracker.revertChanges('mcos', this.selectedMCOOrder.data.uuid, this.selectedMCOOrder.data)
						_mcos2[this.selectedMCOOrder.data.uuid].setAllData(this.selectedMCOOrder.data)
						debug.log('Changes reverted and changeTracker cleared')
						this._populateMCOOrderList()
						this._buildMCOOrderContent()
					}
					orderContentAlertButtonContainer.append(orderContentAlertRevertButton)
				}
			})
		})
	}

	/**
	 * Change the bins associated with a farm
	 * @param {array} newBins Array of strings containing the UUIDs of bins to add to Farm
	 */
	_submitFarmBinsChange(newBins){
		changeTracker.addToChanged('mcos', this.selectedMCOOrder.data.uuid, {'bins': this.selectedMCOOrder.data.bins}, {'bins': newBins})
		this.selectedMCOOrder.data.bins = newBins
		debug.log('Sent MCO bin changes to changeTracker')
		this._showContentAlert(this._populateMCOOrderList())
	}

	/**
	 * Builds out a list of farms and bin that an MCO has
	 * @param {object} farms List of farms we have selected
	 * @param {element} attachTo HTML Element that we want to attach the farm div to
	 */
	_buildFarmBinsGrid(farms, attachTo){
		debug.log('Building bin selection...')
		DOMClearChildren(attachTo)

		farms.forEach((farmUUID) => {
			QReq.request(feedlokAPI.getBinsByFarm(farmUUID)).then(resp => {
				globalCache.mergeCacheListItems('bins', resp.bins, 'uuid', {
					finished: () => {
						buildDataMaps()

						let farm = _farms[farmUUID]
						debug.log('Generating farm "'+farm.name+'"\s container...')

						let mcoFarmDiv = document.createElement('div')
						mcoFarmDiv.classList.add('mco-content-bin-section-farm', 'nowrap')
						mcoFarmDiv.style.gridColumn = '1/3'
						attachTo.append(mcoFarmDiv)

						let assignedFarmName = document.createElement('div')
						assignedFarmName.classList.add('fdo-content-assigned-mco-title')
						assignedFarmName.innerHTML = farm.name
						mcoFarmDiv.append(assignedFarmName)

						let farmBins = globalCache.getCacheList('bins').filter(bin => bin.farm == farmUUID)

						farmBins.forEach((binObj) => {
							debug.log('Bin "'+binObj.name+'" added to farm container')

							if(binObj.trained == false){ return }//if its not trained we cannot assign it to MCO
							let farmBin = document.createElement('div')
							farmBin.classList.add('mco-content-bin-section-bin', 'nowrap')
							if(this.selectedMCOOrder.data.bins == undefined){this.selectedMCOOrder.data.bins = []}
							if(this.selectedMCOOrder.data.bins.includes(binObj.uuid)){ debug.log('Bin "'+binObj.name+'" in MCO bin list - selected'); farmBin.classList.add('mco-content-bin-section-bin-selected') }
							farmBin.innerHTML = binObj.name
							farmBin.onclick = () => {
								debug.log('Bin "'+binObj.name+'" clicked')
								//RECREATE BINS SINCE WE CAN NOT PASS INSTANCE OF SELECTEDMCOORDER.DATA.BINS DUE TO IT PREMATURELY UPDATING
								let newBins = []//before changes for the tracker
								this.selectedMCOOrder.data.bins.forEach((bin) => {newBins.push(bin)})

								if(farmBin.classList.contains('mco-content-bin-section-bin-selected')){//remove from MCO
									debug.log('Bin "'+binObj.name+'" removed from MCO')
									//LOADOUT CHECKS - WE ONLY CARE ABOUT REMOVING MCOS SINCE SOMETHING THAT IS BEING ADDED CANT ALREADY BE IN GATES
									let conflicts = false
									if(typeof this.selectedMCOOrder.data.assignedTo == 'string' && _fdos2[this.selectedMCOOrder.data.assignedTo].getGates() != undefined){
										_fdos2[this.selectedMCOOrder.data.assignedTo].getGates().forEach((gateObject) => {
											if(gateObject.bins.includes(binObj.uuid)){
												conflicts = true
											}
										})
									}
									
									if(conflicts == true){
										debug.log('Conflict detected with bin "'+binObj.name+'"')

										overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
											let div = document.createElement('div')
											div.classList.add('overlay-container')
											overlayDiv.append(div)
									
											let title = document.createElement('div')
											title.classList.add('overlay-title')
											title.innerHTML = 'Conflict Detected'
											div.append(title)
									
											let alertMessage = document.createElement('div')
											alertMessage.style.marginTop = '4px'
											alertMessage.innerHTML = 'Bin "'+binObj.name+'" appears to be assigned to a trailer gate in loadout. Would you like to clear this bin from the loadout?'
											div.append(alertMessage)
									
											let buttonContainer = document.createElement('div')
											buttonContainer.style.display = 'grid'
											buttonContainer.style.gridTemplateColumns = '1fr 1fr'
											div.append(buttonContainer)

											let confirmButton = document.createElement('div')
											confirmButton.classList.add('overlay-button')
											confirmButton.innerHTML = 'Remove from Loadout'
											confirmButton.onclick = () => {
												debug.log('Remove from Loadout button clicked in conflict overlay')
												//REMOVE FROM LOADOUT GATES
												let newGateList = _fdos2[this.selectedMCOOrder.data.assignedTo].getGates()
												newGateList.forEach((gateObject) => {
													if(gateObject.bins.includes(binObj.uuid)){
														let newGateObject = gateObject
														newGateObject.bins.splice(newGateObject.bins.indexOf(binObj.uuid), 1)
														
														newGateList[newGateList.indexOf(gateObject)] = newGateObject
													}
												})

												let changes = {'gates': newGateList}
												QReq.request(feedlokAPI.putFDOrderOrderID(this.selectedMCOOrder.data.assignedTo, changes)).then(resp => {
													//STANDARD ADD TO MCO
													farmBin.classList.remove('mco-content-bin-section-bin-selected')
													newBins.splice(newBins.indexOf(binObj.uuid) ,1)
													this._submitFarmBinsChange(newBins)

													debug.log('Conflict successfully resolved via removal of bin from loadout')

													overlay.close()
												}).catch((e) => {
													errorHandler(e)
												})

											}
											buttonContainer.append(confirmButton)
									
											let cancelButton = document.createElement('div')
											cancelButton.classList.add('overlay-button')
											cancelButton.innerHTML = 'Cancel'
											cancelButton.onclick = () => {
												debug.log('Conflict ignored - no changes were made')
												overlay.close()
											}
											buttonContainer.append(cancelButton)
									
											overlay.show()
										})
									}else{
										//STANDARD ADD TO MCO - no conflicts with a loadout
										farmBin.classList.remove('mco-content-bin-section-bin-selected')
										newBins.splice(newBins.indexOf(binObj.uuid) ,1)
										debug.log('No conflicts detected - Bin removed from MCO')
										this._submitFarmBinsChange(newBins)
									}
								}else{//add to mco
									farmBin.classList.add('mco-content-bin-section-bin-selected')
									newBins.push(binObj.uuid)
									debug.log('Bin "'+binObj.name+'" added to MCO')
									this._submitFarmBinsChange(newBins)
								}
							}
							mcoFarmDiv.append(farmBin)
						})
					}
				})
			})
		})
	}


	/***************************************************************************************FDO*********************************************************************************/
	/**
	 * Builds out the list on the left side of the main content and sorts list by specified field
	 * @param {string} sort What field we are sorting the list by
	 * @param {bool} filtersOpen Whether we display the filter or not
	 */
	_populateFDOOrderList(filtersOpen = false){//create the left side list of MCOs
		debug.log('Populating FDO list with sort of "'+this.fdoSortSettings.sort+'" and filtersOpen of "'+filtersOpen+'"')

		DOMClearChildren(this._divs.list)

		switch(this.fdoSortSettings.sort){
			case 'number':
				globalCache.getCacheList('fdos').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
				debug.log('FDO cache sorted by "name" (asc)')
				break;
			case 'date':
				globalCache.getCacheList('fdos').sort((a, b) => (a.earliestMCODate > b.earliestMCODate) ? 1 : -(a.earliestMCODate === b.earliestMCODate) ? ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) : -1 )
				debug.log('FDO cache sorted by "date" (asc)')
				break;
		}

		//SORT
		let sortBy = document.createElement('div')
		sortBy.innerHTML = 'Sort By: '
		sortBy.style.color = '#fff'
		sortBy.style.textAlign = 'end'
		sortBy.style.paddingBottom = '4px'
		this._divs.list.append(sortBy)

		let sortSelect = document.createElement('select')
		sortSelect.classList.add('content-dropdown')
		sortSelect.onchange = () => {
			debug.log('Sort changed...re-populating FDO list')
			this.fdoSortSettings.sort = sortSelect.value
			this._populateFDOOrderList(filtersOpen)//rebuild list to apply sort
		}
		sortBy.append(sortSelect)

		let sortNumberOption = document.createElement('option')
		sortNumberOption.value = 'number'
		sortNumberOption.innerHTML = 'Name'
		sortSelect.append(sortNumberOption)

		let sortDateOption = document.createElement('option')
		sortDateOption.value = 'date'
		sortDateOption.innerHTML = 'Date'
		sortSelect.append(sortDateOption)

		sortSelect.value = this.fdoSortSettings.sort

		let filters = document.createElement('span')
		filters.innerHTML = 'Filters '
		filters.classList.add('filters-button')
		if(Object.keys(this._filterClassFDO.filters).length > 0){filters.classList.add('filters-button-active')}
		filters.onclick = () => {
			debug.log('Filters button clicked...')

			if(Object.keys(this._filterClassFDO.filters).length > 0){filters.classList.add('filters-button-active')}else{filters.classList.remove('filters-button-active')}

			if(filtersDropdown.style.display == 'none'){//on open of filter section
				filtersDropdown.style.display = 'block'
				filtersOpen = true
				this._buildFilterListFDO()//build the filter list
				return
			}
			filtersOpen = false//if we close we want to make sure it doesnt reopen if we reload of sort application or something like that...
			filtersDropdown.style.display = 'none'//close filter section
		}
		sortBy.append(filters)

		let filtersArrow = document.createElement('span')
		filtersArrow.classList.add('fa', 'fa-chevron-down')
		filters.append(filtersArrow)

		let filtersDropdown = document.createElement('div')
		filtersDropdown.classList.add('filters-dropdown')
		filtersDropdown.style.display = 'none'//by default we hide
		filtersDropdown.style.color = 'var(--content-color)'
		this._filterClassFDO.setParent(filtersDropdown)
		if(filtersOpen == true){ filtersDropdown.style.display = 'block'; this._buildFilterListFDO() }//if we want to show after repopulating (when changing filters)
		sortBy.append(filtersDropdown)

		let searchRow = document.createElement('div')
		searchRow.style.display = 'grid'
		searchRow.style.gridTemplateColumns = '1fr min-content'
		this._divs.list.append(searchRow)

		let listSearch = document.createElement('input')
		listSearch.classList.add('content-input')
		listSearch.style.width = 'calc(100% - 6px)'
		listSearch.style.marginBottom = '4px'
		listSearch.placeholder = 'Search...'
		searchRow.append(listSearch)

		let searchButton = document.createElement('div')
		searchButton.classList.add('content-button')
		searchButton.style.marginTop = '0'
		searchButton.style.marginRight = '0'
		searchButton.innerHTML = 'Search'
		searchButton.onclick = () => {
			let assetDivs = this._divs.list.querySelectorAll('div.content-list-item')//get all of the divs in "machineList" div which contains asset list

			for (var i = 0; i < assetDivs.length; i++) {//loop through all divs
				if(!assetDivs[i].innerText.toLocaleLowerCase().includes(listSearch.value.toLocaleLowerCase())){//see if innerHMTL include search value
					assetDivs[i].style.display = 'none'//hide since we were checking if includes = false
				}else{
					assetDivs[i].style.display = 'block'//this is the default for this particular element
				}
			}
		}
		searchRow.append(searchButton)

		//APPLY FILTERS
		let filteredFDOList = this._filterClassFDO.filterData(globalCache.getCacheList('fdos'))

		//GENERATE LIST
		filteredFDOList.forEach((fdo) => {
			debug.log('Adding FDO "'+fdo.name+'" to list')

			let mainContentGridFDOTestDiv = document.createElement('div')
			mainContentGridFDOTestDiv.id = 'so-me-uuid-here'+fdo.uuid
			mainContentGridFDOTestDiv.classList.add('content-list-item')
			if(this.selectedFDOOrder !== false){
				if(fdo.uuid == this.selectedFDOOrder.data.uuid){ 
					debug.log('"'+fdo.name+'" is detected as being selected...setting to active item')
					mainContentGridFDOTestDiv.classList.add('content-list-item-active')
					this.selectedFDOOrder.div = mainContentGridFDOTestDiv
				}
			}
			mainContentGridFDOTestDiv.innerHTML = fdo.name
			mainContentGridFDOTestDiv.onclick = () => {
				debug.log('"'+fdo.name+'" has been clicked...')

				if(mainContentGridFDOTestDiv.classList.contains('content-list-item-active')) {//if they re-click the already selected order
					mainContentGridFDOTestDiv.classList.remove('content-list-item-active')
					if(this.selectedFDOOrder.data.uuid == fdo.uuid){ //reverify if they re-click the already selected order
						debug.log('"'+fdo.name+'" has been deselected')
						this._resetOrderContent()//clear main grid content area
						this.selectedFDOOrder = false//no order selected
					}else{ return }
				}else{//one new order clicked
					mainContentGridFDOTestDiv.classList.add('content-list-item-active')
					if(this.selectedFDOOrder !== false && this.selectedFDOOrder.data.uuid !== fdo.uuid){//an order is already clicked - we need to remove it
						debug.log('"'+this.selectedFDOOrder.data.name+'" has been automatically deselected')
						this.selectedFDOOrder.div.classList.remove('content-list-item-active')
					}
					//set the new selected order
					this.selectedFDOOrder = {}
					this.selectedFDOOrder.data = _fdos2[fdo.uuid].getAllData()//raw info
					this.selectedFDOOrder.div = mainContentGridFDOTestDiv//raw div

					debug.log('"'+fdo.name+'" has been selected')

					this._buildFDOOrderContent()//load in the order info into main content area of grid
				}
			}

			changeTracker.getWarning('fdos', fdo.uuid, mainContentGridFDOTestDiv)

			this._divs.list.append(mainContentGridFDOTestDiv)
		})
	}

	/**
	 * Builds the form that allows us to edit the selected FDO
	 */
	_buildFDOOrderContent(){//display current info for existing order...new order will be different
		debug.log('Building FDO content...')

		this._resetOrderContent()

		this.selectedFDOOrder.data = _fdos2[this.selectedFDOOrder.data.uuid].getAllData()//update just in case

		//SET CONTENT TITLE
		this._divs.title.innerHTML = this.selectedFDOOrder.data.name

		/************************REFRESH BUTTON*************************/
		let refreshButtonContainer = document.createElement('div')
		refreshButtonContainer.style.gridColumn = '1/3'
		refreshButtonContainer.style.width = 'min-content'
		refreshButtonContainer.style.justifySelf = 'end'
		this._divs.content.append(refreshButtonContainer)

		let refreshButton = document.createElement('div')
		refreshButton.classList.add('content-button')
		refreshButton.style.width = 'min-content'
		refreshButton.style.justifySelf = 'center'
		refreshButton.innerHTML = 'Refresh'
		refreshButton.onclick = () => {
			this._subnavButtons.fdo.click()
		}
		refreshButtonContainer.append(refreshButton)

		//GENERATE CONTENT
		let orderContentMessage = document.createElement('div')
		orderContentMessage.style.gridColumn = '1/3'
		orderContentMessage.style.display = 'none'
		orderContentMessage.style.textAlign = 'center'
		orderContentMessage.style.fontSize = '2rem'
		this._divs.content.append(orderContentMessage)

		/************************FDO NUMBER AND INPUT*************************/
		let orderContentNumber = document.createElement('div')
		orderContentNumber.classList.add('content-section')
		orderContentNumber.style.marginTop = '10px'
		orderContentNumber.innerHTML = 'Name: '
		this._divs.content.append(orderContentNumber)

		let orderContentNumberInput = document.createElement('input')
		orderContentNumberInput.classList.add('content-input')
		orderContentNumberInput.value = this.selectedFDOOrder.data.name
		orderContentNumberInput.onkeyup = () => {
			changeTracker.addToChanged('fdos', this.selectedFDOOrder.data.uuid, {'name': this.selectedFDOOrder.data.name}, {'name': orderContentNumberInput.value})
			this.selectedFDOOrder.data.name = orderContentNumberInput.value
			debug.log('Sent FDO name changes to changeTracker')
			this._showContentAlert(this._populateFDOOrderList())
		}
		orderContentNumber.append(orderContentNumberInput)

		let orderContentCustomID = document.createElement('div')
		orderContentCustomID.classList.add('content-section')
		orderContentCustomID.style.marginTop = '10px'
		orderContentCustomID.innerHTML = 'Custom ID: '
		this._divs.content.append(orderContentCustomID)

		let orderContentCustomIDInput = document.createElement('input')
		orderContentCustomIDInput.classList.add('content-input')
		orderContentCustomIDInput.value = this.selectedFDOOrder.data.customid
		orderContentCustomIDInput.onkeyup = () => {
			changeTracker.addToChanged('fdos', this.selectedFDOOrder.data.uuid, {'customid': this.selectedFDOOrder.data.customid}, {'customid': orderContentCustomIDInput.value})
			this.selectedFDOOrder.data.customid = orderContentCustomIDInput.value
			debug.log('Sent FDO customid changes to changeTracker')
			this._showContentAlert(this._populateFDOOrderList())
		}
		orderContentCustomID.append(orderContentCustomIDInput)

		let orderContentTrailer = document.createElement('div')
		orderContentTrailer.classList.add('content-section')
		orderContentTrailer.innerHTML = 'Assigned To: '
		this._divs.content.append(orderContentTrailer)

		let orderContentTrailerSelect = document.createElement('input')
		orderContentTrailerSelect.classList.add('content-dropdown')
		orderContentTrailerSelect.setAttribute('list', 'trailersList')
		orderContentTrailerSelect.onchange = () => {
			if(orderContentTrailerSelectDataList.querySelector('option[value="'+orderContentTrailerSelect.value+'"]') == null){
				alert("Invalid trailer selected in the \"Assigned To\" section!")
				return
			}

			let newValue = orderContentTrailerSelectDataList.querySelector('option[value="'+orderContentTrailerSelect.value+'"]').id

			changeTracker.addToChanged('fdos', this.selectedFDOOrder.data.uuid, {'trailer': this.selectedFDOOrder.data.trailer}, {'trailer': newValue})
			this.selectedFDOOrder.data.trailer = newValue
			debug.log('Sent FDO trailer changes to changeTracker')
			this._showContentAlert(this._populateFDOOrderList())
		}
		orderContentTrailer.append(orderContentTrailerSelect)

		let orderContentTrailerSelectDataList = document.createElement('datalist')
		orderContentTrailerSelectDataList.id = 'trailersList'
		orderContentTrailer.append(orderContentTrailerSelectDataList)

		let removeTrailerOption = document.createElement('option')
		removeTrailerOption.id = ""
		removeTrailerOption.value = "Remove Trailer"
		orderContentTrailerSelectDataList.append(removeTrailerOption)

		globalCache.getCacheList('trailers').forEach((trailer) => {
			let trailerOption = document.createElement('option')
			trailerOption.id = trailer.uuid
			trailerOption.value = trailer.name
			orderContentTrailerSelectDataList.append(trailerOption)

			debug.log('Trailer "'+trailer.name+'" add to trailer drop down')
		})

		if(this.selectedFDOOrder.data.trailer !== null && this.selectedFDOOrder.data.trailer !== undefined && this.selectedFDOOrder.data.trailer.length !== 0){
			orderContentTrailerSelect.value = _trailers[this.selectedFDOOrder.data.trailer].name
		}

		let orderContentOverride = document.createElement('div')
		orderContentOverride.classList.add('content-section')
		orderContentOverride.innerHTML = 'Override: '
		this._divs.content.append(orderContentOverride)

		let orderContentOverrideButton = document.createElement('div')
		orderContentOverrideButton.classList.add('content-button')
		orderContentOverrideButton.style.width = 'min-content'
		orderContentOverrideButton.style.display = 'inline'
		orderContentOverrideButton.innerHTML = 'View'
		orderContentOverrideButton.onclick = () => {
			overlay.getOverlay().then((overlayDiv) => {
				let div = document.createElement('div')
				div.classList.add('overlay-container')
				overlayDiv.append(div)

				let title = document.createElement('div')
				title.classList.add('overlay-title')
				title.style.fontSize = '3rem'
				title.style.textAlign = 'center'
				title.style.color = 'red'
				title.innerHTML = '<b>WARNING!!!</b>'
				div.append(title)

				let body = document.createElement('div')
				body.style.fontSize ='1.5rem'
				body.style.textAlign = 'center'
				body.innerHTML = '<b>By entering the override code on the trailer you will bypass the geo-location restrictions. Are you sure you would like to continue?</b>'
				div.append(body)

				let override = document.createElement('div')
				override.style.fontSize = '1.5rem'
				override.style.textAlign = 'center'
				override.style.marginTop = '2rem'
				override.innerHTML = 'Override: '
				div.append(override)

				let revealButton = document.createElement('div')
				revealButton.classList.add('content-button')
				revealButton.style.width = 'min-content'
				revealButton.style.display = 'inline'
				revealButton.innerHTML = 'Reveal'
				revealButton.onclick = () => {
					revealButton.remove()
					if(this.selectedFDOOrder.data.override !== null && this.selectedFDOOrder.data.override !== undefined){
						while(this.selectedFDOOrder.data.override.toString().length < 4){this.selectedFDOOrder.data.override = '0'+this.selectedFDOOrder.data.override.toString()}
						override.innerHTML += this.selectedFDOOrder.data.override
					}else{
						override.innerHTML += "NONE - Never sent to trailer!"
					}
				}
				override.append(revealButton)
				
				overlay.show()
			})
		}
		orderContentOverride.append(orderContentOverrideButton)

		/************************FDO ASSIGNED MCO LIST*************************/
		let orderContentAssignedMCOHeader = document.createElement('div')
		orderContentAssignedMCOHeader.classList.add('content-section')
		orderContentAssignedMCOHeader.style.gridColumn = '1/3'
		orderContentAssignedMCOHeader.style.textAlign = 'center'
		orderContentAssignedMCOHeader.innerHTML = 'Assigned MCO\'s: '
		this._divs.content.append(orderContentAssignedMCOHeader)

		/******************************ASSIGN BUTTON********************************/
		let orderContentAssignedMCOButtons = document.createElement('div')
		orderContentAssignedMCOButtons.style.gridColumn = '1/3'
		orderContentAssignedMCOButtons.style.textAlign = 'center'
		orderContentAssignedMCOButtons.style.justifySelf = 'right'
		this._divs.content.append(orderContentAssignedMCOButtons)

		let assignNewMCO = document.createElement('div')
		assignNewMCO.classList.add('content-button', 'nowrap')
		assignNewMCO.style.width = 'min-content'
		assignNewMCO.innerHTML = 'Assign MCO'
		assignNewMCO.onclick = () => {
			debug.log('Assign MCO button clicked - generating overlay...')

			overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
				let selectedMCOs = []

				let div = document.createElement('div')
				div.classList.add('overlay-container')
				overlayDiv.append(div)

				let title = document.createElement('div')
				title.classList.add('overlay-title')
				title.innerHTML = 'Available MCO\'s'
				div.append(title)

				let mcoList = document.createElement('div')
				mcoList.style.display = 'grid'
				mcoList.style.gridTemplateColumns = '1fr 1fr 1fr min-content'
				mcoList.style.columnGap = '4px'
				mcoList.style.rowGap = '4px'
				mcoList.style.paddingTop = '4px'
				div.append(mcoList)

				/******************MCO LIST TITLES***************/
				let mcoListNameHeader = document.createElement('div')
				mcoListNameHeader.classList.add('available-mco-list-cell')
				mcoListNameHeader.style.textAlign = 'center'
				mcoListNameHeader.style.fontWeight = 'bold'
				mcoListNameHeader.innerHTML = 'MCO'
				mcoList.append(mcoListNameHeader)

				let mcoListDateHeader = document.createElement('div')
				mcoListDateHeader.classList.add('available-mco-list-cell')
				mcoListDateHeader.style.textAlign = 'center'
				mcoListDateHeader.style.fontWeight = 'bold'
				mcoListDateHeader.innerHTML = 'Date'
				mcoList.append(mcoListDateHeader)

				let mcoListFarmHeader = document.createElement('div')
				mcoListFarmHeader.classList.add('available-mco-list-cell')
				mcoListFarmHeader.style.textAlign = 'center'
				mcoListFarmHeader.style.fontWeight = 'bold'
				mcoListFarmHeader.innerHTML = 'Bins'
				mcoList.append(mcoListFarmHeader)

				let mcoListIncludeHeader = document.createElement('div')
				mcoListIncludeHeader.classList.add('available-mco-list-cell')
				mcoListIncludeHeader.style.textAlign = 'center'
				mcoListIncludeHeader.style.fontWeight = 'bold'
				mcoListIncludeHeader.innerHTML = 'Add?'
				mcoList.append(mcoListIncludeHeader)

				let availableMCOs = globalCache.getCacheList('mcos').filter(mco => {
					if(mco.assignedTo !== undefined && mco.assignedTo !== 0){
						return false
					}

					return true
				})
				availableMCOs.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
				debug.log('MCO cache filtered to entries with assignedTo undefined or equal to 0 and sorted by name')

				availableMCOs.forEach((mcoObject) => {
					debug.log('Adding MCO "'+mcoObject.name+'" to list of MCOs awaiting assignment')

					if(mcoObject.owner == undefined || mcoObject.owner == ""){ debug.log('MCO "'+mcoObject.name+'" skipped due to no owner being specified'); return }
					let mcoListName = document.createElement('div')
					mcoListName.classList.add('available-mco-list-cell')
					mcoListName.innerHTML = mcoObject.name+' (Owner: '+_owners[mcoObject.owner].name+')'
					mcoList.append(mcoListName)

					let mcoListDate = document.createElement('div')
					mcoListDate.classList.add('available-mco-list-cell')
					mcoListDate.style.textAlign = 'center'
					mcoListDate.innerHTML = (new Date(mcoObject.date*1000)).toLocaleDateString('en-US', {timezone: millTimezone})
					mcoList.append(mcoListDate)

					let mcoListBins = document.createElement('div')
					mcoListBins.classList.add('available-mco-list-cell')
					mcoList.append(mcoListBins)

					if(mcoObject.bins == undefined){mcoObject.bins = []}
					mcoObject.bins.forEach((binUUID) => {
						let binDescription = document.createElement('div')
						binDescription.innerHTML = _bins[binUUID].name+' (Located @ '+_farms[_bins[binUUID].farm].name+')'
						mcoListBins.append(binDescription)
					})

					let mcoListAdd = document.createElement('div')
					mcoListAdd.classList.add('available-mco-list-cell')
					mcoListAdd.style.textAlign = 'center'
					mcoList.append(mcoListAdd)

					let mcoListAddCheckbox = document.createElement('input')
					mcoListAddCheckbox.type = 'checkbox'
					mcoListAddCheckbox.onchange = () => {
						if(selectedMCOs.includes(mcoObject.uuid)){
							selectedMCOs.splice(selectedMCOs.splice(selectedMCOs.indexOf(mcoObject.uuid), 1))
							debug.log('MCO "'+mcoObject.name+'" deselected from being added to FDO')
							return
						}
						selectedMCOs.push(mcoObject.uuid)
						debug.log('MCO "'+mcoObject.name+'" was selected to be added to FDO')
					}
					mcoListAdd.append(mcoListAddCheckbox)
				})

				let addMCOsButton = document.createElement('div')
				addMCOsButton.classList.add('overlay-button')
				addMCOsButton.style.marginTop = '10px'
				addMCOsButton.innerHTML = 'Add MCOs'
				addMCOsButton.onclick = () => {
					debug.log('Add MCOs button clicked...')
					selectedMCOs.forEach((mcoUUID) => {
						debug.log('Adding MCO to FDO - MCO UUID: '+mcoUUID)

						let oldMCOs = []

						this.selectedFDOOrder.data.mcos.forEach((uuid) => {oldMCOs.push(uuid)})

						_mcos2[mcoUUID].setAssignedTo(this.selectedFDOOrder.data.uuid)
						_fdos2[this.selectedFDOOrder.data.uuid].addMCO(mcoUUID)
						changeTracker.addToChanged('fdos', this.selectedFDOOrder.data.uuid, {'mcos': oldMCOs}, {'mcos': _fdos2[this.selectedFDOOrder.data.uuid].getMCOs()})

						if(this.selectedFDOOrder.data.earliestMCODate == undefined || this.selectedFDOOrder.data.earliestMCODate > _mcos2[mcoUUID].getDate()){
							changeTracker.addToChanged('fdos', this.selectedFDOOrder.data.uuid, {'earliestMCODate': this.selectedFDOOrder.data.earliestMCODate}, {'earliestMCODate': _mcos2[mcoUUID].getDate()})
							this.selectedFDOOrder.data.earliestMCODate = _mcos2[mcoUUID].getDate()
						}

						debug.log('Sent FDO mco changes to changeTracker')
					})
					this._buildFDOOrderContent()
					overlay.close()
				}
				div.append(addMCOsButton)
	
				overlay.show()
			})
		}
		orderContentAssignedMCOButtons.append(assignNewMCO)

		/**********************ASSIGNED MCO LIST******************************/
		let orderContentAssignedMCOList = document.createElement('div')
		orderContentAssignedMCOList.style.gridColumn = '1/3'
		orderContentAssignedMCOList.style.padding = '4px'
		this._divs.content.append(orderContentAssignedMCOList)

		if(this.selectedFDOOrder.data.mcos == undefined){ this.selectedFDOOrder.data.mcos = [] }
		this.selectedFDOOrder.data.mcos.forEach((mcoUUID) => {
			debug.log('Adding MCO "'+_mcos2[mcoUUID].getName()+'" container to assigned MCO list')

			let orderContentAssignedMCO = document.createElement('div')
			orderContentAssignedMCO.classList.add('fdo-content-assigned-mco')
			orderContentAssignedMCOList.append(orderContentAssignedMCO)

			let orderContentAssignedMCOTitle = document.createElement('div')
			orderContentAssignedMCOTitle.classList.add('fdo-content-assigned-mco-title')
			orderContentAssignedMCOTitle.innerHTML = _mcos2[mcoUUID].getName()
			changeTracker.getWarning('mcos', mcoUUID, orderContentAssignedMCOTitle)
			orderContentAssignedMCO.append(orderContentAssignedMCOTitle)

			let deliveryDate = (new Date(_mcos2[mcoUUID].getDate()*1000))

			let orderContentAssignedMCODate = document.createElement('div')
			orderContentAssignedMCODate.innerHTML = '<b>Delivery Date:</b> '+(new Date(deliveryDate.getTime())).toLocaleDateString('en-US', {timezone: millTimezone})
			orderContentAssignedMCODate.style.textAlign = 'center'
			orderContentAssignedMCODate.style.paddingTop = '8px'
			orderContentAssignedMCO.append(orderContentAssignedMCODate)

			let orderContentAssignedMCOOwner = document.createElement('div')
			orderContentAssignedMCOOwner.innerHTML = '<b>Owner:</b> '+_owners[_mcos2[mcoUUID].getOwner()].name
			orderContentAssignedMCOOwner.style.textAlign = 'center'
			orderContentAssignedMCOOwner.style.paddingTop = '8px'
			orderContentAssignedMCO.append(orderContentAssignedMCOOwner)

			let orderContentAssignedMCOFarm = document.createElement('div')
			orderContentAssignedMCOFarm.innerHTML = '<b>Farms:</b> '
			orderContentAssignedMCOFarm.style.textAlign = 'center'
			orderContentAssignedMCOFarm.style.paddingTop = '8px'
			orderContentAssignedMCO.append(orderContentAssignedMCOFarm)

			_mcos2[mcoUUID].getFarms().forEach((farmUUID) => {
				let assignedMCOFarmName = document.createElement('div')
				assignedMCOFarmName.innerHTML = _farms[farmUUID].name
				orderContentAssignedMCOFarm.append(assignedMCOFarmName)

				debug.log('Adding Farm "'+_farms[farmUUID].name+'" to MCO container list')
			})

			let orderContentAssignedMCOViewEdit = document.createElement('div')
			orderContentAssignedMCOViewEdit.classList.add('content-button')
			orderContentAssignedMCOViewEdit.style.width = 'min-content'
			orderContentAssignedMCOViewEdit.style.justifySelf = 'center'
			orderContentAssignedMCOViewEdit.innerHTML = 'View/Edit'
			orderContentAssignedMCOViewEdit.onclick = () => {
				debug.log('View/Edit button clicked - redirecting to MCO page')

				if(this.selectedMCOOrder == false){
					this.selectedMCOOrder = {}
				}
				this._subnavButtons.mco.classList.add('nav-asset-list-item-active')
				this._subnavButtons.fdo.classList.remove('nav-asset-list-item-active')
				this.selectedMCOOrder.data = _mcos2[mcoUUID].getAllData()

				this._divs.listTitle.innerHTML = 'Available MCO\'s:'
				this._buildMCOOrderContent()
				this._populateMCOOrderList()
			}
			orderContentAssignedMCO.append(orderContentAssignedMCOViewEdit)

			let orderContentAssignedMCOSpacer = document.createElement('div')
			orderContentAssignedMCO.append(orderContentAssignedMCOSpacer)

			let orderContentAssignedMCORemove = document.createElement('div')
			orderContentAssignedMCORemove.classList.add('content-button')
			orderContentAssignedMCORemove.style.width = 'min-content'
			orderContentAssignedMCORemove.style.justifySelf = 'center'
			orderContentAssignedMCORemove.innerHTML = 'Remove'
			orderContentAssignedMCORemove.onclick = () => {//FOR TESTING AND SIM PURPOSES
				debug.log('Remove button clicked...')

				let conflicts = false
				if(_mcos2[mcoUUID].getBins() != null && _mcos2[mcoUUID].getBins() != undefined){
					_mcos2[mcoUUID].getBins().forEach(binUUID =>{
						if(this.selectedFDOOrder.data.gates != null && this.selectedFDOOrder.data.gates != undefined){
							this.selectedFDOOrder.data.gates.forEach(gateObject => {
								if(gateObject.bins.includes(binUUID)){
									conflicts = true
								}
							})
						}
					})
				}

				if(conflicts){
					if(!confirm('By removine this MCO you will unassigned its bins from the current loadout it is in, would you like to proceed?')){
						return
					}
					let oldGates = []
					this.selectedFDOOrder.data.gates.forEach(object => {
						let newGateObject = {
							id: object.id,
							bins: []
						}
						object.bins.forEach(binUUID => {newGateObject.bins.push(binUUID)})
						oldGates.push(newGateObject)
					})//create old one for change tracker

					_mcos2[mcoUUID].getBins().forEach(binUUID =>{
						this.selectedFDOOrder.data.gates.forEach((gateObject, offset) => {
							if(gateObject.bins.includes(binUUID)){
								gateObject.bins.splice(gateObject.bins.indexOf(binUUID) ,1)
							}
						})
					})

					changeTracker.addToChanged('fdos', this.selectedFDOOrder.data.uuid, {'gates': oldGates}, {'gates': this.selectedFDOOrder.data.gates})
					debug.log("MCO prepped for removal from FDO - removed from loadout")
				}

				//_mcos[mcoUUID].assignedTo = 0//will handle in the update since we are not saved yet it does not make sense to update this yet
				let oldOrders = []
				this.selectedFDOOrder.data.mcos.forEach((uuid) => {oldOrders.push(uuid)})

				this.selectedFDOOrder.data.mcos.splice(this.selectedFDOOrder.data.mcos.indexOf(mcoUUID) ,1)
				_mcos2[mcoUUID].setAssignedTo(0)
 				changeTracker.addToChanged('fdos', this.selectedFDOOrder.data.uuid, {'mcos': oldOrders}, {'mcos': this.selectedFDOOrder.data.mcos})
				debug.log('Sent FDO mcos changes to changeTracker')
				this._buildFDOOrderContent()
			}
			orderContentAssignedMCO.append(orderContentAssignedMCORemove)
		})

		/******************************UPDATE BUTTON********************************/
		let orderContentUpdate = document.createElement('div')
		orderContentUpdate.classList.add('content-button')
		orderContentUpdate.style.gridColumn = '1/3'
		orderContentUpdate.style.width = 'min-content'
		orderContentUpdate.style.justifySelf = 'center'
		orderContentUpdate.innerHTML = 'Update'
		orderContentUpdate.onclick = () => {
			debug.log('Update button clicked...')

			let changes = changeTracker.getChanges('fdos', this.selectedFDOOrder.data.uuid)
			if(changes == false){ debug.log('No changes detected...returning'); return }

			let conflicts = false
			this.selectedFDOOrder.data.mcos.forEach((mcoUUID) => {
				if(changeTracker.contains('mcos', mcoUUID)){
					debug.log('Update failed due to an assigned MCO having unsaved changes');

					conflicts = true
					alertOverlay('MCO "'+_mcos2[mcoUUID].getName()+'" needs to be saved before continuing!')
					return
				}
			})
			if(conflicts == true){return}

			QReq.request(feedlokAPI.putFDOrderOrderID(this.selectedFDOOrder.data.uuid, changes)).then(resp => {
				//to make FDOs2 work
				_fdos2[resp.uuid].setAllData(resp)
				globalCache.setCacheListItem('fdos', {uuid: resp.uuid}, resp)

				debug.log('FDO successfully updated')
				changeTracker.clearChanges('fdos', this.selectedFDOOrder.data.uuid)
				debug.log('FDO changes removed from tracker')
				this._populateFDOOrderList()
				this._buildFDOOrderContent()
			}).catch((resp) => {
				if(resp.status == 403){
					debug.log('FDO update failed due to it being locked')
					orderContentMessage.innerHTML = '<font color ="red">This FDO is currently locked and can not be edited!</font>'
					orderContentMessage.style.display = 'block'
					setTimeout(() => {
						orderContentMessage.style.display = 'none'
					}, 3000)
					return
				}
				debug.log('Unknown update error - potential API outage')
				errorHandler(resp)
			})
		}
		this._divs.content.append(orderContentUpdate)

		/************************SENSITIVE BUTTONS*************************/
		let orderContentButtonsContainer = document.createElement('div')
		orderContentButtonsContainer.style.gridColumn = '1/3'
		orderContentButtonsContainer.style.width = 'min-content'
		orderContentButtonsContainer.style.justifySelf = 'center'

		orderContentButtonsContainer.style.display = 'grid'
		orderContentButtonsContainer.style.gridTemplateColumns = 'min-content min-content'

		this._divs.content.append(orderContentButtonsContainer)

		//TEMPORARY
		let orderContentMarkCompleteButton = document.createElement('div')
		orderContentMarkCompleteButton.classList.add('content-button', 'nowrap')
		orderContentMarkCompleteButton.style.width = 'min-content'
		orderContentMarkCompleteButton.style.justifySelf = 'center'
		orderContentMarkCompleteButton.innerHTML = 'Mark Complete'
		orderContentMarkCompleteButton.onclick = () => {
			if(orderContentTrailerSelect.value == "" || orderContentTrailerSelect.value == null){
				alert('You must assign this FDO to a trailer before marking it complete!')
				return
			}

			debug.log('Delete button clicked...')
			let confirmation = confirm('Are you sure you would like to mark this FDO complete?')
			if(confirmation == true){
				debug.log('Delete confirmed...')
				QReq.request(feedlokAPI.putFDOrdersOrderIDStatusStatus(this.selectedFDOOrder.data.uuid, 'complete')).then(resp => {
					debug.log('FDO successfully marked complete')
	
					//DELETE ALL MCOS
					_fdos2[this.selectedFDOOrder.data.uuid].getMCOs().forEach((mcoUUID) => {
						globalCache.deleteCacheListItemByUUID('mcos', mcoUUID)
						delete _mcos2[mcoUUID]
					})
	
					debug.log('MCOs assigned to FDO removed from cache')
	
					//DELETE FDO
					globalCache.deleteCacheListItemByUUID('fdos', this.selectedFDOOrder.data.uuid)
					delete _fdos2[this.selectedFDOOrder.data.uuid]
	
					debug.log('FDO removed from cache - reloading trailer FDO list')

					this.selectedFDOOrder = false
	
					this._populateFDOOrderList()
					this._resetOrderContent()
				}).catch((e) => {
					errorHandler(e)
				})
			}
		}
		orderContentButtonsContainer.append(orderContentMarkCompleteButton)

		let orderContentDeleteButton = document.createElement('div')
		orderContentDeleteButton.classList.add('content-button')
		orderContentDeleteButton.style.width = 'min-content'
		orderContentDeleteButton.style.justifySelf = 'center'
		orderContentDeleteButton.innerHTML = 'Delete'
		orderContentDeleteButton.onclick = () => {
			debug.log('Delete button clicked...')
			let confirmation = confirm('Are you sure you would like to delete this FDO?')
			if(confirmation == true){
				debug.log('Delete confirmed...')
				QReq.request(feedlokAPI.deleteFDOrderOrderID(this.selectedFDOOrder.data.uuid)).then(resp => {
					if(resp.status == 409){
						debug.log('FDO detected as being in use - delete failed')
						alert('This FDO can not be deleted due to being in use!')
						return
					}
					globalCache.deleteCacheListItem('fdos', _fdos2[this.selectedFDOOrder.data.uuid].getAllData())
					delete _fdos2[this.selectedFDOOrder.data.uuid]
					this.selectedFDOOrder = false
					debug.log('Delete successful and removed from cache')
					this._populateFDOOrderList()
					this._resetOrderContent()
				}).catch((e) => {
					errorHandler(e)
				})
			}
		}
		orderContentButtonsContainer.append(orderContentDeleteButton)

		/************************UNSAVED ALERT CENTER*************************/
		let orderContentAlert = document.createElement('div')
		orderContentAlert.classList.add('content-alert')
		orderContentAlert.innerHTML = 'THIS FDO HAS UNSAVED DATA'
		this._divs.content.append(orderContentAlert)
		this._divs.alert = orderContentAlert
		if(changeTracker.contains('fdos', this.selectedFDOOrder.data.uuid)){ this._showContentAlert(this._populateFDOOrderList()) }

		let orderContentAlertButtonContainer = document.createElement('div')
		orderContentAlertButtonContainer.style.fontSize = '1rem'
		orderContentAlertButtonContainer.style.textAlign = 'center'
		this._divs.alert.append(orderContentAlertButtonContainer)

		let orderContentAlertRevertButton = document.createElement('div')
		orderContentAlertRevertButton.classList.add('content-button')
		orderContentAlertRevertButton.style.fontSize = '1rem'
		orderContentAlertRevertButton.innerHTML = 'Revert Changes'
		orderContentAlertRevertButton.onclick = () => {
			debug.log('Revert Changes button clicked...')

			//re-assign the MCOs we unassigned
			let originalData = changeTracker.getOriginal('fdos', this.selectedFDOOrder.data.uuid)
			if(originalData.mcos !== undefined){
				originalData.mcos.forEach((mcoUUID) => {
					_mcos2[mcoUUID].setAssignedTo(this.selectedFDOOrder.data.uuid)
				})
			}

			//un-assign the MCOs we assigned
			let changedData = changeTracker.getChanges('fdos', this.selectedFDOOrder.data.uuid)
			if(changedData.mcos !== undefined){
				changedData.mcos.forEach((mcoUUID) => {
					_mcos2[mcoUUID].setAssignedTo(0)
				})
			}
			
			//we need to figure out how to tell differences between original and our changes

			this.selectedFDOOrder.data = changeTracker.revertChanges('fdos', this.selectedFDOOrder.data.uuid, this.selectedFDOOrder.data)
			_fdos2[this.selectedFDOOrder.data.uuid].setAllData(this.selectedFDOOrder.data)
			debug.log('Changes reverted and changeTracker cleared')

			this._populateFDOOrderList()
			this._buildFDOOrderContent()
		}
		orderContentAlertButtonContainer.append(orderContentAlertRevertButton)
	}

	/***************************************************************************LOADOUT*****************************************************************************************/
	/**
	 * Builds out the list on the left side of the main content and sorts list by specified field
	 */
	_populateTrailerList(){//create the left side list of Trailers
		debug.log('Populating Trailer list')
		DOMClearChildren(this._divs.list)

		globalCache.getCacheList('trailers').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)

		//GENERATE LIST
		globalCache.getCacheList('trailers').forEach((trailer) => {
			debug.log('Adding Trailer "'+trailer.name+'" to list')

			let mainContentGridTrailerButton = document.createElement('div')
			mainContentGridTrailerButton.classList.add('content-list-item')
			mainContentGridTrailerButton.innerHTML = trailer.name
			mainContentGridTrailerButton.onclick = () => {
				debug.log('Trailer "'+trailer.name+'" clicked - building list of associated FDOs...')
				//::TODO ADD API CALL FOR TRAILER ORDERS AND REMOVE THE FINISHED ONES
				this._populateTrailerFDOList(trailer.uuid)
			}
			this._divs.list.append(mainContentGridTrailerButton)
		})
	}

	/**
	 * Builds out the list on the left side of the main content with the FDOs assigned to the selected trailer
	 * @param {string} trailerUUID UUID of the trailer we selected
	 */
	_populateTrailerFDOList(trailerUUID){
		debug.log('Populating list of FDOs associated with Trailer UUID: '+trailerUUID)
		DOMClearChildren(this._divs.list)

		this._divs.listTitle.innerHTML = 'Quick Links:'

		let mainContentGridTrailerBackButton = document.createElement('div')
		mainContentGridTrailerBackButton.classList.add('content-list-item')
		mainContentGridTrailerBackButton.innerHTML = 'Back to Trailers'
		mainContentGridTrailerBackButton.onclick = () => {
			debug.log('Back to Trailer button clicked - sending back to trailer list...')
			this._divs.listTitle.innerHTML = 'Select a Trailer:'
			this._divs.listButtons.style.display = 'none'
			this._resetOrderContent()
			this._populateTrailerList()
		}
		this._divs.list.append(mainContentGridTrailerBackButton)

		let mainContentGridTrailerFDOsHeader = document.createElement('div')
		mainContentGridTrailerFDOsHeader.classList.add('content-title')
		mainContentGridTrailerFDOsHeader.style.width = '100%'
		mainContentGridTrailerFDOsHeader.style.borderRight = '0'
		mainContentGridTrailerFDOsHeader.style.marginLeft = '-4px'
		mainContentGridTrailerFDOsHeader.style.marginBottom = '4px'
		mainContentGridTrailerFDOsHeader.innerHTML = _trailers[trailerUUID].name+'\'s FDOs:'
		this._divs.list.append(mainContentGridTrailerFDOsHeader)

		let selectedFDO = false

		globalCache.getCacheList('fdos').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)

		//GENERATE LIST
		globalCache.getCacheList('fdos').forEach((fdoObject) => {
			if(fdoObject.trailer == trailerUUID){
				debug.log('Adding FDO "'+fdoObject.name+'" to list of FDOs associated with Trailer UUID: '+trailerUUID)
				let mainContentGridTrailerFDOButton = document.createElement('div')
				mainContentGridTrailerFDOButton.classList.add('content-list-item')
				mainContentGridTrailerFDOButton.innerHTML = fdoObject.name
				if(this.selectedLoadoutFDO == fdoObject.uuid){ mainContentGridTrailerFDOButton.classList.add('content-list-item-active'); selectedFDO = mainContentGridTrailerFDOButton }
				mainContentGridTrailerFDOButton.onclick = () => {
					if(selectedFDO !== false){
						selectedFDO.classList.remove('content-list-item-active')
					}
					mainContentGridTrailerFDOButton.classList.add('content-list-item-active')
					selectedFDO = mainContentGridTrailerFDOButton

					this.selectedLoadoutFDO = fdoObject.uuid

					debug.log('FDO "'+fdoObject.name+'"\'s link has been clicked - generating loadout screen')
					this._buildLoadoutContent(trailerUUID, fdoObject.uuid)
				}
				this._divs.list.append(mainContentGridTrailerFDOButton)

				if(fdoObject.uuid == _trailers[trailerUUID].order){
					mainContentGridTrailerFDOButton.style.borderColor='white'
					mainContentGridTrailerFDOButton.style.color='white'
					mainContentGridTrailerFDOButton.style.backgroundColor='green'
				}

				changeTracker.getWarning('fdos', fdoObject.uuid, mainContentGridTrailerFDOButton)
				changeTracker.getWarning('loadout', fdoObject.uuid, mainContentGridTrailerFDOButton)
			}
		})
	}

	_checkMultipleMCOBin(){
		let gateContainers = document.getElementsByClassName('gateContainer')
		Array.from(gateContainers).forEach(gateContainerDiv => {
			let gateDropDiv = gateContainerDiv.getElementsByClassName('gateDrop')[0]//should only be one

			let binUUIDs = []

			Array.from(gateDropDiv.children).forEach(binDiv => {
				if(!binUUIDs.includes(binDiv.getAttribute('binuuid'))){
					binUUIDs.push(binDiv.getAttribute('binuuid'))
				}
			})

			if(binUUIDs.length > 1){
				gateContainerDiv.style.border = '2px solid yellow'
				return
			}

			gateContainerDiv.style.border = 'none'
		})
	}

	/**
	 * Build main content area based off the FDO and Trailer selected
	 * @param {string} trailerUUID UUID of the Trailer we selected
	 * @param {string} fdoUUID UUID of the FDO we are trying to assign
	 */
	_buildLoadoutContent(trailerUUID, fdoUUID){//IF LOADOUT IS SUBMITTED HERE WE HAVE TO LOOP THROUGH AND SAVE FDO and MCO CHANGES
		debug.log('Creating loadout for FDO UUID: '+fdoUUID+' on trailer UUID: '+trailerUUID)
		DOMClearChildren(this._divs.content)

		let draggingElement = false
		let changingElementCount = 0
		let changingElementOldID = ""

		if(changeTracker.contains('fdos', fdoUUID)){
			debug.log('Looadout aborted due to FDO containing unsaved changes')
			alertOverlay('This loadout can not be edited because FDO "'+_fdos2[fdoUUID].getName()+'" currently has unsaved changes!')
			return
		}

		let conflicts = false
		if(_fdos2[fdoUUID].getMCOs() == undefined){_fdos2[fdoUUID].setMCOs([])}
		_fdos2[fdoUUID].getMCOs().forEach((mcoUUID) => {
			if(changeTracker.contains('mcos', mcoUUID)){
				conflicts = true
				debug.log('Looadout aborted due to FDO containing an MCO with unsaved changes')
				alertOverlay('This loadout can not be edited because it contains MCO "'+_mcos2[mcoUUID].getName()+'" which currently has unsaved changes!')
				return
			}
		})
		if(conflicts == true){return}

		let loadoutList
		let loadoutMap = {}//gate number to offset in loadoutList since gate 1 will not always be the first offset in the list

		if(_fdos2[fdoUUID].getGates() == undefined){
			debug.log('No previous loadout detected on FDO')
			loadoutList = []
		}else{
			debug.log('Previous loadout detected on FDO - building loadoutList and loadoutMap')
			loadoutList = _fdos2[fdoUUID].getGates()
			loadoutList.forEach((gateDefinition) => {
				loadoutMap[gateDefinition.id] = loadoutList.indexOf(gateDefinition)
			})
		}

		this._divs.title.innerHTML = 'Generate loadout...'
		this._divs.content.style.width = '100%'
		this._divs.content.style.justifySelf = 'center'
		this._divs.content.style.overflowY = 'auto'

		//BUILD BIN LIST SHIT FUCK SHIT
		debug.log('Building MCO and bin list in list button container')

		DOMClearChildren(this._divs.listButtons)
		this._divs.listButtons.style.display = 'block'

		let colors = [
			'#048FC4',
			'#CCCCCC',
			'#FF0000',
			'#00FF00',
			'#999999',
			'#FFFF00'
		]
		let currentColor = 0

		_fdos2[fdoUUID].getMCOs().forEach((mcoUUID) => {
			debug.log('Adding MCO "'+_mcos2[mcoUUID].getName()+'" to list')

			let mcoTitle = document.createElement('div')
			mcoTitle.classList.add('fdo-content-assigned-mco-title')
			mcoTitle.style.borderBottom = '1px solid '+colors[currentColor]
			mcoTitle.style.color = colors[currentColor]
			mcoTitle.style.background = '#000'
			mcoTitle.innerHTML = _mcos2[mcoUUID].getName()+' ('+_owners[_mcos2[mcoUUID].getOwner()].name+')'
			this._divs.listButtons.append(mcoTitle)

			_mcos2[mcoUUID].getBins().forEach((binUUID) => {
				debug.log('Adding bin "'+_bins[binUUID].name+'" to MCO list')

				let mcoBin = document.createElement('div')
				mcoBin.setAttribute('droppable', false)
				mcoBin.setAttribute('binUUID', binUUID)
				mcoBin.style.background = 'rgba(0, 0, 0, .7)'
				mcoBin.style.marginBottom = '4px'
				mcoBin.style.marginTop = '4px'
				mcoBin.style.padding = '2px'
				mcoBin.style.border = '1px solid '+colors[currentColor]
				mcoBin.style.color = colors[currentColor]
				this._divs.listButtons.append(mcoBin)

				mcoBin.draggable = true
				mcoBin.id = _farms[_bins[binUUID].farm].uuid+'-'+_bins[binUUID].uuid
				mcoBin.ondragstart = (event) => {
					debug.log('Started dragging bin "'+_bins[binUUID].name+'"...')

					draggingElement = event.target.id
					event.dataTransfer.setData("text", event.target.id)
					event.dataTransfer.setData("identifier", event.target.id.replace(/\s/g, ''))
				}

				mcoBin.ondragend = () => {
					debug.log('Stopped dragging bin "'+_bins[binUUID].name+'"...')

					draggingElement = false
				}

				let binTitle = document.createElement('div')
				binTitle.setAttribute('droppable', false)
				binTitle.style.fontWeight = 'bolder'
				binTitle.innerHTML = _bins[binUUID].name
				mcoBin.append(binTitle)

				let binFarm = document.createElement('div')
				binFarm.setAttribute('droppable', false)
				binFarm.innerHTML = 'Farm: '+_farms[_bins[binUUID].farm].name
				mcoBin.append(binFarm)
			})

			currentColor++
		})

		let mainContentGridLoadoutButtons = document.createElement('div')
		mainContentGridLoadoutButtons.style.display = 'grid'
		mainContentGridLoadoutButtons.style.gridTemplateColumns = '1fr 1fr'
		mainContentGridLoadoutButtons.style.columnGap = '4px'
		mainContentGridLoadoutButtons.style.marginLeft = '4px'
		mainContentGridLoadoutButtons.style.marginRight = '4px'
		this._divs.listButtons.append(mainContentGridLoadoutButtons)

		let sendToTrailer = false

		let mainContentGridLoadoutSaveButton = document.createElement('div')
		mainContentGridLoadoutSaveButton.classList.add('content-list-item')
		mainContentGridLoadoutSaveButton.innerHTML = 'Save Loadout'
		mainContentGridLoadoutSaveButton.onclick = () => {
			debug.log('Save Loadout button clicked...')

			loadoutList = loadoutList.filter(gateObject => gateObject.bins.length !== 0)//remove blank gates

			loadoutMap = {}//reset
			loadoutList.forEach((gateDefinition) => {
				loadoutMap[gateDefinition.id] = loadoutList.indexOf(gateDefinition)
			})

			let changes = {'gates': loadoutList}
			console.log(loadoutList)
			QReq.request(feedlokAPI.putFDOrderOrderID(fdoUUID, changes)).then(resp => {
				debug.log('Save successful..')

				changeTracker.clearChanges('loadout', fdoUUID)
				loadoutContentAlert.style.display = 'none'//hide unsaved warning

				this._populateTrailerFDOList(trailerUUID)//clear warning symbol

				_fdos2[fdoUUID].gates = loadoutList
				_fdos2[fdoUUID].setOverride(undefined)

				if(sendToTrailer == true){
					if(_trailers[trailerUUID].order !== null && _trailers[trailerUUID].order !== undefined){
						let confirmOrderSend = confirm('This trailer already has an order, would you like to continue?')
						if(!confirmOrderSend){return}
					}


					debug.log('Sending to trailer...')

					QReq.request(feedlokAPI.putTrailersTrailerIDOrderOrderID(trailerUUID, fdoUUID)).then(resp => {
						debug.log('Loadout sent to trailer successfully')

						//FOR SWITCHING THE OLD ASSIGNED ORDER TO UNASSIGNED IF NEEDED
						if(_trailers[trailerUUID].order !== null && _trailers[trailerUUID].order !== undefined && _trailers[trailerUUID].order.length > 0 && _fdos2[_trailers[trailerUUID].order] !== undefined){
							_fdos2[_trailers[trailerUUID].order].setStatus('pending')
						}

						_fdos2[fdoUUID].setAllData(resp.order)//because it should be locked now
						changeTracker.clearChanges('fdos', fdoUUID)//clear since we should be locked now
						_trailers[trailerUUID] = resp.trailer
						changeTracker.clearChanges('trailers', trailerUUID)//clear since we replaced it
						
						debug.log('Changing loadout buttons to "Mark Complete" and "Unassign"')
						mainContentGridLoadoutButtons.append(mainContentGridLoadoutMarkCompleteButton)
						mainContentGridLoadoutButtons.append(mainContentGridLoadoutUnassignButton)
						mainContentGridLoadoutButtons.removeChild(mainContentGridLoadoutSaveButton)
						mainContentGridLoadoutButtons.removeChild(mainContentGridLoadoutAssignButton)

						this._populateTrailerFDOList(trailerUUID, fdoUUID)
		
						loadoutMessageDiv.innerHTML = 'Sent to trailer!'
						loadoutMessageDiv.style.display = 'block'
						setTimeout(() => {
							loadoutMessageDiv.style.display = 'none'
						}, 3000)

						sendToTrailer = false
					}).catch((e) => {
						errorHandler(e)
					})
				}
				loadoutMessageDiv.innerHTML = 'Loadout Saved!'
				loadoutMessageDiv.style.display = 'block'
				setTimeout(() => {
					loadoutMessageDiv.style.display = 'none'
				}, 3000)
			}).catch((e) => {
				errorHandler(e)
			})
		}

		let mainContentGridLoadoutAssignButton = document.createElement('div')
		mainContentGridLoadoutAssignButton.classList.add('content-list-item')
		mainContentGridLoadoutAssignButton.innerHTML = 'Send to Trailer'
		mainContentGridLoadoutAssignButton.onclick = () => {
			debug.log('Send to Trailer button clicked...')
			sendToTrailer = true
			mainContentGridLoadoutSaveButton.click()
		}

		let mainContentGridLoadoutMarkCompleteButton = document.createElement('div')
		mainContentGridLoadoutMarkCompleteButton.classList.add('content-list-item')
		mainContentGridLoadoutMarkCompleteButton.innerHTML = 'Mark Complete'
		mainContentGridLoadoutMarkCompleteButton.onclick = () => {
			debug.log('Mark Complete button clicked...')
			QReq.request(feedlokAPI.putFDOrdersOrderIDStatusStatus(fdoUUID, 'complete')).then(resp => {
				debug.log('FDO successfully marked complete')

				//DELETE ALL MCOS
				_fdos2[fdoUUID].getMCOs().forEach((mcoUUID) => {
					globalCache.deleteCacheListItem('mcos', _mcos2[mcoUUID].getAllData())
					delete _mcos2[mcoUUID]
				})

				debug.log('MCOs assigned to FDO removed from cache')

				if(this.selectedFDOOrder !== false && this.selectedFDOOrder.data.uuid == fdoUUID){this.selectedFDOOrder = false}

				//DELETE FDO
				globalCache.deleteCacheListItem('fdos', _fdos2[fdoUUID].getAllData())
				delete _fdos2[fdoUUID]

				debug.log('FDO removed from cache - reloading trailer FDO list')

				this._populateTrailerFDOList(trailerUUID)
				DOMClearChildren(this._divs.content)
				DOMClearChildren(this._divs.listButtons)

				debug.log('UI Content and List Buttons cleared')
			}).catch((e) => {
				errorHandler(e)
			})
		}

		let mainContentGridLoadoutUnassignButton = document.createElement('div')
		mainContentGridLoadoutUnassignButton.classList.add('content-list-item')
		mainContentGridLoadoutUnassignButton.innerHTML = 'Remove from Trailer'
		mainContentGridLoadoutUnassignButton.onclick = () => {
			debug.log('Remove from Trailer button clicked...')

			QReq.request(feedlokAPI.deleteTrailersTrailerIDOrder(trailerUUID)).then(resp => {
				debug.log('FDO successfully removed from trailer')

				_fdos2[fdoUUID].setStatus('pending')
				_fdos2[fdoUUID].setOverride(undefined)
				_trailers[trailerUUID].order = null

				debug.log('Changing loadout buttons to "Saved Loadout" and "Send to Trailer"')
				mainContentGridLoadoutButtons.removeChild(mainContentGridLoadoutMarkCompleteButton)
				mainContentGridLoadoutButtons.removeChild(mainContentGridLoadoutUnassignButton)
				mainContentGridLoadoutButtons.append(mainContentGridLoadoutSaveButton)
				mainContentGridLoadoutButtons.append(mainContentGridLoadoutAssignButton)

				this._populateTrailerFDOList(trailerUUID)

				loadoutMessageDiv.innerHTML = 'Removed from trailer!'
				loadoutMessageDiv.style.display = 'block'
				setTimeout(() => {
					loadoutMessageDiv.style.display = 'none'
				}, 3000)
			}).catch((e) => {
				errorHandler(e)
			})
		}

		if(_fdos2[fdoUUID].getStatus() == "pending"){
			debug.log('FDO detected as not being sent to trailer')

			mainContentGridLoadoutButtons.append(mainContentGridLoadoutSaveButton)
			mainContentGridLoadoutButtons.append(mainContentGridLoadoutAssignButton)
		}else{
			debug.log('FDO detected as being sent to trailer already')

			if(_trailers[trailerUUID].order != fdoUUID){
				mainContentGridLoadoutUnassignButton.innerHTML = "Unlock Order"
				mainContentGridLoadoutUnassignButton.onclick = () => {
					debug.log('Unlock Order button clicked...')

					QReq.request(feedlokAPI.putFDOrderOrderID(fdoUUID, {"status": "pending"})).then(resp => {
						debug.log('FDO successfully unlocked')

						_fdos2[fdoUUID].setStatus('pending')

						debug.log('Changing loadout buttons to "Saved Loadout" and "Send to Trailer"')
						mainContentGridLoadoutButtons.removeChild(mainContentGridLoadoutMarkCompleteButton)
						mainContentGridLoadoutButtons.removeChild(mainContentGridLoadoutUnassignButton)
						mainContentGridLoadoutButtons.append(mainContentGridLoadoutSaveButton)
						mainContentGridLoadoutButtons.append(mainContentGridLoadoutAssignButton)

						loadoutMessageDiv.innerHTML = 'Order Unlocked!'
						loadoutMessageDiv.style.display = 'block'
						setTimeout(() => {
							loadoutMessageDiv.style.display = 'none'
						}, 3000)
					}).catch((e) => {
						errorHandler(e)
					})
				}
			}
			mainContentGridLoadoutButtons.append(mainContentGridLoadoutMarkCompleteButton)
			mainContentGridLoadoutButtons.append(mainContentGridLoadoutUnassignButton)
		}

		//BUILD DRAG AND DROP CONTENT
		debug.log('Building drag and drop...')

		let loadoutContentAlert = document.createElement('div')
		loadoutContentAlert.classList.add('content-alert')
		loadoutContentAlert.innerHTML = 'THIS LOADOUT HAS UNSAVED DATA'
		loadoutContentAlert.style.display = 'none'
		this._divs.content.append(loadoutContentAlert)
		if(changeTracker.contains('loadout', fdoUUID)){ loadoutContentAlert.style.display = 'block' }

		let loadoutContentAlertButtonContainer = document.createElement('div')
		loadoutContentAlertButtonContainer.style.fontSize = '1rem'
		loadoutContentAlertButtonContainer.style.textAlign = 'center'
		loadoutContentAlert.append(loadoutContentAlertButtonContainer)

		let loadoutContentAlertRevertButton = document.createElement('div')
		loadoutContentAlertRevertButton.classList.add('content-button')
		loadoutContentAlertRevertButton.style.fontSize = '1rem'
		loadoutContentAlertRevertButton.innerHTML = 'Revert Changes'
		loadoutContentAlertRevertButton.onclick = () => {
			_fdos2[fdoUUID].setAllData(changeTracker.revertChanges('loadout', fdoUUID, _fdos2[fdoUUID].getAllData()))//this is auto updates because loadoutList stores a reference to it

			this._buildLoadoutContent(trailerUUID, fdoUUID)
			this._populateTrailerFDOList(trailerUUID)
		}
		loadoutContentAlertButtonContainer.append(loadoutContentAlertRevertButton)

		let loadoutContainer = document.createElement('div')
		loadoutContainer.style.gridColumn = '1/3'
		loadoutContainer.style.justifySelf = 'center'
		loadoutContainer.style.width = '50%'
		this._divs.content.append(loadoutContainer)

		let loadoutMessageDiv = document.createElement('div')
		loadoutMessageDiv.style.textAlign = 'center'
		loadoutMessageDiv.style.gridColumn = '1/3'
		loadoutMessageDiv.style.color = 'green'
		loadoutMessageDiv.style.fontSize = '2rem'
		loadoutMessageDiv.innerHTML = 'Loadout saved!'
		loadoutMessageDiv.style.display = 'none'
		loadoutContainer.append(loadoutMessageDiv)

		//below is to store the bins that we Remove through the "Remove" button - we need this so our counts persist through the entire loadout session outherwise our counts would be off
		let loadoutRemovedBinContainer = document.createElement('div')
		loadoutRemovedBinContainer.style.height = '20px'
		loadoutRemovedBinContainer.style.backgroundImage = 'url(assets/img/feedlok/topCone.png)'
		loadoutRemovedBinContainer.style.backgroundSize = '100% 100%'
		loadoutContainer.append(loadoutRemovedBinContainer)

		let remainingGates = _trailers[trailerUUID].numBins
		while(remainingGates >= 1){
			debug.log('Building gate #'+(_trailers[trailerUUID].numBins-remainingGates+1))

			let gateContainer = document.createElement('div')
			gateContainer.classList.add('gateContainer')
			gateContainer.style.gridColumn = '1/3'
			gateContainer.style.backgroundImage = 'url(assets/img/feedlok/midGate.png)'
			if(remainingGates == 1){
				gateContainer.style.backgroundImage = 'url(assets/img/feedlok/lastGate.png)'
			}
			gateContainer.style.backgroundSize = '100% 100%'
			gateContainer.style.position = 'relative'
			loadoutContainer.append(gateContainer)

			let gateTitle = document.createElement('div')
			gateTitle.classList.add('fdo-content-assigned-mco-title')
			gateTitle.style.color = '#fff'
			gateTitle.style.background = '#000'
			gateTitle.style.gridColumn = '1/3'
			gateTitle.style.padding = '4px'
			gateTitle.style.fontSize = '1.25rem'
			gateTitle.draggable = false
			gateTitle.id = 'gate-'+(_trailers[trailerUUID].numBins-remainingGates+1)
			gateTitle.innerHTML = 'Gate #'+(_trailers[trailerUUID].numBins-remainingGates+1)
			gateContainer.append(gateTitle)

			let binDropDiv = document.createElement('div')
			binDropDiv.classList.add('gateDrop')
			//binDropDiv.style.border = '1px solid #048FC4'
			binDropDiv.style.padding = '4px'
			binDropDiv.style.textAlign = 'center'
			binDropDiv.style.gridColumn = '1/3'
			binDropDiv.style.color = '#fff'
			binDropDiv.style.minHeight = '50px'
			binDropDiv.draggable = false
			binDropDiv.id = 'gate-'+(_trailers[trailerUUID].numBins-remainingGates+1)+'-drop'
			gateContainer.append(binDropDiv)

			gateTitle.addEventListener('customDrop', (e) => {
				debug.log('customDrop event triggered on gate #'+(_trailers[trailerUUID].numBins-remainingGates+1))

				gateTitle.ondrop(e)
			})

			gateTitle.ondrop = (event) => {
				if(_fdos2[fdoUUID].getStatus() !== "pending" && event.dataTransfer.getData("specialCase") !== true){return}
				debug.log('onDrop event triggered on gate #'+(_trailers[trailerUUID].numBins-remainingGates+1))

				event.preventDefault()

				//check if the drop container has more than 2 mcos...if so we can not add more. Also check for a droppable false so we cant drop on mcos or titles
				if(document.getElementById(event.target.id+'-drop').childElementCount >= 2 || event.target.getAttribute('droppable') == 'false'){
					debug.log('Gate #'+(_trailers[trailerUUID].numBins-remainingGates+1)+' detected as having 2 bins already assigned')
					return
				}

				var data = event.dataTransfer.getData("text")//the ID of the element being dragged - assgined ondragstart
				let strippedID = 'a'+data.replace(/\s/g, '')//takes the ID of the element and removes whitespaces - there shouldnt be any anyway
				let newGate = parseInt(event.target.id.replace('gate-', ''))-1//the gate number (1-12)
				
				//START REASSIGNMENT
				if(document.getElementById(data) == undefined){//if we are reassigning an MCO from one gate to another - this works because the "text" dataTransfer should be empty
					let changingDiv = document.querySelectorAll('['+event.dataTransfer.getData('oldID')+'=\''+event.dataTransfer.getData('count')+'\']')[0]//div we are moving - we have to fetch by query so we make sure we get the correct element
					let oldGate = parseInt(changingDiv.parentElement.id.replace('gate-', '').replace('-drop', ''))-1//the original gate number (1-12)

					let binUUID = changingDiv.getAttribute('binUUID')//uuid of the farm bin that is being transfered from one gate to another

					debug.log('Bin gate reassignment drop event detected for bin UUID: "'+binUUID+'"...')

					//start checking and preparing the assign list
					if(loadoutMap[newGate] == undefined){//if we currently do not have the new gate in the loadout - this means no bins have been assigned to it
						debug.log('Desired gate has not been created in the loadout yet...creating...')

						let original = []
						loadoutList.forEach((gateObj) => {
							original.push(JSON.parse(JSON.stringify(gateObj)))
						})

						loadoutList.push({'id': newGate, 'bins': [binUUID]})//add the new gate to the loadout

						changeTracker.addToChanged('loadout', fdoUUID, {'gates': original}, {'gates': loadoutList})

						loadoutMap[newGate] = loadoutList.length-1

						let oldGateObj = loadoutList[loadoutMap[oldGate]]//get the old gate loadout
						oldGateObj.bins.splice(oldGateObj.bins.indexOf(binUUID), 1)//remove the reassigned bin from the loadout
						loadoutContentAlert.style.display = 'block'//show unsaved warning
						this._populateTrailerFDOList(trailerUUID)
					}else{//if the gate already had a bin in it, or even currently has a bin in it
						debug.log('Desired gate already exists - swapping bin from original to new...')

						let newGateObj = loadoutList[loadoutMap[newGate]]//where we are placing the bin
						let oldGateObj = loadoutList[loadoutMap[oldGate]]//where we are taking the bin from

						if(newGateObj.bins.length >=2){console.log('This should never show...');return}//redundancy check just to make sure we do not already have 2 bins in gate
						if(newGateObj.bins.includes(binUUID)){return}//if the reassigned bin is already in the gate we need to ignore it...we dont want it in there twice

						let original = []
						loadoutList.forEach((gateObj) => {
							original.push(JSON.parse(JSON.stringify(gateObj)))
						})

						oldGateObj.bins.splice(oldGateObj.bins.indexOf(binUUID), 1)//remove bin from old gate
						newGateObj.bins.push(binUUID)//add bin to new gate

						changeTracker.addToChanged('loadout', fdoUUID, {'gates': original}, {'gates': loadoutList})

						loadoutContentAlert.style.display = 'block'//show unsaved warning
						this._populateTrailerFDOList(trailerUUID)
					}

					changingDiv.ondragstart = () => {//when we start to drag the bin we want to hold the information about it so we can create a simulated ondrop
						debug.log('Dragging started for bin UUID: '+event.dataTransfer.getData('oldID')+' clone')

						//for recreating drag event
						draggingElement = false
						changingElementCount = event.dataTransfer.getData('count')
						changingElementOldID = event.dataTransfer.getData('oldID')
					}

					document.getElementById(event.target.id+'-drop').append(changingDiv)//moves HTML element from old gate to new gate
					
					debug.log('Gate reassignment complete')

					//check if we have more than 1 mco in the gate to display warning border
					this._checkMultipleMCOBin()

					return 
				}

				//START NEW ASSIGNMENT
				debug.log('Bin gate assignment drop event detected for bin UUID: "'+document.getElementById(data).getAttribute('binUUID')+'"...')

				debug.log('Creating bin clone...')
				let binClone = document.getElementById(data).cloneNode(true)//cloned from the left menu options
				binClone.style.display = 'block'
				binClone.id = ''//this needs to be blank so .replace works and we know its a clone
				
				let binRemoveButton = document.createElement('div')//add removal button to clonse
				binRemoveButton.style.color = 'red'
				binRemoveButton.style.cursor = 'pointer'
				binRemoveButton.innerHTML = 'REMOVE'
				binRemoveButton.onclick = () => {
					if(_fdos2[fdoUUID].getStatus() !== "pending"){return}
					let gateNumber = parseInt(binClone.parentElement.id.replace('gate-', '').replace('-drop', ''))-1//get what gate the clone is assigned to

					let original = []
					loadoutList.forEach((gateObj) => {
						original.push(JSON.parse(JSON.stringify(gateObj)))
					})

					let oldGateObj = loadoutList[loadoutMap[gateNumber]]//the gate loadout object the bin is assigned to
					if(oldGateObj !== undefined){
						oldGateObj.bins.splice(oldGateObj.bins.indexOf(document.getElementById(data).getAttribute('binUUID')), 1)//remove bin from gate
					}

					changeTracker.addToChanged('loadout', fdoUUID, {'gates': original}, {'gates': loadoutList})

					debug.log('Bin UUID: '+document.getElementById(data).getAttribute('binUUID')+' unassigned from '+binClone.parentElement.id.replace('-drop', ''))
					loadoutRemovedBinContainer.append(binClone)//move out of gate drop so we can accurately detect children
					binClone.style.display = 'none'//just hide so that offsets remain the same in querySelectorAll for populating the count attribute
					loadoutContentAlert.style.display = 'block'//show unsaved warning
					this._populateTrailerFDOList(trailerUUID)

					//check if we have more than 1 mco in the gate to display warning border
					this._checkMultipleMCOBin()
				}
				binClone.append(binRemoveButton)
				debug.log('Remove button added to bin clone...')

				binClone.setAttribute(strippedID, document.querySelectorAll('['+strippedID+']').length)//ID=count so we can properly grab the div

				//do we need this?
				if(data !== ''){//if we are dealing with a clone
					debug.log('Need Unknown - Non-empty "data" variable detected')

					binClone.ondragstart = (event) => {
						debug.log('Need Unknown - ondragstart event created')

						event.dataTransfer.setData('oldID', strippedID)//this is also an attribute name for easing tracking
						event.dataTransfer.setData('count', binClone.getAttribute(strippedID))//this is which

						//so we can recreate drag event
						draggingElement = false
						changingElementCount = event.dataTransfer.getData('count')
						changingElementOldID = event.dataTransfer.getData('oldID')
					}
				}
				//event.target.appendChild(binClone)
				//event.target.innerHTML = 'Drag here to add...'
				let binUUID = document.getElementById(data).getAttribute('binUUID')//uuid of the farm bin

				if(loadoutMap[newGate] == undefined){//new gate that hasnt had anything assigned to it
					debug.log('Bin being assigned to empty/new gate')

					let original = []
					loadoutList.forEach((gateObj) => {
						original.push(JSON.parse(JSON.stringify(gateObj)))
					})

					loadoutList.push({'id': newGate, 'bins': [binUUID]})//add the new gate to the loadout object

					changeTracker.addToChanged('loadout', fdoUUID, {'gates': original}, {'gates': loadoutList})

					loadoutMap[newGate] = loadoutList.length-1//set the newly pushed object to the map so we can easily track whats in each gate using a map
				}else{//existing gate
					debug.log('Bin being assigned to existing gate')
					
					let newGateObj = loadoutList[loadoutMap[newGate]]//the gate exists so just check and add to it

					if(newGateObj.bins.length > 2){console.log('This should never show...');return}//redundancy for checking to make sure there arent 2 or more bins assigned
					if(newGateObj.bins.includes(binUUID) && event.dataTransfer.getData("specialCase") !== true){return}//if its a special case (page generation) we do not need to add to gate since its already there

					if(event.dataTransfer.getData("specialCase") !== true){
						debug.log('Drop event not tagged as special case - add to ladoutList')

						let original = []
						loadoutList.forEach((gateObj) => {
							original.push(JSON.parse(JSON.stringify(gateObj)))
						})

						newGateObj.bins.push(binUUID)//add to gate if its not loading the saved loadout

						changeTracker.addToChanged('loadout', fdoUUID, {'gates': original}, {'gates': loadoutList})
					}
				}

				document.getElementById(event.target.id+'-drop').append(binClone)//add the bin to the drop container
				debug.log('Bin successfully added to gate #'+binClone.parentElement.id.replace('-drop', ''))

				//check if we have more than 1 mco in the gate to display warning border
				this._checkMultipleMCOBin()

				if(event.dataTransfer.getData("specialCase") !== true){
					loadoutContentAlert.style.display = 'block'//show unsaved warning
					this._populateTrailerFDOList(trailerUUID)
				}

				//console.log('Bin: '+document.getElementById(data).getAttribute('binUUID')+' assigned to '+event.target.id)
			}
			
			gateTitle.ondragover = (event) => {
				event.preventDefault()
			}

			let trueGateNumber = _trailers[trailerUUID].numBins-remainingGates//this is used to search the current loadout to see if there are any assigned bins to it
			if(loadoutList[loadoutMap[trueGateNumber]] !== undefined){
				debug.log('Gate detected as not being empty...adding bins...')

				loadoutList[loadoutMap[trueGateNumber]].bins.forEach((binUUID) => {
					debug.log('Bin UUID: "'+binUUID+'" is being added to gate #'+(trueGateNumber+1)+' - triggered customDrop event with specialCase')

					let desiredElementID = _farms[_bins[binUUID].farm].uuid+'-'+_bins[binUUID].uuid//has to match the bin ID so we know its a new clone
					let event = new CustomEvent('customDrop')//create a simulated on drop
					event.dataTransfer = {
						data: {
							text: desiredElementID,
							identifier: desiredElementID.replace(/\s/g, ''),
							specialCase: true
						},
						getData: function (type){
							return this.data[type]
						}
					}

					document.getElementById('gate-'+(trueGateNumber+1)).dispatchEvent(event)//fires the simulate ondrop
				})
			}

			//for entire container drop
			let gateDropDiv = document.createElement('div')//overlay the entire gate container
			gateDropDiv.classList.add('dropOverlay')
			gateDropDiv.style.position = 'absolute'
			gateDropDiv.style.top = '0'
			gateDropDiv.style.left = '0'
			gateDropDiv.style.width = '100%'
			gateDropDiv.style.height = '100%'
			gateDropDiv.style.background = 'rgba(0, 0, 0, .5)'
			gateDropDiv.style.display = 'none'
			//gateDropDiv.style.pointerEvents = 'none'
			gateContainer.ondragover = () => {//when over the gateContainer display the overlay
				debug.log('Gate #'+(trueGateNumber+1)+' hover detected - displaying overlay')

				gateDropDiv.style.display = 'block'
			}

			gateDropDiv.ondragover = (event) => {
				event.preventDefault()
			}

			gateDropDiv.ondrop = (event) => {
				debug.log('Gate #'+(trueGateNumber+1)+' hover leaved detected - checking action...')

				debug.log('Action detected as a drop on the gate overlay - Assigning to gate #'+(trueGateNumber+1)+' with customDrop event and no specialCase...')

				let newEvent = new CustomEvent('customDrop')

				if(draggingElement !== false){//if we are dragging a new mco from list menu
					debug.log('Dragged item detected as original from MCO list on left menu...')

					//ondrop simulation for NEW mco
					newEvent.dataTransfer = {
						data: {
							text: draggingElement,
							identifier: draggingElement.replace(/\s/g, ''),
						},
						getData: function (type){
							return this.data[type]
						}
					}
				}else{//if we are reassigning from one gate to another
					debug.log('Dragged item detected as a bin clone being reassigned...')

					//ondrop simulation for reassignment mco
					newEvent.dataTransfer = {
						data: {//different data to make things work properly
							"text": "",//blank so we dont error out on .replace()
							"count": changingElementCount,
							"oldID": changingElementOldID,
						},
						getData: function (type){
							return this.data[type]
						}
					}
				}

				document.getElementById('gate-'+(trueGateNumber+1)).dispatchEvent(newEvent)//simulate on drop
				
				gateDropDiv.style.display = 'none'//hide overlay
			}

			gateDropDiv.ondragleave = (event) => {
				gateDropDiv.style.display = 'none'
			}

			gateContainer.append(gateDropDiv)

			remainingGates--
		}
	}
}
