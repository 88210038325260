'use strict';
/*
 *
 */

export class Feedlok {
	constructor() {
		this._apiUrl = '/api/feedlok/v2'
	}

	buildURL(path, query = ''){
		return {
			host: this._apiUrl,
			path: path,
			query: query
		}
	}

	authHeaders(){
		return {
			'Content-Type': 'application/json; charset=utf-8',
			'user': JSON.parse(localStorage.getItem('user')).uuid,
			'token': JSON.parse(localStorage.getItem('user')).token,
		}
	}

	getUsers() {
		return {
			url: this.buildURL(`/users`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	postUsers(newUser) {
		return {
			url: this.buildURL(`/users`),
			method: 'POST',
			headers: this.authHeaders(),
			respType: 'json',
			body: JSON.stringify(newUser)
		}
	}

	putUsersUserID(userID, user) {
		return {
			url: this.buildURL(`/users/user/${userID}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
			body: JSON.stringify(user)
		}
	}

	postUsersUserIDChangepasswd(userID, passwords) {
		return {
			url: this.buildURL(`/users/user/${userID}/changepasswd`),
			method: 'POST',
			headers: this.authHeaders(),
			respType: 'json',
			body: JSON.stringify(passwords)
		}
	}

	deleteUserUserID(userID) {
		return {
			url: this.buildURL(`/users/user/${userID}`),
			method: 'DELETE',
			headers: this.authHeaders(),
			respType: 'none',
		}
	}

	postLogin(username, password) {
		return {
			url: this.buildURL(`/users/login`),
			method: 'POST',
			body: JSON.stringify({
				username: username,
				password: password
			}),
			respType: 'json'
		}
	}

	postUsersValidate() {
		return {
			url: this.buildURL(`/users/validate`),
			method: 'POST',
			headers: this.authHeaders(),
			respType: 'none'
		}
	}

	getMill() {
		return {
			url: this.buildURL(`/mill`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	putMill(mill) {
		return {
			url: this.buildURL(`/mill`),
			method: 'PUT',
			headers: this.authHeaders(),
			body: JSON.stringify(mill),
			respType: 'json'
		}
	}

	getMCOrders(query = '?status=pending&status=locked') {
		return {
			url: this.buildURL(`/mcos`, query),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	getMCOrdersCount() {
		return {
			url: this.buildURL(`/mcos/count`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	postMCOrders(req) {
		return {
			url: this.buildURL(`/mcos`),
			method: 'POST',
			headers: this.authHeaders(),
			body: JSON.stringify(req),
			respType: 'json'
		}
	}

	getMCOrdersOrderID(orderID) {
		return {
			url: this.buildURL(`/mcos/${orderID}`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	getMCOrdersOrderIDLog(orderID) {
		return {
			url: this.buildURL(`/mcos/${orderID}/log`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	putMCOrderOrderID(orderID, order) {
		return {
			url: this.buildURL(`/mcos/${orderID}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
			body: JSON.stringify(order)
		}
	}

	deleteMCOrderOrderID(orderID) {
		return {
			url: this.buildURL(`/mcos/${orderID}`),
			method: 'DELETE',
			headers: this.authHeaders(),
			respType: 'none',
		}
	}

	getFDOrders(query = '?status=pending&status=locked') {
		return {
			url: this.buildURL(`/orders`, query),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	postFDOrders(req) {
		return {
			url: this.buildURL(`/orders`),
			method: 'POST',
			headers: this.authHeaders(),
			body: JSON.stringify(req),
			respType: 'json'
		}
	}

	getFDOrdersOrderID(orderID) {
		return {
			url: this.buildURL(`/orders/${orderID}`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	getFDOrdersOrderIDLog(orderID) {
		return {
			url: this.buildURL(`/orders/${orderID}/log`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	getFDOrdersOrderIDLogPDF(orderID) {
		return {
			url: this.buildURL(`/orders/${orderID}/log/pdf`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: ''
		}
	}

	putFDOrderOrderID(orderID, order) {
		return {
			url: this.buildURL(`/orders/${orderID}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
			body: JSON.stringify(order)
		}
	}

	putFDOrdersOrderIDStatusStatus(trainingID, newStatus) {
		return {
			url: this.buildURL(`/orders/${trainingID}/status/${newStatus}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
		}
	}

	deleteFDOrderOrderID(orderID) {
		return {
			url: this.buildURL(`/orders/${orderID}`),
			method: 'DELETE',
			headers: this.authHeaders(),
			respType: 'none',
		}
	}

	getOwners(query = '') {
		return {
			url: this.buildURL(`/owners`, query),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	putOwnersOwnerID(ownerID, owner) {
		return {
			url: this.buildURL(`/owners/${ownerID}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
			body: JSON.stringify(owner)
		}
	}

	postOwners(req) {
		return {
			url: this.buildURL(`/owners`),
			method: 'POST',
			headers: this.authHeaders(),
			body: JSON.stringify(req),
			respType: 'json'
		}
	}

	deleteOwnersOwnerID(ownerID) {
		return {
			url: this.buildURL(`/owners/${ownerID}`),
			method: 'DELETE',
			headers: this.authHeaders(),
			respType: 'none',
		}
	}

	getFarms(query = '') {
		return {
			url: this.buildURL(`/farms`, query),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	getFarmsByOwner(ownerUUID) {
		return {
			url: this.buildURL(`/owners/${ownerUUID}/farms`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	putFarmsFarmID(farmID, farm) {
		return {
			url: this.buildURL(`/farms/${farmID}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
			body: JSON.stringify(farm)
		}
	}

	postFarms(req) {
		return {
			url: this.buildURL(`/farms`),
			method: 'POST',
			headers: this.authHeaders(),
			body: JSON.stringify(req),
			respType: 'json'
		}
	}

	deleteFarmsFarmID(farmID) {
		return {
			url: this.buildURL(`/farms/${farmID}`),
			method: 'DELETE',
			headers: this.authHeaders(),
			respType: 'none',
		}
	}

	getBins(query = '') {
		return {
			url: this.buildURL(`/bins`, query),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	getBinsByFarm(farmUUID) {
		return {
			url: this.buildURL(`/farms/${farmUUID}/bins`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	putBinsBinID(binID, bin) {
		return {
			url: this.buildURL(`/bins/${binID}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
			body: JSON.stringify(bin)
		}
	}

	postBins(req) {
		return {
			url: this.buildURL(`/bins`),
			method: 'POST',
			headers: this.authHeaders(),
			body: JSON.stringify(req),
			respType: 'json'
		}
	}

	deleteBinsBinIDTraining(binID) {
		return {
			url: this.buildURL(`/bins/${binID}/training`),
			method: 'DELETE',
			headers: this.authHeaders(),
			respType: 'none',
		}
	}

	deleteBinsBinID(binID) {
		return {
			url: this.buildURL(`/bins/${binID}`),
			method: 'DELETE',
			headers: this.authHeaders(),
			respType: 'none',
		}
	}

	getTrailers(query = '') {
		return {
			url: this.buildURL(`/trailers`, query),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	putTrailersTrailerID(trailerID, trailer) {
		return {
			url: this.buildURL(`/trailers/${trailerID}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
			body: JSON.stringify(trailer)
		}
	}

	putTrailersTrailerIDTrainingTrainingID(trailerID, trainingID) {
		return {
			url: this.buildURL(`/trailers/${trailerID}/training/${trainingID}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
		}
	}

	putTrailersTrailerIDOrderOrderID(trailerID, orderID) {
		return {
			url: this.buildURL(`/trailers/${trailerID}/order/${orderID}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
		}
	}

	deleteTrailersTrailerIDOrder(trailerID) {
		return {
			url: this.buildURL(`/trailers/${trailerID}/order`),
			method: 'DELETE',
			headers: this.authHeaders(),
			respType: 'none',
		}
	}

	deleteTrailersTrailerID(trailerID) {
		return {
			url: this.buildURL(`/trailers/${trailerID}`),
			method: 'DELETE',
			headers: this.authHeaders(),
			respType: 'none',
		}
	}

	postTrailers(req) {
		return {
			url: this.buildURL(`/trailers`),
			method: 'POST',
			headers: this.authHeaders(),
			body: JSON.stringify(req),
			respType: 'json'
		}
	}

	getTraining(query = '?status=pending&status=locked') {
		return {
			url: this.buildURL(`/training`, query),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	postTraining(req) {
		return {
			url: this.buildURL(`/training`),
			method: 'POST',
			headers: this.authHeaders(),
			body: JSON.stringify(req),
			respType: 'json'
		}
	}

	putTrainingTrainingID(trainingID, training) {
		return {
			url: this.buildURL(`/training/${trainingID}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
			body: JSON.stringify(training)
		}
	}

	getTrainingTrainingIDLog(trainingID) {
		return {
			url: this.buildURL(`/training/${trainingID}/log`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	putTrainingTrainingIDStatusStatus(trainingID, newStatus) {
		return {
			url: this.buildURL(`/training/${trainingID}/status/${newStatus}`),
			method: 'PUT',
			headers: this.authHeaders(),
			respType: 'json',
		}
	}

	deleteTrainingTrainingID(trainingID) {
		return {
			url: this.buildURL(`/training/${trainingID}`),
			method: 'DELETE',
			headers: this.authHeaders(),
			respType: 'none',
		}
	}

	getV1Reports(query = '?status=pending&status=locked') {
		return {
			url: this.buildURL(`/legacy/v1/orders`, query),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	getV1ReportsCount() {
		return {
			url: this.buildURL(`/legacy/v1/orders/count`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	getV1ReportReportIDLog(reportID) {
		return {
			url: this.buildURL(`/legacy/v1/orders/${reportID}/log`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: 'json'
		}
	}

	getV1ReportReportIDLogPDF(reportID) {
		return {
			url: this.buildURL(`/legacy/v1/orders/${reportID}/log/pdf`),
			method: 'GET',
			headers: this.authHeaders(),
			respType: ''
		}
	}
}
