'use strict';

import {globalCache} from './main';

export class FDO{
    constructor(data){
        this._rawData = data

        this.setAllData(data)
    }

    setAllData(data){
        this.created = data.created
        this.customid = data.customid

        this.earliestMCODate = undefined

        this.gates = data.gates

        this.lastUpdate = data.lastUpdate

        this.mcos = data.mcos
        this.mill = data.mill

        this.name = data.name

        this.override = data.override

        this.status = data.status
        this.statusTimestamp = data.statusTimestamp

        this.trailer = data.trailer

        this.uuid = data.uuid
    }

    getAllData(){
        return {
            uuid: this.uuid,
            created: this.created,
            customid: this.customid,
            earliestMCODate: this.earliestMCODate,
            gates: this.gates,
            lastUpdate: this.lastUpdate,
            mcos: this.mcos,
            mill: this.mill,
            name: this.name,
            override: this.override,
            status: this.status,
            statusTimestamp: this.statusTimestamp,
            trailer: this.trailer,
        }
    }

    getUUID(){
        return this.uuid
    }

    getName(){
        return this.name
    }

    setName(name){
        this.name = name
        globalCache.setCacheListItem('fdos', {uuid: this.uuid}, {name: this.name})
    }

    getGates(){
        return this.gates
    }

    setGates(gates){
        this.gates = gates
        globalCache.setCacheListItem('fdos', {uuid: this.uuid}, {gates: this.gates})
    }

    getMCOs(){
        if(this.mcos == undefined){this.mcos = []}

        return this.mcos
    }

    setMCOs(mcos){
        this.mcos = mcos
        globalCache.setCacheListItem('fdos', {uuid: this.uuid}, {mcos: this.mcos})
    }

    addMCO(mcoUUID){
        if(this.mcos == undefined){this.mcos = []}

        this.mcos.push(mcoUUID)
        globalCache.setCacheListItem('fdos', {uuid: this.uuid}, {mcos: this.mcos})
    }

    removeMCO(mcoUUID){
        this.mcos.splice(this.mcos.indexOf(mcoUUID), 1)
        globalCache.setCacheListItem('fdos', {uuid: this.uuid}, {mcos: this.mcos})
    }

    getEarliestMCODate(){
        return this.earliestMCODate
    }

    setEarliestMCODate(date){
        this.earliestMCODate = date
        globalCache.setCacheListItem('fdos', {uuid: this.uuid}, {earliestMCODate: this.earliestMCODate})
    }

    getOverride(){
        return this.override
    }

    setOverride(override){
        this.override = override
        globalCache.setCacheListItem('fdos', {uuid: this.uuid}, {override: this.override})
    }

    getStatus(){
        return this.status
    }

    setStatus(status){
        this.status = status
        globalCache.setCacheListItem('fdos', {uuid: this.uuid}, {stats: this.status})
    }
}