'use strict';

import {QReq, DOMClearChildren} from '../ms/ms.js';
import {debug} from '../ms/debug';
import {_bins, _farms, _fdos2, _mcos2, _owners, _trailers, _training, feedlokAPI, globalCache, overlay} from './main';

export class TrainingPage {
	constructor() {
		this._divs = {}
		this.selectedOwner = false
		this.selectedTrailer = false

		this._fakeTrainingOrders = []//{trailerUUID: 0, farmUUID: 0, bins: [uuids]}
	}

	generateButton(button) {
		button.div.classList.add('navbar-items-item')
		button.icon.classList.add('navbar-items-icon')
		button.icon.classList.add('fa')
		button.icon.classList.add('fa-book')
		button.text.classList.add('large')
		button.text.innerHTML = 'Training'
		button.div.append(button.icon)
		button.div.append(button.text)
	}

	generateSubnav(subnav) {//hard codes menu since it wont be dynamic...
		let listLink = document.createElement('div')
		listLink.classList.add('nav-asset-list-item')
		listLink.innerHTML = 'Asset List'
		listLink.onclick = () => {
			ordersLink.classList.remove('nav-asset-list-item-active')
			listLink.classList.add('nav-asset-list-item-active')
			this.generateContentGrid()
		}
		subnav.append(listLink)

		let ordersLink = document.createElement('div')
		ordersLink.classList.add('nav-asset-list-item')
		ordersLink.innerHTML = 'Training Orders'
		ordersLink.onclick = () => {
			ordersLink.classList.add('nav-asset-list-item-active')
			listLink.classList.remove('nav-asset-list-item-active')
			this.generateContentGrid('orders')
		}
		subnav.append(ordersLink)
	}

	generateContent(content) {
		content.classList.add('dashboard', 'content-page')

		let mainContentSection = document.createElement('div')
		mainContentSection.classList.add('card', 'dashboard-content')
		content.append(mainContentSection)

		this.mainContent = mainContentSection
	}

	/**
	 * Build the main content grid
	 * @param {string} link Whether we are viewing the Asset List or Training Orders
	 */
	generateContentGrid(link = 'list'){//basic layout grid for the sections we will need to edit
		debug.log('Link "'+link+'" clicked - Generating content...')

		DOMClearChildren(this.mainContent)

		let mainContentGrid = document.createElement('div')
		mainContentGrid.classList.add('content-layout')
		this.mainContent.append(mainContentGrid)

		let mainContentGridListTitle = document.createElement('div')
		mainContentGridListTitle.classList.add('content-title')
		mainContentGridListTitle.innerHTML = 'Owners:'
		mainContentGrid.append(mainContentGridListTitle)
		this._divs.listTitle = mainContentGridListTitle

		let mainContentGridContentTitle = document.createElement('div')
		mainContentGridContentTitle.classList.add('content-title')
		mainContentGridContentTitle.style.borderRight = 'none'
		mainContentGridContentTitle.innerHTML = 'Farms:'
		mainContentGrid.append(mainContentGridContentTitle)
		this._divs.title = mainContentGridContentTitle

		let mainContentGridList = document.createElement('div')
		mainContentGridList.classList.add('content-list')
		mainContentGridList.style.borderBottom = 'none'
		mainContentGrid.append(mainContentGridList)
		this._divs.list = mainContentGridList

		let mainContentGridContent = document.createElement('div')
		mainContentGridContent.classList.add('content-order-panel')
		mainContentGrid.append(mainContentGridContent)
		this._divs.content = mainContentGridContent

		let mainContentGridListButtons = document.createElement('div')
		mainContentGridListButtons.classList.add('content-list-buttons')
		mainContentGrid.append(mainContentGridListButtons)
		this._divs.listButtons = mainContentGridListButtons

		let mainContentGridCustomOrderButton = document.createElement('div')
		mainContentGridCustomOrderButton.classList.add('content-button')
		mainContentGridCustomOrderButton.innerHTML = '+ Custom Order'
		mainContentGridCustomOrderButton.onclick = () => {this.generateNewCustomOrderOverlay()}
		mainContentGridListButtons.append(mainContentGridCustomOrderButton)

		if(link == 'list'){
			this._divs.listTitle.innerHTML = 'Owners'
			this._divs.listButtons.style.display = 'block'
			this._populateOwnerList()
		}else{
			this._divs.listButtons.style.display = 'none'
			this._populateTrailerList()
		}
	}

	/**********************************************************************************CUSTOM ORDER****************************************************************************/
	generateNewCustomOrderOverlay(){
		debug.log('Generating new customer training order overlay...')
		overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
			let selectedBins = []

			let div = document.createElement('div')
			div.classList.add('overlay-container')
			overlayDiv.append(div)

			let title = document.createElement('div')
			title.classList.add('overlay-title')
			title.innerHTML = 'New Custom Training Order'
			div.append(title)

			//ORDER INFORMATION SECTION
			let orderFormGrid = document.createElement('div')
			orderFormGrid.style.display = 'grid'
			orderFormGrid.style.gridTemplateColumns = '1fr 1fr'
			orderFormGrid.style.fontSize = '1.5rem'
			orderFormGrid.style.marginTop = '4px'
			orderFormGrid.style.rowGap = '4px'
			div.append(orderFormGrid)

			let orderName = document.createElement('div')
			orderName.innerHTML = 'Name:'
			orderFormGrid.append(orderName)

			let orderNameInputContainer = document.createElement('div')
			orderFormGrid.append(orderNameInputContainer)

			let orderNameInput = document.createElement('input')
			orderNameInput.classList.add('content-input')
			orderNameInput.style.fontSize = '1.5rem'
			orderNameInputContainer.append(orderNameInput)

			let orderTrailer = document.createElement('div')
			orderTrailer.innerHTML = 'Trailer:'
			orderFormGrid.append(orderTrailer)

			let orderTrailerInputContainer = document.createElement('div')
			orderFormGrid.append(orderTrailerInputContainer)

			let trailerSelect = document.createElement('select')
			trailerSelect.classList.add('content-dropdown')
			trailerSelect.style.fontSize = '1.5rem'
			orderTrailerInputContainer.append(trailerSelect)

			let trailerSelectBlankOption = document.createElement('option')
			trailerSelectBlankOption.innerHTML = '--UNASSIGNED--'
			trailerSelectBlankOption.value = -1
			trailerSelect.append(trailerSelectBlankOption)

			globalCache.getCacheList('trailers').forEach((trailer) => {
				debug.log('Adding trailer "'+trailer.name+'" to dropdown...')

				let trailerSelectOption = document.createElement('option')
				trailerSelectOption.innerHTML = trailer.name
				trailerSelectOption.value = trailer.uuid
				trailerSelect.append(trailerSelectOption)
			})

			let buttonContainer = document.createElement('div')
			div.append(buttonContainer)

			let submitButton = document.createElement('div')
			submitButton.classList.add('overlay-button')
			submitButton.innerHTML = 'Create New Training Order'
			submitButton.onclick = () => {
				debug.log('Create New Training Order button clicked...')
				/*if(selectedBins.length == 0){
					debug.log('Form failed submission check - no bins selected')
					alert('You must select at least 1 bin!')
					return
				}else */if(orderNameInput.value.length == 0){
					debug.log('Form failed submission check - no name defined')
					alert('A name is required!')
					return
				}

				let newTrainingOrder = {
					'name': orderNameInput.value,
					'bins': selectedBins,
					'trailer': ""//unassigned
				}

				if(trailerSelect.value !== '-1'){
					newTrainingOrder.trailer = trailerSelect.value
				}

				QReq.request(feedlokAPI.postTraining(newTrainingOrder)).then(resp => {
					debug.log('New custom training order created')
					_training[resp.uuid] = resp
					globalCache.setNewCacheListItem('training', resp)
					debug.log('New custom training order added to cache')
					overlay.close()

					this.generateEditCustomOrderOverlay(resp.uuid)
				}).catch((e) => {
					errorHandler(e)
				})
			}
			buttonContainer.append(submitButton)

			overlay.show()
		})
	}

	/**
	 * Generate an overlay that allows the editing of a training order - includes adding bins
	 * @param {string} orderUUID UUID of the order we are trying to edit
	 */
	generateEditCustomOrderOverlay(orderUUID){
		debug.log('Generating edit custom training order overlay...')
		overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
			let selectedBins = _training[orderUUID].bins
			if(_training[orderUUID].bins == undefined){selectedBins = []}

			let div = document.createElement('div')
			div.classList.add('overlay-container')
			overlayDiv.append(div)

			//CURRENT SELECTED BINS SECTION
			let currentBinsGrid = document.createElement('div')
			currentBinsGrid.style.display = 'grid'
			currentBinsGrid.style.gridTemplateColumns = '1fr 1fr 1fr'
			currentBinsGrid.style.fontSize = '1.5rem'
			currentBinsGrid.style.marginTop = '4px'
			currentBinsGrid.style.rowGap = '4px'
			currentBinsGrid.style.columnGap = '4px'
			currentBinsGrid.style.border = '1px solid #048FC4'
			currentBinsGrid.style.padding = '4px'
			div.append(currentBinsGrid)

			let currentBinsTitle = document.createElement('div')
			currentBinsTitle.style.textAlign = 'center'
			currentBinsTitle.style.gridColumn = '1/4'
			currentBinsTitle.innerHTML = 'Current Selected Bins:'
			currentBinsGrid.append(currentBinsTitle)

			selectedBins.forEach((binUUID) => {
				let bin = _bins[binUUID]

				//DRAW TO SELECTED BINS CONTAINER
				let selectedBinDiv = document.createElement('div', 'nowrap')
				selectedBinDiv.style.display = 'grid'
				selectedBinDiv.style.gridTemplateColumns = 'min-content 1fr'
				selectedBinDiv.style.columnGap = '4px'
				selectedBinDiv.style.padding = '4px'
				selectedBinDiv.style.border = '1px solid #048FC4'
				currentBinsGrid.append(selectedBinDiv)

				let selectedBinNameLabel = document.createElement('div')
				selectedBinNameLabel.style.fontWeight = 'bold'
				selectedBinNameLabel.innerHTML = 'Name:'
				selectedBinDiv.append(selectedBinNameLabel)

				let selectedBinName = document.createElement('div')
				selectedBinName.innerHTML = bin.name
				selectedBinDiv.append(selectedBinName)

				let selectedBinFarmLabel = document.createElement('div')
				selectedBinFarmLabel.style.fontWeight = 'bold'
				selectedBinFarmLabel.innerHTML = 'Farm:'
				selectedBinDiv.append(selectedBinFarmLabel)

				let selectedBinFarm = document.createElement('div')
				selectedBinFarm.innerHTML = _farms[bin.farm].name
				selectedBinDiv.append(selectedBinFarm)

				let selectedBinFarmOwnerLabel = document.createElement('div')
				selectedBinFarmOwnerLabel.style.fontWeight = 'bold'
				selectedBinFarmOwnerLabel.innerHTML = 'Owner:'
				selectedBinDiv.append(selectedBinFarmOwnerLabel)

				let selectedBinFarmOwner = document.createElement('div')
				selectedBinFarmOwner.innerHTML = _owners[_farms[bin.farm].owner].name
				selectedBinDiv.append(selectedBinFarmOwner)

				let removeButton = document.createElement('div')
				removeButton.classList.add('overlay-button')
				removeButton.style.gridColumn = '1/3'
				removeButton.innerHTML = 'Remove'
				removeButton.onclick = () => {
					selectedBins.splice(selectedBins.indexOf(bin.uuid), 1)
					currentBinsGrid.removeChild(selectedBinDiv)
					orderFarmInput.onchange()
				}
				selectedBinDiv.append(removeButton)
			})

			//ADD NEW BIN TO CUSTOM ORDER
			let newTitle = document.createElement('div')
			newTitle.classList.add('overlay-title')
			newTitle.style.marginTop = '3rem'
			newTitle.innerHTML = 'Add Bin'
			div.append(newTitle)

			let formGrid = document.createElement('div')
			formGrid.style.display = 'grid'
			formGrid.style.gridTemplateColumns = '1fr 1fr'
			formGrid.style.fontSize = '1.5rem'
			formGrid.style.marginTop = '4px'
			formGrid.style.rowGap = '4px'
			div.append(formGrid)

			let orderFarm = document.createElement('div')
			orderFarm.innerHTML = 'Farm:'
			formGrid.append(orderFarm)

			let orderFarmInputContainer = document.createElement('div')
			formGrid.append(orderFarmInputContainer)

			let orderFarmInput = document.createElement('select')
			orderFarmInput.classList.add('content-dropdown')
			orderFarmInput.style.fontSize = '1.5rem'
			orderFarmInputContainer.append(orderFarmInput)

			let orderFarmBlankOption = document.createElement('option')
			orderFarmBlankOption.value = ""
			orderFarmBlankOption.innerHTML = ""
			orderFarmInput.append(orderFarmBlankOption)

			globalCache.getCacheList('farms').forEach((farm) => {
				debug.log('Adding farm "'+farm.name+'" to Add New Bin dropdown...')
				let orderFarmOption = document.createElement('option')
				orderFarmOption.value = farm.uuid
				orderFarmOption.innerHTML = farm.name+' ('+_owners[farm.owner].name+')'
				orderFarmInput.append(orderFarmOption)
			})

			orderFarmInput.onchange = () => {
				debug.log('Farm changed...updating bin list...')

				DOMClearChildren(orderFarmBinsContainer)

				let binList = globalCache.getCacheList('bins').filter(bin => bin.farm == orderFarmInput.value)
				
				binList.forEach((bin) => {
					if(selectedBins.includes(bin.uuid)){ debug.log('Bin "'+bin.name+'" is already in order...skipping'); return}//dont create div for already selected bins
					
					debug.log('Adding bin "'+bin.name+'" to Add New Bin list...')
					
					//CREATE BIN IN BIN SECTION OF ADD NEW BIN
					let binDiv = document.createElement('div', 'nowrap')
					binDiv.style.display = 'grid'
					binDiv.style.gridTemplateColumns = 'min-content 1fr'
					binDiv.style.columnGap = '4px'
					binDiv.style.marginBottom = '4px'
					binDiv.style.border = '1px solid #048FC4'
					orderFarmBinsContainer.append(binDiv)

					let binNameLabel = document.createElement('div')
					binNameLabel.style.fontWeight = 'bold'
					binNameLabel.innerHTML = 'Name:'
					binDiv.append(binNameLabel)

					let binName = document.createElement('div')
					binName.innerHTML = bin.name
					binDiv.append(binName)

					let binStatusLabel = document.createElement('div')
					binStatusLabel.style.fontWeight = 'bold'
					binStatusLabel.innerHTML = 'Status:'
					binDiv.append(binStatusLabel)

					let binStatus = document.createElement('div')
					binDiv.append(binStatus)
					if(bin.trained == true){
						binStatus.innerHTML = '<font color="green">Trained</font> <br>Lat: '+bin.latitude/10000000+'°<br> Long: '+bin.longitude/10000000+'°'
					}else{
						binStatus.innerHTML = '<font color="red">Untrained</font>'
					}

					let binAdd = document.createElement('div')
					binAdd.classList.add('overlay-button')
					binAdd.style.gridColumn = '1/3'
					binAdd.innerHTML = 'Add'
					binAdd.onclick = () => {
						debug.log('Add button clicked on bin "'+bin.name+'" - adding to custom training order')

						//DRAW TO SELECTED BINS CONTAINER
						let selectedBinDiv = document.createElement('div', 'nowrap')
						selectedBinDiv.style.display = 'grid'
						selectedBinDiv.style.gridTemplateColumns = 'min-content 1fr'
						selectedBinDiv.style.columnGap = '4px'
						selectedBinDiv.style.padding = '4px'
						selectedBinDiv.style.border = '1px solid #048FC4'
						currentBinsGrid.append(selectedBinDiv)

						let selectedBinNameLabel = document.createElement('div')
						selectedBinNameLabel.style.fontWeight = 'bold'
						selectedBinNameLabel.innerHTML = 'Name:'
						selectedBinDiv.append(selectedBinNameLabel)

						let selectedBinName = document.createElement('div')
						selectedBinName.innerHTML = bin.name
						selectedBinDiv.append(selectedBinName)

						let selectedBinFarmLabel = document.createElement('div')
						selectedBinFarmLabel.style.fontWeight = 'bold'
						selectedBinFarmLabel.innerHTML = 'Farm:'
						selectedBinDiv.append(selectedBinFarmLabel)

						let selectedBinFarm = document.createElement('div')
						selectedBinFarm.innerHTML = _farms[bin.farm].name
						selectedBinDiv.append(selectedBinFarm)

						let selectedBinFarmOwnerLabel = document.createElement('div')
						selectedBinFarmOwnerLabel.style.fontWeight = 'bold'
						selectedBinFarmOwnerLabel.innerHTML = 'Owner:'
						selectedBinDiv.append(selectedBinFarmOwnerLabel)

						let selectedBinFarmOwner = document.createElement('div')
						selectedBinFarmOwner.innerHTML = _owners[_farms[bin.farm].owner].name
						selectedBinDiv.append(selectedBinFarmOwner)

						let removeButton = document.createElement('div')
						removeButton.classList.add('overlay-button')
						removeButton.style.gridColumn = '1/3'
						removeButton.innerHTML = 'Remove'
						removeButton.onclick = () => {
							selectedBins.splice(selectedBins.indexOf(bin.uuid), 1)
							currentBinsGrid.removeChild(selectedBinDiv)
							orderFarmInput.onchange()
						}
						selectedBinDiv.append(removeButton)

						selectedBins.push(bin.uuid)
						orderFarmBinsContainer.removeChild(binDiv)
					}
					binDiv.append(binAdd)
				})
			}

			let orderFarmBins = document.createElement('div')
			orderFarmBins.innerHTML = 'Bins:'
			formGrid.append(orderFarmBins)

			let orderFarmBinsContainer = document.createElement('div')
			orderFarmBinsContainer.style.border = '1px solid #048FC4'
			orderFarmBinsContainer.style.padding = '4px'
			formGrid.append(orderFarmBinsContainer)

			let buttonContainer = document.createElement('div')
			buttonContainer.style.marginTop = '2rem'
			div.append(buttonContainer)

			let submitButton = document.createElement('div')
			submitButton.classList.add('overlay-button')
			submitButton.innerHTML = 'Update Training Order'
			submitButton.onclick = () => {
				debug.log('Updating training order...')
				let updatedBins = {
					'bins': selectedBins,
				}

				QReq.request(feedlokAPI.putTrainingTrainingID(orderUUID, updatedBins)).then(resp => {
					debug.log('Custom Training Order Updated')
					_training[resp.uuid].bins = selectedBins

					/*if(this.selectedTrailer == false){
						this.selectedTrailer.data = {}
					}

					this.selectedTrailer.data = {id: -1}
					if(_training[orderUUID].trailer !== undefined && _training[orderUUID].trailer !== null && _training[orderUUID].trailer !== 0){
						this.selectedTrailer.data = {id: _training[orderUUID].trailer}
					}
					
					this._buildOrdersContent()*/

					overlay.close()
				}).catch((e) => {
					errorHandler(e)
				})
			}
			buttonContainer.append(submitButton)

			overlay.show()
		})
	}

	/**********************************************************************************LIST OF OWNERS****************************************************************************/
	/**
	 * Builds out the list of available owners
	 */
	_populateOwnerList(){//create the left side list of MCOs
		DOMClearChildren(this._divs.list)

		let searchRow = document.createElement('div')
		searchRow.style.display = 'grid'
		searchRow.style.gridTemplateColumns = '1fr min-content'
		this._divs.list.append(searchRow)

		let ownerSearch = document.createElement('input')
		ownerSearch.classList.add('content-input')
		ownerSearch.style.width = 'calc(100% - 6px)'
		ownerSearch.style.marginBottom = '4px'
		ownerSearch.placeholder = 'Search...'
		searchRow.append(ownerSearch)

		let searchButton = document.createElement('div')
		searchButton.classList.add('content-button')
		searchButton.style.marginTop = '0'
		searchButton.style.marginRight = '0'
		searchButton.innerHTML = 'Search'
		searchButton.onclick = () => {
			let assetDivs = this._divs.list.querySelectorAll('div.content-list-item')//get all of the divs in "machineList" div which contains asset list

			for (var i = 0; i < assetDivs.length; i++) {//loop through all divs
				if(!assetDivs[i].innerText.toLocaleLowerCase().includes(ownerSearch.value.toLocaleLowerCase())){//see if innerHMTL include search value
					assetDivs[i].style.display = 'none'//hide since we were checking if includes = false
				}else{
					assetDivs[i].style.display = 'grid'//this is the default for this particular element
				}
			}
		}
		searchRow.append(searchButton)

		globalCache.getCacheList('owners').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)

		//GENERATE LIST
		globalCache.getCacheList('owners').forEach((owner) => {
			let mainContentGridOwnerListOption = document.createElement('div')
			mainContentGridOwnerListOption.id = 'so-me-uuid-here'+owner.uuid
			mainContentGridOwnerListOption.classList.add('content-list-item')
			if(this.selectedOwner !== false){
				if(owner.uuid == this.selectedOwner.data.uuid){ 
					mainContentGridOwnerListOption.classList.add('content-list-item-active')
					this.selectedOwner.div = mainContentGridOwnerListOption
					this._buildTrainingContent()//load in the order info into main content area of grid
				}
			}
			mainContentGridOwnerListOption.innerHTML = owner.name
			mainContentGridOwnerListOption.onclick = () => {
				if (mainContentGridOwnerListOption.classList.contains('content-list-item-active')) {//if they re-click the already selected order
					mainContentGridOwnerListOption.classList.remove('content-list-item-active')
					if(this.selectedOwner.data.uuid == owner.uuid){ //reverify if they re-click the already selected order
						DOMClearChildren(this._divs.content)
						this.selectedOwner = false//no order selected
					}else{ return }
				} else {//one new order clicked
					mainContentGridOwnerListOption.classList.add('content-list-item-active')
					if(this.selectedOwner !== false && this.selectedOwner.data.uuid !== owner.uuid){//an order is already clicked - we need to remove it
						this.selectedOwner.div.classList.remove('content-list-item-active')
					}
					//set the new selected order
					this.selectedOwner = {}
					this.selectedOwner.data = owner//raw info
					this.selectedOwner.div = mainContentGridOwnerListOption//raw link div
					this._buildTrainingContent()//load in the order info into main content area of grid
				}
			}
			this._divs.list.append(mainContentGridOwnerListOption)
		})
	}

	/**
	 * Builds a list of farms and bins based on the _selectedOWner in Asset List
	 */
	_buildTrainingContent(){
		DOMClearChildren(this._divs.content)

		let farmSearch = document.createElement('input')
		farmSearch.classList.add('content-input')
		farmSearch.style.width = 'calc(100% - 6px)'
		farmSearch.style.marginBottom = '4px'
		farmSearch.placeholder = 'Search...'
		farmSearch.onkeyup = ()=>{
			let assetDivs = this._divs.content.querySelectorAll('div.training-content-farm')//get all of the divs in "machineList" div which contains asset list

			for (var i = 0; i < assetDivs.length; i++) {//loop through all divs
				if(!assetDivs[i].innerText.toLocaleLowerCase().includes(farmSearch.value.toLocaleLowerCase())){//see if innerHMTL include search value
					assetDivs[i].style.display = 'none'//hide since we were checking if includes = false
				}else{
					assetDivs[i].style.display = 'grid'//this is the default for this particular element
				}
			}
		}
		this._divs.content.append(farmSearch)

		let farmList = globalCache.getCacheList('farms').filter(farm => farm.owner == this.selectedOwner.data.uuid)
		
		farmList.forEach((farm) => {
			let binList = globalCache.getCacheList('bins').filter(bin => bin.farm == farm.uuid)

			let farmGrid = document.createElement('div')
			farmGrid.classList.add('training-content-farm')
			farmGrid.style.gridColumn = '1/3'//MCO is broken into 2 sections for the main content area
			this._divs.content.append(farmGrid)

			let farmGridTitle = document.createElement('div')
			farmGridTitle.classList.add('training-content-farm-title')
			farmGridTitle.style.gridColumn = '1/4'
			farmGridTitle.innerHTML = farm.name
			farmGrid.append(farmGridTitle)

			//MESSIEST CONFIRMATION DIV IN THE HISTORY OF HUMAN KIND
			let farmGridHiddenQuickTrain = document.createElement('div')
			farmGridHiddenQuickTrain.style.gridColumn = '1/4'
			farmGridHiddenQuickTrain.style.display = 'none'
			farmGridHiddenQuickTrain.style.textAlign = 'center'
			farmGridHiddenQuickTrain.style.justifySelf = 'center'
			farmGridHiddenQuickTrain.innerHTML = 'Please select a trailer to assign the quick training of '
			let binsQuickTrainList = binList.filter(bin => bin.trained == false)//list of untrained bins
			binsQuickTrainList.forEach((bin) => {
				let binOffset = binsQuickTrainList.indexOf(bin)
				farmGridHiddenQuickTrain.innerHTML += bin.name

				if(binOffset !== binsQuickTrainList.length-1){ farmGridHiddenQuickTrain.innerHTML += ', '  }
			})
			farmGridHiddenQuickTrain.innerHTML += ' to: '
			if(binsQuickTrainList.length == 0){ farmGridHiddenQuickTrain.innerHTML = 'ERROR: All farm bins trained' }else{
				let trailerSelect = document.createElement('select')
				trailerSelect.classList.add('content-dropdown')
				farmGridHiddenQuickTrain.append(trailerSelect)

				let trailerSelectBlankOption = document.createElement('option')
				trailerSelectBlankOption.innerHTML = '--UNASSIGNED--'
				trailerSelectBlankOption.value = -1
				trailerSelect.append(trailerSelectBlankOption)

				globalCache.getCacheList('trailers').forEach((trailer) => {
					let trailerSelectOption = document.createElement('option')
					trailerSelectOption.innerHTML = trailer.name
					trailerSelectOption.value = trailer.uuid
					trailerSelect.append(trailerSelectOption)
				})

				let farmGridHiddenQuickTrainButtons = document.createElement('div')
				farmGridHiddenQuickTrainButtons.style.marginTop = '4px'
				farmGridHiddenQuickTrain.append(farmGridHiddenQuickTrainButtons)

				let confirmButton = document.createElement('span')
				confirmButton.classList.add('button', 'nowrap')
				confirmButton.style.width = 'min-content'
				confirmButton.style.padding = '4px'
				confirmButton.style.fontSize = '1rem'
				confirmButton.innerHTML = 'Submit Order'
				confirmButton.onclick = () => {
					let binUUIDList = []
					binsQuickTrainList.forEach((binObj) => {
						binUUIDList.push(binObj.uuid)
					})

					let newTrainingOrder = {
						'name': 'Quick Training Order '+farm.name,
						'bins': binUUIDList,
						'trailer': ""//unassigned
					}

					if(trailerSelect.value !== '-1'){
						newTrainingOrder.trailer = trailerSelect.value
					}

					QReq.request(feedlokAPI.postTraining(newTrainingOrder)).then(resp => {
						_training[resp.uuid] = resp
						globalCache.setNewCacheListItem('training', resp)

						farmGridHiddenQuickTrain.innerHTML = 'Training order created!'
						setTimeout(() => {
							farmGridHiddenQuickTrain.style.display = 'none'
						}, 2500)
					}).catch((e) => {
						console.log(e)
						errorHandler(e)
					})
				}
				farmGridHiddenQuickTrainButtons.append(confirmButton)

				let cancelButton = document.createElement('span')
				cancelButton.classList.add('button', 'nowrap')
				cancelButton.style.width = 'min-content'
				cancelButton.style.padding = '4px'
				cancelButton.style.fontSize = '1rem'
				cancelButton.style.marginLeft = '4px'
				cancelButton.innerHTML = 'Cancel'
				cancelButton.onclick = () => {
					farmGridHiddenQuickTrain.style.display = 'none'
				}
				farmGridHiddenQuickTrainButtons.append(cancelButton)
			}
			farmGrid.append(farmGridHiddenQuickTrain)
			//END DISGUSTING CONFIRMATION

			let farmGridLinks = document.createElement('div')
			farmGridLinks.style.justifySelf = 'end'
			farmGridLinks.style.gridColumn = '1/4'
			farmGrid.append(farmGridLinks)

			let farmGridQuickTrainLink = document.createElement('div')
			farmGridQuickTrainLink.classList.add('button', 'nowrap')
			farmGridQuickTrainLink.style.width = 'min-content'
			farmGridQuickTrainLink.style.padding = '4px'
			farmGridQuickTrainLink.style.fontSize = '1rem'
			farmGridQuickTrainLink.innerHTML = 'Quick Train'
			farmGridQuickTrainLink.onclick = () => {
				farmGridHiddenQuickTrain.style.display = 'block'
			}
			farmGridLinks.append(farmGridQuickTrainLink)

			binList.forEach((bin) => {
				let farmGridBin = document.createElement('div')
				farmGridBin.classList.add('training-content-farm-bin')
				farmGridBin.style.display = 'grid'
				farmGridBin.style.gridTemplateColumns = 'min-content 1fr'
				farmGridBin.style.rowGap = '4px'
				farmGrid.append(farmGridBin)

				let farmGridBinNameValue = document.createElement('div')
				farmGridBinNameValue.style.gridColumn = '1/3'
				farmGridBinNameValue.style.fontWeight = 'bold'
				if(bin.trained == false){
					farmGridBinNameValue.style.borderBottom = '1px solid red'
				}else{
					farmGridBinNameValue.style.borderBottom = '1px solid #048FC4'
				}
				farmGridBinNameValue.innerHTML = bin.name
				farmGridBin.append(farmGridBinNameValue)

				let latitudeValue
				let longitudeValue
				let geofenceValue
				if(bin.trained == false){ 
					latitudeValue = 'Untrained'
					longitudeValue = 'Untrained'
					geofenceValue = 'Untrained'
					farmGridBin.style.border = '1px solid red' 
				}else{
					latitudeValue = bin.latitude/10000000+'°'
					longitudeValue = bin.longitude/10000000+'°'
					geofenceValue = bin.geofence+'ft'
				}

				let farmGridBinLatitude = document.createElement('div')
				farmGridBinLatitude.style.textAlign = 'left'
				farmGridBinLatitude.innerHTML = 'Latitude:'
				farmGridBin.append(farmGridBinLatitude)

				let farmGridBinLatitudeValue = document.createElement('div')
				farmGridBinLatitudeValue.innerHTML = latitudeValue
				farmGridBin.append(farmGridBinLatitudeValue)

				let farmGridBinLongitude = document.createElement('div')
				farmGridBinLongitude.style.textAlign = 'left'
				farmGridBinLongitude.innerHTML = 'Longitude:'
				farmGridBin.append(farmGridBinLongitude)

				let farmGridBinLongitudeValue = document.createElement('div')
				farmGridBinLongitudeValue.innerHTML = longitudeValue
				farmGridBin.append(farmGridBinLongitudeValue)

				if(bin.binType !== 0){
					let farmGridBinGeofence = document.createElement('div')
					farmGridBinGeofence.style.textAlign = 'left'
					farmGridBinGeofence.innerHTML = 'Geofence:'
					farmGridBin.append(farmGridBinGeofence)

					let farmGridBinGeofenceValue = document.createElement('div')
					farmGridBinGeofenceValue.innerHTML = geofenceValue
					farmGridBin.append(farmGridBinGeofenceValue)
				}
			})
		})
	}

	/**********************************************************************************TRAILERS****************************************************************************/
	/**
	 * Builds out the list of trailers
	 */
	_populateTrailerList(){//create the left side list of MCOs
		DOMClearChildren(this._divs.list)

		this._divs.listTitle.innerHTML = 'Categories'

		let mainContentGridTrailerUnassignedListOption = document.createElement('div')
		mainContentGridTrailerUnassignedListOption.classList.add('content-list-item')
		mainContentGridTrailerUnassignedListOption.innerHTML = 'Unassigned Training Orders'
		mainContentGridTrailerUnassignedListOption.onclick = () => {
			if (mainContentGridTrailerUnassignedListOption.classList.contains('content-list-item-active')) {//if they re-click the already selected order
				mainContentGridTrailerUnassignedListOption.classList.remove('content-list-item-active')
				if(this.selectedTrailer.data.uuid == -1){ //reverify if they re-click the already selected order
					DOMClearChildren(this._divs.content)
					this.selectedTrailer = false//no order selected
				}else{ return }
			} else {//one new order clicked
				mainContentGridTrailerUnassignedListOption.classList.add('content-list-item-active')
				if(this.selectedTrailer !== false && this.selectedTrailer.data.uuid !== -1){//an order is already clicked - we need to remove it
					this.selectedTrailer.div.classList.remove('content-list-item-active')
				}
				//set the new selected order
				this.selectedTrailer = {}
				this.selectedTrailer.data = {uuid: -1}//raw info
				this.selectedTrailer.div = mainContentGridTrailerUnassignedListOption//raw link div
				this._buildOrdersContent()//load in the order info into main content area of grid
			}
		}
		this._divs.list.append(mainContentGridTrailerUnassignedListOption)

		let mainContentGridTrailerAssignedListOption = document.createElement('div')
		mainContentGridTrailerAssignedListOption.classList.add('content-list-item')
		mainContentGridTrailerAssignedListOption.innerHTML = 'Assigned Training Orders'
		mainContentGridTrailerAssignedListOption.style.marginBottom = '2rem'
		mainContentGridTrailerAssignedListOption.onclick = () => {
			if (mainContentGridTrailerAssignedListOption.classList.contains('content-list-item-active')) {//if they re-click the already selected order
				mainContentGridTrailerAssignedListOption.classList.remove('content-list-item-active')
				if(this.selectedTrailer.data.uuid == 'all'){ //reverify if they re-click the already selected order
					DOMClearChildren(this._divs.content)
					this.selectedTrailer = false//no order selected
				}else{ return }
			} else {//one new order clicked
				mainContentGridTrailerAssignedListOption.classList.add('content-list-item-active')
				if(this.selectedTrailer !== false && this.selectedTrailer.data.uuid !== 'all'){//an order is already clicked - we need to remove it
					this.selectedTrailer.div.classList.remove('content-list-item-active')
				}
				//set the new selected order
				this.selectedTrailer = {}
				this.selectedTrailer.data = {uuid: 'all'}//raw info
				this.selectedTrailer.div = mainContentGridTrailerAssignedListOption//raw link div
				this._buildOrdersContent()//load in the order info into main content area of grid
			}
		}
		this._divs.list.append(mainContentGridTrailerAssignedListOption)


		let mainContentGridTrailerSeperator = document.createElement('div')
		mainContentGridTrailerSeperator.classList.add('content-title')
		mainContentGridTrailerSeperator.style.width = '100%'
		mainContentGridTrailerSeperator.style.borderRight = '0'
		mainContentGridTrailerSeperator.style.marginLeft = '-4px'
		mainContentGridTrailerSeperator.style.marginBottom = '4px'
		mainContentGridTrailerSeperator.innerHTML = 'Individual Trailers:'
		this._divs.list.append(mainContentGridTrailerSeperator)

		//GENERATE LIST
		globalCache.getCacheList('trailers').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)

		globalCache.getCacheList('trailers').forEach((trailer) => {
			let mainContentGridTrailerListOption = document.createElement('div')
			mainContentGridTrailerListOption.id = 'so-me-uuid-here'+trailer.uuid
			mainContentGridTrailerListOption.classList.add('content-list-item')
			if(this.selectedTrailer !== false){
				if(trailer.uuid == this.selectedTrailer.data.uuid){ 
					mainContentGridTrailerListOption.classList.add('content-list-item-active')
					this.selectedTrailer.div = mainContentGridTrailerListOption
					this._buildOrdersContent()//load in the order info into main content area of grid
				}
			}
			mainContentGridTrailerListOption.innerHTML = trailer.name
			mainContentGridTrailerListOption.onclick = () => {
				if (mainContentGridTrailerListOption.classList.contains('content-list-item-active')) {//if they re-click the already selected order
					mainContentGridTrailerListOption.classList.remove('content-list-item-active')
					if(this.selectedTrailer.data.uuid == trailer.uuid){ //reverify if they re-click the already selected order
						DOMClearChildren(this._divs.content)
						this.selectedTrailer = false//no order selected
					}else{ return }
				} else {//one new order clicked
					mainContentGridTrailerListOption.classList.add('content-list-item-active')
					if(this.selectedTrailer !== false && this.selectedTrailer.data.uuid !== trailer.uuid){//an order is already clicked - we need to remove it
						this.selectedTrailer.div.classList.remove('content-list-item-active')
					}
					//set the new selected order
					this.selectedTrailer = {}
					this.selectedTrailer.data = trailer//raw info
					this.selectedTrailer.div = mainContentGridTrailerListOption//raw link div
					this._buildOrdersContent()//load in the order info into main content area of grid
				}
			}
			this._divs.list.append(mainContentGridTrailerListOption)
		})
	}

	/**
	 * Builds out a list of Training Orders related to the selected trailer
	 */
	_buildOrdersContent(){
		DOMClearChildren(this._divs.content)

		let farmSearch = document.createElement('input')
		farmSearch.classList.add('content-input')
		farmSearch.style.width = 'calc(100% - 6px)'
		farmSearch.style.marginBottom = '4px'
		farmSearch.placeholder = 'Search...'
		farmSearch.onkeyup = ()=>{
			let assetDivs = this._divs.content.querySelectorAll('div.training-content-farm')//get all of the divs in "machineList" div which contains asset list

			for (var i = 0; i < assetDivs.length; i++) {//loop through all divs
				if(!assetDivs[i].innerText.toLocaleLowerCase().includes(farmSearch.value.toLocaleLowerCase())){//see if innerHMTL include search value
					assetDivs[i].style.display = 'none'//hide since we were checking if includes = false
				}else{
					assetDivs[i].style.display = 'grid'//this is the default for this particular element
				}
			}
		}
		this._divs.content.append(farmSearch)

		let orderList
		if(this.selectedTrailer.data.uuid == -1){//unassigned
			orderList = globalCache.getCacheList('training').filter(order => order.trailer == "" || order.trailer == undefined)
		}else if(this.selectedTrailer.data.uuid == 'all'){//all assigned
			orderList = globalCache.getCacheList('training').filter(order => order.trailer !== "" && order.trailer !== undefined)
		}else{//specific trailer
			orderList = globalCache.getCacheList('training').filter(order => order.trailer == this.selectedTrailer.data.uuid)
		}

		orderList.forEach((order) => {
			let farmGrid = document.createElement('div')
			farmGrid.classList.add('training-content-farm')
			farmGrid.style.gridColumn = '1/3'//MCO is broken into 2 sections for the main content area
			this._divs.content.append(farmGrid)

			let farmGridTitle = document.createElement('div')
			farmGridTitle.classList.add('training-content-farm-title')
			farmGridTitle.style.gridColumn = '1/4'
			farmGridTitle.innerHTML = '<b>'+order.name+'</b>'
			farmGrid.append(farmGridTitle)

			//ADD DROPDOWN HERE
			let farmGridMessage = document.createElement('div')
			farmGridMessage.style.gridColumn = '1/3'
			farmGridMessage.style.marginLeft = '50%'
			farmGridMessage.innerHTML = ''
			farmGrid.append(farmGridMessage)

			let farmGridOptions = document.createElement('div')
			farmGridOptions.style.textAlign = 'right'
			farmGridOptions.style.color = '#fff'
			farmGridOptions.style.marginRight = '4px'
			farmGridOptions.innerHTML = '<b>Assign To:</b>'
			farmGrid.append(farmGridOptions)

			let trailerSelect = document.createElement('select')
			trailerSelect.classList.add('content-dropdown')
			farmGridOptions.append(trailerSelect)

			let trailerSelectBlankOption = document.createElement('option')
			trailerSelectBlankOption.innerHTML = '--Unassign--'
			trailerSelectBlankOption.value = -1
			trailerSelect.append(trailerSelectBlankOption)

			globalCache.getCacheList('trailers').forEach((trailer) => {
				let trailerSelectOption = document.createElement('option')
				trailerSelectOption.innerHTML = trailer.name
				trailerSelectOption.value = trailer.uuid
				trailerSelect.append(trailerSelectOption)
			})

			if(order.trailer == "" || order.trailer == undefined){
				trailerSelect.value = -1
			}else{
				trailerSelect.value = order.trailer
			}

			trailerSelect.onchange = () => {
				if(trailerSelect.value !== "-1"){
					farmGridMessage.innerHTML = 'Are you sure you would like to assign this training order to '+_trailers[trailerSelect.value].name+'?'
				}else{
					farmGridMessage.innerHTML = 'Are you sure you would like to unassign this training order?'
				}

				let switchConfirm = document.createElement('div')
				switchConfirm.classList.add('content-button')
				switchConfirm.innerHTML = 'Confirm'
				switchConfirm.onclick = () => {
					let trailerID = trailerSelect.value
					if(trailerID == "-1"){trailerID = ""}//if it is set to "--Unassign--"
					console.log(order)
					QReq.request(feedlokAPI.putTrainingTrainingID(order.uuid, {'trailer': trailerID})).then(resp => {
						DOMClearChildren(farmGridMessage)
						farmGridMessage.innerHTML = ''

						_training[order.uuid].trailer = trailerID

						this._buildOrdersContent()
					})
				}
				farmGridMessage.append(switchConfirm)

				let switchCancel = document.createElement('div')
				switchCancel.classList.add('content-button')
				switchCancel.innerHTML = 'Cancel'
				switchCancel.onclick = () => {
					DOMClearChildren(farmGridMessage)
					farmGridMessage.innerHTML = ''
					if(order.trailer == ""){
						trailerSelect.value = -1
					}else{
						trailerSelect.value = order.trailer
					}
				}
				farmGridMessage.append(switchCancel)
			}

			if(order.bins == undefined){order.bins=[]}
			order.bins.forEach((binUUID) => {
				let bin = _bins[binUUID]
				let farmGridBin = document.createElement('div')
				farmGridBin.classList.add('training-content-farm-bin')
				farmGridBin.style.display = 'grid'
				farmGridBin.style.gridTemplateColumns = 'min-content 1fr'
				farmGridBin.style.rowGap = '4px'
				farmGrid.append(farmGridBin)

				let farmGridBinNameValue = document.createElement('div')
				farmGridBinNameValue.style.gridColumn = '1/3'
				farmGridBinNameValue.style.fontWeight = 'bold'
				if(bin.trained == false){
					farmGridBinNameValue.style.borderBottom = '1px solid red'
				}else{
					farmGridBinNameValue.style.borderBottom = '1px solid #048FC4'
				}
				farmGridBinNameValue.innerHTML = bin.name
				farmGridBin.append(farmGridBinNameValue)

				let latitudeValue
				let longitudeValue
				let geofenceValue
				if(bin.trained == false){ 
					latitudeValue = 'Untrained'
					longitudeValue = 'Untrained'
					geofenceValue = 'Untrained'
					farmGridBin.style.border = '1px solid red' 
				}else{
					latitudeValue = bin.latitude/10000000+'°'
					longitudeValue = bin.longitude/10000000+'°'
					geofenceValue = bin.geofence+'ft'
				}

				let farmGridBinLatitude = document.createElement('div')
				farmGridBinLatitude.style.textAlign = 'left'
				farmGridBinLatitude.innerHTML = 'Latitude:'
				farmGridBin.append(farmGridBinLatitude)

				let farmGridBinLatitudeValue = document.createElement('div')
				farmGridBinLatitudeValue.innerHTML = latitudeValue
				farmGridBin.append(farmGridBinLatitudeValue)

				let farmGridBinLongitude = document.createElement('div')
				farmGridBinLongitude.style.textAlign = 'left'
				farmGridBinLongitude.innerHTML = 'Longitude:'
				farmGridBin.append(farmGridBinLongitude)

				let farmGridBinLongitudeValue = document.createElement('div')
				farmGridBinLongitudeValue.innerHTML = longitudeValue
				farmGridBin.append(farmGridBinLongitudeValue)

				if(bin.binType !== 0){
					let farmGridBinGeofence = document.createElement('div')
					farmGridBinGeofence.style.textAlign = 'left'
					farmGridBinGeofence.innerHTML = 'Geofence:'
					farmGridBin.append(farmGridBinGeofence)

					let farmGridBinGeofenceValue = document.createElement('div')
					farmGridBinGeofenceValue.innerHTML = geofenceValue
					farmGridBin.append(farmGridBinGeofenceValue)
				}
			})

			let editButton = document.createElement('div')
			editButton.classList.add('content-button', 'nowrap')
			editButton.style.gridColumn = '1/4'
			editButton.style.justifySelf = 'center'
			editButton.innerHTML = 'Edit Training Order'
			editButton.onclick = () => {
				this.generateEditCustomOrderOverlay(order.uuid)
			}
			farmGrid.append(editButton)

			let markCompleteButton = document.createElement('div')
			markCompleteButton.classList.add('content-button', 'nowrap')
			markCompleteButton.style.gridColumn = '1/4'
			markCompleteButton.style.justifySelf = 'center'
			markCompleteButton.style.marginTop = '-4px'
			markCompleteButton.innerHTML = 'Mark Complete'
			markCompleteButton.onclick = () => {
				let confirmation = confirm('Are you sure you would like to mark this training order complete?')
				if(confirmation == true){
					QReq.request(feedlokAPI.putTrainingTrainingIDStatusStatus(order.uuid, 'complete')).then(resp => {
						//DELETE FDO
						globalCache.deleteCacheListItemByUUID('training', order.uuid)
						delete _training[order.uuid]

						this._populateTrailerList()
						this._buildOrdersContent()
					}).catch((e) => {
						errorHandler(e)
					})
				}
			}
			farmGrid.append(markCompleteButton)

			let deleteButton = document.createElement('div')
			deleteButton.classList.add('content-button', 'nowrap')
			deleteButton.style.gridColumn = '1/4'
			deleteButton.style.justifySelf = 'center'
			deleteButton.style.marginTop = '-4px'
			deleteButton.innerHTML = 'Delete'
			deleteButton.onclick = () => {
				let confirmation = confirm('Are you sure you would like to delete this training order?')
				if(confirmation == true){
					QReq.request(feedlokAPI.deleteTrainingTrainingID(order.uuid)).then(resp => {
						//DELETE training
						globalCache.deleteCacheListItem('training', _training[order.uuid])
						delete _training[order.uuid]
		
						this._populateTrailerList()
						this._buildOrdersContent()
					}).catch((e) => {
						errorHandler(e)
					})
				}
			}
			farmGrid.append(deleteButton)
		})
	}

	start(page) {
		page.getButton().div.classList.add('navbar-items-item-active')
	}

	stop(page) {
		page.getButton().div.classList.remove('navbar-items-item-active')
	}

	initCB(page) {
	}
}
