'use strict';

import {QReq, DOMClearChildren} from '../ms/ms.js';
import {debug} from '../ms/debug';
import {feedlokAPI, overlay, _bins} from './main';

export class HistoryPage {
	constructor() {
		this.mcoContent
		this.fdoContent
		this.trainingContent
	}

	generateButton(button) {
		button.div.classList.add('navbar-items-item')
		button.icon.classList.add('navbar-items-icon')
		button.icon.classList.add('fa')
		button.icon.classList.add('fa-history')
		button.text.classList.add('large')
		button.text.innerHTML = 'History'
		button.div.append(button.icon)
		button.div.append(button.text)
	}

	generateSubnav(subnav) {//hard codes menu since it wont be dynamic...
		let downloadLink = document.createElement('div')
		downloadLink.classList.add('nav-asset-list-item')
		downloadLink.classList.add('nav-asset-list-item-active')
		downloadLink.innerHTML = 'Downloads'
		downloadLink.onclick = () => {
			this._generateDownloadHistoryContent()
		}
		subnav.append(downloadLink)
	}

	generateContent(content) {
		content.classList.add('dashboard', 'content-page')

		let mainContentSection = document.createElement('div')
		mainContentSection.classList.add('card', 'dashboard-content')
		content.append(mainContentSection)

		this.mainContent = mainContentSection
	}

	/**
	 * A general layout for downloading history reports
	 */
	_generateDownloadHistoryContent(){
		debug.log('Generating History Downloads content...')

		DOMClearChildren(this.mainContent)

		//JUST TEMPORARY
		let mainHistoryGrid = document.createElement('div')
		mainHistoryGrid.classList.add('history-content')
		this.mainContent.append(mainHistoryGrid)

		let startDateLabel = document.createElement('div')
		startDateLabel.style.padding = '4px'
		startDateLabel.style.border = '1px solid var(--content-button-border)'
		startDateLabel.style.background = 'var(--content-button-background)'
		startDateLabel.style.color = 'var(--content-button-color)'
		startDateLabel.style.fontWeight = 'bolder'
		startDateLabel.style.textAlign = 'center'
		startDateLabel.innerHTML = 'Start Date:'
		mainHistoryGrid.append(startDateLabel)

		let timezoneOffset = ((new Date()).getTimezoneOffset() * 60000)

		let startDateInput = document.createElement('input')
		startDateInput.style.gridColumn = '2/4'
		startDateInput.type = 'datetime-local'
		startDateInput.style.padding = '4px'
		startDateInput.classList.add('date-input')
		let startDataTimestamp = Date.now()-86400000-timezoneOffset//default to 24 hours ago
		startDateInput.value = new Date(startDataTimestamp).toISOString().slice(0,16);
		mainHistoryGrid.append(startDateInput)

		let endDateLabel = document.createElement('div')
		endDateLabel.style.padding = '4px'
		endDateLabel.style.border = '1px solid var(--content-button-border)'
		endDateLabel.style.background = 'var(--content-button-background)'
		endDateLabel.style.color = 'var(--content-button-color)'
		endDateLabel.style.fontWeight = 'bolder'
		endDateLabel.style.textAlign = 'center'
		endDateLabel.innerHTML = 'End Date:'
		mainHistoryGrid.append(endDateLabel)

		let endDateInput = document.createElement('input')
		endDateInput.type = 'datetime-local'
		endDateInput.style.gridColumn = '5/7'
		endDateInput.style.padding = '4px'
		endDateInput.classList.add('date-input')
		let endDataTimestamp = Date.now()-timezoneOffset//default to 24 hours ago
		endDateInput.value = new Date(endDataTimestamp).toISOString().slice(0,16);
		mainHistoryGrid.append(endDateInput)

		let submitButton = document.createElement('div')
		submitButton.classList.add('button')
		submitButton.innerHTML = 'Submit'
		submitButton.onclick = () => {
			debug.log('Date submit button clicked...loading history...')

			this.loadHistory((new Date(startDateInput.value).getTime()/1000), (new Date(endDateInput.value).getTime()/1000))
		}
		mainHistoryGrid.append(submitButton)

		//DATA SECTIONS
		let tabSection = document.createElement('div')
		tabSection.classList.add('tab-section')
		mainHistoryGrid.append(tabSection)

		let mcosTab = document.createElement('div')
		mcosTab.classList.add('tab')
		mcosTab.style.marginLeft = '0'
		mcosTab.innerHTML = 'MCOs'
		tabSection.append(mcosTab)

		mcosTab.onclick = () => {
			debug.log('MCOs tab clicked...')

			mcosTab.classList.add('tab-active')
			fdosTab.classList.remove('tab-active')
			trainingTab.classList.remove('tab-active')
			v1ReportsTab.classList.remove('tab-active')

			mcoHistory.style.display = 'block'
			fdoHistory.style.display = 'none'
			trainingHistory.style.display = 'none'
			v1ReportsHistory.style.display = 'none'
		}

		let fdosTab = document.createElement('div')
		fdosTab.classList.add('tab')
		fdosTab.innerHTML = 'FDOs'
		tabSection.append(fdosTab)

		fdosTab.onclick = () => {
			debug.log('FDOs tab clicked...')

			mcosTab.classList.remove('tab-active')
			fdosTab.classList.add('tab-active')
			trainingTab.classList.remove('tab-active')
			v1ReportsTab.classList.remove('tab-active')

			mcoHistory.style.display = 'none'
			fdoHistory.style.display = 'block'
			trainingHistory.style.display = 'none'
			v1ReportsHistory.style.display = 'none'
		}

		let trainingTab = document.createElement('div')
		trainingTab.classList.add('tab', 'nowrap')
		trainingTab.innerHTML = 'Training Orders'
		tabSection.append(trainingTab)

		trainingTab.onclick = () => {
			debug.log('Training Orders tab clicked...')

			mcosTab.classList.remove('tab-active')
			fdosTab.classList.remove('tab-active')
			trainingTab.classList.add('tab-active')
			v1ReportsTab.classList.remove('tab-active')

			mcoHistory.style.display = 'none'
			fdoHistory.style.display = 'none'
			trainingHistory.style.display = 'block'
			v1ReportsHistory.style.display = 'none'
		}

		let v1ReportsTab = document.createElement('div')
		v1ReportsTab.classList.add('tab', 'nowrap')
		v1ReportsTab.style.display = 'none'
		v1ReportsTab.innerHTML = 'V1 Reports'
		tabSection.append(v1ReportsTab)

		QReq.request(feedlokAPI.getV1ReportsCount()).then(resp => {
			if(resp.count > 0){v1ReportsTab.style.display = 'block'}
		})

		v1ReportsTab.onclick = () => {
			debug.log('Training Orders tab clicked...')

			mcosTab.classList.remove('tab-active')
			fdosTab.classList.remove('tab-active')
			trainingTab.classList.remove('tab-active')
			v1ReportsTab.classList.add('tab-active')

			mcoHistory.style.display = 'none'
			fdoHistory.style.display = 'none'
			trainingHistory.style.display = 'none'
			v1ReportsHistory.style.display = 'block'
		}

		let mcoHistory = document.createElement('div')
		mcoHistory.classList.add('tab-data')
		mainHistoryGrid.append(mcoHistory)

		let fdoHistory = document.createElement('div')
		fdoHistory.classList.add('tab-data')
		fdoHistory.style.display = 'none'
		mainHistoryGrid.append(fdoHistory)

		let trainingHistory = document.createElement('div')
		trainingHistory.classList.add('tab-data')
		trainingHistory.style.display = 'none'
		mainHistoryGrid.append(trainingHistory)

		let v1ReportsHistory = document.createElement('div')
		v1ReportsHistory.classList.add('tab-data')
		v1ReportsHistory.style.display = 'none'
		mainHistoryGrid.append(v1ReportsHistory)

		//MCOS
		let mcoHeader = document.createElement('div')
		mcoHeader.style.fontSize = '2rem'
		mcoHeader.style.fontWeight = 'bolder'
		mcoHeader.style.gridColumn = '1/8'
		mcoHeader.innerHTML = 'MCOs<hr>'
		mcoHistory.append(mcoHeader)

		let mcoContainer = document.createElement('div')
		mcoContainer.style.gridColumn = '1/8'
		mcoContainer.style.display = 'grid'
		mcoContainer.style.gridTemplateColumns = '1fr min-content min-content'
		mcoHistory.append(mcoContainer)
		this.mcoContent = mcoContainer

		//FDOS
		let fdoHeader = document.createElement('div')
		fdoHeader.style.fontSize = '2rem'
		fdoHeader.style.fontWeight = 'bolder'
		fdoHeader.style.gridColumn = '1/8'
		fdoHeader.innerHTML = 'FDOs<hr>'
		fdoHistory.append(fdoHeader)

		let fdoContainer = document.createElement('div')
		fdoContainer.style.gridColumn = '1/8'
		fdoContainer.style.display = 'grid'
		fdoContainer.style.gridTemplateColumns = '1fr min-content min-content'
		fdoHistory.append(fdoContainer)
		this.fdoContent = fdoContainer

		//TRAINING
		let trainingHeader = document.createElement('div')
		trainingHeader.style.fontSize = '2rem'
		trainingHeader.style.fontWeight = 'bolder'
		trainingHeader.style.gridColumn = '1/8'
		trainingHeader.innerHTML = 'Training Orders<hr>'
		trainingHistory.append(trainingHeader)

		let trainingContainer = document.createElement('div')
		trainingContainer.style.gridColumn = '1/8'
		trainingContainer.style.display = 'grid'
		trainingContainer.style.gridTemplateColumns = '1fr min-content min-content'
		trainingHistory.append(trainingContainer)
		this.trainingContent = trainingContainer

		//v1 Reports
		let v1ReportsHeader = document.createElement('div')
		v1ReportsHeader.style.fontSize = '2rem'
		v1ReportsHeader.style.fontWeight = 'bolder'
		v1ReportsHeader.style.gridColumn = '1/8'
		v1ReportsHeader.innerHTML = 'v1 Reports<hr>'
		v1ReportsHistory.append(v1ReportsHeader)

		let v1ReportsContainer = document.createElement('div')
		v1ReportsContainer.style.gridColumn = '1/8'
		v1ReportsContainer.style.display = 'grid'
		v1ReportsContainer.style.gridTemplateColumns = '1fr min-content min-content'
		v1ReportsHistory.append(v1ReportsContainer)
		this.v1ReportsContent = v1ReportsContainer

		debug.log('Auto-loading last 24 hours...')
		this.loadHistory((new Date(startDateInput.value).getTime()/1000), (new Date(endDateInput.value).getTime()/1000))
	}

	/**
	 * Parse a log array into a human readable string
	 * @param {array} logMsg Array of message parts
	 * @returns String representing log
	 */
	decypherLogMessage(logMsg){
		let logParts = logMsg.split(',')
		let overriden = false
		let binName = "~~UNKNOWN~~"

		switch(logParts[0]){//switch the log code
			case "901":
				if(_bins[logParts[3]] != undefined && _bins[logParts[3]] != null){
				    binName = _bins[logParts[3]].name
				}
				return 'Bin '+binName+' was trained to '+parseInt(logParts[1])/10000000+'°, '+parseInt(logParts[2])/10000000+'°'
				break;
			case "903":
				if(logParts[5] == "1"){overriden=true}
				return 'Bin opened - Latitude: '+parseInt(logParts[2])/10000000+' Longitude: '+parseInt(logParts[3])/10000000+' Trailer Gate: '+logParts[4]+' Bin ID: '+logParts[5]+' Override Used: '+overriden
				break;
			case "904":
				return 'Feedlok override - Bin Count: '+logParts[2]+' Order ID: '+logParts[1]
				break
			case "905":
				return 'Trailer gate '+logParts[1]+' was acknowledged as empty'
				break
			case "906":
				return 'Trailer gate '+logParts[3]+' was flushed at '+parseInt(logParts[1])/10000000+'°, '+parseInt(logParts[2])/10000000+'°'
				break
			case "907":
				break
			case "908":
				return 'Extended Geofence Acknowledged - Latitude: '+parseInt(logParts[1])/10000000+' Longitude: '+parseInt(logParts[2])/10000000
				break
			case "910":
				if(logParts[5] == "1"){overriden=true}
				if(_bins[logParts[1]] != undefined && _bins[logParts[1]] != null){
				    binName = _bins[logParts[1]].name
				}
				return 'Trailer gate '+logParts[4]+' on trailer '+logParts[6]+' was marked done at bin '+binName+' (Located @: '+parseInt(logParts[2])/10000000+'°, '+parseInt(logParts[3])/10000000+'°) after '+logParts[7]+' seconds of runtime. Override Used: '+overriden
				break
			case "911":
				return 'Load done - Gate 1 Runtime: '+logParts[1]+' seconds; Gate 2 Runtime: '+logParts[2]+' seconds; Gate 3 Runtime: '+logParts[3]+' seconds; Gate 4 Runtime: '+logParts[4]+' seconds; Gate 5 Runtime: '+logParts[5]+' seconds; Gate 6 Runtime: '+logParts[6]+' seconds; Gate 7 Runtime: '+logParts[7]+' seconds; Gate 8 Runtime: '+logParts[8]+' seconds; Gate 9 Runtime: '+logParts[9]+' seconds; Gate 10 Runtime: '+logParts[10]+' seconds; Gate 11 Runtime: '+logParts[11]+' seconds; Gate 12 Runtime: '+logParts[12]+' seconds'
				break
			case "950":
				return 'Error: '+logParts[1]
				break
		}
	}

	/**
	 * Get FDOs, MCOs, and Training Order within a specific date range
	 * @param {int} start Timestamp of the start time of the data we want to fetch
	 * @param {int} stop Timestamp of the end time of the data we want to fetch
	 */
	loadHistory(start, stop){
		debug.log('Loading History between '+start+' and '+stop+'...')

		DOMClearChildren(this.mcoContent)
		DOMClearChildren(this.fdoContent)
		DOMClearChildren(this.trainingContent)
		//MCOS
		QReq.request(feedlokAPI.getMCOrders('?status=complete&datestart='+start+'&datestop='+stop)).then(resp => {
			debug.log('MCO orders retrieved...')

			resp = resp.orders
			resp.sort((a, b) => (a.statusTimestamp > b.statusTimestamp) ? 1 : -1)

			resp.forEach((mco) => {
				debug.log('Adding MCO "'+mco.name+'" to history list')

				let mcoName = document.createElement('div')
				mcoName.classList.add('content-section')
				mcoName.style.paddingTop = 'calc(.5rem + 4px)'
				mcoName.innerHTML = mco.name
				this.mcoContent.append(mcoName)

				let mcoDate = document.createElement('div')
				mcoDate.classList.add('content-section', 'nowrap')
				mcoDate.style.paddingTop = 'calc(.5rem + 4px)'
				mcoDate.innerHTML = new Date(mco.statusTimestamp*1000).toLocaleString()
				this.mcoContent.append(mcoDate)

				let mcoButtons = document.createElement('div')
				mcoButtons.classList.add('content-section', 'nowrap')
				this.mcoContent.append(mcoButtons)

				let mcoViewButton = document.createElement('div')
				mcoViewButton.classList.add('content-button')
				mcoViewButton.innerHTML = 'View Logs'
				mcoViewButton.onclick = () => {
					debug.log('MCO "'+mco.name+'"\'s View Logs button has be clicked - generating overlay...')
					overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
						let div = document.createElement('div')
						div.classList.add('overlay-container')
						overlayDiv.append(div)
		
						let title = document.createElement('div')
						title.classList.add('overlay-title')
						title.innerHTML = 'Logs - '+mco.name
						div.append(title)

						let logGrid = document.createElement('div')
						logGrid.style.marginTop = '4px'
						logGrid.style.columnGap = '4px'
						logGrid.style.rowGap = '4px'
						logGrid.style.display = 'grid'
						logGrid.style.gridTemplateColumns = 'min-content 1fr'
						div.append(logGrid)

						let logGridDateHeader = document.createElement('div')
						logGridDateHeader.classList.add('available-mco-list-cell')
						logGridDateHeader.style.textAlign = 'center'
						logGridDateHeader.style.fontWeight = 'bold'
						logGridDateHeader.innerHTML = 'Date'
						logGrid.append(logGridDateHeader)

						let logGridMessageHeader = document.createElement('div')
						logGridMessageHeader.classList.add('available-mco-list-cell')
						logGridMessageHeader.style.textAlign = 'center'
						logGridMessageHeader.style.fontWeight = 'bold'
						logGridMessageHeader.innerHTML = 'Message'
						logGrid.append(logGridMessageHeader)

						QReq.request(feedlokAPI.getMCOrdersOrderIDLog(mco.uuid)).then(resp => {
							debug.log('Fetching MCO "'+mco.name+'"\'s logs...')

							resp.log.events.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1)

							resp.log.events.forEach((log)=>{
								debug.log('Adding log to grid...')

								let message = this.decypherLogMessage(log.msg)
								
								let logGridDate = document.createElement('div')
								logGridDate.classList.add('available-mco-list-cell', 'nowrap')
								logGridDate.innerHTML = new Date(log.timestamp*1000).toLocaleString()
								logGrid.append(logGridDate)

								let logGridMessage = document.createElement('div')
								logGridMessage.classList.add('available-mco-list-cell')
								logGridMessage.innerHTML = message
								logGrid.append(logGridMessage)
							})

							let logGridDownload = document.createElement('div')
							logGridDownload.classList.add('overlay-button')
							logGridDownload.style.textAlign = 'center'
							logGridDownload.style.gridColumn = '1/3'
							logGridDownload.style.width = 'min-content'
							logGridDownload.style.justifySelf = 'center'
							logGridDownload.innerHTML = 'Download'
							logGridDownload.onclick = () => {
								debug.log('Download button clicked...')

								let dataString = 'Order,Timestamp,Message\n'

								resp.log.events.forEach((log)=>{
									let message = this.decypherLogMessage(log.msg).replace(',', '')
									let dateString = new Date(log.timestamp*1000).toLocaleString().replace(',', '')
									
									dataString += resp.log.order+','+dateString+','+message+'\n'
								})

								let csvContent = "data:text/csv;charset=utf-8,"+dataString
								var encodedUri = encodeURI(csvContent);
								var link = document.createElement("a")
								link.setAttribute("href", encodedUri)
								link.setAttribute("download", mco.name+".csv")
								document.body.appendChild(link) // Required for FF
								link.click()
								document.body.removeChild(link)

								debug.log('Logs downloaded...')
							}
							logGrid.append(logGridDownload)
						})
			
						overlay.show()
					})
				}
				mcoButtons.append(mcoViewButton)
			})
		}).catch((e) => {
			errorHandler(e)
		})

		//FDOS
		QReq.request(feedlokAPI.getFDOrders('?status=complete&datestart='+start+'&datestop='+stop)).then(resp => {
			debug.log('FDO orders retrieved...')

			resp = resp.orders

			resp.sort((a, b) => (a.statusTimestamp > b.statusTimestamp) ? 1 : -1)

			resp.forEach((fdo) => {
				debug.log('Adding FDO "'+fdo.name+'" to history list')

				let fdoName = document.createElement('div')
				fdoName.classList.add('content-section')
				fdoName.style.paddingTop = 'calc(.5rem + 4px)'
				fdoName.innerHTML = fdo.name
				this.fdoContent.append(fdoName)

				let fdoDate = document.createElement('div')
				fdoDate.classList.add('content-section', 'nowrap')
				fdoDate.style.paddingTop = 'calc(.5rem + 4px)'
				fdoDate.innerHTML = new Date(fdo.statusTimestamp*1000).toLocaleString()
				this.fdoContent.append(fdoDate)

				let fdoButtons = document.createElement('div')
				fdoButtons.classList.add('content-section', 'nowrap')
				fdoButtons.style.display = 'grid'
				fdoButtons.style.gridTemplateColumns = 'min-content min-content'
				this.fdoContent.append(fdoButtons)

				let fdoViewButton = document.createElement('div')
				fdoViewButton.classList.add('content-button')
				fdoViewButton.innerHTML = 'View Logs'
				fdoViewButton.onclick = () => {
					debug.log('FDO "'+fdo.name+'"\'s View Logs button has be clicked - generating overlay...')
					overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
						let div = document.createElement('div')
						div.classList.add('overlay-container')
						overlayDiv.append(div)
		
						let title = document.createElement('div')
						title.classList.add('overlay-title')
						title.innerHTML = 'Logs - '+fdo.name
						div.append(title)

						let logGrid = document.createElement('div')
						logGrid.style.marginTop = '4px'
						logGrid.style.columnGap = '4px'
						logGrid.style.rowGap = '4px'
						logGrid.style.display = 'grid'
						logGrid.style.gridTemplateColumns = 'min-content 1fr'
						div.append(logGrid)

						let logGridDateHeader = document.createElement('div')
						logGridDateHeader.classList.add('available-mco-list-cell')
						logGridDateHeader.style.textAlign = 'center'
						logGridDateHeader.style.fontWeight = 'bold'
						logGridDateHeader.innerHTML = 'Date'
						logGrid.append(logGridDateHeader)

						let logGridMessageHeader = document.createElement('div')
						logGridMessageHeader.classList.add('available-mco-list-cell')
						logGridMessageHeader.style.textAlign = 'center'
						logGridMessageHeader.style.fontWeight = 'bold'
						logGridMessageHeader.innerHTML = 'Message'
						logGrid.append(logGridMessageHeader)

						QReq.request(feedlokAPI.getFDOrdersOrderIDLog(fdo.uuid)).then(resp => {
							debug.log('Fetching FDO "'+fdo.name+'"\'s logs...')

							resp.log.events.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1)

							resp.log.events.forEach((log)=>{
								debug.log('Adding log to grid...')
								let message = this.decypherLogMessage(log.msg)
								
								let logGridDate = document.createElement('div')
								logGridDate.classList.add('available-mco-list-cell', 'nowrap')
								logGridDate.innerHTML = new Date((log.timestamp+((new Date()).getTimezoneOffset()*60))*1000).toLocaleString()
								logGrid.append(logGridDate)

								let logGridMessage = document.createElement('div')
								logGridMessage.classList.add('available-mco-list-cell')
								logGridMessage.innerHTML = message
								logGrid.append(logGridMessage)
							})

							let logGridDownload = document.createElement('div')
							logGridDownload.classList.add('overlay-button')
							logGridDownload.style.textAlign = 'center'
							logGridDownload.style.gridColumn = '1/3'
							logGridDownload.style.width = 'min-content'
							logGridDownload.style.justifySelf = 'center'
							logGridDownload.innerHTML = 'Download'
							logGridDownload.onclick = () => {
								debug.log('Download button clicked...')
								let dataString = 'Order,Timestamp,Message\n'

								resp.log.events.forEach((log)=>{
									let message = this.decypherLogMessage(log.msg).replace(',', '')
									let dateString = new Date((log.timestamp+((new Date()).getTimezoneOffset()*60))*1000).toLocaleString().replace(',', '')
									
									dataString += resp.log.order+','+dateString+','+message+'\n'
								})

								let csvContent = "data:text/csv;charset=utf-8,"+dataString
								var encodedUri = encodeURI(csvContent);
								var link = document.createElement("a")
								link.setAttribute("href", encodedUri)
								link.setAttribute("download", fdo.name+".csv")
								document.body.appendChild(link) // Required for FF
								link.click()
								document.body.removeChild(link)

								debug.log('Logs downloaded...')
							}
							logGrid.append(logGridDownload)
						})
			
						overlay.show()
					})
				}
				fdoButtons.append(fdoViewButton)

				let fdoPDFButton = document.createElement('div')
				fdoPDFButton.classList.add('content-button')
				fdoPDFButton.innerHTML = 'Download PDF'
				fdoPDFButton.onclick = () => {
					debug.log('Download PDF button clicked...')
					QReq.request(feedlokAPI.getFDOrdersOrderIDLogPDF(fdo.uuid)).then(resp => {
						debug.log('PDF retrieved from server...')
						if(resp.status !== 200){alert('PDF not found');return}
						return resp.blob().then((file)=>{
							var a = document.createElement("a")
							a.href = URL.createObjectURL(file)
							a.setAttribute("download", fdo.name.replace(' ', '')+"-"+fdo.uuid+".pdf")
							a.click()

							debug.log('PDF downloaded')
						})
					})
				}
				fdoButtons.append(fdoPDFButton)
			})
		}).catch((e) => {
			errorHandler(e)
		})

		//TRAINING
		QReq.request(feedlokAPI.getTraining('?status=complete&datestart='+start+'&datestop='+stop)).then(resp => {
			debug.log('Training orders retrieved...')

			resp = resp.trainings
			
			resp.sort((a, b) => (a.statusTimestamp > b.statusTimestamp) ? 1 : -1)

			resp.forEach((training) => {
				debug.log('Adding Training Order "'+training.name+'" to history list')

				let trainingName = document.createElement('div')
				trainingName.classList.add('content-section')
				trainingName.style.paddingTop = 'calc(.5rem + 4px)'
				trainingName.innerHTML = training.name
				this.trainingContent.append(trainingName)

				let trainingDate = document.createElement('div')
				trainingDate.classList.add('content-section', 'nowrap')
				trainingDate.style.paddingTop = 'calc(.5rem + 4px)'
				trainingDate.innerHTML = new Date(training.statusTimestamp*1000).toLocaleString()
				this.trainingContent.append(trainingDate)

				let trainingButtons = document.createElement('div')
				trainingButtons.classList.add('content-section', 'nowrap')
				this.trainingContent.append(trainingButtons)

				let trainingViewButton = document.createElement('div')
				trainingViewButton.classList.add('content-button')
				trainingViewButton.innerHTML = 'View Logs'
				trainingViewButton.onclick = () => {
					debug.log('Training Order "'+training.name+'"\'s View Logs button has be clicked - generating overlay...')
					overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
						let div = document.createElement('div')
						div.classList.add('overlay-container')
						overlayDiv.append(div)
		
						let title = document.createElement('div')
						title.classList.add('overlay-title')
						title.innerHTML = 'Logs - '+training.name
						div.append(title)

						let logGrid = document.createElement('div')
						logGrid.style.marginTop = '4px'
						logGrid.style.columnGap = '4px'
						logGrid.style.rowGap = '4px'
						logGrid.style.display = 'grid'
						logGrid.style.gridTemplateColumns = 'min-content 1fr'
						div.append(logGrid)

						let logGridDateHeader = document.createElement('div')
						logGridDateHeader.classList.add('available-mco-list-cell')
						logGridDateHeader.style.textAlign = 'center'
						logGridDateHeader.style.fontWeight = 'bold'
						logGridDateHeader.innerHTML = 'Date'
						logGrid.append(logGridDateHeader)

						let logGridMessageHeader = document.createElement('div')
						logGridMessageHeader.classList.add('available-mco-list-cell')
						logGridMessageHeader.style.textAlign = 'center'
						logGridMessageHeader.style.fontWeight = 'bold'
						logGridMessageHeader.innerHTML = 'Message'
						logGrid.append(logGridMessageHeader)

						QReq.request(feedlokAPI.getTrainingTrainingIDLog(training.uuid)).then(resp => {
							debug.log('Fetching Training Order "'+training.name+'"\'s logs...')
							resp.log.events.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1)

							resp.log.events.forEach((log)=>{
								debug.log('Adding log to grid...')
								let message = this.decypherLogMessage(log.msg)
								
								let logGridDate = document.createElement('div')
								logGridDate.classList.add('available-mco-list-cell', 'nowrap')
								logGridDate.innerHTML = new Date(log.timestamp*1000).toLocaleString()
								logGrid.append(logGridDate)

								let logGridMessage = document.createElement('div')
								logGridMessage.classList.add('available-mco-list-cell')
								logGridMessage.innerHTML = message
								logGrid.append(logGridMessage)
							})

							let logGridDownload = document.createElement('div')
							logGridDownload.classList.add('overlay-button')
							logGridDownload.style.textAlign = 'center'
							logGridDownload.style.gridColumn = '1/3'
							logGridDownload.style.width = 'min-content'
							logGridDownload.style.justifySelf = 'center'
							logGridDownload.innerHTML = 'Download'
							logGridDownload.onclick = () => {
								debug.log('Download button clicked...')

								let dataString = 'Training Order,Timestamp,Message\n'

								resp.log.events.forEach((log)=>{
									let message = this.decypherLogMessage(log.msg).replace(',', '')
									let dateString = new Date(log.timestamp*1000).toLocaleString().replace(',', '')
									
									dataString += resp.log.training+','+dateString+','+message+'\n'
								})

								let csvContent = "data:text/csv;charset=utf-8,"+dataString
								var encodedUri = encodeURI(csvContent);
								var link = document.createElement("a")
								link.setAttribute("href", encodedUri)
								link.setAttribute("download", training.name+".csv")
								document.body.appendChild(link) // Required for FF
								link.click()
								document.body.removeChild(link)

								debug.log('Log downloaded...')
							}
							logGrid.append(logGridDownload)
						})
			
						overlay.show()
					})
				}
				trainingButtons.append(trainingViewButton)
			})
		}).catch((e) => {
			errorHandler(e)
		})

		//V1
		QReq.request(feedlokAPI.getV1Reports('?status=complete&datestart='+start+'&datestop='+stop)).then(resp => {
			debug.log('V1 orders retrieved...')
			
			resp.sort((a, b) => (a.statusTimestamp > b.statusTimestamp) ? 1 : -1)

			resp.forEach((report) => {
				debug.log('Adding V1 Order "'+report.name+'" to history list')

				report.statusTimestamp = report.statustimestamp

				let reportName = document.createElement('div')
				reportName.classList.add('content-section')
				reportName.style.paddingTop = 'calc(.5rem + 4px)'
				reportName.innerHTML = report.name
				this.v1ReportsContent.append(reportName)

				let reportDate = document.createElement('div')
				reportDate.classList.add('content-section', 'nowrap')
				reportDate.style.paddingTop = 'calc(.5rem + 4px)'
				reportDate.innerHTML = new Date(report.statusTimestamp*1000).toLocaleString()
				this.v1ReportsContent.append(reportDate)

				let reportButtons = document.createElement('div')
				reportButtons.classList.add('content-section', 'nowrap')
				reportButtons.style.display = 'grid'
				reportButtons.style.gridTemplateColumns = 'min-content min-content'
				this.v1ReportsContent.append(reportButtons)

				let reportViewButton = document.createElement('div')
				reportViewButton.classList.add('content-button')
				reportViewButton.innerHTML = 'View Logs'
				reportViewButton.onclick = () => {
					debug.log('V1 Order "'+report.name+'"\'s View Logs button has be clicked - generating overlay...')
					overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
						let div = document.createElement('div')
						div.classList.add('overlay-container')
						overlayDiv.append(div)
		
						let title = document.createElement('div')
						title.classList.add('overlay-title')
						title.innerHTML = 'Logs - '+report.name
						div.append(title)

						let logGrid = document.createElement('div')
						logGrid.style.marginTop = '4px'
						logGrid.style.columnGap = '4px'
						logGrid.style.rowGap = '4px'
						logGrid.style.display = 'grid'
						logGrid.style.gridTemplateColumns = 'min-content 1fr'
						div.append(logGrid)

						let logGridDateHeader = document.createElement('div')
						logGridDateHeader.classList.add('available-mco-list-cell')
						logGridDateHeader.style.textAlign = 'center'
						logGridDateHeader.style.fontWeight = 'bold'
						logGridDateHeader.innerHTML = 'Date'
						logGrid.append(logGridDateHeader)

						let logGridMessageHeader = document.createElement('div')
						logGridMessageHeader.classList.add('available-mco-list-cell')
						logGridMessageHeader.style.textAlign = 'center'
						logGridMessageHeader.style.fontWeight = 'bold'
						logGridMessageHeader.innerHTML = 'Message'
						logGrid.append(logGridMessageHeader)

						QReq.request(feedlokAPI.getV1ReportReportIDLog(report.uuid)).then(resp => {
							debug.log('Fetching V1 Order "'+report.name+'"\'s logs...')
							resp.events.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1)

							resp.events.forEach((log)=>{
								debug.log('Adding log to grid...')
								let message = this.decypherLogMessage(log.msg)
								
								let logGridDate = document.createElement('div')
								logGridDate.classList.add('available-mco-list-cell', 'nowrap')
								logGridDate.innerHTML = new Date(log.timestamp*1000).toLocaleString()
								logGrid.append(logGridDate)

								let logGridMessage = document.createElement('div')
								logGridMessage.classList.add('available-mco-list-cell')
								logGridMessage.innerHTML = message
								logGrid.append(logGridMessage)
							})

							let logGridDownload = document.createElement('div')
							logGrid.append(logGridDownload)
						})
			
						overlay.show()
					})
				}
				reportButtons.append(reportViewButton)

				let v1ReportPDFButton = document.createElement('div')
				v1ReportPDFButton.classList.add('content-button')
				v1ReportPDFButton.innerHTML = 'Download PDF'
				v1ReportPDFButton.onclick = () => {
					debug.log('Download PDF button clicked...')
					QReq.request(feedlokAPI.getV1ReportReportIDLogPDF(report.uuid)).then(resp => {
						debug.log('PDF retrieved from server...')
						if(resp.status !== 200){alert('PDF not found');return}
						return resp.blob().then((file)=>{
							var a = document.createElement("a")
							a.href = URL.createObjectURL(file)
							a.setAttribute("download", report.name.replace(' ', '')+"-"+report.uuid+".pdf")
							a.click()

							debug.log('PDF downloaded')
						})
					})
				}
				reportButtons.append(v1ReportPDFButton)
			})
		}).catch((e) => {
			console.log(e)
			errorHandler(e)
		})
	}

	start(page) {
		page.getButton().div.classList.add('navbar-items-item-active')
		this._generateDownloadHistoryContent()
	}

	stop(page) {
		page.getButton().div.classList.remove('navbar-items-item-active')
	}

	initCB(page) {
	}
}
