'use strict'

import {DOMClearChildren} from '../ms/ms.js';

/**
 * Create a simplified filter for data
 */
export class Filter{
    constructor(){
        this.filters = {}
        this.parentDiv
        this.sections = {}
    }

    /**
     * Reset all filters
     */
    reset(){
        this.filters = {}
        this.parentDiv = undefined
        this.sections = {}
    }

    /**
     * Clear a particular selected filter type
     * @param {string} filterID Filter ID we are trying to clear - example: owners, farms, etc
     * @returns 
     */
    clearFilter(filterID = undefined){
        if(filterID !== undefined){
            delete this.filters[filterID]
            return
        }
        this.filters = {}
    }

    /**
     * Set the parent for the filter list
     * @param {element} div Parent for filter list
     */
    setParent(div){
        this.parentDiv = div
    }

    /**
     * Create the framework for building a filter section - DOES NOT BUILD IT ONLY PREPS
     * @param {*} settingsObject Defined object containing: title, availableFilters, labelKey, identifierKey, type, dataAlias, onFilterChange
     */
    createFilterSection(settingsObject){//DOES NOT DISPLAY IT - SIMPLY ADDED IT AS A POSSIBILITY
        this.sections[settingsObject.filterID] ={
            title: settingsObject.title,//what we show above the section
            filtersObject: settingsObject.availableFilters,//the list of available info to pick from to filter (list of farms for example)
            labelKey: settingsObject.labelKey,//what key from the data (above) we display as the label next to the checkbox (farm.name for example)
            identifierKey: settingsObject.identifierKey,//the unique key we can use to identify if it matches data in the filtering stage (farm.uuid for example)
            type: settingsObject.type,
            //0 -> If value is equal to filter
            //-> For example we use 0 to determine if an MCO has a particular owner
            //1 -> If value is in filter selection (array)
            //-> For example we use 1 to determine if an MCO contains a particular farm
            dataAlias: settingsObject.dataAlias,//what the data we are filtering knows the identifierKey as (the mco might know farm.uuid from identifierKey as farmUUID)
            callback : settingsObject.onFilterChange//function to be called when filter section changes
        }
    }

    /**
     * Remove a filter section by it's ID - defined via filterID in the createFilterSection object
     * @param {string} filterID What we know the filter as
     */
    removeFilterSection(filterID){//DELETE FILTER SECTION - NOT REMOVE FROM UI
        delete this.sections[filterID]
    }

    /**
     * Clear Filter Parent DIV - Acts as a hide
     */
    clearFilterDiv(){//REMOVE EVERYTHING FROM FILTER DIV
        DOMClearChildren(this.parentDiv)
    }

    /**
     * Build out the ENTIRE filter container - all sections
     */
    buildFilterDiv(){//BUILDS ENTIRE FILTER DIV
        Object.keys(this.sections).forEach((filterID) => {
            this.buildNewFilterSection(filterID)
        })
    }

    /**
     * Builds 1 specific filer section by it's ID
     * @param {string} filterID Which filter section to build - defined via filterID in the createFilterSection object
     */
    buildNewFilterSection(filterID){//BUILDS 1 SECTION OF THE FILTER...maybe we only want farm filter?
        let filterTitle = document.createElement('div')
		filterTitle.innerHTML = this.sections[filterID].title+':<hr>'
        this.parentDiv.append(filterTitle)

        //sort by displayed value (such as owner name and farm name)
        this.sections[filterID].filtersObject.sort((a, b) => (a[this.sections[filterID].labelKey] > b[this.sections[filterID].labelKey]) ? 1 : -1)
        
        this.sections[filterID].filtersObject.forEach((option) => {
			let optionDiv = document.createElement('div')
			this.parentDiv.append(optionDiv)

			let optionCheckbox = document.createElement('input')
			optionCheckbox.type = 'checkbox'
			if(Object.keys(this.filters).includes(filterID) && this.filters[filterID].includes(option[this.sections[filterID].identifierKey])){ optionCheckbox.checked = true }//if we have it selected in filters
			//ON CHANGE WE NEED TO ADD TO FILTERS AND RESET THE LIST FOR REAL TIME FILTERING
			optionCheckbox.onchange = () => {
				if(Object.keys(this.filters).includes(filterID)){//we already have some farm filters...add/remove
					if(optionCheckbox.checked == true && !this.filters[filterID].includes(option[this.sections[filterID].identifierKey])){//if we are looking to add and it currently isnt already in
						this.filters[filterID].push(option[this.sections[filterID].identifierKey])//add farm uuid to filter list
					}else{
						this.filters[filterID].splice(this.filters[filterID].indexOf(option[this.sections[filterID].identifierKey]), 1)//removes the farm uuid from the list
						if(this.filters[filterID].length == 0){ delete this.filters[filterID] }//if it was the only one in list remove the filter key
					}
				}else{
					if(optionCheckbox.checked == false){ return }//if the filter key doesnt exist and the option isnt checked...reset...something is wrong
					this.filters[filterID] = [option[this.sections[filterID].identifierKey]]//add the farm that we want in a list, so that we can loop :)
                }
                this.sections[filterID].callback()//reload list (uses callback) so we can see live results - second param is if the filter selection is open so we hard code it since they are obviously making changes
			}
			//END ON CHANGE
			this.parentDiv.append(optionCheckbox)

			let optionLabel = document.createElement('span')
			optionLabel.innerHTML = ' '+option[this.sections[filterID].labelKey]
			this.parentDiv.append(optionLabel)
        })
        
        this.parentDiv.append(document.createElement('hr'))
    } 

    /**
     * Filter data based on selected filter criteria
     * @param {object} dataToFilter Data to filter based on our criteria
     * @returns Object of the data the matched our criteria
     */
    filterData(dataToFilter){
        let filteredList = dataToFilter
        if(Object.keys(this.filters).length > 0){
			Object.keys(this.filters).forEach((filterID) => {
                if(this.sections[filterID].type == 0){//check if our value is in the set passed to the settings object (list of owners for example)
                    filteredList = filteredList.filter(item => this.filters[filterID].includes(item[this.sections[filterID].dataAlias]))
                }else if(this.sections[filterID].type == 1){//check if our data includes one of the selected options
                    filteredList = filteredList.filter(item => {//true will auto keep it as a match - false will auto remove it
                        let filterMatch = false//default to no

                        this.filters[filterID].forEach((availableOption) => {//for each selected option
                            if(item[this.sections[filterID].dataAlias].includes(availableOption)){//item[farms] for example includes a selected option
                                filterMatch = true
                            }
                        })

                        return filterMatch
                    })
                }
			})
		}
        return filteredList
    }
}