'use strict';

import {QReq, DOMClearChildren} from '../ms/ms.js';
import {buildDataMaps, _bins, _farms, _fdos2, _mcos2, _owners, _trailers, _training, feedlokAPI, globalCache, changeTracker, overlay, errorHandler} from './main';

export class AssetsPage {
	constructor() {
		/**
		 * Object that stores HTML elements related to different portions of the UI
		 */
		this._divs = {}
		this._subnavButtons = {}

		/**
		 * Tracks the current selected owner so we can switch tabs without seemingly losing that progress - false means no current selected owner
		 */
		this.selectedOwner = false
		/**
		 * Tracks the current selected farm so we can switch tabs without seemingly losing that progress - false means no current selected farm
		 */
		this.selectedFarm = false
		/**
		 * Tracks the current selected trailer so we can switch tabs without seemingly losing that progress - false means no current selected trailer
		 */
		this.selectedTrailer = false
		/**
		 * Tracks the current selected bin so we can switch tabs without seemingly losing that progress - false means no current selected bin
		 */
		this.selectedBin = false
	}

	/**
	 * Generate the button that will be added to the left menu
	 * @param {element} button HTML Element where the button will be created
	 */
	generateButton(button) {
		button.div.classList.add('navbar-items-item')
		button.icon.classList.add('navbar-items-icon')
		button.icon.classList.add('fa')
		button.icon.classList.add('fa-list')
		button.text.classList.add('large')
		button.text.innerHTML = 'Assets'
		button.div.append(button.icon)
		button.div.append(button.text)
	}

	/**
	 * Generate the sub-navigation of the page
	 * @param {element} subnav HTML Element of the left menu where the page's subnav will be created
	 */
	generateSubnav(subnav) {//hard codes menu since it wont be dynamic...
		let ownersLink = document.createElement('div')
		ownersLink.classList.add('nav-asset-list-item')
		ownersLink.innerHTML = 'Owners'
		ownersLink.onclick = () => {
			QReq.request(feedlokAPI.getOwners()).then(resp => {
				globalCache.mergeCacheListItems('owners', resp.owners, 'uuid', {
					updated: (definingFieldValue) => {
						changeTracker.clearChanges('owners', definingFieldValue)
					},
					finished: () => {
						buildDataMaps()

						if(this.selectedOwner !== false){//reset data
							this.selectedOwner.data = _owners[this.selectedOwner.data.uuid]
						}

						ownersLink.classList.add('nav-asset-list-item-active')
						farmsLink.classList.remove('nav-asset-list-item-active')
						binsLink.classList.remove('nav-asset-list-item-active')
						trailersLink.classList.remove('nav-asset-list-item-active')
						this.generateContentGrid()
					}
				})
			})
		}
		subnav.append(ownersLink)
		this._subnavButtons.owners = ownersLink

		let farmsLink = document.createElement('div')
		farmsLink.classList.add('nav-asset-list-item')
		farmsLink.innerHTML = 'Farms'
		farmsLink.onclick = () => {
			QReq.request(feedlokAPI.getFarms()).then(resp => {
				globalCache.mergeCacheListItems('farms', resp.farms, 'uuid', {
					updated: (definingFieldValue) => {
						changeTracker.clearChanges('farms', definingFieldValue)
					},
					finished: () => {
						buildDataMaps()

						if(this.selectedFarm !== false){//reset data
							this.selectedFarm.data = _farms[this.selectedFarm.data.uuid]
						}

						ownersLink.classList.remove('nav-asset-list-item-active')
						farmsLink.classList.add('nav-asset-list-item-active')
						binsLink.classList.remove('nav-asset-list-item-active')
						trailersLink.classList.remove('nav-asset-list-item-active')
						this.generateContentGrid('farm')
					}
				})
			})
		}
		subnav.append(farmsLink)
		this._subnavButtons.farms = farmsLink

		let binsLink = document.createElement('div')
		binsLink.classList.add('nav-asset-list-item')
		binsLink.innerHTML = 'Bins'
		binsLink.onclick = () => {
			QReq.request(feedlokAPI.getBins()).then(resp => {
				globalCache.mergeCacheListItems('bins', resp.bins, 'uuid', {
					updated: (definingFieldValue) => {
						changeTracker.clearChanges('bins', definingFieldValue)
					},
					finished: () => {
						buildDataMaps()

						if(this.selectedBin !== false){//reset data
							this.selectedBin.data = _bins[this.selectedBin.data.uuid]
						}

						ownersLink.classList.remove('nav-asset-list-item-active')
						farmsLink.classList.remove('nav-asset-list-item-active')
						binsLink.classList.add('nav-asset-list-item-active')
						trailersLink.classList.remove('nav-asset-list-item-active')
						this.generateContentGrid('bin')
					}
				})
			})
		}
		subnav.append(binsLink)
		this._subnavButtons.bins = binsLink

		let trailersLink = document.createElement('div')
		trailersLink.classList.add('nav-asset-list-item')
		trailersLink.innerHTML = 'Trailers'
		trailersLink.onclick = () => {
			QReq.request(feedlokAPI.getTrailers()).then(resp => {
				globalCache.mergeCacheListItems('trailers', resp.trailers, 'uuid', {
					updated: (definingFieldValue) => {
						changeTracker.clearChanges('trailers', definingFieldValue)
					},
					finished: () => {
						buildDataMaps()

						if(this.selectedTrailer !== false){//reset data
							this.selectedTrailer.data = _trailers[this.selectedTrailer.data.uuid]
						}

						ownersLink.classList.remove('nav-asset-list-item-active')
						farmsLink.classList.remove('nav-asset-list-item-active')
						binsLink.classList.remove('nav-asset-list-item-active')
						trailersLink.classList.add('nav-asset-list-item-active')
						this.generateContentGrid('trailer')
					}
				})
			})
		}
		subnav.append(trailersLink)
		this._subnavButtons.trailers = trailersLink
	}

	/**
	 * Clears the asset selection menu and main content section so we can reuse the main layout
	 */
	_resetContent(){
		DOMClearChildren(this._divs.content)
	}

	/**
	 * Update the main list button for an asset - checks changes
	 * @param {object} data Object of the data associated with this button
	 * @param {string} type Asset type - owner, farm, bin, trailer
	 */
	_updateListButton(data, type){
		data.div.innerHTML = data.data.name
		changeTracker.getWarning(type, data.data.uuid, data.div) 
	}

	/**
	 * Show warning mentioning there are unsaved changes
	 * @param {function} callback Function to call immediately after we show content warning - can be used to call _updateListButton
	 */
	_showContentAlert(callback = ()=>{}){
		this._divs.alert.style.display = 'block'
		callback()
	}

	/**
	 * Hide warning mentioning there are unsaved changes
	 */
	_hideContentAlert(){
		this._divs.alert.style.display = 'none'
	}

	/**
	 * Create the main grid with titles based on the type
	 * @param {string} type What sub page we are view - Owners, Farms, Bins, Trailers, ETC
	 */
	generateContentGrid(type = 'owner'){//basic layout grid for the sections we will need to edit
		DOMClearChildren(this.mainContent)

		let mainContentGrid = document.createElement('div')
		mainContentGrid.classList.add('content-layout')
		this.mainContent.append(mainContentGrid)

		let mainContentGridTitle = document.createElement('div')
		mainContentGridTitle.classList.add('content-title')
		mainContentGridTitle.innerHTML = 'Available Orders:'
		mainContentGrid.append(mainContentGridTitle)
		this._divs.listTitle = mainContentGridTitle

		let mainContentGridOrderTitle = document.createElement('div')
		mainContentGridOrderTitle.classList.add('content-title')
		mainContentGridOrderTitle.style.borderRight = 'none'
		mainContentGridOrderTitle.innerHTML = 'Select an order...'
		mainContentGrid.append(mainContentGridOrderTitle)
		this._divs.title = mainContentGridOrderTitle

		let mainContentGridList = document.createElement('div')
		mainContentGridList.classList.add('content-list')
		mainContentGrid.append(mainContentGridList)
		this._divs.list = mainContentGridList

		let mainContentGridContent = document.createElement('div')
		mainContentGridContent.classList.add('content-order-panel')
		mainContentGrid.append(mainContentGridContent)

		let mainContent = document.createElement('div')
		mainContent.classList.add('content-order-panel-grid')
		mainContentGridContent.append(mainContent)
		this._divs.content = mainContent

		let mainContentGridButtons = document.createElement('div')
		mainContentGridButtons.classList.add('content-list-buttons')
		mainContentGridButtons.style.gridTemplateColumns = '1fr 1fr'
		mainContentGrid.append(mainContentGridButtons)
		this._divs.listButtons = mainContentGridButtons

		let mainContentGridAddNewButton = document.createElement('div')
		mainContentGridAddNewButton.classList.add('content-button')
		mainContentGridAddNewButton.innerHTML = '+ Add New'
		mainContentGridButtons.append(mainContentGridAddNewButton)

		let mainContentGridImportButton = document.createElement('div')
		mainContentGridImportButton.classList.add('content-button')
		mainContentGridImportButton.onclick = () => {
			overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
				let div = document.createElement('div')
				div.classList.add('overlay-container')
				overlayDiv.append(div)

				let title = document.createElement('div')
				title.classList.add('overlay-title')
				title.innerHTML = 'Import Data'
				div.append(title)

				let errorLog = document.createElement('div')
				errorLog.style.paddingTop = '4px'
				div.append(errorLog)

				let fileInput = document.createElement('input')
				fileInput.style.paddingTop = '4px'
				fileInput.type = 'file'
				fileInput.required = true
				fileInput.onchange = (event) => {
					event.preventDefault()
					processImport(type, event, errorLog, (newEntries) => {
						if(type == 'owner'){
							newEntries.forEach((ownerObject) => {
								let existingOwner = globalCache.getCacheList('owners').filter(owner => owner.customid == parseInt(ownerObject.customid))

								ownerObject.addr = {}
								ownerObject.addr.addr1 = ownerObject.addr1
								delete ownerObject.addr1
								ownerObject.addr.addr2 = ownerObject.addr2
								delete ownerObject.addr2
								ownerObject.addr.city = ownerObject.city
								delete ownerObject.city
								ownerObject.addr.state = ownerObject.state
								delete ownerObject.state
								ownerObject.addr.zip = ownerObject.zip
								delete ownerObject.zip

								if(existingOwner.length == 1){
									QReq.request(feedlokAPI.putOwnersOwnerID(existingOwner[0].uuid, ownerObject)).then(resp => {
										Object.keys(ownerObject).forEach(field => {
											_owners[resp.uuid][field] = ownerObject[field]
										})
										this._populateOwnerList()
									})
								}else if(existingOwner.length == 0){//new order
									QReq.request(feedlokAPI.postOwners(ownerObject)).then(resp => {
										_owners[resp.uuid] = resp
										globalCache.setNewCacheListItem('owners', resp)
										this._populateOwnerList()
									})
								}else{
									alert('The editing of Owner "'+ownerObject.name+'" has been canceled due to multiple matching IDs')
								}
							})
						}else if(type == 'farm'){
							newEntries.forEach((farmObject) => {
								let existingFarm = globalCache.getCacheList('farms').filter(farm => farm.customid == parseInt(farmObject.customid))

								let ownerList = globalCache.getCacheList('owners').filter(owner => owner.customid == parseInt(farmObject.owner))
								if(ownerList.length !== 1){
									alert('The creation of Farm "'+farmObject.name+'" has been aborted due to the specified owner not existing!')
									return
								}

								farmObject.owner = ownerList[0].uuid

								farmObject.addr = {}
								farmObject.addr.addr1 = farmObject.addr1
								delete farmObject.addr1
								farmObject.addr.addr2 = farmObject.addr2
								delete farmObject.addr2
								farmObject.addr.city = farmObject.city
								delete farmObject.city
								farmObject.addr.state = farmObject.state
								delete farmObject.state
								farmObject.addr.zip = farmObject.zip
								delete farmObject.zip


								if(existingFarm.length == 1){
									QReq.request(feedlokAPI.putFarmsFarmID(existingFarm[0].uuid, farmObject)).then(resp => {
										Object.keys(farmObject).forEach(field => {
											_farms[resp.uuid][field] = farmObject[field]
										})
										this._populateFarmList()
									})
								}else if(existingFarm.length == 0){//new order
									QReq.request(feedlokAPI.postFarms(farmObject)).then(resp => {
										_farms[resp.uuid] = resp
										globalCache.setNewCacheListItem('farms', resp)
										this._populateFarmList()
									})
								}else{
									alert('The editing of Farm "'+farmObject.name+'" has been canceled due to multiple matching IDs')
								}
							})
						}else if(type == 'bin'){
							newEntries.forEach((binObject) => {
								let existingBin = globalCache.getCacheList('bins').filter(bin => bin.customid == parseInt(binObject.customid))

								let farmList = globalCache.getCacheList('farms').filter(farm => farm.customid == parseInt(binObject.farm))
								if(farmList.length !== 1){
									alert('The creation of Bin "'+binObject.name+'" has been aborted due to the specified farm not existing!')
									return
								}

								binObject.farm = farmList[0].uuid

								if(existingBin.length == 1){
									QReq.request(feedlokAPI.putBinsBinID(existingBin[0].uuid, binObject)).then(resp => {
										Object.keys(binObject).forEach(field => {
											_bins[resp.uuid][field] = binObject[field]
										})
										this._populateBinList()
									})
								}else if(existingBin.length == 0){//new order
									QReq.request(feedlokAPI.postBins(binObject)).then(resp => {
										_bins[resp.uuid] = resp
										globalCache.setNewCacheListItem('bins', resp)
										this._populateBinList()
									})
								}else{
									alert('The editing of Bin "'+binObject.name+'" has been canceled due to multiple matching IDs')
								}
							})
						}else if(type == 'trailer'){
							newEntries.forEach((trailerObject) => {
								let existingTrailer = globalCache.getCacheList('trailers').filter(trailer => trailer.customid == parseInt(trailerObject.customid))

								trailerObject.numBins = parseInt(trailerObject.numBins)
								
								if(existingTrailer.length == 1){
									QReq.request(feedlokAPI.putBinsBinID(existingTrailer[0].uuid, trailerObject)).then(resp => {
										Object.keys(trailerObject).forEach(field => {
											_trailers[resp.uuid][field] = trailerObject[field]
										})
										this._populateTrailerList()
									})
								}else if(existingTrailer.length == 0){//new order
									QReq.request(feedlokAPI.postTrailers(trailerObject)).then(resp => {
										_trailers[resp.uuid] = resp
										globalCache.setNewCacheListItem('trailers', resp)
										this._populateTrailerList()
									})
								}else{
									alert('The editing of Trailer "'+trailerObject.name+'" has been canceled due to multiple matching IDs')
								}
							})
						}
					})
				}
				div.append(fileInput)
	
				overlay.show()
			})
		}
		mainContentGridImportButton.innerHTML = 'Import'

		if(type == 'owner'){
			this._divs.listTitle.innerHTML = 'Owners:'
			this._divs.title.innerHTML = 'Owner Information'
			this._divs.listButtons.style.display = 'grid'
			DOMClearChildren(this._divs.listButtons)
			mainContentGridAddNewButton.onclick = () => {this.generateNewOwnerOverlay()}
			this._divs.listButtons.append(mainContentGridAddNewButton)
			this._divs.listButtons.append(mainContentGridImportButton)
			this._populateOwnerList()//after we make the grid and know where the list is we need to populate the list
			//if(this.selectedOwner !== false){ this._buildMCOOrderContent() }
			return
		}else if(type == 'farm'){
			this._divs.listTitle.innerHTML = 'Farms:'
			this._divs.title.innerHTML = 'Farm Information'
			this._divs.listButtons.style.display = 'grid'
			DOMClearChildren(this._divs.listButtons)
			mainContentGridAddNewButton.onclick = () => {this.generateNewFarmOverlay()}
			this._divs.listButtons.append(mainContentGridAddNewButton)
			this._divs.listButtons.append(mainContentGridImportButton)
			this._populateFarmList()//after we make the grid and know where the list is we need to populate the list
			//if(this.selectedOwner !== false){ this._buildMCOOrderContent() }
		}else if(type == 'bin'){
			this._divs.listTitle.innerHTML = 'Bins:'
			this._divs.title.innerHTML = 'Bin Information'
			this._divs.listButtons.style.display = 'grid'
			DOMClearChildren(this._divs.listButtons)
			mainContentGridAddNewButton.onclick = () => {this.generateNewBinOverlay()}
			this._divs.listButtons.append(mainContentGridAddNewButton)
			this._divs.listButtons.append(mainContentGridImportButton)
			this._populateBinList()//after we make the grid and know where the list is we need to populate the list
			//if(this.selectedOwner !== false){ this._buildMCOOrderContent() }
		}else if(type == 'trailer'){
			this._divs.listTitle.innerHTML = 'Trailers:'
			this._divs.title.innerHTML = 'Trailer Information'
			this._divs.listButtons.style.display = 'grid'
			DOMClearChildren(this._divs.listButtons)
			mainContentGridAddNewButton.onclick = () => {this.generateNewTrailerOverlay()}
			this._divs.listButtons.append(mainContentGridAddNewButton)
			this._divs.listButtons.append(mainContentGridImportButton)
			this._populateTrailerList()//after we make the grid and know where the list is we need to populate the list
			//if(this.selectedOwner !== false){ this._buildMCOOrderContent() }
		}
	}

	/*******************************************************************************OWNER***************************************************************************************/
	generateNewOwnerOverlay(){
		overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
			let div = document.createElement('div')
			div.classList.add('overlay-container')
			overlayDiv.append(div)

			let title = document.createElement('div')
			title.classList.add('overlay-title')
			title.innerHTML = 'New Owner'
			div.append(title)

			let formGrid = document.createElement('div')
			formGrid.style.display = 'grid'
			formGrid.style.gridTemplateColumns = '1fr 1fr'
			formGrid.style.fontSize = '1.5rem'
			formGrid.style.marginTop = '4px'
			formGrid.style.rowGap = '4px'
			div.append(formGrid)

			let ownerName = document.createElement('div')
			ownerName.innerHTML = 'Name:'
			formGrid.append(ownerName)

			let ownerNameInputContainer = document.createElement('div')
			formGrid.append(ownerNameInputContainer)

			let ownerNameInput = document.createElement('input')
			ownerNameInput.classList.add('content-input')
			ownerNameInputContainer.append(ownerNameInput)

			let ownerCustomID = document.createElement('div')
			ownerCustomID.innerHTML = 'Custom ID:'
			formGrid.append(ownerCustomID)

			let ownerCustomIDInputContainer = document.createElement('div')
			formGrid.append(ownerCustomIDInputContainer)

			let ownerCustomIDInput = document.createElement('input')
			ownerCustomIDInput.classList.add('content-input')
			ownerCustomIDInputContainer.append(ownerCustomIDInput)

			let buttonContainer = document.createElement('div')
			buttonContainer.style.gridColumn = '1/3'
			formGrid.append(buttonContainer)

			let submitButton = document.createElement('div')
			submitButton.classList.add('overlay-button')
			submitButton.innerHTML = 'Create'
			submitButton.onclick = () => {
				if(ownerNameInput.value.length == 0){
					alert('A name is required!')
					return
				}

				let newOwner = {
					'name': ownerNameInput.value,
					'customid': ownerCustomIDInput.value,
					'email': '',
					'phone': '',
					'addr': {
						'addr1': '',
						'addr2': '',
						'city': '',
						'state': '',
						'zip': ''
					}
				}

				QReq.request(feedlokAPI.postOwners(newOwner)).then(resp => {
					_owners[resp.uuid] = resp
					globalCache.setNewCacheListItem('owners', resp)
					if(this.selectedOwner == false){this.selectedOwner = {}}
					this.selectedOwner.data = _owners[resp.uuid]
					this._populateOwnerList()
					this._buildOwnerContent()
					overlay.close()
				}).catch((e) => {
					errorHandler(e)
				})
			}
			buttonContainer.append(submitButton)

			overlay.show()
		})
	}
	
	/**
	 * Generates the list of owners in the left menu of the main content section
	 */
	_populateOwnerList(){//create the left side list of MCOs
		DOMClearChildren(this._divs.list)

		let searchRow = document.createElement('div')
		searchRow.style.display = 'grid'
		searchRow.style.gridTemplateColumns = '1fr min-content'
		this._divs.list.append(searchRow)

		let listSearch = document.createElement('input')
		listSearch.classList.add('content-input')
		listSearch.style.width = 'calc(100% - 6px)'
		listSearch.style.marginBottom = '4px'
		listSearch.placeholder = 'Search...'
		searchRow.append(listSearch)

		let searchButton = document.createElement('div')
		searchButton.classList.add('content-button')
		searchButton.style.marginTop = '0'
		searchButton.style.marginRight = '0'
		searchButton.innerHTML = 'Search'
		searchButton.onclick = () => {
			let assetDivs = this._divs.list.querySelectorAll('div.content-list-item')//get all of the divs in "machineList" div which contains asset list

			for (var i = 0; i < assetDivs.length; i++) {//loop through all divs
				if(!assetDivs[i].innerText.toLocaleLowerCase().includes(listSearch.value.toLocaleLowerCase())){//see if innerHMTL include search value
					assetDivs[i].style.display = 'none'//hide since we were checking if includes = false
				}else{
					assetDivs[i].style.display = 'block'//this is the default for this particular element
				}
			}
		}
		searchRow.append(searchButton)

		globalCache.getCacheList('owners').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)

		//GENERATE LIST
		globalCache.getCacheList('owners').forEach((owner) => {
			let mainContentGridOwner = document.createElement('div')
			mainContentGridOwner.id = 'so-me-uuid-here'+owner.uuid
			mainContentGridOwner.classList.add('content-list-item')
			if(this.selectedOwner !== false){
				if(owner.uuid == this.selectedOwner.data.uuid){ 
					mainContentGridOwner.classList.add('content-list-item-active')
					this.selectedOwner.div = mainContentGridOwner
					this._buildOwnerContent()
				}
			}
			mainContentGridOwner.innerHTML = owner.name
			mainContentGridOwner.onclick = () => {
				if (mainContentGridOwner.classList.contains('content-list-item-active')) {//if they re-click the already selected order
					mainContentGridOwner.classList.remove('content-list-item-active')
					if(this.selectedOwner.data.uuid == owner.uuid){ //reverify if they re-click the already selected order
						this._resetContent()//clear main grid content area
						this.selectedOwner = false//no order selected
					}else{ return }
				} else {//one new order clicked
					mainContentGridOwner.classList.add('content-list-item-active')
					if(this.selectedOwner !== false && this.selectedOwner.data.uuid !== owner.uuid){//an order is already clicked - we need to remove it
						this.selectedOwner.div.classList.remove('content-list-item-active')
					}
					//set the new selected order
					this.selectedOwner = {}
					this.selectedOwner.data = owner//raw info
					this.selectedOwner.div = mainContentGridOwner//raw link div
					this._buildOwnerContent()//load in the order info into main content area of grid
				}
			}

			changeTracker.getWarning('owners', owner.uuid, mainContentGridOwner)

			this._divs.list.append(mainContentGridOwner)
		})
	}

	/**
	 * Populates the main content section with the form to edit the owner
	 */
	_buildOwnerContent(){//display current info for existing order...new order will be different
		this._resetContent()
		//waiting to complete - we only have owner name...not worth creating

		let ownerName = document.createElement('div')
		ownerName.classList.add('content-section')
		ownerName.style.marginTop = '10px'
		ownerName.innerHTML = 'Owner Name: '
		this._divs.content.append(ownerName)

		let ownerNameInput = document.createElement('input')
		ownerNameInput.classList.add('content-input')
		ownerNameInput.value = this.selectedOwner.data.name
		ownerNameInput.onkeyup = () => {
			changeTracker.addToChanged('owners', this.selectedOwner.data.uuid, {'name': this.selectedOwner.data.name}, {'name': ownerNameInput.value})
			this.selectedOwner.data.name = ownerNameInput.value
			this._showContentAlert(this._updateListButton(this.selectedOwner, 'owners'))
		}
		ownerName.append(ownerNameInput)

		let ownerMill = document.createElement('div')
		ownerMill.classList.add('content-section')
		ownerMill.style.marginTop = '10px'
		ownerMill.innerHTML = 'Custom ID: '
		this._divs.content.append(ownerMill)

		let ownerMillInput = document.createElement('input')
		ownerMillInput.classList.add('content-input')
		ownerMillInput.value = this.selectedOwner.data.customid
		ownerMillInput.onkeyup = () => {
			changeTracker.addToChanged('owners', this.selectedOwner.data.uuid, {'customid': this.selectedOwner.data.customid}, {'customid': ownerMillInput.value})
			this.selectedOwner.data.customid = ownerMillInput.value
			this._showContentAlert(this._updateListButton(this.selectedOwner, 'owners'))
		}
		ownerMill.append(ownerMillInput)

		let ownerEmail = document.createElement('div')
		ownerEmail.classList.add('content-section')
		ownerEmail.innerHTML = 'Email: '
		this._divs.content.append(ownerEmail)

		let ownerEmailInput = document.createElement('input')
		ownerEmailInput.classList.add('content-input')
		ownerEmailInput.value = this.selectedOwner.data.email
		ownerEmailInput.onkeyup = () => {
			changeTracker.addToChanged('owners', this.selectedOwner.data.uuid, {'email': this.selectedOwner.data.email}, {'email': ownerEmailInput.value})
			this.selectedOwner.data.email = ownerEmailInput.value
			this._showContentAlert(this._updateListButton(this.selectedOwner, 'owners'))
		}
		ownerEmail.append(ownerEmailInput)

		let ownerPhone = document.createElement('div')
		ownerPhone.classList.add('content-section')
		ownerPhone.innerHTML = 'Phone: '
		this._divs.content.append(ownerPhone)

		let ownerPhoneInput = document.createElement('input')
		ownerPhoneInput.classList.add('content-input')
		ownerPhoneInput.value = this.selectedOwner.data.phone
		ownerPhoneInput.onkeyup = () => {
			changeTracker.addToChanged('owners', this.selectedOwner.data.uuid, {'phone': this.selectedOwner.data.phone}, {'phone': ownerPhoneInput.value})
			this.selectedOwner.data.phone = ownerPhoneInput.value
			this._showContentAlert(this._updateListButton(this.selectedOwner, 'owners'))
		}
		ownerPhone.append(ownerPhoneInput)

		let addressHeader = document.createElement('div')
		addressHeader.classList.add('content-section')
		addressHeader.style.gridColumn = '1/3'
		addressHeader.style.background = '#000'
		addressHeader.style.marginTop = '10px'
		addressHeader.style.textAlign = 'center'
		addressHeader.innerHTML = 'Address: '
		this._divs.content.append(addressHeader)

		let ownerAddr1 = document.createElement('div')
		ownerAddr1.classList.add('content-section')
		ownerAddr1.innerHTML = 'Address 1: '
		this._divs.content.append(ownerAddr1)

		if(this.selectedOwner.data.addr == undefined){
			this.selectedOwner.data.addr = {
				addr1: "",
				addr2: "",
				city: "",
				state: "",
				zip: ""
			}
		}

		let ownerAddr1Input = document.createElement('input')
		ownerAddr1Input.classList.add('content-input')
		ownerAddr1Input.value = this.selectedOwner.data.addr.addr1
		ownerAddr1Input.onkeyup = () => {
			changeTracker.addToChanged('owners', this.selectedOwner.data.uuid, {'addr1': this.selectedOwner.data.addr.addr1}, {'addr1': ownerAddr1Input.value})
			this.selectedOwner.data.addr.addr1 = ownerAddr1Input.value
			this._showContentAlert(this._updateListButton(this.selectedOwner, 'owners'))
		}
		ownerAddr1.append(ownerAddr1Input)

		let ownerAddr2 = document.createElement('div')
		ownerAddr2.classList.add('content-section')
		ownerAddr2.innerHTML = 'Address 2: '
		this._divs.content.append(ownerAddr2)

		let ownerAddr2Input = document.createElement('input')
		ownerAddr2Input.classList.add('content-input')
		ownerAddr2Input.value = this.selectedOwner.data.addr.addr2
		ownerAddr2Input.onkeyup = () => {
			changeTracker.addToChanged('owners', this.selectedOwner.data.uuid, {'addr2': this.selectedOwner.data.addr.addr2}, {'addr2': ownerAddr2Input.value})
			this.selectedOwner.data.addr.addr2 = ownerAddr2Input.value
			this._showContentAlert(this._updateListButton(this.selectedOwner, 'owners'))
		}
		ownerAddr2.append(ownerAddr2Input)

		let ownerCity = document.createElement('div')
		ownerCity.classList.add('content-section')
		ownerCity.innerHTML = 'City: '
		this._divs.content.append(ownerCity)

		let ownerCityInput = document.createElement('input')
		ownerCityInput.classList.add('content-input')
		ownerCityInput.value = this.selectedOwner.data.addr.city
		ownerCityInput.onkeyup = () => {
			changeTracker.addToChanged('owners', this.selectedOwner.data.uuid, {'city': this.selectedOwner.data.addr.city}, {'city': ownerCityInput.value})
			this.selectedOwner.data.addr.city = ownerCityInput.value
			this._showContentAlert(this._updateListButton(this.selectedOwner, 'owners'))
		}
		ownerCity.append(ownerCityInput)

		let ownerState = document.createElement('div')
		ownerState.classList.add('content-section')
		ownerState.innerHTML = 'State: '
		this._divs.content.append(ownerState)

		let ownerStateInput = document.createElement('input')
		ownerStateInput.classList.add('content-input')
		ownerStateInput.value = this.selectedOwner.data.addr.state
		ownerStateInput.onkeyup = () => {
			changeTracker.addToChanged('owners', this.selectedOwner.data.uuid, {'state': this.selectedOwner.data.addr.state}, {'state': ownerStateInput.value})
			this.selectedOwner.data.addr.state = ownerStateInput.value
			this._showContentAlert(this._updateListButton(this.selectedOwner, 'owners'))
		}
		ownerState.append(ownerStateInput)

		let ownerZip = document.createElement('div')
		ownerZip.classList.add('content-section')
		ownerZip.innerHTML = 'Zip: '
		this._divs.content.append(ownerZip)

		let ownerZipInput = document.createElement('input')
		ownerZipInput.classList.add('content-input')
		ownerZipInput.value = this.selectedOwner.data.addr.zip
		ownerZipInput.onkeyup = () => {
			changeTracker.addToChanged('owners', this.selectedOwner.data.uuid, {'zip': this.selectedOwner.data.addr.zip}, {'zip': ownerZipInput.value})
			this.selectedOwner.data.addr.zip = ownerZipInput.value
			this._showContentAlert(this._updateListButton(this.selectedOwner, 'owners'))
		}
		ownerZip.append(ownerZipInput)

		let farmsHeader = document.createElement('div')
		farmsHeader.classList.add('content-section')
		farmsHeader.style.gridColumn = '1/3'
		farmsHeader.style.background = '#000'
		farmsHeader.style.marginTop = '10px'
		farmsHeader.style.textAlign = 'center'
		farmsHeader.innerHTML = 'Farms: '
		this._divs.content.append(farmsHeader)

		let ownerFarms = globalCache.getCacheList('farms').filter(farm => farm.owner == this.selectedOwner.data.uuid)
		ownerFarms.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
		ownerFarms.forEach((farm) => {
			let farmDiv = document.createElement('div')
			farmDiv.style.display = 'grid'
			farmDiv.style.gridTemplateColumns = '1fr min-content'
			farmDiv.classList.add('content-section')
			farmDiv.style.gridColumn = '1/3'
			this._divs.content.append(farmDiv)

			let farmName = document.createElement('div')
			farmName.style.marginTop = '8px'
			farmName.innerHTML = farm.name
			farmDiv.append(farmName)

			let farmButton = document.createElement('div')
			farmButton.classList.add('content-button')
			farmButton.innerHTML = 'View/Edit'
			farmButton.onclick = () => {
				if(this.selectedFarm == false){this.selectedFarm = {}}
				this.selectedFarm.data = farm
				this._subnavButtons.farms.click()
			}
			farmDiv.append(farmButton)
		})

		//UPDATE BUTTON
		let ownerContentUpdate = document.createElement('div')
		ownerContentUpdate.classList.add('content-button')
		ownerContentUpdate.style.gridColumn = '1/3'
		ownerContentUpdate.style.width = 'min-content'
		ownerContentUpdate.style.justifySelf = 'center'
		ownerContentUpdate.innerHTML = 'Update'
		ownerContentUpdate.onclick = () => {
			let changes = changeTracker.getChanges('owners', this.selectedOwner.data.uuid)
			if(changes == false){return}

			Object.keys(changes).forEach((field) => {
				if(field == "addr1" || field == "addr2" || field == "city" || field == "state" || field == "zip"){
					if(changes.addr == undefined){changes.addr = this.selectedOwner.data.addr}
					changes.addr[field] = changes[field]
					delete changes[field]
				}
			})

			QReq.request(feedlokAPI.putOwnersOwnerID(this.selectedOwner.data.uuid, changes)).then(resp => {
				changeTracker.clearChanges('owners', this.selectedOwner.data.uuid)
				this._populateOwnerList()
				this._buildOwnerContent()
			}).catch((e) => {
				errorHandler(e)
			})
		}
		this._divs.content.append(ownerContentUpdate)

		//SENSITIVE BUTTONS
		let ownerContentButtonsContainer = document.createElement('div')
		ownerContentButtonsContainer.style.gridColumn = '1/3'
		ownerContentButtonsContainer.style.width = 'min-content'
		ownerContentButtonsContainer.style.justifySelf = 'center'
		this._divs.content.append(ownerContentButtonsContainer)

		let ownerContentDeleteButton = document.createElement('div')
		ownerContentDeleteButton.classList.add('content-button')
		ownerContentDeleteButton.style.width = 'min-content'
		ownerContentDeleteButton.style.justifySelf = 'center'
		ownerContentDeleteButton.innerHTML = 'Delete'
		ownerContentDeleteButton.onclick = () => {
			let confirmation = confirm('Are you sure you would like to delete this owner?')
			if(confirmation == true){
				QReq.request(feedlokAPI.deleteOwnersOwnerID(this.selectedOwner.data.uuid)).then(resp => {
					if(resp.status == 409){
						alert('This owner can not be deleted due to being in use!')
						return
					}
					globalCache.deleteCacheListItem('owners', _owners[this.selectedOwner.data.uuid])
					delete _owners[this.selectedOwner.data.uuid]
					this.selectedOwner = false
					this._populateOwnerList()
					this._resetContent()
				}).catch((e) => {
					errorHandler(e)
				})
			}
		}
		ownerContentButtonsContainer.append(ownerContentDeleteButton)

		//ALERT
		let ownerContentAlert = document.createElement('div')
		ownerContentAlert.classList.add('content-alert')
		ownerContentAlert.innerHTML = 'THIS OWNER HAS UNSAVED DATA'
		this._divs.content.append(ownerContentAlert)
		this._divs.alert = ownerContentAlert
		if(changeTracker.contains('owners', this.selectedOwner.data.uuid)){ this._showContentAlert() }

		let ownerContentAlertButtonContainer = document.createElement('div')
		ownerContentAlertButtonContainer.style.fontSize = '1rem'
		ownerContentAlertButtonContainer.style.textAlign = 'center'
		this._divs.alert.append(ownerContentAlertButtonContainer)

		let ownerContentAlertRevertButton = document.createElement('div')
		ownerContentAlertRevertButton.classList.add('content-button')
		ownerContentAlertRevertButton.style.fontSize = '1rem'
		ownerContentAlertRevertButton.innerHTML = 'Revert Changes'
		ownerContentAlertRevertButton.onclick = () => {
			this.selectedOwner.data = changeTracker.revertChanges('owners', this.selectedOwner.data.uuid, this.selectedOwner.data)

			Object.keys(this.selectedOwner.data).forEach((field) => {
				if(field == "addr1" || field == "addr2" || field == "city" || field == "state" || field == "zip"){
					this.selectedOwner.data.addr[field] = this.selectedOwner.data[field]
					delete this.selectedOwner.data[field]
				}
			})

			this._populateOwnerList()
			this._buildOwnerContent()
		}
		ownerContentAlertButtonContainer.append(ownerContentAlertRevertButton)
		//ALERT
	}

	/********************************************************************************FARMS***************************************************************************************/
	generateNewFarmOverlay(){
		overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
			let div = document.createElement('div')
			div.classList.add('overlay-container')
			overlayDiv.append(div)

			let title = document.createElement('div')
			title.classList.add('overlay-title')
			title.innerHTML = 'New Farm'
			div.append(title)

			let formGrid = document.createElement('div')
			formGrid.style.display = 'grid'
			formGrid.style.gridTemplateColumns = '1fr 1fr'
			formGrid.style.fontSize = '1.5rem'
			formGrid.style.marginTop = '4px'
			formGrid.style.rowGap = '4px'
			div.append(formGrid)

			let farmName = document.createElement('div')
			farmName.innerHTML = 'Name:'
			formGrid.append(farmName)

			let farmNameInputContainer = document.createElement('div')
			formGrid.append(farmNameInputContainer)

			let farmNameInput = document.createElement('input')
			farmNameInput.classList.add('content-input')
			farmNameInputContainer.append(farmNameInput)

			let farmOwner = document.createElement('div')
			farmOwner.innerHTML = 'Owner:'
			formGrid.append(farmOwner)

			let farmOwnerInputContainer = document.createElement('div')
			formGrid.append(farmOwnerInputContainer)

			let farmOwnerInput = document.createElement('select')
			farmOwnerInput.classList.add('content-dropdown')
			farmOwnerInput.style.fontSize = '1.5rem'
			farmOwnerInputContainer.append(farmOwnerInput)

			let farmOwnerBlankOption = document.createElement('option')
			farmOwnerBlankOption.value = ""
			farmOwnerBlankOption.innerHTML = ""
			farmOwnerInput.append(farmOwnerBlankOption)

			globalCache.getCacheList('owners').forEach((owner) => {
				let farmOwnerOption = document.createElement('option')
				farmOwnerOption.value = owner.uuid
				farmOwnerOption.innerHTML = owner.name
				farmOwnerInput.append(farmOwnerOption)
			})

			let farmCustomID = document.createElement('div')
			farmCustomID.innerHTML = 'Custom ID:'
			formGrid.append(farmCustomID)

			let farmCustomIDInputContainer = document.createElement('div')
			formGrid.append(farmCustomIDInputContainer)

			let farmCustomIDInput = document.createElement('input')
			farmCustomIDInput.classList.add('content-input')
			farmCustomIDInputContainer.append(farmCustomIDInput)

			let buttonContainer = document.createElement('div')
			buttonContainer.style.gridColumn = '1/3'
			formGrid.append(buttonContainer)

			let submitButton = document.createElement('div')
			submitButton.classList.add('overlay-button')
			submitButton.innerHTML = 'Create'
			submitButton.onclick = () => {
				if(farmNameInput.value.length == 0){
					alert('A name is required!')
					return
				}else if(farmOwnerInput.value.length == 0){
					alert('An owner is required!')
					return
				}

				let newFarm = {
					'name': farmNameInput.value,
					'customid': farmCustomIDInput.value,
					'owner': farmOwnerInput.value,
					'addr': {
						'addr1': '',
						'addr2': '',
						'city': '',
						'state': '',
						'zip': ''
					}
				}

				QReq.request(feedlokAPI.postFarms(newFarm)).then(resp => {
					_farms[resp.uuid] = resp
					globalCache.setNewCacheListItem('farms', resp)
					if(this.selectedFarm == false){this.selectedFarm = {}}
					this.selectedFarm.data = _farms[resp.uuid]
					this._populateFarmList()
					this._buildFarmContent()
					overlay.close()
				}).catch((e) => {
					errorHandler(e)
				})
			}
			buttonContainer.append(submitButton)

			overlay.show()
		})
	}
	
	/**
	 * Generates the list of farms in the left menu of the main content section
	 */
	_populateFarmList(){//create the left side list of MCOs
		DOMClearChildren(this._divs.list)

		let searchRow = document.createElement('div')
		searchRow.style.display = 'grid'
		searchRow.style.gridTemplateColumns = '1fr min-content'
		this._divs.list.append(searchRow)

		let listSearch = document.createElement('input')
		listSearch.classList.add('content-input')
		listSearch.style.width = 'calc(100% - 6px)'
		listSearch.style.marginBottom = '4px'
		listSearch.placeholder = 'Search...'
		searchRow.append(listSearch)

		let searchButton = document.createElement('div')
		searchButton.classList.add('content-button')
		searchButton.style.marginTop = '0'
		searchButton.style.marginRight = '0'
		searchButton.innerHTML = 'Search'
		searchButton.onclick = () => {
			let assetDivs = this._divs.list.querySelectorAll('div.content-list-item')//get all of the divs in "machineList" div which contains asset list

			for (var i = 0; i < assetDivs.length; i++) {//loop through all divs
				if(!assetDivs[i].innerText.toLocaleLowerCase().includes(listSearch.value.toLocaleLowerCase())){//see if innerHMTL include search value
					assetDivs[i].style.display = 'none'//hide since we were checking if includes = false
				}else{
					assetDivs[i].style.display = 'block'//this is the default for this particular element
				}
			}
		}
		searchRow.append(searchButton)

		globalCache.getCacheList('farms').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)

		//GENERATE LIST
		globalCache.getCacheList('farms').forEach((farm) => {
			let mainContentGridFarm = document.createElement('div')
			mainContentGridFarm.id = 'so-me-uuid-here'+farm.uuid
			mainContentGridFarm.classList.add('content-list-item')
			if(this.selectedFarm !== false){
				if(farm.uuid == this.selectedFarm.data.uuid){ 
					mainContentGridFarm.classList.add('content-list-item-active')
					this.selectedFarm.div = mainContentGridFarm
					this._buildFarmContent()
				}
			}
			mainContentGridFarm.innerHTML = farm.name+' ('+_owners[farm.owner].name+')'
			mainContentGridFarm.onclick = () => {
				if (mainContentGridFarm.classList.contains('content-list-item-active')) {//if they re-click the already selected order
					mainContentGridFarm.classList.remove('content-list-item-active')
					if(this.selectedFarm.data.uuid == farm.uuid){ //reverify if they re-click the already selected order
						this._resetContent()//clear main grid content area
						this.selectedFarm = false//no order selected
					}else{ return }
				} else {//one new order clicked
					mainContentGridFarm.classList.add('content-list-item-active')
					if(this.selectedFarm !== false && this.selectedFarm.data.uuid !== farm.uuid){//an order is already clicked - we need to remove it
						this.selectedFarm.div.classList.remove('content-list-item-active')
					}
					//set the new selected order
					this.selectedFarm = {}
					this.selectedFarm.data = farm//raw info
					this.selectedFarm.div = mainContentGridFarm//raw link div
					this._buildFarmContent()//load in the order info into main content area of grid
				}
			}

			changeTracker.getWarning('farms', farm.uuid, mainContentGridFarm)

			this._divs.list.append(mainContentGridFarm)
		})
	}

	/**
	 * Build main data section based on select farm
	 */
	_buildFarmContent(){
		this._resetContent()

		let farmName = document.createElement('div')
		farmName.classList.add('content-section')
		farmName.style.marginTop = '10px'
		farmName.style.gridColumn = '1/3'
		farmName.style.textAlign = 'center'
		farmName.innerHTML = 'Farm Name: '
		this._divs.content.append(farmName)

		let farmNameInput = document.createElement('input')
		farmNameInput.classList.add('content-input')
		farmNameInput.value = this.selectedFarm.data.name
		farmNameInput.onkeyup = () => {
			changeTracker.addToChanged('farms', this.selectedFarm.data.uuid, {'name': this.selectedFarm.data.name}, {'name': farmNameInput.value})
			this.selectedFarm.data.name = farmNameInput.value
			this._showContentAlert(this._updateListButton(this.selectedFarm, 'farms'))
		}
		farmName.append(farmNameInput)

		let farmCustomID = document.createElement('div')
		farmCustomID.classList.add('content-section')
		farmCustomID.innerHTML = 'Custom ID: '
		this._divs.content.append(farmCustomID)

		let farmCustomIDInput = document.createElement('input')
		farmCustomIDInput.classList.add('content-input')
		farmCustomIDInput.value = this.selectedFarm.data.customid
		farmCustomIDInput.onkeyup = () => {
			changeTracker.addToChanged('farms', this.selectedFarm.data.uuid, {'customid': this.selectedFarm.data.customid}, {'customid': farmCustomIDInput.value})
			this.selectedFarm.data.customid = farmCustomIDInput.value
			this._showContentAlert(this._updateListButton(this.selectedFarm, 'farms'))
		}
		farmCustomID.append(farmCustomIDInput)

		let farmOwner = document.createElement('div')
		farmOwner.classList.add('content-section')
		farmOwner.innerHTML = 'Owner: '
		this._divs.content.append(farmOwner)

		let farmOwnerInput = document.createElement('select')
		farmOwnerInput.classList.add('content-dropdown')
		farmOwner.append(farmOwnerInput)

		globalCache.getCacheList('owners').forEach((owner) => {
			let farmOwnerOption = document.createElement('option')
			farmOwnerOption.value = owner.uuid
			farmOwnerOption.innerHTML = owner.name
			farmOwnerInput.append(farmOwnerOption)
		})

		farmOwnerInput.value = this.selectedFarm.data.owner
		farmOwnerInput.onchange = () => {
			changeTracker.addToChanged('farms', this.selectedFarm.data.uuid, {'owner': this.selectedFarm.data.owner}, {'owner': farmOwnerInput.value})
			this.selectedFarm.data.owner = farmOwnerInput.value
			this._showContentAlert(this._updateListButton(this.selectedFarm, 'farms'))
		}

		let viewOwnerButton = document.createElement('div')
		viewOwnerButton.classList.add('content-button')
		viewOwnerButton.style.display = 'inline'
		viewOwnerButton.style.paddingTop = '0'
		viewOwnerButton.style.paddingBottom = '0'
		viewOwnerButton.innerHTML = 'View/Edit'
		viewOwnerButton.onclick = () => {
			if(this.selectedOwner == false){this.selectedOwner = {}}
			this.selectedOwner.data = _owners[farmOwnerInput.value]
			this._subnavButtons.owners.click()
		}
		farmOwner.append(viewOwnerButton)

		let addressHeader = document.createElement('div')
		addressHeader.classList.add('content-section')
		addressHeader.style.gridColumn = '1/3'
		addressHeader.style.background = '#000'
		addressHeader.style.marginTop = '10px'
		addressHeader.style.textAlign = 'center'
		addressHeader.innerHTML = 'Address: '
		this._divs.content.append(addressHeader)

		if(this.selectedFarm.data.addr == undefined){
			this.selectedFarm.data.addr = {
				addr1: "",
				addr2: "",
				city: "",
				state: "",
				zip: ""
			}
		}

		let farmAddr1 = document.createElement('div')
		farmAddr1.classList.add('content-section')
		farmAddr1.innerHTML = 'Address 1: '
		this._divs.content.append(farmAddr1)

		let farmAddr1Input = document.createElement('input')
		farmAddr1Input.classList.add('content-input')
		farmAddr1Input.value = this.selectedFarm.data.addr.addr1
		farmAddr1Input.onkeyup = () => {
			changeTracker.addToChanged('farms', this.selectedFarm.data.uuid, {'addr1': this.selectedFarm.data.addr.addr1}, {'addr1': farmAddr1Input.value})
			this.selectedFarm.data.addr.addr1 = farmAddr1Input.value
			this._showContentAlert(this._updateListButton(this.selectedFarm, 'farms'))
		}
		farmAddr1.append(farmAddr1Input)

		let farmAddr2 = document.createElement('div')
		farmAddr2.classList.add('content-section')
		farmAddr2.innerHTML = 'Address 2: '
		this._divs.content.append(farmAddr2)

		let farmAddr2Input = document.createElement('input')
		farmAddr2Input.classList.add('content-input')
		farmAddr2Input.value = this.selectedFarm.data.addr.addr2
		farmAddr2Input.onkeyup = () => {
			changeTracker.addToChanged('farms', this.selectedFarm.data.uuid, {'addr2': this.selectedFarm.data.addr.addr2}, {'addr2': farmAddr2Input.value})
			this.selectedFarm.data.addr.addr2 = farmAddr2Input.value
			this._showContentAlert(this._updateListButton(this.selectedFarm, 'farms'))
		}
		farmAddr2.append(farmAddr2Input)

		let farmCity = document.createElement('div')
		farmCity.classList.add('content-section')
		farmCity.innerHTML = 'City: '
		this._divs.content.append(farmCity)

		let farmCityInput = document.createElement('input')
		farmCityInput.classList.add('content-input')
		farmCityInput.value = this.selectedFarm.data.addr.city
		farmCityInput.onkeyup = () => {
			changeTracker.addToChanged('farms', this.selectedFarm.data.uuid, {'city': this.selectedFarm.data.addr.city}, {'city': farmCityInput.value})
			this.selectedFarm.data.addr.city = farmCityInput.value
			this._showContentAlert(this._updateListButton(this.selectedFarm, 'farms'))
		}
		farmCity.append(farmCityInput)

		let farmState = document.createElement('div')
		farmState.classList.add('content-section')
		farmState.innerHTML = 'State: '
		this._divs.content.append(farmState)

		let farmStateInput = document.createElement('input')
		farmStateInput.classList.add('content-input')
		farmStateInput.value = this.selectedFarm.data.addr.state
		farmStateInput.onkeyup = () => {
			changeTracker.addToChanged('farms', this.selectedFarm.data.uuid, {'state': this.selectedFarm.data.addr.state}, {'state': farmStateInput.value})
			this.selectedFarm.data.addr.state = farmStateInput.value
			this._showContentAlert(this._updateListButton(this.selectedFarm, 'farms'))
		}
		farmState.append(farmStateInput)

		let farmZip = document.createElement('div')
		farmZip.classList.add('content-section')
		farmZip.innerHTML = 'Zip: '
		this._divs.content.append(farmZip)

		let farmZipInput = document.createElement('input')
		farmZipInput.classList.add('content-input')
		farmZipInput.value = this.selectedFarm.data.addr.zip
		farmZipInput.onkeyup = () => {
			changeTracker.addToChanged('farms', this.selectedFarm.data.uuid, {'zip': this.selectedFarm.data.addr.zip}, {'zip': farmZipInput.value})
			this.selectedFarm.data.addr.zip = farmZipInput.value
			this._showContentAlert(this._updateListButton(this.selectedFarm, 'farms'))
		}
		farmZip.append(farmZipInput)

		let binsHeader = document.createElement('div')
		binsHeader.classList.add('content-section')
		binsHeader.style.gridColumn = '1/3'
		binsHeader.style.background = '#000'
		binsHeader.style.marginTop = '10px'
		binsHeader.style.textAlign = 'center'
		binsHeader.innerHTML = 'Bins: '
		this._divs.content.append(binsHeader)

		let farmBins = globalCache.getCacheList('bins').filter(bin => bin.farm == this.selectedFarm.data.uuid)
		farmBins.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
		farmBins.forEach((bin) => {
			let binDiv = document.createElement('div')
			binDiv.style.display = 'grid'
			binDiv.style.gridTemplateColumns = '1fr min-content'
			binDiv.classList.add('content-section')
			binDiv.style.gridColumn = '1/3'
			this._divs.content.append(binDiv)

			let binName = document.createElement('div')
			binName.style.marginTop = '8px'
			binName.innerHTML = bin.name
			binDiv.append(binName)

			let binButton = document.createElement('div')
			binButton.classList.add('content-button')
			binButton.innerHTML = 'View/Edit'
			binButton.onclick = () => {
				if(this.selectedBin == false){this.selectedBin = {}}
				this.selectedBin.data = bin
				this._subnavButtons.bins.click()
			}
			binDiv.append(binButton)
		})

		let addNewBinButton = document.createElement('div')
		addNewBinButton.classList.add('content-button')
		addNewBinButton.style.gridColumn = '1/3'
		addNewBinButton.innerHTML = 'Add New Bin'
		addNewBinButton.onclick = () => {
			this.generateNewBinOverlay(this.selectedFarm.data.uuid)
		}
		this._divs.content.append(addNewBinButton)

		//UPDATE
		let farmContentUpdate = document.createElement('div')
		farmContentUpdate.classList.add('content-button')
		farmContentUpdate.style.gridColumn = '1/3'
		farmContentUpdate.style.width = 'min-content'
		farmContentUpdate.style.justifySelf = 'center'
		farmContentUpdate.innerHTML = 'Update'
		farmContentUpdate.onclick = () => {
			let changes = changeTracker.getChanges('farms', this.selectedFarm.data.uuid)
			if(changes == false){return}

			Object.keys(changes).forEach((field) => {
				if(field == "addr1" || field == "addr2" || field == "city" || field == "state" || field == "zip"){
					if(changes.addr == undefined){changes.addr = this.selectedFarm.data.addr}
					changes.addr[field] = changes[field]
					delete changes[field]
				}
			})

			QReq.request(feedlokAPI.putFarmsFarmID(this.selectedFarm.data.uuid, changes)).then(resp => {
				changeTracker.clearChanges('farms', this.selectedFarm.data.uuid)
				this._populateFarmList()
				this._buildFarmContent()
			}).catch((e) => {
				errorHandler(e)
			})
		}
		this._divs.content.append(farmContentUpdate)

		//SENSITIVE BUTTONS
		let farmContentButtonsContainer = document.createElement('div')
		farmContentButtonsContainer.style.gridColumn = '1/3'
		farmContentButtonsContainer.style.width = 'min-content'
		farmContentButtonsContainer.style.justifySelf = 'center'
		this._divs.content.append(farmContentButtonsContainer)

		let farmContentDeleteButton = document.createElement('div')
		farmContentDeleteButton.classList.add('content-button')
		farmContentDeleteButton.style.width = 'min-content'
		farmContentDeleteButton.style.justifySelf = 'center'
		farmContentDeleteButton.innerHTML = 'Delete'
		farmContentDeleteButton.onclick = () => {
			let confirmation = confirm('Are you sure you would like to delete this farm?')
			if(confirmation == true){
				QReq.request(feedlokAPI.deleteFarmsFarmID(this.selectedFarm.data.uuid)).then(resp => {
					if(resp.status == 409){
						alert('This farm can not be deleted due to being in use!')
						return
					}
					globalCache.deleteCacheListItem('farms', _farms[this.selectedFarm.data.uuid])
					delete _farms[this.selectedFarm.data.uuid]
					this.selectedFarm = false
					this._populateFarmList()
					this._resetContent()
				}).catch((e) => {
					errorHandler(e)
				})
			}
		}
		farmContentButtonsContainer.append(farmContentDeleteButton)

		//ALERT
		let orderContentAlert = document.createElement('div')
		orderContentAlert.classList.add('content-alert')
		orderContentAlert.innerHTML = 'THIS FARM HAS UNSAVED DATA'
		this._divs.content.append(orderContentAlert)
		this._divs.alert = orderContentAlert
		if(changeTracker.contains('farms', this.selectedFarm.data.uuid)){ this._showContentAlert() }

		let orderContentAlertButtonContainer = document.createElement('div')
		orderContentAlertButtonContainer.style.fontSize = '1rem'
		orderContentAlertButtonContainer.style.textAlign = 'center'
		this._divs.alert.append(orderContentAlertButtonContainer)

		let orderContentAlertRevertButton = document.createElement('div')
		orderContentAlertRevertButton.classList.add('content-button')
		orderContentAlertRevertButton.style.fontSize = '1rem'
		orderContentAlertRevertButton.innerHTML = 'Revert Changes'
		orderContentAlertRevertButton.onclick = () => {
			this.selectedFarm.data = changeTracker.revertChanges('farms', this.selectedFarm.data.uuid, this.selectedFarm.data)
			
			Object.keys(this.selectedFarm.data).forEach((field) => {
				if(field == "addr1" || field == "addr2" || field == "city" || field == "state" || field == "zip"){
					this.selectedFarm.data.addr[field] = this.selectedFarm.data[field]
					delete this.selectedFarm.data[field]
				}
			})

			this._populateFarmList()
			this._buildFarmContent()
		}
		orderContentAlertButtonContainer.append(orderContentAlertRevertButton)
		//ALERT
	}

	/********************************************************************************BINS**********************************************************************************/
	generateNewBinOverlay(farm = null){
		overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
			let binTypes = ['Auger', 'Blower', 'Bunker']

			let div = document.createElement('div')
			div.classList.add('overlay-container')
			overlayDiv.append(div)

			let title = document.createElement('div')
			title.classList.add('overlay-title')
			title.innerHTML = 'New Bin'
			div.append(title)

			let formGrid = document.createElement('div')
			formGrid.style.display = 'grid'
			formGrid.style.gridTemplateColumns = '1fr 1fr'
			formGrid.style.fontSize = '1.5rem'
			formGrid.style.marginTop = '4px'
			formGrid.style.rowGap = '4px'
			div.append(formGrid)

			let binName = document.createElement('div')
			binName.innerHTML = 'Name:'
			formGrid.append(binName)

			let binNameInputContainer = document.createElement('div')
			formGrid.append(binNameInputContainer)

			let binNameInput = document.createElement('input')
			binNameInput.classList.add('content-input')
			binNameInputContainer.append(binNameInput)

			let binType = document.createElement('div')
			binType.innerHTML = 'Type:'
			formGrid.append(binType)

			let binTypeInputContainer = document.createElement('div')
			formGrid.append(binTypeInputContainer)

			let binTypeInput = document.createElement('select')
			binTypeInput.classList.add('content-dropdown')
			binTypeInput.style.fontSize = '1.5rem'
			binTypeInputContainer.append(binTypeInput)

			let binTypeBlankOption = document.createElement('option')
			binTypeBlankOption.value = ""
			binTypeBlankOption.innerHTML = ""
			binTypeInput.append(binTypeBlankOption)

			binTypes.forEach((type) => {
				let binTypeOption = document.createElement('option')
				binTypeOption.value = binTypes.indexOf(type)
				binTypeOption.innerHTML = type
				binTypeInput.append(binTypeOption)
			})

			let binFarm = document.createElement('div')
			binFarm.innerHTML = 'Farm:'
			formGrid.append(binFarm)

			let binFarmInputContainer = document.createElement('div')
			formGrid.append(binFarmInputContainer)

			let binFarmInput = document.createElement('select')
			binFarmInput.classList.add('content-dropdown')
			binFarmInput.style.fontSize = '1.5rem'
			binFarmInputContainer.append(binFarmInput)

			let binFarmBlankOption = document.createElement('option')
			binFarmBlankOption.value = ""
			binFarmBlankOption.innerHTML = ""
			binFarmInput.append(binFarmBlankOption)

			globalCache.getCacheList('farms').forEach((farm) => {
				let binFarmOption = document.createElement('option')
				binFarmOption.value = farm.uuid
				binFarmOption.innerHTML = farm.name
				binFarmInput.append(binFarmOption)
			})

			if(farm !== null){
				binFarmInput.value = farm
			}

			let binCustomID = document.createElement('div')
			binCustomID.innerHTML = 'Custom ID:'
			formGrid.append(binCustomID)

			let binCustomIDInputContainer = document.createElement('div')
			formGrid.append(binCustomIDInputContainer)

			let binCustomIDInput = document.createElement('input')
			binCustomIDInput.classList.add('content-input')
			binCustomIDInputContainer.append(binCustomIDInput)

			let buttonContainer = document.createElement('div')
			buttonContainer.style.gridColumn = '1/3'
			formGrid.append(buttonContainer)

			let submitButton = document.createElement('div')
			submitButton.classList.add('overlay-button')
			submitButton.innerHTML = 'Create'
			submitButton.onclick = () => {
				if(binNameInput.value.length == 0){
					alert('A name is required!')
					return
				}else if(binFarmInput.value.length == 0){
					alert('An owner is required!')
					return
				}else if(binTypeInput.value.length == 0){
					alert('A bin type is required!')
					return
				}


				let newBin = {
					'name': binNameInput.value,
					'customid': binCustomIDInput.value,
					'farm': binFarmInput.value,
					'binType': parseInt(binTypeInput.value)
				}

				if(parseInt(binTypeInput.value) !== 0){
					newBin.geofence = 5
				}

				QReq.request(feedlokAPI.postBins(newBin)).then(resp => {
					_bins[resp.uuid] = resp
					globalCache.setNewCacheListItem('bins', resp)
					if(farm == null){
						if(this.selectedBin == false){this.selectedBin = {}}
						this.selectedBin.data = _bins[resp.uuid]
						this._populateBinList()
						this._buildBinContent()
					}else{
						this._populateFarmList()
						this._buildFarmContent()
					}
					overlay.close()
				})
			}
			buttonContainer.append(submitButton)

			overlay.show()
		})
	}

	/**
	 * Generates the list of bins in the left menu of the main content section
	 */
	_populateBinList(){//create the left side list of MCOs
		DOMClearChildren(this._divs.list)

		let searchRow = document.createElement('div')
		searchRow.style.display = 'grid'
		searchRow.style.gridTemplateColumns = '1fr min-content'
		this._divs.list.append(searchRow)

		let listSearch = document.createElement('input')
		listSearch.classList.add('content-input')
		listSearch.style.width = 'calc(100% - 6px)'
		listSearch.style.marginBottom = '4px'
		listSearch.placeholder = 'Search...'
		searchRow.append(listSearch)

		let searchButton = document.createElement('div')
		searchButton.classList.add('content-button')
		searchButton.style.marginTop = '0'
		searchButton.style.marginRight = '0'
		searchButton.innerHTML = 'Search'
		searchButton.onclick = () => {
			let assetDivs = this._divs.list.querySelectorAll('div.content-list-item')//get all of the divs in "machineList" div which contains asset list

			for (var i = 0; i < assetDivs.length; i++) {//loop through all divs
				if(!assetDivs[i].innerText.toLocaleLowerCase().includes(listSearch.value.toLocaleLowerCase())){//see if innerHMTL include search value
					assetDivs[i].style.display = 'none'//hide since we were checking if includes = false
				}else{
					assetDivs[i].style.display = 'block'//this is the default for this particular element
				}
			}
		}
		searchRow.append(searchButton)

		globalCache.getCacheList('bins').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -(a.name.toLowerCase() === b.name.toLowerCase()) ? ((_farms[a.farm].name.toLowerCase() > _farms[b.farm].name.toLowerCase()) ? 1 : -1) : -1 )
		//globalCache.getCacheList('bins').sort((a, b) => (a.farm > b.farm) ? -1 : 1)//flip -1 and 1 so that bins are in order still

		//GENERATE LIST
		globalCache.getCacheList('bins').forEach((bin) => {
			let mainContentGridBin = document.createElement('div')
			mainContentGridBin.id = 'so-me-uuid-here'+bin.uuid
			mainContentGridBin.classList.add('content-list-item')
			if(this.selectedBin !== false){
				if(bin.uuid == this.selectedBin.data.uuid){ 
					mainContentGridBin.classList.add('content-list-item-active')
					this.selectedBin.div = mainContentGridBin
					this._buildBinContent()
				}
			}
			mainContentGridBin.innerHTML = bin.name+' ('+_farms[bin.farm].name+')'
			mainContentGridBin.onclick = () => {
				if (mainContentGridBin.classList.contains('content-list-item-active')) {//if they re-click the already selected order
					mainContentGridBin.classList.remove('content-list-item-active')
					if(this.selectedBin.data.uuid == bin.uuid){ //reverify if they re-click the already selected order
						this._resetContent()//clear main grid content area
						this.selectedBin = false//no order selected
					}else{ return }
				} else {//one new order clicked
					mainContentGridBin.classList.add('content-list-item-active')
					if(this.selectedBin !== false && this.selectedBin.data.uuid !== bin.uuid){//an order is already clicked - we need to remove it
						this.selectedBin.div.classList.remove('content-list-item-active')
					}
					//set the new selected order
					this.selectedBin = {}
					this.selectedBin.data = bin//raw info
					this.selectedBin.div = mainContentGridBin//raw link div
					this._buildBinContent()//load in the order info into main content area of grid
				}
			}

			changeTracker.getWarning('bins', bin.uuid, mainContentGridBin)

			this._divs.list.append(mainContentGridBin)
		})
	}

	/**
	 * Build main content section based on selected bin
	 */
	_buildBinContent(){
		this._resetContent()

		let binTypes = ['Auger', 'Blower', 'Bunker']

		let binName = document.createElement('div')
		binName.classList.add('content-section')
		binName.style.marginTop = '10px'
		binName.innerHTML = 'Bin Name: '
		this._divs.content.append(binName)

		let binNameInput = document.createElement('input')
		binNameInput.classList.add('content-input')
		binNameInput.value = this.selectedBin.data.name
		binNameInput.onkeyup = () => {
			changeTracker.addToChanged('bins', this.selectedBin.data.uuid, {'name': this.selectedBin.data.name}, {'name': binNameInput.value})
			this.selectedBin.data.name = binNameInput.value
			this._showContentAlert(this._updateListButton(this.selectedBin, 'bins'))
		}
		binName.append(binNameInput)

		let binFarm = document.createElement('div')
		binFarm.classList.add('content-section')
		binFarm.style.marginTop = '10px'
		binFarm.innerHTML = 'Farm: '+_farms[this.selectedBin.data.farm].name
		this._divs.content.append(binFarm)

		let viewFarmButton = document.createElement('div')
		viewFarmButton.classList.add('content-button')
		viewFarmButton.style.display = 'inline'
		viewFarmButton.style.paddingTop = '0'
		viewFarmButton.style.paddingBottom = '0'
		viewFarmButton.innerHTML = 'View/Edit'
		viewFarmButton.onclick = () => {
			if(this.selectedFarm == false){this.selectedFarm = {}}
			this.selectedFarm.data = _farms[this.selectedBin.data.farm]
			this._subnavButtons.farms.click()
		}
		binFarm.append(viewFarmButton)

		let binCustomID = document.createElement('div')
		binCustomID.classList.add('content-section')
		binCustomID.innerHTML = 'Custom ID: '
		this._divs.content.append(binCustomID)

		let binCustomIDInput = document.createElement('input')
		binCustomIDInput.classList.add('content-input')
		binCustomIDInput.value = this.selectedBin.data.customid
		binCustomIDInput.onkeyup = () => {
			changeTracker.addToChanged('bins', this.selectedBin.data.uuid, {'customid': this.selectedBin.data.customid}, {'customid': binCustomIDInput.value})
			this.selectedBin.data.customid = binCustomIDInput.value
			this._showContentAlert(this._updateListButton(this.selectedBin, 'bins'))
		}
		binCustomID.append(binCustomIDInput)

		let binType = document.createElement('div')
		binType.classList.add('content-section')
		binType.innerHTML = 'Type: '
		this._divs.content.append(binType)

		let binTypeInput = document.createElement('select')
		binTypeInput.classList.add('content-dropdown')
		binType.append(binTypeInput)

		binTypes.forEach((type) => {
			let binTypeOption = document.createElement('option')
			binTypeOption.value = binTypes.indexOf(type)
			binTypeOption.innerHTML = type
			binTypeInput.append(binTypeOption)
		})

		binTypeInput.value = this.selectedBin.data.binType
		binTypeInput.onchange = () => {
			changeTracker.addToChanged('bins', this.selectedBin.data.uuid, {'binType': this.selectedBin.data.binType}, {'binType': parseInt(binTypeInput.value)})
			this.selectedBin.data.binType = binTypeInput.value
			if(this.selectedBin.data.binType !== 0){
				changeTracker.addToChanged('bins', this.selectedBin.data.uuid, {'geofence': this.selectedBin.data.geofence}, {'geofence': 5})
				this.selectedBin.data.geofence = 5
			}
			this._showContentAlert(this._updateListButton(this.selectedBin, 'bins'))
		}

		//LOCATION DATA
		let locationHeader = document.createElement('div')
		locationHeader.classList.add('content-section')
		locationHeader.style.gridColumn = '1/3'
		locationHeader.style.background = '#000'
		locationHeader.style.marginTop = '10px'
		locationHeader.style.textAlign = 'center'
		locationHeader.innerHTML = 'Location Information: '
		this._divs.content.append(locationHeader)

		let binLatitude = document.createElement('div')
		binLatitude.classList.add('content-section')
		let latitudeValue = 'Untrained'
		if(this.selectedBin.data.trained == true){latitudeValue = this.selectedBin.data.latitude/10000000+'°'}
		binLatitude.innerHTML = 'Latitude: '+latitudeValue
		this._divs.content.append(binLatitude)

		let binLongitude = document.createElement('div')
		binLongitude.classList.add('content-section')
		let longitudeValue = 'Untrained'
		if(this.selectedBin.data.trained == true){longitudeValue = this.selectedBin.data.longitude/10000000+'°'}
		binLongitude.innerHTML = 'Longitude: '+longitudeValue
		this._divs.content.append(binLongitude)

		let binGeofence = document.createElement('div')
		binGeofence.classList.add('content-section')
		binGeofence.innerHTML = 'Geofence Radius (ft): '
		this._divs.content.append(binGeofence)

		let binGeofenceInput = document.createElement('input')
		binGeofenceInput.classList.add('content-input')
		binGeofenceInput.type = 'number'
		binGeofenceInput.min = '0'
		binGeofenceInput.style.width = '5rem'
		binGeofenceInput.value = this.selectedBin.data.geofence
		binGeofenceInput.onkeyup = () => {
			changeTracker.addToChanged('bins', this.selectedBin.data.uuid, {'geofence': this.selectedBin.data.geofence}, {'geofence': parseInt(binGeofenceInput.value)})
			this.selectedBin.data.geofence = parseInt(binGeofenceInput.value)
			this._showContentAlert(this._updateListButton(this.selectedBin, 'bins'))
		}
		binGeofenceInput.onchange = () => {binGeofenceInput.onkeyup()}
		binGeofence.append(binGeofenceInput)

		//UPDATE
		let binContentUpdate = document.createElement('div')
		binContentUpdate.classList.add('content-button')
		binContentUpdate.style.gridColumn = '1/3'
		binContentUpdate.style.width = 'min-content'
		binContentUpdate.style.justifySelf = 'center'
		binContentUpdate.innerHTML = 'Update'
		binContentUpdate.onclick = () => {
			let changes = changeTracker.getChanges('bins', this.selectedBin.data.uuid)
			if(changes == false){return}

			QReq.request(feedlokAPI.putBinsBinID(this.selectedBin.data.uuid, changes)).then(resp => {
				changeTracker.clearChanges('bins', this.selectedBin.data.uuid)
				this._populateBinList()
				this._buildBinContent()
			}).catch((e) => {
				errorHandler(e)
			})
		}
		this._divs.content.append(binContentUpdate)

		//SENSITIVE BUTTONS
		let binContentButtonsContainer = document.createElement('div')
		binContentButtonsContainer.style.display = 'grid'
		binContentButtonsContainer.style.gridTemplateColumns = 'min-content min-content'
		binContentButtonsContainer.style.gridColumn = '1/3'
		binContentButtonsContainer.style.width = 'min-content'
		binContentButtonsContainer.style.justifySelf = 'center'
		this._divs.content.append(binContentButtonsContainer)

		let binContentUntrainButton = document.createElement('div')
		binContentUntrainButton.classList.add('content-button')
		binContentUntrainButton.style.width = 'min-content'
		binContentUntrainButton.style.justifySelf = 'center'
		binContentUntrainButton.innerHTML = 'Untrain'
		binContentUntrainButton.onclick = () => {
			let confirmation = confirm('Are you sure you would like to untrain this bin?')
			if(confirmation == true){
				QReq.request(feedlokAPI.deleteBinsBinIDTraining(this.selectedBin.data.uuid)).then(resp => {
					_bins[this.selectedBin.data.uuid].trained = false
					this.selectedBin.data.trained = false
					this._buildBinContent()
				}).catch((e) => {
					console.log(e)
					errorHandler(e)
				})
			}
		}
		binContentButtonsContainer.append(binContentUntrainButton)

		let binContentDeleteButton = document.createElement('div')
		binContentDeleteButton.classList.add('content-button')
		binContentDeleteButton.style.width = 'min-content'
		binContentDeleteButton.style.justifySelf = 'center'
		binContentDeleteButton.innerHTML = 'Delete'
		binContentDeleteButton.onclick = () => {
			let confirmation = confirm('Are you sure you would like to delete this bin?')
			if(confirmation == true){
				QReq.request(feedlokAPI.deleteBinsBinID(this.selectedBin.data.uuid)).then(resp => {
					if(resp.status == 409){
						alert('This bin can not be deleted due to being in use!')
						return
					}
					globalCache.deleteCacheListItem('bins', _bins[this.selectedBin.data.uuid])
					delete _bins[this.selectedBin.data.uuid]
					this.selectedBin = false
					this._populateBinList()
					this._resetContent()
				}).catch((e) => {
					errorHandler(e)
				})
			}
		}
		binContentButtonsContainer.append(binContentDeleteButton)

		//ALERT
		let orderContentAlert = document.createElement('div')
		orderContentAlert.classList.add('content-alert')
		orderContentAlert.innerHTML = 'THIS BIN HAS UNSAVED DATA'
		this._divs.content.append(orderContentAlert)
		this._divs.alert = orderContentAlert
		if(changeTracker.contains('bins', this.selectedBin.data.uuid)){ this._showContentAlert() }

		let orderContentAlertButtonContainer = document.createElement('div')
		orderContentAlertButtonContainer.style.fontSize = '1rem'
		orderContentAlertButtonContainer.style.textAlign = 'center'
		this._divs.alert.append(orderContentAlertButtonContainer)

		let orderContentAlertRevertButton = document.createElement('div')
		orderContentAlertRevertButton.classList.add('content-button')
		orderContentAlertRevertButton.style.fontSize = '1rem'
		orderContentAlertRevertButton.innerHTML = 'Revert Changes'
		orderContentAlertRevertButton.onclick = () => {
			this.selectedBin.data = changeTracker.revertChanges('bins', this.selectedBin.data.uuid, this.selectedBin.data)
			this._populateBinList()
			this._buildBinContent()
		}
		orderContentAlertButtonContainer.append(orderContentAlertRevertButton)
		//ALERT
	}

	/********************************************************************************TRAILERS**********************************************************************************/
	generateNewTrailerOverlay(){
		overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
			let div = document.createElement('div')
			div.classList.add('overlay-container')
			overlayDiv.append(div)

			let title = document.createElement('div')
			title.classList.add('overlay-title')
			title.innerHTML = 'New Trailer'
			div.append(title)

			let formGrid = document.createElement('div')
			formGrid.style.display = 'grid'
			formGrid.style.gridTemplateColumns = '1fr 1fr'
			formGrid.style.fontSize = '1.5rem'
			formGrid.style.marginTop = '4px'
			formGrid.style.rowGap = '4px'
			div.append(formGrid)

			let trailerName = document.createElement('div')
			trailerName.innerHTML = 'Name:'
			formGrid.append(trailerName)

			let trailerNameInputContainer = document.createElement('div')
			formGrid.append(trailerNameInputContainer)

			let trailerNameInput = document.createElement('input')
			trailerNameInput.classList.add('content-input')
			trailerNameInputContainer.append(trailerNameInput)

			let trailerSerial = document.createElement('div')
			trailerSerial.innerHTML = 'Serial:'
			formGrid.append(trailerSerial)

			let trailerSerialInputContainer = document.createElement('div')
			formGrid.append(trailerSerialInputContainer)

			let trailerSerialInput = document.createElement('input')
			trailerSerialInput.classList.add('content-input')
			trailerSerialInputContainer.append(trailerSerialInput)

			let trailerGates = document.createElement('div')
			trailerGates.innerHTML = 'Gate Quantity:'
			formGrid.append(trailerGates)

			let trailerGatesInputContainer = document.createElement('div')
			formGrid.append(trailerGatesInputContainer)

			let trailerGatesInput = document.createElement('input')
			trailerGatesInput.type = 'number'
			trailerGatesInput.min = '0'
			trailerGatesInput.classList.add('content-input')
			trailerGatesInputContainer.append(trailerGatesInput)

			let trailerCustomID = document.createElement('div')
			trailerCustomID.innerHTML = 'Custom ID:'
			formGrid.append(trailerCustomID)

			let trailerCustomIDInputContainer = document.createElement('div')
			formGrid.append(trailerCustomIDInputContainer)

			let trailerCustomIDInput = document.createElement('input')
			trailerCustomIDInput.classList.add('content-input')
			trailerCustomIDInputContainer.append(trailerCustomIDInput)

			let buttonContainer = document.createElement('div')
			buttonContainer.style.gridColumn = '1/3'
			formGrid.append(buttonContainer)

			let submitButton = document.createElement('div')
			submitButton.classList.add('overlay-button')
			submitButton.innerHTML = 'Create'
			submitButton.onclick = () => {
				if(trailerNameInput.value.length == 0){
					alert('A name is required!')
					return
				}else if(trailerSerialInput.value.length == 0){
					alert('A serial number is required!')
					return
				}else if(trailerGatesInput.value.length == 0){
					alert('A gate quantity is required!')
					return
				}


				let newTrailer = {
					'name': trailerNameInput.value,
					'customid': trailerCustomIDInput.value,
					'serialNumber': trailerSerialInput.value,
					'numBins': parseInt(trailerGatesInput.value)
				}

				QReq.request(feedlokAPI.postTrailers(newTrailer)).then(resp => {
					_trailers[resp.uuid] = resp
					globalCache.setNewCacheListItem('trailers', resp)
					if(this.selectedTrailer == false){this.selectedTrailer = {}}
					this.selectedTrailer.data = _trailers[resp.uuid]
					this._populateTrailerList()
					this._buildTrailerContent()
					overlay.close()
				})
			}
			buttonContainer.append(submitButton)

			overlay.show()
		})
	}

	/**
	 * Generates the list of trailers in the left menu of the main content section
	 */
	_populateTrailerList(){//create the left side list of MCOs
		DOMClearChildren(this._divs.list)

		let searchRow = document.createElement('div')
		searchRow.style.display = 'grid'
		searchRow.style.gridTemplateColumns = '1fr min-content'
		this._divs.list.append(searchRow)

		let listSearch = document.createElement('input')
		listSearch.classList.add('content-input')
		listSearch.style.width = 'calc(100% - 6px)'
		listSearch.style.marginBottom = '4px'
		listSearch.placeholder = 'Search...'
		searchRow.append(listSearch)

		let searchButton = document.createElement('div')
		searchButton.classList.add('content-button')
		searchButton.style.marginTop = '0'
		searchButton.style.marginRight = '0'
		searchButton.innerHTML = 'Search'
		searchButton.onclick = () => {
			let assetDivs = this._divs.list.querySelectorAll('div.content-list-item')//get all of the divs in "machineList" div which contains asset list

			for (var i = 0; i < assetDivs.length; i++) {//loop through all divs
				if(!assetDivs[i].innerText.toLocaleLowerCase().includes(listSearch.value.toLocaleLowerCase())){//see if innerHMTL include search value
					assetDivs[i].style.display = 'none'//hide since we were checking if includes = false
				}else{
					assetDivs[i].style.display = 'block'//this is the default for this particular element
				}
			}
		}
		searchRow.append(searchButton)

		globalCache.getCacheList('trailers').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)

		//GENERATE LIST
		globalCache.getCacheList('trailers').forEach((trailer) => {
			let mainContentGridTrailer = document.createElement('div')
			mainContentGridTrailer.id = 'so-me-uuid-here'+trailer.uuid
			mainContentGridTrailer.classList.add('content-list-item')
			if(this.selectedTrailer !== false){
				if(trailer.uuid == this.selectedTrailer.data.uuid){ 
					mainContentGridTrailer.classList.add('content-list-item-active')
					this.selectedTrailer.div = mainContentGridTrailer
					this._buildTrailerContent()
				}
			}
			mainContentGridTrailer.innerHTML = trailer.name
			mainContentGridTrailer.onclick = () => {
				if (mainContentGridTrailer.classList.contains('content-list-item-active')) {//if they re-click the already selected order
					mainContentGridTrailer.classList.remove('content-list-item-active')
					if(this.selectedTrailer.data.uuid == trailer.uuid){ //reverify if they re-click the already selected order
						this._resetContent()//clear main grid content area
						this.selectedTrailer = false//no order selected
					}else{ return }
				} else {//one new order clicked
					mainContentGridTrailer.classList.add('content-list-item-active')
					if(this.selectedTrailer !== false && this.selectedTrailer.data.uuid !== trailer.uuid){//an order is already clicked - we need to remove it
						this.selectedTrailer.div.classList.remove('content-list-item-active')
					}
					//set the new selected order
					this.selectedTrailer = {}
					this.selectedTrailer.data = trailer//raw info
					this.selectedTrailer.div = mainContentGridTrailer//raw link div
					this._buildTrailerContent()//load in the order info into main content area of grid
				}
			}

			changeTracker.getWarning('trailers', trailer.uuid, mainContentGridTrailer)

			this._divs.list.append(mainContentGridTrailer)
		})
	}

	/**
	 * Generate main content section based on selected trailer
	 */
	_buildTrailerContent(){
		this._resetContent()

		this.selectedTrailer.data = _trailers[this.selectedTrailer.data.uuid]

		let trailerName = document.createElement('div')
		trailerName.classList.add('content-section')
		trailerName.style.gridColumn = '1/3'
		trailerName.style.textAlign = 'center'
		trailerName.style.marginTop = '10px'
		trailerName.innerHTML = 'Trailer Name: '
		this._divs.content.append(trailerName)

		let trailerNameInput = document.createElement('input')
		trailerNameInput.classList.add('content-input')
		trailerNameInput.value = this.selectedTrailer.data.name
		trailerNameInput.onkeyup = () => {
			changeTracker.addToChanged('trailers', this.selectedTrailer.data.uuid, {'name': this.selectedTrailer.data.name}, {'name': trailerNameInput.value})
			this.selectedTrailer.data.name = trailerNameInput.value
			this._showContentAlert(this._updateListButton(this.selectedTrailer, 'trailers'))
		}
		trailerName.append(trailerNameInput)

		let trailerSerial = document.createElement('div')
		trailerSerial.classList.add('content-section')
		trailerSerial.innerHTML = 'Serial: '
		this._divs.content.append(trailerSerial)

		let trailerSerialInput = document.createElement('input')
		trailerSerialInput.classList.add('content-input')
		trailerSerialInput.value = this.selectedTrailer.data.serialNumber
		trailerSerialInput.onkeyup = () => {
			changeTracker.addToChanged('trailers', this.selectedTrailer.data.uuid, {'serialNumber': this.selectedTrailer.data.serialNumber}, {'serialNumber': trailerSerialInput.value})
			this.selectedTrailer.data.serialNumber = trailerSerialInput.value
			this._showContentAlert(this._updateListButton(this.selectedTrailer, 'trailers'))
		}
		trailerSerial.append(trailerSerialInput)
		
		let trailerCustomID = document.createElement('div')
		trailerCustomID.classList.add('content-section')
		trailerCustomID.innerHTML = 'Custom ID: '
		this._divs.content.append(trailerCustomID)

		let trailerCustomIDInput = document.createElement('input')
		trailerCustomIDInput.classList.add('content-input')
		trailerCustomIDInput.value = this.selectedTrailer.data.customid
		trailerCustomIDInput.onkeyup = () => {
			changeTracker.addToChanged('trailers', this.selectedTrailer.data.uuid, {'customid': this.selectedTrailer.data.customid}, {'customid': trailerCustomIDInput.value})
			this.selectedTrailer.data.serialNumber = trailerCustomIDInput.value
			this._showContentAlert(this._updateListButton(this.selectedTrailer, 'trailers'))
		}
		trailerCustomID.append(trailerCustomIDInput)

		let trailerBins = document.createElement('div')
		trailerBins.classList.add('content-section')
		trailerBins.innerHTML = 'Gate qty: '
		this._divs.content.append(trailerBins)

		let trailerBinsInput = document.createElement('input')
		trailerBinsInput.classList.add('content-input')
		trailerBinsInput.type = 'number'
		trailerBinsInput.min = '0'
		trailerBinsInput.value = this.selectedTrailer.data.numBins
		trailerBinsInput.onkeyup = () => {
			changeTracker.addToChanged('trailers', this.selectedTrailer.data.uuid, {'numBins': this.selectedTrailer.data.numBins}, {'numBins': parseInt(trailerBinsInput.value)})
			this.selectedTrailer.data.numBins = trailerBinsInput.value
			this._showContentAlert(this._updateListButton(this.selectedTrailer, 'trailers'))
		}
		trailerBinsInput.onchange = () => {trailerBinsInput.onkeyup()}
		trailerBins.append(trailerBinsInput)

		if(typeof this.selectedTrailer.data.order == "string" && this.selectedTrailer.data.order.length > 0 && _fdos2[this.selectedTrailer.data.order] !== undefined){
			let trailerOverride = document.createElement('div')
			trailerOverride.classList.add('content-section')
			while(_fdos2[this.selectedTrailer.data.order].getOverride().toString().length < 4){_fdos2[this.selectedTrailer.data.order].setOverride('0'+_fdos2[this.selectedTrailer.data.order].getOverride().toString())}
			trailerOverride.innerHTML = 'Override: '+_fdos2[this.selectedTrailer.data.order].getOverride()
			this._divs.content.append(trailerOverride)
		}

		//UPDATE
		let trailerContentUpdate = document.createElement('div')
		trailerContentUpdate.classList.add('content-button')
		trailerContentUpdate.style.gridColumn = '1/3'
		trailerContentUpdate.style.width = 'min-content'
		trailerContentUpdate.style.justifySelf = 'center'
		trailerContentUpdate.innerHTML = 'Update'
		trailerContentUpdate.onclick = () => {
			let changes = changeTracker.getChanges('trailers', this.selectedTrailer.data.uuid)
			if(changes == false){return}

			QReq.request(feedlokAPI.putTrailersTrailerID(this.selectedTrailer.data.uuid, changes)).then(resp => {
				changeTracker.clearChanges('trailers', this.selectedTrailer.data.uuid)
				this._populateTrailerList()
				this._buildTrailerContent()
			}).catch((e) => {
				errorHandler(e)
			})
		}
		this._divs.content.append(trailerContentUpdate)

		//SENSITIVE BUTTONS
		let trailerContentButtonsContainer = document.createElement('div')
		trailerContentButtonsContainer.style.gridColumn = '1/3'
		trailerContentButtonsContainer.style.width = 'min-content'
		trailerContentButtonsContainer.style.justifySelf = 'center'
		this._divs.content.append(trailerContentButtonsContainer)

		let trailerContentDeleteButton = document.createElement('div')
		trailerContentDeleteButton.classList.add('content-button')
		trailerContentDeleteButton.style.width = 'min-content'
		trailerContentDeleteButton.style.justifySelf = 'center'
		trailerContentDeleteButton.innerHTML = 'Delete'
		trailerContentDeleteButton.onclick = () => {
			let confirmation = confirm('Are you sure you would like to delete this trailer?')
			if(confirmation == true){
				QReq.request(feedlokAPI.deleteTrailersTrailerID(this.selectedTrailer.data.uuid)).then(resp => {
					if(resp.status == 409){
						alert('This trailer can not be deleted due to being in use!')
						return
					}
					globalCache.deleteCacheListItem('trailers', _trailers[this.selectedTrailer.data.uuid])
					delete _trailers[this.selectedTrailer.data.uuid]
					this.selectedTrailer = false
					this._populateTrailerList()
					this._resetContent()
				}).catch((e) => {
					errorHandler(e)
				})
			}
		}
		trailerContentButtonsContainer.append(trailerContentDeleteButton)

		//ALERT
		let orderContentAlert = document.createElement('div')
		orderContentAlert.classList.add('content-alert')
		orderContentAlert.innerHTML = 'THIS TRAILER HAS UNSAVED DATA'
		this._divs.content.append(orderContentAlert)
		this._divs.alert = orderContentAlert
		if(changeTracker.contains('trailers', this.selectedTrailer.data.uuid)){ this._showContentAlert() }

		let orderContentAlertButtonContainer = document.createElement('div')
		orderContentAlertButtonContainer.style.fontSize = '1rem'
		orderContentAlertButtonContainer.style.textAlign = 'center'
		this._divs.alert.append(orderContentAlertButtonContainer)

		let orderContentAlertRevertButton = document.createElement('div')
		orderContentAlertRevertButton.classList.add('content-button')
		orderContentAlertRevertButton.style.fontSize = '1rem'
		orderContentAlertRevertButton.innerHTML = 'Revert Changes'
		orderContentAlertRevertButton.onclick = () => {
			this.selectedTrailer.data = changeTracker.revertChanges('trailers', this.selectedTrailer.data.uuid, this.selectedTrailer.data)
			this._populateTrailerList()
			this._buildTrailerContent()
		}
		orderContentAlertButtonContainer.append(orderContentAlertRevertButton)
		//ALERT
	}


	/**
	 * Sets background and styling for the main content section and assigned the global variable
	 * @param {element} content HTML Element where the main data for the page will be created
	 */
	generateContent(content) {
		content.classList.add('dashboard', 'content-page')

		let mainContentSection = document.createElement('div')
		mainContentSection.classList.add('card', 'dashboard-content')
		content.append(mainContentSection)

		this.mainContent = mainContentSection
	}

	start(page) {
		if(this._subnavButtons.trailers.classList.contains('nav-asset-list-item-active')){this._subnavButtons.trailers.click()}//regenerate in case of changes due to sending to trailer
		page.getButton().div.classList.add('navbar-items-item-active')
	}

	stop(page) {
		page.getButton().div.classList.remove('navbar-items-item-active')
	}

	initCB(page) {
	}
}
