'use strict'

export class Cache{
    constructor(){
        this._cacheList = {}
    }

    resetCache(){
        this._cacheList = {}
    }

    /**
     * Set data into a new list
     * @param {string} listName Unique name for the dataset
     * @param {object} list list of the items to store
     */
    setNewCacheList(listName, list = []){
        this._cacheList[listName] = [...list]
    }

    /**
     * Fetch all or a portion of a cached list
     * @param {string} listName List name provided when data was cached using setNewCacheList
     * @param {object} parameters Object of the filter parameters in the format of field => value
     * @returns list of data from cache matching the parameters
     * @example 
     * getCacheList('assets') would return ALL of the items in the 'assets' list
     * getCahceList('assets', {uuid: 1}) would return the item in the 'assets' list that has the UUID of 1
     */
    getCacheList(listName, parameters = {}){
        if(Object.keys(parameters).length == 0){
            return this._cacheList[listName]
        }

        let returnData = this._cacheList[listName]

        Object.keys(parameters).forEach((field) => {
            returnData = returnData.filter(item => item[field] == parameters[field])
        })

        return returnData
    }

    /**
     * Returns a single item from a cache list based on parameters
     * @param {string} listName Name provided in setNewCacheList
     * @param {object} parameters Description of data to update in the format of field => value
     * @returns object of single entry into cache
     */
    getCacheListItem(listName, parameters = {}){
        let returnItem = this._cacheList[listName]

        Object.keys(parameters).forEach((field) => {
            returnItem = returnItem.filter(item => item[field] == parameters[field])
        })

        return returnItem[0]
    }

    /**
     * Update cache - alternative to inline updating - USE AFTER QREQ TO MAKE SURE THE DATA UPDATES SUCCESSFULLY
     * @param {string} listName Name provided in setNewCacheList
     * @param {object} parameters Description of data to update in the format of field => value
     * @param {object} newData Data to update in the form of field => value
     */
    setCacheListItem(listName, parameters = {}, newData = {}){
        let originalObjects = this.getCacheList(listName, parameters)

        originalObjects.forEach((object) => {
            let originalOffset = this._cacheList[listName].indexOf(object)
            if(originalOffset == -1){ return }

            Object.keys(newData).forEach((field) => {
                object[field] = newData[field]
            })

            this._cacheList[listName][originalOffset] = object
        })
    }

    /**
     * Sets new object into a cache list
     * @param {string} listName Name provided in setNewCacheList
     * @param {object} newObject Object to be appended to end of cache
     */
    setNewCacheListItem(listName, newObject = {}){
        this._cacheList[listName].push(newObject)
    }

    /**
     * Remove a particular item from the cache
     * @param {string} listName Name provided in setNewCacheList
     * @param {object} item Object to remove from the cache list
     */
    deleteCacheListItem(listName, item){
        this._cacheList[listName].splice(this._cacheList[listName].indexOf(item), 1)
    }

    deleteCacheListItemByUUID(listName, uuid){
        let itemToDelete = this._cacheList[listName]
        itemToDelete = itemToDelete.filter(item => item["uuid"] == uuid)

        this._cacheList[listName].splice(this._cacheList[listName].indexOf(itemToDelete[0]), 1)
    }

    /**
     * Merges a list of objects with the current list
     * @param {string} listName Name provided in setNewCacheList
     * @param {list} mergeList List to be compared to cache to add or update
     * @param {string} definingField String representing to key to be compared to the current cache list
     * @param {object} callbacks updates, added, and finished callbacks
     */
    mergeCacheListItems(listName, mergeList = [], definingField = 'uuid', callbacks = {
        updated: () => {},
        added: () => {},
        finished: () => {}
    }){
        mergeList.forEach((newItem) => {
            let addNewItem = true
            this._cacheList[listName].forEach((cachedItem, offset) => {
                if(cachedItem[definingField] == newItem[definingField] && newItem[definingField] !== undefined){
                    if(this._cacheList[listName][offset] !== newItem){//if we have changes
                        this._cacheList[listName][offset] = newItem//replace old and we will clear changes
                    }

                    if(typeof callbacks.updated == 'function'){callbacks.updated(newItem[definingField])}

                    addNewItem = false
                }
            })

            if(addNewItem){
                this._cacheList[listName].push(newItem)
                if(typeof callbacks.added == 'function'){callbacks.added()}
            }
        })

        if(typeof callbacks.finished == 'function'){callbacks.finished()}
    }
}