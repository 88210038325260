'use strict';
import {Footer, OverlayHandler, QReq, Navigation, LoadingOverlay, Page} from '../ms/ms.js';
import {MCO} from './mco.js';
import {FDO} from './fdo.js';
import {OrdersPage} from './orders.js';
import {AssetsPage} from './assets';
import {HistoryPage} from './history';
import {TrainingPage} from './training';
import {MillPage} from './mill';
import {SyncPage} from './sync';

import {Feedlok} from '../apis/feedlok';
import {Cache} from '../libraries/cache.js';
import {ChangeTracker} from '../libraries/changeTracker.js';
/**
 * Data that will appear in the footer of the page
 */
let footer = new Footer({
	vers: 'v2.0.18',
	app: 'FeedLok',
	text: 'MobileStar &copy; '+(new Date()).getFullYear()
})
/**
 * Define the different elements that correspond with the parts of the overlay
 */
export let overlay = new OverlayHandler({
	modal: document.getElementById('overlayModal'),
	close: document.getElementById('overlayClose'),
	content: document.getElementById('overlayContent')
})

function alertOverlay(message, callback = ()=>{}){
	overlay.getOverlay().then((overlayDiv) => {//generate confirmation/cancel overlay
		let div = document.createElement('div')
		div.classList.add('overlay-container')
		overlayDiv.append(div)

		let title = document.createElement('div')
		title.classList.add('overlay-title')
		title.innerHTML = 'Alert'
		div.append(title)

		let alertMessage = document.createElement('div')
		alertMessage.style.marginTop = '4px'
		alertMessage.innerHTML = message
		div.append(alertMessage)

		let buttonContainer = document.createElement('div')
		div.append(buttonContainer)

		let okayButton = document.createElement('div')
		okayButton.classList.add('overlay-button')
		okayButton.innerHTML = 'Okay'
		okayButton.onclick = () => {
			overlay.close()
			callback()
		}
		buttonContainer.append(okayButton)

		overlay.show()
	})
}

/**
 * Array of object containing information about the pages in the application
 */
let pages

/**
 * Initialized the left side menus
 */
let mainNav = new Navigation(document.getElementById('navbar-items'),
	document.getElementById('content'),
	{
		subnav: document.getElementById('navbar-subnav')
	}
)

export let millTimezone = 'US/Central'

let user
export let feedlokAPI = new Feedlok()
export let globalCache = new Cache()
export let changeTracker = new ChangeTracker()

/**
 * Object with a key representing the UUID of an MCO and a value referencing the cached (globalCache) object related to it
 */
let _mcos = {}
export let _mcos2 = {}
/**
 * Object with a key representing the UUID of an FDO and a value referencing the cached (globalCache) object related to it
 */
let _fdos = {}
export let _fdos2 = {}
/**
 * Object with a key representing the UUID of an OWNER and a value referencing the cached (globalCache) object related to it
 */
export let _owners = {}
/**
 * Object with a key representing the UUID of a FARM and a value referencing the cached (globalCache) object related to it
 */
export let _farms = {}
/**
 * Object with a key representing the UUID of a TRAILER and a value referencing the cached (globalCache) object related to it
 */
export let _trailers = {}
/**
 * Object with a key representing the UUID of a BIN and a value referencing the cached (globalCache) object related to it
 */
export  let _bins = {}
export let _training = {}

if(localStorage.getItem('user') == undefined){
	overlay.getOverlay().then((overlayDiv) => {
		let div = document.createElement('div')
		div.classList.add('overlay-content', 'control-panel-set-point')
		overlayDiv.append(div)

		let divTitle = document.createElement('div')
		divTitle.classList.add('noselect', 'nowrap')
		divTitle.style.fontSize = '2rem'
		divTitle.innerHTML = 'Login:'
		div.append(divTitle)

		let loginMessage = document.createElement('div')
		div.append(loginMessage)

		let loginGrid = document.createElement('div')
		loginGrid.classList.add('login-grid')
		div.append(loginGrid)

		let usernameLabel = document.createElement('div')
		usernameLabel.style.textAlign = 'left'
		usernameLabel.innerHTML = 'Username:'
		loginGrid.append(usernameLabel)

		let usernameInput = document.createElement('input')
		usernameInput.type = 'text'
		usernameInput.addEventListener("keyup", function(event) {
			let keyCode = event.code || event.key
			if (keyCode == 'Enter') {
				submit.click()
			}
		})
		loginGrid.append(usernameInput)

		let passwordLabel = document.createElement('div')
		passwordLabel.style.textAlign = 'left'
		passwordLabel.innerHTML = 'Password:'
		loginGrid.append(passwordLabel)

		let passwordInput = document.createElement('input')
		passwordInput.type = 'password'
		passwordInput.addEventListener("keyup", function(event) {
			let keyCode = event.code || event.key
			if (keyCode == 'Enter') {
				submit.click()
			}
		})
		loginGrid.append(passwordInput)

		let submit = document.createElement('input')
		submit.style.gridColumn = '1/3'
		submit.type = 'submit'
		submit.innerHTML = 'Submit'
		loginGrid.append(submit)

		submit.onclick = () => {
			QReq.request(feedlokAPI.postLogin(usernameInput.value, passwordInput.value)).then(resp => {
				overlay.close()

				localStorage.setItem('user', JSON.stringify(resp))
				user = resp
				buildCache()
			}).catch(() => {
				loginMessage.innerHTML = '<font color="red">Invalid login!</font>'
			})
		}

		overlay.show()
		overlay._overlay.close.style.display = 'none'//hide close button since we cant escape the login muhahahahahhah
		overlay._overlay.modal.style.background = '#000'//hide close button since we cant escape the login muhahahahahhah
	})
}else{
	QReq.request(feedlokAPI.postUsersValidate()).then(resp => {
		if(resp.ok == false){//validation failed
			localStorage.clear()
			window.location.replace('/')
			return
		}

		user = JSON.parse(localStorage.getItem('user'))
		buildCache()
	}).catch(() => {
		localStorage.clear()
		window.location.replace('/')
	})
}

/** For looping order purposes */
let buildCacheList = ['mcos', 'fdos', 'owners', 'trailers', 'farms', 'bins', 'training']

/**
 * Load in the cache of a certain type - mcos, fdos, farms, owners, bins, trailers, etc
 * @param {string} type Cache type we are loading
 * @param {bool} sync Whether we are reloading and existing cash or not
 */
export function buildCache(type = 'mcos', sync = false){
	LoadingOverlay.on()
	LoadingOverlay.setText('Loading '+type)

	if(buildCacheList.indexOf(type) == 0){ 
		globalCache.resetCache() 
		_mcos = {}
		_fdos = {}
		_owners = {}
		_trailers = {}
		_farms = {}
		_bins = {}
	}

	requestData(type, sync)
}

let loadChunks = 100

function requestData(type, sync, start = 0){
	LoadingOverlay.setText('Loading '+type+': #'+start)

	let requestUrl
	switch(type){
		case 'mcos':
			requestUrl = feedlokAPI.getMCOrders('?status=pending&status=locked&start='+start+'&stop='+(start+loadChunks))
			break
		case 'fdos':
			requestUrl = feedlokAPI.getFDOrders('?status=pending&status=locked&start='+start+'&stop='+(start+loadChunks))
			break
		case 'owners':
			requestUrl = feedlokAPI.getOwners('?start='+start+'&stop='+(start+loadChunks))
			break
		case 'bins':
			requestUrl = feedlokAPI.getBins('?start='+start+'&stop='+(start+loadChunks))
			break
		case 'trailers':
			requestUrl = feedlokAPI.getTrailers('?start='+start+'&stop='+(start+loadChunks))
			break
		case 'farms':
			requestUrl = feedlokAPI.getFarms('?start='+start+'&stop='+(start+loadChunks))
			break
		case 'training':
			requestUrl = feedlokAPI.getTraining('?status=pending&status=locked&start='+start+'&stop='+(start+loadChunks))
			break
	}

	QReq.request(requestUrl).then(resp => {
		let data
		switch(type){
			case 'mcos':
				data = resp.orders
				break
			case 'fdos':
				data = resp.orders
				break
			case 'owners':
				data = resp.owners
				break
			case 'bins':
				data = resp.bins
				break
			case 'trailers':
				data = resp.trailers
				break
			case 'farms':
				data = resp.farms
				break
			case 'training':
				data = resp.trainings
				break
		}

		if(globalCache.getCacheList(type) == undefined){
			globalCache.setNewCacheList(type, data)
		}else{
			globalCache.mergeCacheListItems(type, data)
		}

		if((resp.stop - resp.start) == loadChunks){//if we hit the max AND start is lower than stop (0 means we dont have any) AND data size is small than chucnks
			requestData(type, sync, resp.stop)
			return
		}

		if(buildCacheList.indexOf(type) == buildCacheList.length-1){//if its the last in the list
			LoadingOverlay.setText('Building Cache...')

			//build data structures
			buildDataMaps()
			LoadingOverlay.off()
			LoadingOverlay.setText('')
			//build out UI
			if(sync == false){
				init()
			}
			return
		}

		buildCache(buildCacheList[buildCacheList.indexOf(type)+1], sync)
	}).catch((resp) => {
		console.log(resp)
		alert('FATAL ERROR')
	})
}

/**
 * Requests MCO data from server, stores it in cache, and populates additional fields based off other information to make references easier
 */
function buildMCOMap(){//puts the MCOs in an object of uuid => cache object
	//enclose below in QREQ
	//_mcos = {}
	_mcos2 = {}
	globalCache.getCacheList('mcos').forEach((mcoObject) => {
		//_mcos[mcoObject.uuid] = mcoObject
		_mcos2[mcoObject.uuid] = new MCO(mcoObject)

		//_mcos[mcoObject.uuid].farms = []//also adds to cache since it uses a reference
		_mcos2[mcoObject.uuid].setFarms([])

		if(mcoObject.bins != undefined){
			mcoObject.bins.forEach((binUUID) => {
				if(_mcos2[mcoObject.uuid].checkFarmIncludes(_bins[binUUID].farm) == false){//check if we already have farm in list
					//_mcos[mcoObject.uuid].farms.push(_bins[binUUID].farm)//set farms to make references to this easier later (prevents a loop)
					_mcos2[mcoObject.uuid].addFarm(_bins[binUUID].farm)
				}
			})
		}
	})
}

/**
 * Requests FDO data from server, stores it in cache, and populates additional fields based off other information to make references easier
 */
function buildFDOMap(){//puts the MCOs in an object of uuid => cache object
	//_fdos = {}
	_fdos2 = {}
	globalCache.getCacheList('fdos').forEach((fdoObject) => {
		//_fdos[fdoObject.uuid] = fdoObject
		_fdos2[fdoObject.uuid] = new FDO(fdoObject)

		let mcoList = _fdos2[fdoObject.uuid].getMCOs()

		if(mcoList != undefined){
			mcoList.forEach((mcoUUID) => {
				if(_mcos2[mcoUUID] == undefined){//if mco doesnt exit remove it
					fdoObject.mcos.splice(fdoObject.mcos.indexOf(mcoUUID), 1)
					_fdos2[fdoObject.uuid].removeMCO(mcoUUID)
					return
				}

				if(_fdos2[fdoObject.uuid].getEarliestMCODate() == undefined || _fdos2[fdoObject.uuid].getEarliestMCODate() > _mcos2[mcoUUID].getDate()){
					//_fdos[fdoObject.uuid].earliestMCODate = _mcos[mcoUUID].date
					_fdos2[fdoObject.uuid].setEarliestMCODate(_mcos2[mcoUUID].getDate())
				}

				//_mcos[mcoUUID].assignedTo = fdoObject.uuid//set assignedTo to make references to this easier later (prevents a loop)
				_mcos2[mcoUUID].setAssignedTo(fdoObject.uuid)
				//globalCache.setCacheListItem('mcos', {uuid: mcoUUID}, {assignedTo: fdoObject.uuid})
			})
		}

		//if(_fdos[fdoObject.uuid].earliestMCODate == undefined){_fdos[fdoObject.uuid].earliestMCODate = 0}
		if(_fdos2[fdoObject.uuid].getEarliestMCODate() == undefined){_fdos2[fdoObject.uuid].setEarliestMCODate(0)}
	})
}

/**
 * Requests OWNER data from server and stores it in cache
 */
function buildOwnerMap(){//puts the MCOs in an object of uuid => cache object
	_owners = {}
	globalCache.getCacheList('owners').forEach((ownerObject) => {
		_owners[ownerObject.uuid] = ownerObject
	})
}

/**
 * Requests FARM data from server and stores it in cache
 */
function buildFarmsMap(){//puts the MCOs in an object of uuid => cache object
	_farms = {}
	globalCache.getCacheList('farms').forEach((farmObject) => {
		_farms[farmObject.uuid] = farmObject
	})
}

/**
 * Requests TRAILER data from server and stores it in cache
 */
function buildTrailerMap(){//puts the MCOs in an object of uuid => cache object
	_trailers = {}
	globalCache.getCacheList('trailers').forEach((trailerObject) => {
		_trailers[trailerObject.uuid] = trailerObject
	})
}

/**
 * Requests BIN data from server and stores it in cache
 */
function buildBinMap(){//puts the MCOs in an object of uuid => object
	_bins = {}
	globalCache.getCacheList('bins').forEach((binObject) => {
		_bins[binObject.uuid] = binObject
	})
}

function buildTrainingMap(){//puts the MCOs in an object of uuid => object
	_training = {}
	globalCache.getCacheList('training').forEach((trainingObject) => {
		_training[trainingObject.uuid] = trainingObject
	})
}

/**
 * Request all information we will need for the UI
 */
export function buildDataMaps(){//these functions are auto-tied to cache due to references so it auto backed up :)
	buildBinMap()//needs to be before bins so we can build dynamic field in MCOs
	buildFarmsMap()

	//verify every bin has a valid farm
	globalCache.getCacheList('bins').forEach((bin) => {
		if(_farms[bin.farm] == undefined){
			globalCache.deleteCacheListItem('bins', bin)
			delete _bins[bin.uuid]
		}
	})

	buildMCOMap()//has to be before FDOs so that we can build dynamic assignedTo variable
	buildFDOMap()

	buildOwnerMap()
	buildTrailerMap()

	buildTrainingMap()
}

/**
 * Build out the application UI - This need to be called AFTER we populate cache
 */
function init() {
	QReq.request(feedlokAPI.getMill()).then(resp => {
		if(resp.timezone !== undefined && resp.timezone !== null){
			millTimezone = resp.timezone
		}
	})
	
	// Add pages and load first
	pages = [
		{ref: "orders", page: new Page(new OrdersPage())},
		{ref: "history", page: new Page(new HistoryPage())},
		{ref: "training", page: new Page(new TrainingPage())},
		{ref: "assets", page: new Page(new AssetsPage())},
		{ref: "mill", page: new Page(new MillPage())},
		{ref: "sync", page: new Page(new SyncPage())}
	]

	for (let i = 0; i < pages.length; i++) {
		mainNav.addPage(pages[i].ref, pages[i].page)
	}
	
	mainNav.loadPage(pages[0].ref)

	LoadingOverlay.off()
}

function launchAppDrawer() {
	apps.launchAppDrawer()
}

function toggleSubnav() {
	let subnav = document.getElementById('navbar-subnav')
	if (subnav.style.display != 'block') {
		subnav.style.display = 'block'
	} else {
		subnav.style.display = 'none'
	}
}

export function resizeAnomalies() {
	let bodySize = document.body.getBoundingClientRect()
	if (bodySize.width <= 768) {
		document.getElementById('navbar-subnav').style.display = 'none'
	} else {
		document.getElementById('navbar-subnav').style.display = 'grid'
	}
}

function sortHTMLCollection(collection) {
	return Array.prototype.slice.call(collection).sort((a,b) => {
		return a.innerHTML.toLowerCase().localeCompare(b.innerHTML.toLowerCase())
	})
}

export function errorHandler(e){
	let urlSplit = e.url.split("/")
	urlSplit.splice(0, urlSplit.indexOf('api'))
	let builtUrl = '/'
	urlSplit.forEach(urlSegment => {
		if(urlSegment.includes('-')){//uuids have slashes :) Nothing esle should :)
			urlSegment = '{uuid}'
		}
		builtUrl += urlSegment+"/"
	})

	let message = "If this error persist contact MobileStar"
	e.json().then((resp) => {
		console.log(resp)
		message = resp.errorMsg

		alert("Error Code: "+e.status+" on "+builtUrl+" - "+message)
	})
}

export function buildTheme(){
	let variables = document.querySelector(':root').style

	switch(localStorage.getItem('theme')){
		case "dark":
			//dark theme
			variables.setProperty('--color-scheme', 'none')

			variables.setProperty('--main-background', null)

			variables.setProperty('--subnav-bar-color', null)

			variables.setProperty('--subnav-item-border', null)
			variables.setProperty('--subnav-item-background', null)
			variables.setProperty('--subnav-item-color', null)
			variables.setProperty('--subnav-item-border-active', null)
			variables.setProperty('--subnav-item-background-active', null)
			variables.setProperty('--subnav-item-color-active', null)

			variables.setProperty('--nav-item-color', null)
			variables.setProperty('--nav-item-background-active', null)
			variables.setProperty('--nav-item-color-active', null)

			variables.setProperty('--content-background', null)
			variables.setProperty('--content-border', null)
			variables.setProperty('--content-color', null)
			variables.setProperty('--content-title-background', null)

			variables.setProperty('--content-list-item-background-active', null)

			variables.setProperty('--content-input-background', null)
			variables.setProperty('--content-input-color', null)

			variables.setProperty('--content-dropdown-background', null)
			variables.setProperty('--content-dropdown-color', null)
			variables.setProperty('--content-dropdown-border', null)

			variables.setProperty('--content-section-background', null)

			//inverted :)
			variables.setProperty('--content-date-border', null)
			variables.setProperty('--content-date-color', null)
			variables.setProperty('--content-date-background', null)

			variables.setProperty('--content-button-color', null)
			variables.setProperty('--content-button-border', null)
			variables.setProperty('--content-button-background-active', null)

			variables.setProperty('--overlay-background', null)
			variables.setProperty('--overlay-border', null)
			variables.setProperty('--overlay-color', null)

			variables.setProperty('--overlay-button-background', null)
			variables.setProperty('--overlay-button-border', null)
			variables.setProperty('--overlay-button-color', null)
			variables.setProperty('--overlay-button-background-active', null)
			variables.setProperty('--overlay-button-color-active', null)

			variables.setProperty('--tab-background', null)
			variables.setProperty('--tab-border', null)
			variables.setProperty('--tab-color', null)
			variables.setProperty('--tab-background-active', null)
			variables.setProperty('--tab-border-active', null)
			variables.setProperty('--tab-color-active', null)

			variables.setProperty('--footer-color', null)

			variables.setProperty('--mco-farm-bin-selection-color', null)
			variables.setProperty('--mco-farm-bin-selection-color', null)
			variables.setProperty('--mco-farm-bin-selection-bin-color-active', null)
			variables.setProperty('--mco-farm-bin-selection-bin-background-active', null)

			variables.setProperty('--fdo-mco-color', null)
			variables.setProperty('--fdo-mco-title-border', null)
			break;
		/*case "test":
			//dark theme
			variables.setProperty('--main-background', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--subnav-bar-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--subnav-item-border', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--subnav-item-background', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--subnav-item-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--subnav-item-border-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--subnav-item-background-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--subnav-item-color-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--nav-item-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--nav-item-background-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--nav-item-color-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--content-background', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--content-border', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--content-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--content-title-background', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--content-list-item-background-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--content-input-background', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--content-input-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--content-dropdown-background', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--content-dropdown-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--content-dropdown-border', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--content-section-background', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			//inverted :)
			variables.setProperty('--content-date-border', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--content-date-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--content-date-background', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--content-button-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--content-button-border', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--content-button-background-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--overlay-background', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--overlay-border', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--overlay-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--overlay-button-background', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--overlay-button-border', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--overlay-button-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--overlay-button-background-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--overlay-button-color-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--tab-background', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--tab-border', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--tab-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--tab-background-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--tab-border-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--tab-color-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--footer-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--mco-farm-bin-selection-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--mco-farm-bin-selection-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--mco-farm-bin-selection-bin-color-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--mco-farm-bin-selection-bin-background-active', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")

			variables.setProperty('--fdo-mco-color', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			variables.setProperty('--fdo-mco-title-border', "rgba("+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+", "+Math.floor(Math.random() * 256)+")")
			setTimeout(()=>{buildTheme()}, 100)
			break;*/
		default:
			//light theme
			variables.setProperty('--color-scheme', 'dark')

			variables.setProperty('--main-background', '#fff')

			variables.setProperty('--subnav-bar-color', '#000')

			variables.setProperty('--subnav-item-border', '#000')
			variables.setProperty('--subnav-item-background', '#fff')
			variables.setProperty('--subnav-item-color', '#000')
			variables.setProperty('--subnav-item-border-active', '#fff')
			variables.setProperty('--subnav-item-background-active', '#000')
			variables.setProperty('--subnav-item-color-active', '#fff')

			variables.setProperty('--nav-item-color', '#fff')
			variables.setProperty('--nav-item-background-active', '#fff')
			variables.setProperty('--nav-item-color-active', '#000')

			variables.setProperty('--content-background', '#fff')
			variables.setProperty('--content-border', '#000')
			variables.setProperty('--content-color', '#000')
			variables.setProperty('--content-title-background', '#999')

			variables.setProperty('--content-list-item-background-active', 'rgba(0, 0, 0, .5)')

			variables.setProperty('--content-input-background', '#fff')
			variables.setProperty('--content-input-color', '#000')

			variables.setProperty('--content-dropdown-background', '#fff')
			variables.setProperty('--content-dropdown-color', '#000')
			variables.setProperty('--content-dropdown-border', '#000')

			variables.setProperty('--content-section-background', 'rgba(0, 0, 0, 0)')

			//inverted :)
			variables.setProperty('--content-date-border', '#fff')
			variables.setProperty('--content-date-color', '#fff')
			variables.setProperty('--content-date-background', '#000')

			variables.setProperty('--content-button-color', '#fff')
			variables.setProperty('--content-button-border', '#fff')
			variables.setProperty('--content-button-background-active', '#444')

			variables.setProperty('--overlay-background', '#fff')
			variables.setProperty('--overlay-border', '#000')
			variables.setProperty('--overlay-color', '#000')

			variables.setProperty('--overlay-button-background', '#000')
			variables.setProperty('--overlay-button-border', '#000')
			variables.setProperty('--overlay-button-color', '#fff')
			variables.setProperty('--overlay-button-background-active', '#444')
			variables.setProperty('--overlay-button-color-active', '#fff')

			variables.setProperty('--tab-background', '#fff')
			variables.setProperty('--tab-border', '#000')
			variables.setProperty('--tab-color', '#000')
			variables.setProperty('--tab-background-active', '#000')
			variables.setProperty('--tab-border-active', '#fff')
			variables.setProperty('--tab-color-active', '#fff')

			variables.setProperty('--footer-color', '#fff')

			variables.setProperty('--mco-farm-bin-selection-color', '#fff')
			variables.setProperty('--mco-farm-bin-selection-color', '#fff')
			variables.setProperty('--mco-farm-bin-selection-bin-color-active', '#000')
			variables.setProperty('--mco-farm-bin-selection-bin-background-active', '#fff')

			variables.setProperty('--fdo-mco-color', '#fff')
			variables.setProperty('--fdo-mco-title-border', '#fff')
			break;
	}
}

buildTheme()
