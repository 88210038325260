'use strict';

import {globalCache} from './main';

export class MCO{
    constructor(data){
        this._rawData = data

        this.setAllData(data)
    }

    getAllData(){
        return {
            assignedTo: this.assignedTo,
            bins: this.bins,
            created: this.created,
            customid: this.customid,
            date: this.date,
            farms: this.farms,
            lastUpdate: this.lastUpdate,
            mill: this.mill,
            name: this.name,
            owner: this.owner,
            status: this.status,
            statusTimestamp: this.statusTimestamp,
            uuid: this.uuid
        }
    }

    setAllData(data){
        this.assignedTo = undefined//assigned in FDO class

        this.bins = data.bins

        this.created = data.created
        this.customid = data.customid

        this.date = data.date

        this.farms = []//created later

        this.lastUpdate = data.lastUpdate

        this.mill = data.mill

        this.name = data.name

        this.owner = data.owner

        this.status = data.status
        this.statusTimestamp = data.statusTimestamp

        this.uuid = data.uuid
    }

    getUUID(){
        return this.uuid
    }

    getName(){
        return this.name
    }

    getDate(){
        return this.date
    }

    getOwner(){
        return this.owner
    }

    checkFarmIncludes(uuid){
        return this.farms.includes(uuid)
    }

    addFarm(uuid){
        if(this.farms == undefined){this.farms = []}

        this.farms.push(uuid)
        globalCache.setCacheListItem('mcos', {uuid: this.uuid}, {farms: this.farms})
    }

    getFarms(){
        if(this.farms == undefined){this.farms = []}

        return this.farms
    }

    setFarms(farms){
        if(this.farms == undefined){this.farms = []}

        this.farms = farms
        globalCache.setCacheListItem('mcos', {uuid: this.uuid}, {farms: farms})
    }

    getBins(){
        if(this.bins == undefined){this.bins = []}
        
        return this.bins
    }

    getAssignedTo(){
        return this.assignedTo
    }

    setAssignedTo(uuid){
        this.assignedTo = uuid

        globalCache.setCacheListItem('mcos', {uuid: this.uuid}, {assignedTo: uuid})
    }
}